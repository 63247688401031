import { createContext } from 'react';

import Box from '@/design_system/Box';
import { ShipmentWithRelations } from '@/models/shipment';
import {
  ArticlesCardList,
  ArticlesTable,
} from '@/routes/Shipments/Shipment/components/ArticlesTable/ArticlesTable';
import { useCurrentSession } from '@/services/auth';
import useViewPort from '@/utils/useViewport';

interface ShipmentArticlesContextData {
  showVerificationActions: boolean;
  showVerificationStatus: boolean;
  showPrintArticleSheetActions: boolean;
  showDeleteArticleAction: boolean;
  showStartAnalysisColumn: boolean;
  showActionsColumn: boolean;
  shipment: ShipmentWithRelations;
}

export const ShipmentArticlesContext = createContext({} as ShipmentArticlesContextData);

export const ShipmentArticles = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const { isMobile } = useViewPort();
  const { currentSession } = useCurrentSession();

  const showVerificationActions =
    shipment.step === 'verification' && shipment.canReceptionBeVerifiedBy(currentSession);
  const showVerificationStatus = showVerificationActions || shipment.step === 'validated';
  const showPrintArticleSheetActions = shipment.step === 'preparation';
  const showDeleteArticleAction = shipment.step === 'preparation';
  // Has permission to analyse at least one article that is in analysis step
  const showStartAnalysisColumn = !!shipment.articles.some(
    (shipmentArticle) =>
      currentSession?.hasPermission('analyze_article', {
        workshopId: shipmentArticle.article.workshopId,
      }) && shipmentArticle.article.step?.step === 'analysis'
  );
  const showActionsColumn =
    showVerificationActions ||
    showPrintArticleSheetActions ||
    showDeleteArticleAction ||
    showStartAnalysisColumn;

  const contextValue = {
    showVerificationActions,
    showVerificationStatus,
    showPrintArticleSheetActions,
    showDeleteArticleAction,
    showStartAnalysisColumn,
    showActionsColumn,
    shipment,
  } satisfies ShipmentArticlesContextData;

  return (
    <ShipmentArticlesContext value={contextValue}>
      <Box padding="0" removeStyleOnMobile>
        {isMobile ? (
          <ArticlesCardList shipmentArticles={shipment.articles} />
        ) : (
          <ArticlesTable shipmentArticles={shipment.articles} />
        )}
      </Box>
    </ShipmentArticlesContext>
  );
};

import { useQuery } from '@tanstack/react-query';

import { Endpoints } from '@/api';
import { useFetch } from '@/utils/fetch';
import { Currency } from '@/utils/number';

import { Model } from './model';
import { Organization } from './organization';

export class OrganizationCountry extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  id!: string;

  organizationId!: string;
  country!: string;
  currency!: Currency;

  createdAt!: string;

  get name() {
    if ('organization' in this) {
      return `${(this.organization as Organization).name} ${this.country}`;
    }
  }
}

export const useOrganizationCountries = (params?: { limit?: number; offset?: number }) => {
  const fetch = useFetch<Endpoints['GET /organization-countries']>();

  return useQuery({
    queryKey: ['organizationCountries', params],
    queryFn: () =>
      fetch('/organization-countries', params).then(({ organizationCountries, meta }) => ({
        organizationCountries: organizationCountries.map(
          (organizationCountry) => new OrganizationCountry(organizationCountry)
        ),
        meta,
      })),
  });
};

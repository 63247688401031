import { useState } from 'react';
import { getLocalTimeZone, today } from '@internationalized/date';
import { Trans, useLingui } from '@lingui/react/macro';
import { parseISO } from 'date-fns';
import { differenceInYears } from 'date-fns/differenceInYears';

import BasicRadioGroup, {
  yesNoRadioOptions,
} from '@/design_system/BasicRadioGroup/BasicRadioGroup';
import Box from '@/design_system/Box';
import InputDate from '@/design_system/InputDate';
import Stack from '@/design_system/Stack';
import { useUpdateArticle } from '@/models/article';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import ProofOfPurchase from '@/routes/Requests/Request/Article/ProofOfPurchase';
import useViewPort from '@/utils/useViewport';

const Warranty = () => {
  const { i18n, t } = useLingui();

  const { isMobile } = useViewPort();

  const { request, article, view, errors } = useArticleContext();

  const { mutate: updateArticle } = useUpdateArticle({
    articleId: article.id,
    requestId: request.id,
  });

  const [purchaseDate, setPurchaseDate] = useState(article.purchaseDate ?? null);

  const handleBlurInputDate = () => {
    if (purchaseDate && parseISO(purchaseDate) <= new Date()) {
      if (purchaseDate !== article.purchaseDate) {
        updateArticle({
          data: {
            purchaseDate,
            warranty: differenceInYears(new Date(), parseISO(purchaseDate)) < 2,
          },
        });
      }
    } else {
      updateArticle({
        data: {
          purchaseDate: null,
        },
      });
      setPurchaseDate(null);
    }
  };

  if (!view.warranty.shown || !view.warranty.editable) {
    return null;
  }

  return (
    <Stack gap="0.5rem">
      <h2 className="headline-400-bold">
        <Trans id="requests.new.articles.drawer.warranty.title">Warranty</Trans>
      </h2>

      <Box
        padding={isMobile ? '1rem' : '1rem 1.5rem'}
        gap="1rem"
        style={{
          backgroundColor: 'var(--color-neutral-0)',
        }}
      >
        <Stack gap="1rem">
          <InputDate
            label={t({ id: 'article.form.purchase-date.label', message: 'Purchase date' })}
            value={purchaseDate}
            onChange={(date) => setPurchaseDate(date ?? null)}
            maxValue={today(getLocalTimeZone())}
            style={{ flex: 1 }}
            onBlur={handleBlurInputDate}
            error={
              errors.warranty?.purchaseDate
                ? t({
                    id: 'article.form.purchase-date.error',
                    message: 'The purchase date must be in the past',
                  })
                : undefined
            }
          />
          <ProofOfPurchase />
          <BasicRadioGroup
            label={t({
              id: 'article.form.warranty.label',
              message: 'Is the product still on warranty?',
            })}
            value={article.warranty ? 'yes' : 'no'}
            onChange={(value) =>
              updateArticle({
                data: {
                  warranty: value === 'yes',
                },
              })
            }
            options={yesNoRadioOptions.map((option) => ({
              ...option,
              children: i18n._(option.children),
            }))}
            style={{ flex: 1 }}
          />
        </Stack>
      </Box>
    </Stack>
  );
};

export default Warranty;

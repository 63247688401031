import { Trans, useLingui } from '@lingui/react/macro';

import { RequestOrganizationPrice } from '@/components/RequestOrganizationPrice';
import { RequestWorkshopPrice } from '@/components/RequestWorkshopPrice';
import Box from '@/design_system/Box';
import Stack from '@/design_system/Stack';
import IconArchive from '@/icons/Archive.svg';
import IconClock from '@/icons/Clock.svg';
import IconTask from '@/icons/Task.svg';
import {
  ArchivedArticlesCardList,
  ArchivedArticlesTable,
  CompletedArticlesCardList,
  CompletedArticlesTable,
  OnGoingArticlesCardList,
} from '@/routes/Requests/components/ArticlesTable';
import { OnGoingArticlesTable } from '@/routes/Requests/components/ArticlesTable/OnGoingArticlesTable';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';
import { useCurrentSession } from '@/services/auth';
import { createBEMClasses } from '@/utils/classname';
import useViewPort from '@/utils/useViewport';

import './RequestArticles.css';

const { block, element } = createBEMClasses('request-articles');

export const RequestArticles = () => {
  const { t } = useLingui();
  const { isMobile, isWidescreen } = useViewPort();
  const { currentSession, isWorkshop } = useCurrentSession();

  const {
    request,
    view: { price, cost },
  } = useRequestContext();

  const articlesWithTaskToDo = request.articles.filter((article) =>
    article.task?.userRoles.some(({ permission, scope }) =>
      currentSession?.hasPermission(permission, scope)
    )
  );

  const completedArticles = request.articles.filter((article) => article.completed);

  const otherArticles = request.articles.filter(
    (article) => !articlesWithTaskToDo.includes(article) && !completedArticles.includes(article)
  );

  return (
    <Stack gap="1rem" className={block()}>
      {articlesWithTaskToDo.length > 0 && (
        <Box padding="0">
          <Stack>
            <Stack className={element('pending-tasks')} row>
              <IconTask />
              <span className="paragraph-100-medium">
                <Trans id="request.articles.table.pending-tasks.label">Pending item tasks</Trans>
              </span>
            </Stack>
            {isMobile ? (
              <OnGoingArticlesCardList
                articles={articlesWithTaskToDo}
                mode="task"
                label={t({
                  id: 'request.articles.table.pending-tasks.label',
                  message: 'Pending item tasks',
                })}
              />
            ) : (
              <OnGoingArticlesTable articles={articlesWithTaskToDo} mode="task" />
            )}
          </Stack>
        </Box>
      )}
      {otherArticles.length > 0 && (
        <Box padding="0">
          <Stack>
            <Stack className={element('awaiting-tasks')} row>
              <IconClock />
              <span className="paragraph-100-medium">
                <Trans id="request.articles.table.waiting-tasks.label">Waiting for others</Trans>
              </span>
            </Stack>
            {isMobile ? (
              <OnGoingArticlesCardList
                articles={otherArticles}
                mode="step"
                label={t({
                  id: 'request.articles.table.waiting-tasks.label',
                  message: 'Waiting for others',
                })}
              />
            ) : (
              <OnGoingArticlesTable articles={otherArticles} mode="step" />
            )}
          </Stack>
        </Box>
      )}
      {completedArticles.length > 0 && (
        <Box padding="0">
          <Stack>
            <Stack className={element('completed-articles')} row>
              <IconClock />
              <span className="paragraph-100-medium">
                <Trans id="request.articles.table.completed.label">Completed items</Trans>
              </span>
            </Stack>
            {isMobile ? (
              <CompletedArticlesCardList articles={completedArticles} />
            ) : (
              <CompletedArticlesTable articles={completedArticles} />
            )}
          </Stack>
        </Box>
      )}
      {request.archivedArticles.length > 0 && (
        <Box padding="0">
          <Stack>
            <Stack className={element('archived-articles')} row>
              <IconArchive />
              <span className="paragraph-100-medium">
                <Trans id="request.articles.table.archived-articles.label">Archived items</Trans>
              </span>
            </Stack>
            {isMobile ? (
              <ArchivedArticlesCardList articles={request.archivedArticles} />
            ) : (
              <ArchivedArticlesTable articles={request.archivedArticles} />
            )}
          </Stack>
        </Box>
      )}
      {!isWorkshop && (
        <Stack row={isWidescreen} gap="1rem">
          {cost.enabled && !!request.cost?.amountPerCurrency.length && (
            <Stack style={{ flex: 1 }} gap="1rem">
              {request.cost?.amountPerCurrency.map((amount) => (
                <RequestWorkshopPrice
                  key={amount.currency}
                  currency={amount.currency}
                  priceAggregate={request.cost!}
                  label={
                    request.cost!.amountPerCurrency.length > 1
                      ? `${cost.labelWithoutVATInfo} ${amount.currency}`
                      : cost.labelWithoutVATInfo
                  }
                  articles={request.allArticles}
                />
              ))}
            </Stack>
          )}
          {price.enabled && !!request.price && (
            <div style={{ flex: 1 }}>
              <RequestOrganizationPrice
                price={request.price?.amountPerCurrency[0]}
                label={price.labelWithoutVATInfo}
              />
            </div>
          )}
        </Stack>
      )}
      {isWorkshop && price.enabled && !!request.price && (
        <RequestWorkshopPrice
          currency={request.price.amountPerCurrency[0].currency}
          priceAggregate={request.price}
          label={price.labelWithoutVATInfo}
          articles={request.allArticles}
        />
      )}
    </Stack>
  );
};

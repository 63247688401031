import { Trans } from '@lingui/react/macro';

import Stack from '@/design_system/Stack';
import IconStar from '@/icons/Star.svg';
import { createBEMClasses } from '@/utils/classname';

import './VipBadge.css';

const { block } = createBEMClasses('vip-badge');

const VipBadge = () => (
  <Stack row gap="0.125rem" className={block()} alignItems="center" flexWrap="nowrap">
    <IconStar filled />
    <Trans id="request.total-line.vip">VIP</Trans>
  </Stack>
);

export default VipBadge;

import { useState } from 'react';
import { useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconCheck from '@/icons/Check.svg';
import IconEdit from '@/icons/Edit.svg';
import IconError from '@/icons/Error.svg';
import IconUndo from '@/icons/Undo.svg';
import { ShipmentWithRelations, useVerifyArticle } from '@/models/shipment';
import useViewPort from '@/utils/useViewport';

import ReportIssueDialog from './ReportIssueDialog';

const ArticleReceivedVerificationActionCell = ({
  shipmentArticle,
  shipment,
}: {
  shipmentArticle: ShipmentWithRelations['articles'][number];
  shipment: ShipmentWithRelations;
}) => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();

  const {
    mutateAsync: verifyArticle,
    isPending,
    isSuccess,
  } = useVerifyArticle({ shipmentId: shipment.id, articleId: shipmentArticle.articleId });

  const [isOpenReportIssueDialog, setIsOpenReportIssueDialog] = useState(false);

  const undoVerificationLabel = t({ id: 'article.verification.undo', message: 'Undo' });

  const undoVerification = () =>
    verifyArticle({
      verified: false,
      issue: null,
    });

  const verifyLabel = t({ id: 'article.verification.verify', message: 'Verify' });

  const verify = () =>
    verifyArticle({
      verified: true,
    });

  const reportIssueLabel = t({ id: 'article.verification.report', message: 'Report an issue' });
  const editVerificationLabel = t({ id: 'article.verification.edit', message: 'Edit' });

  if (shipmentArticle.verified) {
    return (
      <>
        {isMobile ? (
          <Stack row gap="0.5rem" style={{ flex: 1 }}>
            {!!shipmentArticle.issue && (
              <Button
                size="medium"
                variant="secondary"
                onPress={() => setIsOpenReportIssueDialog(true)}
                style={{ flex: 1 }}
              >
                <IconEdit />
                {editVerificationLabel}
              </Button>
            )}
            <Button
              size="medium"
              variant="secondary"
              onPress={undoVerification}
              isLoading={isPending || isSuccess}
              style={{ flex: 1 }}
            >
              <IconUndo />
              {undoVerificationLabel}
            </Button>
          </Stack>
        ) : (
          <Stack row gap="0.5rem">
            {!!shipmentArticle.issue && (
              <Button
                size="medium"
                variant="secondary"
                iconOnly
                ariaLabel={editVerificationLabel}
                tooltip={editVerificationLabel}
                onPress={() => setIsOpenReportIssueDialog(true)}
              >
                <IconEdit />
              </Button>
            )}
            <Button
              size="medium"
              variant="secondary"
              iconOnly
              ariaLabel={undoVerificationLabel}
              tooltip={undoVerificationLabel}
              onPress={undoVerification}
              isLoading={isPending || isSuccess}
            >
              <IconUndo />
            </Button>
          </Stack>
        )}
        <ReportIssueDialog
          shipmentArticle={shipmentArticle}
          isOpen={isOpenReportIssueDialog}
          setIsOpen={setIsOpenReportIssueDialog}
        />
      </>
    );
  }

  return (
    <>
      {isMobile ? (
        <Stack row gap="0.5rem" style={{ flex: 1 }}>
          <Button
            size="medium"
            variant="secondary-danger"
            onPress={() => setIsOpenReportIssueDialog(true)}
            style={{ flex: 1 }}
            className="text-ellipsis"
          >
            <IconError />
            {reportIssueLabel}
          </Button>
          <Button
            size="medium"
            variant="secondary"
            onPress={verify}
            isLoading={isPending || isSuccess}
            style={{ flex: 1 }}
          >
            <IconCheck />
            {verifyLabel}
          </Button>
        </Stack>
      ) : (
        <Stack row gap="0.5rem">
          <Button
            size="medium"
            variant="secondary-danger"
            iconOnly
            ariaLabel={reportIssueLabel}
            tooltip={reportIssueLabel}
            onPress={() => setIsOpenReportIssueDialog(true)}
          >
            <IconError />
          </Button>
          <Button
            size="medium"
            variant="secondary"
            iconOnly
            ariaLabel={verifyLabel}
            tooltip={verifyLabel}
            onPress={verify}
            isLoading={isPending || isSuccess}
          >
            <IconCheck />
          </Button>
        </Stack>
      )}
      <ReportIssueDialog
        shipmentArticle={shipmentArticle}
        isOpen={isOpenReportIssueDialog}
        setIsOpen={setIsOpenReportIssueDialog}
      />
    </>
  );
};

export default ArticleReceivedVerificationActionCell;

import { useState } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import Stack from '@/design_system/Stack';
import TextArea from '@/design_system/TextArea';
import IconCancel from '@/icons/Cancel.svg';
import { useRefuseRequalification, useValidateRequalification } from '@/models/article';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';
import useViewPort from '@/utils/useViewport';

import { ArticleActionProps } from './ArticleActions';

export const AcceptRequalificationAction = ({ onActionDone }: ArticleActionProps) => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();

  const { view: requestView } = useRequestContext();
  const { article, view, state, hasError } = useArticleContext();

  const [apiError, setApiError] = useState<string | null>(null);

  const {
    mutateAsync: refuseRequalification,
    isPending: isPendingRefuseRequalification,
    isSuccess: isSuccessRefuseRequalification,
  } = useRefuseRequalification({
    articleId: article.id,
  });

  const {
    mutateAsync: validateRequalification,
    isPending: isPendingValidateRequalification,
    isSuccess: isSuccessValidateRequalification,
  } = useValidateRequalification({
    articleId: article.id,
  });

  const [isOpenRefusalModal, setIsOpenRefusalModal] = useState(false);

  const handleRefuseRequalification = async (reason: string) => {
    await refuseRequalification({ comment: reason });
    setIsOpenRefusalModal(false);
    onActionDone();
  };

  const handleValidateRequalification = async () => {
    const { hasError } = state.errors.check({
      services: {
        actions: {
          actions: {
            missingPhotos: !view.services.defects.shown,
            missingCost: false,
            missingPrice: requestView.price.enabled,
          },
        },
      },
    });
    setApiError(null);

    if (!hasError) {
      try {
        await validateRequalification();
        onActionDone();
      } catch (err: any) {
        console.error(err);
        setApiError(
          (err.message as string) ?? t({ id: '_general.error.unknown', message: 'Unknown error' })
        );
      }
    }
  };

  return (
    <Stack gap="1rem" style={{ flex: 1 }}>
      {hasError && (
        <p className="paragraph-100-medium text-danger">
          <Trans id="article.actions.accept_requalification.errors">
            Please fill the missing information in order to validate or refuse the requalification
          </Trans>
        </p>
      )}
      {apiError && <p className="paragraph-100-medium text-danger">{apiError}</p>}
      <Stack gap="0.5rem" row={!isMobile} style={{ alignSelf: isMobile ? 'stretch' : 'flex-end' }}>
        <Button
          variant="secondary"
          size="medium"
          onPress={() => setIsOpenRefusalModal(true)}
          isLoading={isPendingRefuseRequalification || isSuccessRefuseRequalification}
        >
          <IconCancel />
          <Trans id="article.actions.accept_requalification.refuse">Refuse requalification</Trans>
        </Button>
        <Dialog
          title={t({
            id: 'article.actions.accept_requalification.refuse.modal.title',
            message: 'Refuse requalification',
          })}
          isOpen={isOpenRefusalModal}
          onOpenChange={setIsOpenRefusalModal}
        >
          <RefusalDialogForm
            onCancel={() => setIsOpenRefusalModal(false)}
            onRefuse={handleRefuseRequalification}
            isLoadingRefuse={isPendingRefuseRequalification || isSuccessRefuseRequalification}
          />
        </Dialog>
        <Button
          variant="primary"
          size="medium"
          onPress={handleValidateRequalification}
          isLoading={isPendingValidateRequalification || isSuccessValidateRequalification}
        >
          <Trans id="article.actions.accept_requalification.validate">
            Validate requalification
          </Trans>
        </Button>
      </Stack>
    </Stack>
  );
};

export const RefusalDialogForm = ({
  onCancel,
  onRefuse,
  isLoadingRefuse,
}: {
  onCancel: () => void;
  onRefuse: (reason: string) => Promise<void>;
  isLoadingRefuse: boolean;
}) => {
  const { t } = useLingui();
  const [reason, setReason] = useState('');

  const [showError, setShowError] = useState(false);

  const error = !reason
    ? t({
        id: 'article.actions.accept_requalification.refuse.modal.reason.error',
        message: 'Please provide a reason',
      })
    : undefined;

  const handleRefuse = () => {
    setShowError(true);

    if (error) {
      return;
    }

    onRefuse(reason);
  };

  return (
    <>
      <main>
        <Stack gap="1rem">
          <p className="paragraph-100-regular">
            <Trans id="article.actions.accept_requalification.refuse.modal.text">
              Please specify the refusal reason to the workshop. The workshop will have to send the
              package to another workshop.
            </Trans>
          </p>
          <TextArea
            label={t({
              id: 'article.actions.accept_requalification.refuse.modal.reason.label',
              message: 'Specify the reason for refusal',
            })}
            placeholder={t({
              id: 'article.actions.accept_requalification.refuse.modal.reason.placeholder',
              message: 'Describe the reason...',
            })}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            error={showError ? error : undefined}
          />
        </Stack>
      </main>
      <footer>
        <Button variant="secondary" onPress={onCancel}>
          <Trans id="article.actions.accept_requalification.refuse.modal.cancel">Cancel</Trans>
        </Button>
        <Button variant="danger" onPress={handleRefuse} isLoading={isLoadingRefuse}>
          <Trans id="article.actions.accept_requalification.refuse.modal.refuse">
            Refuse requalification
          </Trans>
        </Button>
      </footer>
    </>
  );
};

import { useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router';
import { Trans, useLingui } from '@lingui/react/macro';

import { MIN_PASSWORD_LENGTH, NewPassword } from '@/components/NewPassword/NewPassword';
import AlertBar from '@/design_system/AlertBar';
import Button from '@/design_system/Button';
import InputText from '@/design_system/InputText/InputText';
import Stack from '@/design_system/Stack/Stack';
import { useRequestPasswordReset, useSetNewPassword } from '@/models/user';

const ForgottenPassword = () => {
  const { t } = useLingui();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const token = queryParams.get('token') as string;

  const { mutateAsync: requestReset, isPending: isRequesting } = useRequestPasswordReset();
  const { mutateAsync: setNewPassword, isPending: isSetting } = useSetNewPassword();

  const [email, setEmail] = useState((queryParams.get('email') as string) ?? '');
  const [password, setPassword] = useState('');

  // Value will be -1 until PasswordStrengthBar is lazy-loaded
  const [passwordScore, setPasswordScore] = useState(-1);

  const [error, setError] = useState('');
  const [successText, setSuccessText] = useState('');
  const [successTitle, setSuccessTitle] = useState('');

  const onSubmit = async () => {
    setError('');

    if (token) {
      if (!password) {
        setError(
          t({
            id: 'forgotten-password.error.missing-password',
            message: 'Password is required',
          })
        );

        return;
      }

      if (password.length < MIN_PASSWORD_LENGTH) {
        setError(
          t({
            id: 'new-password.complexity.min-chars',
            message: `The password must be at least ${MIN_PASSWORD_LENGTH} characters long`,
          })
        );

        return;
      }

      if (passwordScore < 1) {
        setError(
          t({
            id: 'new-password.complexity.too-weak',
            message: 'The complexity of this password is too weak',
          })
        );

        return;
      }

      await setNewPassword({
        email,
        token,
        newPassword: password,
      })
        .then(() => {
          setSuccessTitle(t({ id: 'forgotten-password.success.title', message: 'Success' }));
          setSuccessText(
            t({
              id: 'forgotten-password.success.text',
              message: 'Your password has been reset. You can now log in.',
            })
          );
        })
        .catch((err: any) => {
          console.log(err);
          setError(
            (err.message as string) ?? t({ id: '_general.error.unknown', message: 'Unknown error' })
          );
        });
    } else {
      await requestReset(email)
        .then(() => {
          setSuccessTitle(t({ id: 'forgotten-password.sent.title', message: 'Request sent' }));
          setSuccessText(
            t({
              id: 'forgotten-password.sent.text',
              message: 'Check your email to reset your password.',
            })
          );
        })
        .catch((err: any) => {
          console.log(err);
          setError(
            (err.message as string) ?? t({ id: '_general.error.unknown', message: 'Unknown error' })
          );
        });
    }
  };

  if (successText) {
    return (
      <Stack gap="1.5rem" alignItems="center">
        <AlertBar size="large" title={successTitle} type="success">
          {successText}
        </AlertBar>
        {token && (
          <Link className="text-link" to="/login">
            <Trans id="forgotten-password.go-to-login">Go to Login</Trans>
          </Link>
        )}
      </Stack>
    );
  }

  if (token) {
    return (
      <Stack gap="1.5rem">
        <NewPassword
          password={password}
          setPassword={(password) => {
            setPassword(password);
            setError('');
          }}
          setStrengthScore={setPasswordScore}
          messageType={error ? 'error' : undefined}
          messageText={error}
        />
        <Stack row justifyContent="space-between">
          <Button
            type="submit"
            isLoading={isSetting}
            disabled={passwordScore === -1 || isSetting}
            onPress={onSubmit}
          >
            <Trans id="forgotten-password.new-password.submit">Set New Password</Trans>
          </Button>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack gap="1.5rem">
      <InputText
        isRequired
        name="email"
        label={<Trans id="forgotten-password.email.label">Email</Trans>}
        type="email"
        placeholder={t({
          id: 'forgotten-password.email.placeholder',
          message: 'Enter your email address...',
        })}
        value={email}
        onChange={setEmail}
        messageType={error ? 'error' : undefined}
        messageText={error}
      />
      <Stack row justifyContent="space-between">
        <Button type="button" variant="secondary" to="/login">
          <Trans id="forgotten-password.email.cancel">Cancel</Trans>
        </Button>
        <Button type="submit" isLoading={isRequesting} disabled={isRequesting} onPress={onSubmit}>
          <Trans id="forgotten-password.email.submit">Request Password Reset</Trans>
        </Button>
      </Stack>
    </Stack>
  );
};

export default ForgottenPassword;

import { useCallback, useState } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';

import Box from '@/design_system/Box';
import InputSearch from '@/design_system/InputSearch';
import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import { PaginationFooter } from '@/design_system/Pagination/Pagination';
import Stack from '@/design_system/Stack';
import { Body, Cell, Column, Header, Row, Table } from '@/design_system/Table/Table';
import { Store, useStores } from '@/models/store';
import { useUsers } from '@/models/user';
import { useWorkshops, Workshop } from '@/models/workshop';
import { useCurrentSession } from '@/services/auth';
import { ErrorBoundary } from '@/services/sentry';
import useDebouncedState from '@/utils/useDebouncedState';

import { InviteUser } from './InviteUser';
import { RemoveUser } from './RemoveUser';
import { UpdateUserRoles } from './UpdateUserRoles';

const USERS_PER_PAGE = 10;

export const Members = () => {
  const { t } = useLingui();
  const { currentSession } = useCurrentSession();
  const [page, setPage] = useState(1);
  const resetPage = useCallback(() => setPage(1), [setPage]);

  const [searchWorkshop, debouncedSearchWorkshop, setSearchWorkshop] = useDebouncedState(
    '',
    300,
    resetPage
  );
  const [workshop, setWorkshop] = useState<Workshop | null>(null);
  const { data: { workshops } = {}, isFetching: isFetchingWorkshops } = useWorkshops({
    internal: true,
    search: debouncedSearchWorkshop,
  });

  const [searchStore, debouncedSearchStore, setSearchStore] = useDebouncedState('', 300, resetPage);
  const [store, setStore] = useState<Store | null>(null);
  const { data: { stores } = {}, isFetching: isFetchingStores } = useStores({
    internal: true,
    search: debouncedSearchStore,
  });

  const [search, debouncedSearch, setSearch] = useDebouncedState('', 300, resetPage);
  const { data: { users, meta } = {}, isFetching: isFetchingUsers } = useUsers({
    limit: USERS_PER_PAGE,
    offset: (page - 1) * USERS_PER_PAGE,
    search: debouncedSearch,
    workshopId: workshop?.id,
    storeId: store?.id,
  });

  return (
    <Box padding="24px" gap="24px">
      <Stack row alignItems="center" justifyContent="space-between">
        <h2 className="headline-200-bold">
          <Trans id="settings.users.title">Organization members</Trans>
        </h2>
        <InviteUser />
      </Stack>
      <Stack row alignItems="center" gap="1rem">
        <ErrorBoundary>
          <InputSearch
            placeholder={t({
              id: 'settings.users.search.placeholder',
              message: 'Search by name or email',
            })}
            style={{ flex: 1, minWidth: 175, maxWidth: 500 }}
            size="medium"
            value={search}
            onChange={setSearch}
            isLoading={isFetchingUsers}
          />
          <div style={{ width: '200px' }}>
            <InputSelect
              aria-label={t({
                id: 'settings.users.filter.workshop',
                message: 'Filter by workshop',
              })}
              placeholder={t({
                id: 'settings.users.filter.workshop',
                message: 'Filter by workshop',
              })}
              filterOption={null}
              inputValue={searchWorkshop}
              onInputChange={setSearchWorkshop}
              isLoading={isFetchingWorkshops}
              value={workshop}
              onChange={(newWorkshop) => {
                setWorkshop(newWorkshop?.id !== workshop?.id ? newWorkshop : null);
                resetPage();
              }}
              options={workshops}
              getOptionValue={(workshop) => workshop.id}
              getOptionLabel={(workshop) => workshop.name}
            />
          </div>
          <div style={{ width: '200px' }}>
            <InputSelect
              aria-label={t({ id: 'settings.users.filter.store', message: 'Filter by store' })}
              placeholder={t({ id: 'settings.users.filter.store', message: 'Filter by store' })}
              filterOption={null}
              inputValue={searchStore}
              onInputChange={setSearchStore}
              isLoading={isFetchingStores}
              value={store}
              onChange={(newStore) => {
                setStore(newStore?.id !== store?.id ? newStore : null);
                resetPage();
              }}
              options={stores}
              getOptionValue={(store) => store.id}
              getOptionLabel={(store) => store.name}
            />
          </div>
        </ErrorBoundary>
      </Stack>

      <Table
        ariaLabel={t({ id: 'settings.users.table.label', message: 'Membres' })}
        columnWidths="minmax(200px, 2fr) minmax(200px, 2fr) minmax(200px, 2fr) auto"
      >
        <Header>
          <Row>
            <Column>
              <Trans id="settings.users.column.name">Name</Trans>
            </Column>
            <Column>
              <Trans id="settings.users.column.email">Email</Trans>
            </Column>
            <Column>
              <Trans id="settings.users.column.roles">Roles</Trans>
            </Column>
            <Column
              ariaLabel={t({
                id: 'settings.users.column.actions',
                message: 'Actions',
              })}
            />
          </Row>
        </Header>
        <Body>
          {users?.map((user) => (
            <Row key={user.id}>
              <Cell className="sentry-mask">
                <b>{user.name}</b>
              </Cell>
              <Cell className="sentry-mask">{user.email}</Cell>
              <Cell>
                <Stack gap="0.25rem">
                  {user.roles
                    .map((userRole) => {
                      if (userRole.store) {
                        return `${userRole.role.name} (${userRole.store.name})`;
                      }

                      if (userRole.workshop) {
                        return `${userRole.role.name} (${userRole.workshop.name})`;
                      }

                      return userRole.role.name;
                    })
                    .map((label) => (
                      <div key={label}>{label}</div>
                    ))}
                </Stack>
              </Cell>
              <Cell>
                <Stack row gap="4px">
                  <UpdateUserRoles user={user} />
                  {currentSession?.id !== user.id && <RemoveUser user={user} />}
                </Stack>
              </Cell>
            </Row>
          ))}
        </Body>
        <PaginationFooter
          page={page}
          itemsPerPage={USERS_PER_PAGE}
          count={meta?.count}
          onPageChange={setPage}
        />
      </Table>
    </Box>
  );
};

import { isValidElement, ReactNode } from 'react';
import { MessageDescriptor } from '@lingui/core';
import { useLingui } from '@lingui/react/macro';

import Stack from '@/design_system/Stack';
import IconInfo from '@/icons/Info.svg';
import { useGetArticleName } from '@/models/article';
import { createBEMClasses } from '@/utils/classname';
import { formatDate } from '@/utils/date';

import './BaseActivity.css';

const { block, element } = createBEMClasses('activity');

export const BaseActivity = ({
  createdAt,
  creatorName,
  message,
  icon = <IconInfo />,
  type = 'neutral',
  children,
}: {
  createdAt: string;
  creatorName: string | MessageDescriptor;
  message: ReactNode | MessageDescriptor;
  icon?: ReactNode;
  type?: 'neutral' | 'primary' | 'secondary' | 'warning' | 'danger';
  children?: ReactNode;
}) => {
  const { i18n } = useLingui();

  return (
    <Stack className={block()}>
      <div className={element('line')}></div>
      <Stack className={element('content')}>
        <div className={element('icon', { type })}>{icon}</div>
        <div>
          <span className="paragraph-100-medium sentry-mask">
            {typeof creatorName === 'string' ? creatorName : i18n._(creatorName)}
          </span>{' '}
          {isValidElement(message) ? (
            message
          ) : (
            <BaseActivityText>{i18n._(message as MessageDescriptor)}</BaseActivityText>
          )}
          <span
            className="paragraph-100-regular text-disabled"
            title={formatDate(new Date(createdAt), { dateStyle: 'full', timeStyle: 'short' })}
          >
            {' '}
            • {formatDate(new Date(createdAt), { timeStyle: 'short' })}
          </span>
        </div>
      </Stack>
      <Stack row className={element('detailed-content')}>
        {children}
      </Stack>
    </Stack>
  );
};
export const BaseActivityText = ({ children }: { children: ReactNode }) => {
  return <span className="paragraph-100-regular text-secondary">{children}</span>;
};

export const BaseActivityDetailedContent = ({ children }: { children: ReactNode }) => {
  return <div className="paragraph-100-regular activity__comment-container">{children}</div>;
};

type ActivityWithArticle = {
  article: {
    product?: {
      name?: string | null;
      productL2?: string | null;
      productL3?: string | null;
      data?: { color?: string | null } | null;
    } | null;
    productL1?: string | null;
    productL2?: string | null;
    productL3?: string | null;
    data?: { color?: string | null; size?: string | null } | null;
  };
};

export const BaseActivityArticlesNames = ({
  activities,
  displayArticleName,
}: {
  activities: ActivityWithArticle[];
  displayArticleName: boolean;
}) => {
  const { t } = useLingui();
  const getArticleName = useGetArticleName();
  const articleNames = activities.map(
    (activity) =>
      // @ts-expect-error -- useArticleName is currently typed with very wide types, they should be refined
      getArticleName(activity.article) ??
      t({
        id: 'activities.article_without_name',
        message: 'An item',
      })
  );

  return displayArticleName ? (
    <span className="paragraph-100-medium"> {articleNames.sort().join(', ')} </span>
  ) : null;
};

export const BaseActivityArticleName = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityWithArticle;
  displayArticleName: boolean;
}) => {
  return (
    <BaseActivityArticlesNames activities={[activity]} displayArticleName={displayArticleName} />
  );
};

import { useEffect } from 'react';
import { BlockerFunction, useBlocker, useLocation, useNavigate } from 'react-router';

export const useShowWarningBeforeLeavingApp = () => {
  useEffect(() => {
    const handleUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
    };
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);
};

const blockerFunction: BlockerFunction = ({ currentLocation, nextLocation }) =>
  currentLocation.pathname !== nextLocation.pathname && !nextLocation.state?.skipRouterBlocker;

export const useSimpleBlocker = () => useBlocker(blockerFunction);

export const useResetSimpleBlocker = () => {
  const { pathname, state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (state?.skipRouterBlocker) {
      navigate(pathname, { state: { ...state, skipRouterBlocker: false }, replace: true });
    }
  }, [navigate, pathname, state]);
};

import { Link } from 'react-router';
import { Plural, Trans } from '@lingui/react/macro';

import ContactDetails from '@/components/ContactDetails';
import AlertBar from '@/design_system/AlertBar';
import Stack from '@/design_system/Stack';
import { ClientRequestWithRelations } from '@/models/request';
import IconSuccess from '@/routes/Brand/Requests/Request/components/shared/IconSuccess';
import { useCurrentOrganization } from '@/services/auth';
import { createBEMClasses } from '@/utils/classname';

import './Refused.css';

const { block } = createBEMClasses('client-request-refused');

const Refused = ({ request }: { request: ClientRequestWithRelations }) => {
  const [organization] = useCurrentOrganization();

  const isViaStore = !!request.storeId;

  return (
    <div className={block()}>
      <Stack gap="1rem">
        <Stack row justifyContent="center" gap="0.5rem" alignItems="center">
          <IconSuccess />
          <p className="headline-200-bold text-center">
            <Trans id="client.request.refusal.refused.title">
              Your care & repair service has been cancelled
            </Trans>
          </p>
        </Stack>
        {isViaStore && (
          <>
            <p className="paragraph-50-regular text-center">
              <Trans id="client.request.refusal.refused.store-pickup">
                We understand your decision. You can now pick your{' '}
                <Plural value={request.articles.length} one="item" other="items" /> back at the{' '}
                <b>{request.store?.name}</b> store.
              </Trans>
            </p>
            <div>
              <AlertBar type="info" hideIcon size="large">
                <ContactDetails
                  type="store"
                  name={request.store?.name}
                  email={request.store?.address.contactEmail}
                  address={request.store?.address}
                  phone={request.store?.phone}
                />
              </AlertBar>
            </div>
          </>
        )}
        {!isViaStore && (
          <p className="paragraph-50-regular">
            <Trans id="client.request.refusal.refused.new">
              We understand your decision. Please feel free to request a{' '}
              <Link to={`/brand/${organization?.slug}/requests/new`}>new evaluation.</Link>
            </Trans>
          </p>
        )}
      </Stack>
    </div>
  );
};

export default Refused;

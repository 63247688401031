import { Trans, useLingui } from '@lingui/react/macro';

import Box from '@/design_system/Box';
import Stack from '@/design_system/Stack';
import { Body, Cell, Column, Header, Row, Table } from '@/design_system/Table/Table';
import { useActionTypeOrganizationWorkshops } from '@/models/actionType';
import { formatCurrency } from '@/utils/number';

const ActionTypesWorkshop = () => {
  const { t } = useLingui();

  const { data: { actionTypeOrganizationWorkshops } = {} } = useActionTypeOrganizationWorkshops({
    limit: 500,
  });

  return (
    <Stack gap="40px">
      <Box padding="24px" gap="24px">
        <h2 className="headline-200-bold">
          <Trans id="settings.action-types-workshop">Prices</Trans>
        </h2>
        <Table
          ariaLabel={t({ id: 'settings.action-types-workshop.table.label', message: 'Prices' })}
          columnWidths="100px 70px 400px 150px 300px 150px"
        >
          <Header>
            <Row>
              <Column>
                <Trans id="settings.action-types.column.organization">Organization</Trans>
              </Column>
              <Column>
                <Trans id="settings.action-types.column.price-without-tax">Price (w/o tax)</Trans>
              </Column>
              <Column>
                <Trans id="settings.action-types.column.name">Name (en/fr)</Trans>
              </Column>
              <Column>
                <Trans id="settings.action-types.column.productLevel1">Product family</Trans>
              </Column>
              <Column>
                <Trans id="settings.action-types.column.productLevel2">Product type</Trans>
              </Column>
              <Column>
                <Trans id="settings.action-types.column.productLevel3">Product Material</Trans>
              </Column>
            </Row>
          </Header>
          <Body>
            {actionTypeOrganizationWorkshops
              ?.filter(({ actionTypeOrganization }) => !!actionTypeOrganization)
              .map((actionTypeOrganizationWorkshop) => (
                <Row key={actionTypeOrganizationWorkshop.actionTypeOrganizationId}>
                  <Cell>
                    {actionTypeOrganizationWorkshop.actionTypeOrganization!.organization.name}
                  </Cell>
                  <Cell>
                    {formatCurrency(
                      actionTypeOrganizationWorkshop.amountBeforeTax,
                      actionTypeOrganizationWorkshop.currency
                    )}
                  </Cell>
                  <Cell>
                    {actionTypeOrganizationWorkshop.actionTypeOrganization!.actionType.name.en}
                    <br />
                    {actionTypeOrganizationWorkshop.actionTypeOrganization!.actionType.name.fr}
                  </Cell>
                  <Cell>
                    {actionTypeOrganizationWorkshop.actionTypeOrganization!.productL1.join(', ')}
                  </Cell>
                  <Cell>
                    {actionTypeOrganizationWorkshop.actionTypeOrganization!.productL2.join(', ')}
                  </Cell>
                  <Cell>
                    {actionTypeOrganizationWorkshop.actionTypeOrganization!.productL3.join(', ')}
                  </Cell>
                </Row>
              ))}
          </Body>
        </Table>
      </Box>
    </Stack>
  );
};

export default ActionTypesWorkshop;

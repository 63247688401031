import { CSSProperties, useState } from 'react';
import {
  Button as AriaButton,
  DateInput,
  DatePicker,
  DateSegment,
  Dialog,
  Group,
  Label,
  Popover,
} from 'react-aria-components';
import { CalendarDate, parseDate } from '@internationalized/date';

import Hint from '@/design_system/Hint';
import Message from '@/design_system/Message';
import IconCalendar from '@/icons/Calendar.svg';
import { createBEMClasses } from '@/utils/classname';

import { Calendar } from './Calendar';

import './InputDate.css';

const { block, element } = createBEMClasses('input-date');

export type CommonDateProps = {
  /**
   * The InputDate is intended to select whole days, without specifying a particular time of the day.
   * Therefore, `value` is a string with the format YYYY-MM-DD
   */
  value?: string | null;
  onChange?: (value: string | undefined) => void;
};

export type CommonDateFilterProps = {
  /**
   * `minValue`, `maxValue` and `isDateUnavailable` use the format `CalendarDate` from react-aria
   */
  minValue?: CalendarDate;
  maxValue?: CalendarDate;
  isDateUnavailable?: (date: CalendarDate) => boolean;
};

export type InputDateProps = CommonDateProps &
  CommonDateFilterProps & {
    label?: React.ReactNode;
    ariaLabel?: string;
    hintText?: string;
    messageType?: 'error' | 'success' | 'info';
    messageText?: string;

    size?: 'small' | 'medium' | 'large';
    variant?: 'default' | 'brand';
    isDisabled?: boolean;
    className?: string;
    style?: CSSProperties;
    onBlur?: () => void;
    error?: string;
  };

export const dateStringToCalendarDate = (value: string | undefined | null) => {
  let date: CalendarDate | undefined = undefined;

  if (value) {
    try {
      date = parseDate(value.slice(0, 10));
    } catch (e) {
      console.error(e);
    }
  }

  return date;
};

const InputDate = ({
  value,
  onChange,
  minValue,
  maxValue,
  isDateUnavailable,
  label,
  ariaLabel,
  hintText,
  messageType,
  messageText,
  size = 'medium',
  variant = 'default',
  isDisabled,
  className,
  style,
  onBlur,
  error,
}: InputDateProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DatePicker
      value={dateStringToCalendarDate(value) ?? null}
      onChange={(value) => onChange?.(value?.toString())}
      minValue={minValue}
      maxValue={maxValue}
      isDateUnavailable={
        isDateUnavailable ? (date) => isDateUnavailable(date as CalendarDate) : undefined
      }
      granularity="day"
      isDisabled={isDisabled}
      aria-label={ariaLabel}
      className={block({ size, variant }, className)}
      style={style}
      onBlur={onBlur}
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      onFocusChange={(isFocused) => (isFocused ? setIsOpen(true) : undefined)}
    >
      {label && (
        <Label className={size === 'large' ? 'paragraph-50-medium' : 'label-100'}>{label}</Label>
      )}
      <Group className={element('input')}>
        <DateInput className={element('input__date')}>
          {(segment) => (
            <DateSegment segment={segment} className={element('input__date__segment')} />
          )}
        </DateInput>
        <AriaButton isDisabled={isDisabled}>
          <IconCalendar />
        </AriaButton>
      </Group>
      {!!error && <Message type="error">{error}</Message>}
      {hintText && <Hint>{hintText}</Hint>}
      {messageText && <Message type={messageType}>{messageText}</Message>}
      <Popover placement="bottom">
        <Dialog>
          <Calendar embedded variant={variant} size={size === 'large' ? 'large' : 'medium'} />
        </Dialog>
      </Popover>
    </DatePicker>
  );
};

export default InputDate;

import { Trans, useLingui } from '@lingui/react/macro';

import IconPackageIssue from '@/icons/PackageIssue.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import type { TNotificationOfType } from '@/models/notification';

export const ShipmentIssueReportedNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'shipment_issue_reported'>;
}) => {
  const { t } = useLingui();

  return (
    <BaseNotification
      icon={<IconPackageIssue />}
      type="warning"
      notification={notification}
      notificationLabel={t({
        id: 'notification.shipment-issue-reported.label',
        message: `Issue reported for shipment ${notification.shipment.reference}`,
      })}
      notificationHeader={
        <Trans id="notification.shipment-issue-reported">
          An issue has been reported during the verification of the shipment{' '}
          <span className="paragraph-100-medium">{notification.shipment.reference}</span>
        </Trans>
      }
    />
  );
};

import { useLingui } from '@lingui/react/macro';

import './ProgressBar.css';

export const ProgressBar = ({ progress }: { progress: number }) => {
  const { t } = useLingui();

  return (
    <div
      className="client__progress-bar"
      aria-label={t({
        id: 'client.progress-bar.label',
        message: `Progress: ${progress}% complete`,
      })}
    >
      <div className="client__progress-bar__fill" style={{ width: `${progress}%` }} />
    </div>
  );
};

import { Trans } from '@lingui/react/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconPackageValidation from '@/icons/PackageValidation.svg';

export const ShipmentArticleVerifiedActivity = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityOfType<'shipment_article_verified'>;
  displayArticleName: boolean;
}) => {
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="neutral"
      icon={<IconPackageValidation />}
      creatorName={activity.creator?.name ?? ''}
      message={
        <>
          <BaseActivityText>
            <Trans id="activities.shipment_article_verified.message">verified the item</Trans>
          </BaseActivityText>
          <BaseActivityArticleName activity={activity} displayArticleName={displayArticleName} />
        </>
      }
    />
  );
};

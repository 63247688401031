import { lazy, Suspense } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';

import InputText from '@/design_system/InputText';
import Stack from '@/design_system/Stack';

const PasswordStrengthBar = lazy(() => import('react-password-strength-bar'));

export const MIN_PASSWORD_LENGTH = import.meta.env.DEV ? 4 : 12;

export const NewPassword = ({
  password,
  setPassword,
  setStrengthScore,
  messageType,
  messageText,
}: {
  password: string;
  setPassword: (password: string) => void;
  setStrengthScore: (score: number) => void;
  messageType?: 'error' | 'success' | 'info';
  messageText?: string;
}) => {
  const { t } = useLingui();

  return (
    <Stack gap="4px">
      <InputText
        isRequired
        name="password"
        label={<Trans id="new-password.label">New password</Trans>}
        type="password"
        placeholder={t({ id: 'new-password.placeholder', message: 'Enter your new password...' })}
        autoComplete="new-password"
        value={password}
        onChange={setPassword}
        messageType={messageType}
        messageText={messageText}
      />
      <Suspense>
        <PasswordStrengthBar
          scoreWordClassName="paragraph-100-regular"
          minLength={MIN_PASSWORD_LENGTH}
          password={password}
          onChangeScore={setStrengthScore}
          shortScoreWord={
            <Trans id="new-password.complexity.min-chars">
              The password must be at least {MIN_PASSWORD_LENGTH} characters long
            </Trans>
          }
          scoreWords={[
            <Trans key={0} id="new-password.complexity.too-weak">
              The complexity of this password is too weak
            </Trans>,
            <Trans key={1} id="new-password.complexity.weak">
              The complexity of this password is weak
            </Trans>,
            <Trans key={2} id="new-password.complexity.okay">
              The complexity of this password is okay
            </Trans>,
            <Trans key={3} id="new-password.complexity.good">
              The complexity of this password is good
            </Trans>,
            <Trans key={4} id="new-password.complexity.strong">
              The complexity of this password is strong
            </Trans>,
          ]}
        />
      </Suspense>
    </Stack>
  );
};

import { Trans } from '@lingui/react/macro';

import ArticlePhoto from '@/components/ArticlePhoto';
import Stack from '@/design_system/Stack';
import { useArticleName } from '@/models/article';
import { ArticleWithRelations } from '@/models/shipment';
import { createBEMClasses } from '@/utils/classname';
import { formatWeight } from '@/utils/number';

import './ArticleNameCell.css';

const { block } = createBEMClasses('article-name-cell');

export const ArticleNameCell = ({
  article,
  photoSize,
  showRequestDetails = false,
}: {
  article: ArticleWithRelations;
  photoSize: 'small' | 'medium' | 'large';
  showRequestDetails?: boolean;
}) => {
  const articleName = useArticleName({ article });

  return (
    <Stack className={block()} ariaLabel={articleName}>
      <ArticlePhoto photo={article.articlePhoto} size={photoSize} />
      <div style={{ overflow: 'hidden' }}>
        <p className="paragraph-100-medium text-ellipsis">{articleName}</p>

        {article.product?.data.reference && (
          <p className="paragraph-200-regular text-secondary">
            <Trans id="article.reference.short">Ref:</Trans> {article.product.data.reference}
            {article.product.data.weight && ` • ${formatWeight(article.product.data.weight)}`}
          </p>
        )}

        {showRequestDetails && article.request && (
          <p className="paragraph-200-regular text-secondary">
            [{article.request.reference}] {article.request.client?.name}
          </p>
        )}
      </div>
    </Stack>
  );
};

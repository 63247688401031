import { Trans, useLingui } from '@lingui/react/macro';

import IconPackage from '@/icons/Package.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import type { TNotificationOfType } from '@/models/notification';

export const ShipmentToCreateNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'shipment_to_create'>;
}) => {
  const { t } = useLingui();

  const requestReference = notification.request.reference;
  const workshopName = notification.data?.onBehalfOfWorkshopName;

  return (
    <BaseNotification
      notificationLabel={
        workshopName
          ? t({
              id: 'notification.shipment-to-create.label.on-behalf',
              message: `New shipment to create on behalf of workshop ${workshopName} for request ${requestReference}`,
            })
          : t({
              id: 'notification.shipment-to-create.label',
              message: `New shipment to create for request ${requestReference}`,
            })
      }
      icon={<IconPackage />}
      type="new"
      notification={notification}
      notificationHeader={
        workshopName ? (
          <Trans id="notification.shipment-to-create.on-behalf">
            <span className="paragraph-100-medium">You</span> have a new shipment to create on
            behalf of workshop <span className="paragraph-100-medium">{workshopName}</span>
          </Trans>
        ) : (
          <Trans id="notification.shipment-to-create">
            <span className="paragraph-100-medium">You</span> have a new shipment to create
          </Trans>
        )
      }
    />
  );
};

import { Trans } from '@lingui/react/macro';

import Stack from '@/design_system/Stack';
import IconPayment from '@/icons/Payment.svg';
import { BrandWrapper, BrandWrapperCenter } from '@/layouts/Brand';
import { ClientRequestWithRelations } from '@/models/request';
import ClientArticlesTable from '@/routes/Brand/Requests/Request/components/shared/ClientArticlesTable';
import ClientInfo from '@/routes/Brand/Requests/Request/components/shared/ClientInfo';
import ClientStepper from '@/routes/Brand/Requests/Request/components/shared/ClientStepper';

const Payment = ({ request }: { request: ClientRequestWithRelations }) => {
  return (
    <BrandWrapper>
      <BrandWrapperCenter>
        <Stack gap="1.5rem">
          <ClientStepper request={request} />
          <Stack row gap="0.5rem">
            <IconPayment className="color-tertiary-700" style={{ fontSize: '24px' }} />
            <p className="paragraph-50-regular paragraph-100-regular-mobile">
              <Trans id="store.request.payment.text">
                The client has been notified to proceed to the payment.
              </Trans>
            </p>
          </Stack>
          <Stack gap="1rem">
            <ClientArticlesTable request={request} showPrice showArticleComment />
            <ClientInfo request={request} />
          </Stack>
        </Stack>
      </BrandWrapperCenter>
    </BrandWrapper>
  );
};

export default Payment;

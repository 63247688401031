import { CSSProperties, PropsWithChildren } from 'react';
import {
  Dialog as AriaDialog,
  DialogTrigger,
  Heading,
  Modal,
  ModalOverlay,
} from 'react-aria-components';
import { useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import CloseIcon from '@/icons/Cross.svg';
import { createBEMClasses } from '@/utils/classname';
import useViewPort from '@/utils/useViewport';
import { useWebkitVirtualKeyboardHeight } from '@/utils/useWebkitVirtualKeyboardHeight';

import './Dialog.css';

const { block, element } = createBEMClasses('dialog');

interface DialogWrapperProps {
  isOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  trigger?: React.ReactNode;
  dismissable?: boolean;
}

export const DialogWrapper = ({
  isOpen,
  onOpenChange,
  trigger,
  children,
  dismissable = true,
}: PropsWithChildren<DialogWrapperProps>) => {
  const webkitVirtualKeyboardHeight = useWebkitVirtualKeyboardHeight();

  if (trigger) {
    return (
      <DialogTrigger isOpen={isOpen} onOpenChange={onOpenChange}>
        {trigger}
        <ModalOverlay
          className={element('overlay')}
          isDismissable={dismissable}
          isKeyboardDismissDisabled={dismissable}
          style={{
            marginBottom: `${webkitVirtualKeyboardHeight}px`,
          }}
        >
          {children}
        </ModalOverlay>
      </DialogTrigger>
    );
  }

  return (
    <ModalOverlay
      className={element('overlay')}
      isDismissable={dismissable}
      isKeyboardDismissDisabled={dismissable}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    >
      {children}
    </ModalOverlay>
  );
};

interface DialogProps extends DialogWrapperProps {
  title?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  dismissable?: boolean;
  fullHeight?: boolean;
}

const Dialog = ({
  isOpen,
  onOpenChange,
  children,
  title,
  trigger,
  className,
  style,
  dismissable = true,
  fullHeight,
}: DialogProps) => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();

  return (
    <DialogWrapper
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      trigger={trigger}
      dismissable={dismissable}
    >
      <Modal
        className={block({ 'full-height': fullHeight }, className)}
        style={{
          ...style,
          width: isMobile ? undefined : style?.width,
          maxWidth: isMobile ? undefined : style?.maxWidth,
        }}
      >
        <AriaDialog>
          {({ close }) => (
            <>
              <header>
                <Heading slot="title" className="headline-300-bold text-primary">
                  {title}
                </Heading>
                <Button
                  className={element('close-button')}
                  onPress={close}
                  ariaLabel={t({ id: 'design-system.dialog.close', message: 'Close modal' })}
                  iconOnly
                  size="medium"
                  variant="neutral"
                >
                  <CloseIcon className={element('close-icon')} />
                </Button>
              </header>
              {children}
            </>
          )}
        </AriaDialog>
      </Modal>
    </DialogWrapper>
  );
};

export default Dialog;

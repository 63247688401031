import { Trans } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import useViewPort from '@/utils/useViewport';

export const StartAnalysis = ({
  requestId,
  articleId,
}: {
  requestId: string;
  articleId: string;
}) => {
  const { isMobile } = useViewPort();

  return (
    <Button
      variant="primary"
      size="medium"
      to={`/requests/${requestId}/articles/${articleId}`}
      style={isMobile ? { flex: 1 } : undefined}
    >
      <Trans id="shipment.article.start-analysis">Start the analysis</Trans>
    </Button>
  );
};

import { Trans } from '@lingui/react/macro';

import RefashionLogo from '@/components/RefashionLogo';
import Button from '@/design_system/Button';
import Tooltip from '@/design_system/Tooltip';
import IconBandage from '@/icons/Bandage.svg';
import IconCross from '@/icons/Cross.svg';
import IconInfo from '@/icons/Info.svg';
import IconTask from '@/icons/Task.svg';
import { getGroupedActions, useGetActionName } from '@/models/actionType';
import { getGroupedDefects, useGetDefectName } from '@/models/defectType';
import { ClientArticleWithRelations } from '@/models/request';
import { useClientToken, useCurrentOrganization, useCurrentSession } from '@/services/auth';
import { createBEMClasses } from '@/utils/classname';

import './ArticleActionsCell.css';

const { block, element } = createBEMClasses('article-actions-cell');

const ArticleActionsCell = ({
  article,
  mode,
  showBonus,
}: {
  article: ClientArticleWithRelations;
  mode: 'defect' | 'action';
  showBonus?: boolean;
}) => {
  const getDefectName = useGetDefectName();
  const getActionName = useGetActionName();
  const clientToken = useClientToken();
  const storeToken = useClientToken();

  const defects = article.snapshot.articleDefects;
  const actions = article.snapshot.articleActions;

  const priceWithPossibleBonus = clientToken || storeToken ? 'cost' : 'price';

  const notToBeRepairedDefects = defects.filter((defect) => !defect.toBeRepaired);

  if (mode === 'defect') {
    return (
      <ul className={block()}>
        {getGroupedDefects(defects).map((defect) => (
          <li key={defect.id}>
            <div className={element('icon')}>
              <IconBandage />
            </div>
            <p>
              {getDefectName(defect)}
              {defect.quantity > 1 && ` (x${defect.quantity})`}
            </p>
          </li>
        ))}
      </ul>
    );
  } else {
    if (article.serviceChoice === 'exchange') {
      return (
        <ul className={block()}>
          <li>
            <Trans id="service-choice.radio.exchange.label">Exchange</Trans>
          </li>
        </ul>
      );
    } else if (article.serviceChoice === 'refund') {
      return (
        <ul className={block()}>
          <li>
            <Trans id="service-choice.radio.refund.label">Refund</Trans>
          </li>
        </ul>
      );
    } else if (article.serviceChoice === 'none') {
      return (
        <ul className={block()}>
          <li>
            <div className={element('icon')}>
              <IconCross />
            </div>
            <p>
              <Trans id="service-choice.radio.none.sub-text">No service can be provided</Trans>
            </p>
          </li>
        </ul>
      );
    } else {
      return (
        <ul className={block()}>
          {getGroupedActions(actions).map((action) => (
            <li key={action.id}>
              <ActionIcon brandResponsibility={action.brandResponsibility} />
              <p>
                {getActionName({ action })}
                {action.quantity > 1 && ` (x${action.quantity})`}
                {showBonus && action[`${priceWithPossibleBonus}RefashionStatus`] === 'applied' && (
                  <>
                    {' '}
                    <RefashionLogo />
                  </>
                )}
              </p>
            </li>
          ))}
          {getGroupedDefects(notToBeRepairedDefects).map((defect) => (
            <li key={defect.id} className="paragraph-100-regular text-disabled">
              <div className={element('icon')}>
                <IconBandage />
              </div>
              <p style={{ textDecoration: 'line-through' }}>
                {getDefectName(defect)}
                {defect.quantity > 1 && ` (x${defect.quantity})`}
              </p>
              <Tooltip
                content={
                  <Trans id="article-actions.not-to-be-repaired-defect">
                    This defect won&apos;t be repaired
                  </Trans>
                }
              >
                <Button variant="style-less" style={{ alignSelf: 'center' }}>
                  <IconInfo style={{ fontSize: '1rem' }} />
                </Button>
              </Tooltip>
            </li>
          ))}
        </ul>
      );
    }
  }
};

const ActionIcon = ({ brandResponsibility }: { brandResponsibility?: boolean }) => {
  const [organization] = useCurrentOrganization();
  const { currentSession } = useCurrentSession();

  const isFromCurrentOrganization = !!currentSession?.organizations.find(
    ({ id }) => id === organization?.id
  );

  if (brandResponsibility && !!organization && isFromCurrentOrganization) {
    return (
      <Tooltip
        content={
          <Trans id="article-actions.brand-responsibility">
            Under {organization.name} responsibility
          </Trans>
        }
        className={element('tooltip')}
      >
        <Button variant="style-less" className={element('icon')}>
          {<IconTask />}
          <div className={element('brand-responsibility')} />
        </Button>
      </Tooltip>
    );
  }

  return <div className={element('icon')}>{<IconTask />}</div>;
};

export default ArticleActionsCell;

import { useLingui } from '@lingui/react/macro';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';

import Button from '@/design_system/Button';
import IconCancel from '@/icons/Cancel.svg';
import { useRemoveArticleFromShipment } from '@/models/shipment';
import useViewPort from '@/utils/useViewport';

export const ArticleDeleteArticleActionCell = ({
  shipmentId,
  articleId,
  isDisabled,
}: {
  shipmentId: string;
  articleId: string;
  isDisabled: boolean;
}) => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();

  const { mutateAsync: removeArticleFromShipment } = useRemoveArticleFromShipment({ shipmentId });

  // We need to disable the button if the shipment is being fetched or mutated to avoid race conditions
  const isFetchingShipment = useIsFetching({ queryKey: ['shipments', shipmentId] }) > 0;
  const isMutatingShipment = useIsMutating({ mutationKey: ['shipments', shipmentId] }) > 0;

  const label = t({ id: 'article.remove.message', message: 'Remove' });

  return (
    <Button
      size="medium"
      variant="secondary-danger"
      iconOnly={!isMobile}
      onPress={() => removeArticleFromShipment({ articleId })}
      ariaLabel={label}
      tooltip={label}
      disabled={isDisabled || isMutatingShipment || isFetchingShipment}
      style={{ flex: 1 }}
    >
      <IconCancel />
      {isMobile && <div>{label}</div>}
    </Button>
  );
};

import { MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import { BaseActivity, BaseActivityText } from '@/components/activities/BaseActivity/BaseActivity';
import IconShipmentDone from '@/icons/ShipmentDone.svg';
import { SHIPMENT_CARRIERS } from '@/models/shipment';

export const ShipmentTransitCompletedActivity = ({
  activity,
}: {
  activity: ActivityOfType<'shipment_transit_completed'>;
}) => {
  let creatorName: string | MessageDescriptor = '';

  if (activity.shipment.carrier === 'self-handled') {
    creatorName = activity.creator?.name ?? '';
  } else if (activity.shipment.carrier === 'private-carrier') {
    creatorName = msg({
      id: 'activities.shipment_transit_completed.private-carrier',
      message: 'A private carrier',
    });
  } else {
    creatorName = SHIPMENT_CARRIERS.find((c) => c.id === activity.shipment.carrier)?.name ?? '';
  }

  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="primary"
      icon={<IconShipmentDone />}
      creatorName={creatorName}
      message={
        <BaseActivityText>
          {activity.shipment.carrier === 'self-handled' ? (
            <Trans id="activities.shipment_transit_completed.self-handled.message">
              self-delivered the package to{' '}
              <span className="paragraph-100-medium">
                {activity.shipment.destinationAddress?.contactName}
              </span>
            </Trans>
          ) : (
            <Trans id="activities.shipment_transit_completed.message">
              delivered the package to{' '}
              <span className="paragraph-100-medium">
                {activity.shipment.destinationAddress?.contactName}
              </span>
            </Trans>
          )}
        </BaseActivityText>
      }
    />
  );
};

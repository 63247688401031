import { Trans, useLingui } from '@lingui/react/macro';

import IconAnalysis from '@/icons/Analysis.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import type { TNotificationOfType } from '@/models/notification';

export const NewRequalificationNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'new_requalification'>;
}) => {
  const { t } = useLingui();

  return (
    <BaseNotification
      notificationLabel={t({
        id: 'notification.new_requalification.label',
        message: `New re-qualification to analyze for request ${notification.request.reference}`,
      })}
      icon={<IconAnalysis />}
      type="new"
      notification={notification}
      notificationHeader={
        <Trans id="notification.new_requalification">
          <span className="paragraph-100-medium">You</span> have a new re-qualification to analyze
        </Trans>
      }
    />
  );
};

import {
  Calendar as AriaCalendar,
  CalendarCell,
  CalendarGrid,
  CalendarGridBody,
  CalendarGridHeader,
  CalendarHeaderCell,
  Heading,
  RangeCalendar as AriaRangeCalendar,
} from 'react-aria-components';
import { CalendarDate, getLocalTimeZone, isToday } from '@internationalized/date';

import Button from '@/design_system/Button';
import IconChevron from '@/icons/Chevron.svg';
import { createBEMClasses } from '@/utils/classname';

import { CommonDateFilterProps, CommonDateProps, dateStringToCalendarDate } from './InputDate';
import {
  calendarDateToIsoStringRange,
  DateRangeValue,
  isoStringToCalendarDateRange,
} from './InputDateRange';

import './Calendar.css';

const { block, element } = createBEMClasses('calendar');

type CalendarStyleProps = {
  embedded?: boolean; // Adds styles to be used on a popover within an InputDate
  variant?: 'default' | 'brand';
  size?: 'medium' | 'large';
};

type CalendarProps = CommonDateProps & CommonDateFilterProps & CalendarStyleProps;

export const Calendar = ({
  value,
  onChange,
  minValue,
  maxValue,
  isDateUnavailable,
  embedded,
  variant = 'default',
  size = 'medium',
}: CalendarProps) => {
  return (
    <AriaCalendar
      className={block({ embedded, variant, size })}
      value={value ? (dateStringToCalendarDate(value) ?? null) : undefined}
      onChange={(value) => onChange?.(value?.toString())}
      minValue={minValue}
      maxValue={maxValue}
      isDateUnavailable={
        isDateUnavailable ? (date) => isDateUnavailable(date as CalendarDate) : undefined
      }
    >
      <CalendarContent variant={variant} size={size} />
    </AriaCalendar>
  );
};

type CalendarRangeProps = {
  value?: DateRangeValue;
  onChange?: (value: DateRangeValue | undefined) => void;
} & CommonDateFilterProps &
  CalendarStyleProps;

export const RangeCalendar = ({
  value,
  onChange,
  embedded,
  minValue,
  maxValue,
  isDateUnavailable,
  variant = 'default',
  size = 'medium',
}: CalendarRangeProps) => {
  return (
    <AriaRangeCalendar
      className={block({ range: true, embedded, variant, size })}
      value={value ? isoStringToCalendarDateRange(value) : undefined}
      onChange={(value) => onChange?.(value ? calendarDateToIsoStringRange(value) : undefined)}
      minValue={minValue}
      maxValue={maxValue}
      isDateUnavailable={
        isDateUnavailable ? (date) => isDateUnavailable(date as CalendarDate) : undefined
      }
    >
      <CalendarContent variant={variant} size={size} />
    </AriaRangeCalendar>
  );
};

export const CalendarContent = ({ variant, size }: Exclude<CalendarStyleProps, 'embedded'>) => {
  return (
    <>
      <header className={element('header', { size, variant })}>
        <Button
          slot="previous"
          variant={variant === 'brand' ? 'secondary-brand' : 'secondary'}
          size="medium"
          iconOnly
        >
          <IconChevron left></IconChevron>
        </Button>
        <Heading />
        <Button
          slot="next"
          variant={variant === 'brand' ? 'secondary-brand' : 'secondary'}
          size="medium"
          iconOnly
        >
          <IconChevron right></IconChevron>
        </Button>
      </header>
      <CalendarGrid className={element('grid', { size, variant })}>
        <CalendarGridHeader>
          {(day) => (
            <CalendarHeaderCell>
              <div className={element('header-cell')}>{day}</div>
            </CalendarHeaderCell>
          )}
        </CalendarGridHeader>
        <CalendarGridBody>
          {(date) => (
            <CalendarCell
              date={date}
              className={element('cell', {
                today: isToday(date, getLocalTimeZone()),
              })}
            >
              <div className={element('cell__day')}>{date.day}</div>
            </CalendarCell>
          )}
        </CalendarGridBody>
      </CalendarGrid>
    </>
  );
};

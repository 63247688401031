import { Trans } from '@lingui/react/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticlesNames,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconValidation from '@/icons/Validation.svg';

export const JobAcceptedActivity = ({
  activities,
}: {
  activities: ActivityOfType<'job_accepted'>[];
}) => {
  const firstActivity = activities[0];
  const workshopName = firstActivity.workshop.name;

  return (
    <BaseActivity
      createdAt={firstActivity.createdAt}
      type="primary"
      icon={<IconValidation />}
      creatorName={
        firstActivity.data.onBehalfOfWorkshop
          ? firstActivity.creator.name
          : `${firstActivity.creator.name} (${workshopName})`
      }
      message={
        <BaseActivityText>
          {firstActivity.data.onBehalfOfWorkshop ? (
            <Trans id="activities.job_accepted.message.on-behalf">
              accepted the job on behalf of {workshopName}
            </Trans>
          ) : (
            <Trans id="activities.job_accepted.message">accepted the job</Trans>
          )}
          {activities.length > 1 && (
            <>
              {' '}
              <Trans id="activities.job_accepted.message_multiple_articles">with items:</Trans>{' '}
              <BaseActivityArticlesNames activities={activities} displayArticleName />
            </>
          )}
        </BaseActivityText>
      }
    />
  );
};

import { Trans } from '@lingui/react/macro';
import isNil from 'lodash/isNil';

import { PriceAmountWithDetails } from '@/api';
import { CardItem } from '@/components/Card/Card';
import { PriceWithDetailsTooltip } from '@/components/PriceWithDetails/PriceWithDetails';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import Tooltip from '@/design_system/Tooltip';
import IconInfo from '@/icons/Info.svg';
import IconPay from '@/icons/Pay.svg';
import { ArticleWithRelations } from '@/models/request';
import { formatCurrency } from '@/utils/number';
import useViewPort from '@/utils/useViewport';

const BaseMoneyCell = ({
  price,
  label,
  cancelledPriceTooltip,
  className = 'paragraph-200-regular',
  isInvalid,
  showAmountBeforeTaxes,
  size,
}: {
  price: PriceAmountWithDetails | undefined | null;
  label?: string;
  cancelledPriceTooltip?: string;
  className?: string;
  isInvalid?: boolean;
  showAmountBeforeTaxes: boolean;
  size?: 'x-small' | 'small' | 'medium';
}) => {
  const { isMobile } = useViewPort();

  if (isMobile) {
    return (
      <Stack alignItems="flex-end" row gap="0.25rem">
        {label && <p className={`${className} text-secondary`}>{label}</p>}
        {!isNil(price?.amount) ? (
          <Stack
            row
            flexWrap="nowrap"
            gap="0.25rem"
            alignItems="center"
            className={`${className} text-primary`}
          >
            {!!cancelledPriceTooltip && (
              <Tooltip content={cancelledPriceTooltip}>
                <Button variant="style-less" className="text-disabled">
                  <IconInfo style={{ fontSize: '1rem' }} />
                </Button>
              </Tooltip>
            )}
            {price.cancelled ? (
              <s className="text-disabled">
                {formatCurrency(
                  showAmountBeforeTaxes ? price.amountBeforeTaxes : price.amount,
                  price.currency
                )}
              </s>
            ) : (
              <PriceWithDetailsTooltip
                price={price}
                showAmountBeforeTaxes={showAmountBeforeTaxes}
                size={size}
              />
            )}
          </Stack>
        ) : (
          <p style={{ color: isInvalid ? 'var(--color-danger-700)' : className }}>-</p>
        )}
      </Stack>
    );
  }

  return (
    <Stack alignItems="flex-end">
      {label && <p className="paragraph-100-regular text-secondary">{label}</p>}
      {!isNil(price?.amount) ? (
        <Stack
          row
          alignItems="center"
          className="paragraph-100-regular"
          style={{ whiteSpace: 'nowrap' }}
          gap="0.25rem"
        >
          {!!cancelledPriceTooltip && (
            <Tooltip content={cancelledPriceTooltip}>
              <Button variant="style-less" className="text-disabled">
                <IconInfo style={{ fontSize: '1.25rem' }} />
              </Button>
            </Tooltip>
          )}
          {price.cancelled ? (
            <s className="text-disabled">
              {formatCurrency(
                showAmountBeforeTaxes ? price.amountBeforeTaxes : price.amount,
                price.currency
              )}
            </s>
          ) : (
            <PriceWithDetailsTooltip
              price={price}
              showAmountBeforeTaxes={showAmountBeforeTaxes}
              size={size}
            />
          )}
        </Stack>
      ) : (
        <p className="paragraph-100-regular text-disabled">-</p>
      )}
    </Stack>
  );
};

export const ArticleCostCardItem = ({
  article,
  costLabel,
  showAmountBeforeTaxes,
}: {
  article: ArticleWithRelations;
  costLabel: string;
  showAmountBeforeTaxes: boolean;
}) => {
  return (
    <CardItem>
      <IconPay payOut style={{ fontSize: '1rem' }} />
      <span>
        {costLabel}
        <Trans id="_general.colon">:</Trans>
      </span>
      <BaseMoneyCell
        price={article.snapshot.cost?.amountPerEntity.find(
          (amount) => amount.entityId === article.workshopId
        )}
        showAmountBeforeTaxes={showAmountBeforeTaxes}
        size="x-small"
      />
    </CardItem>
  );
};

export const ArticlePriceCardItem = ({
  article,
  priceLabel,
  cancelledPriceTooltip,
  showAmountBeforeTaxes,
}: {
  article: ArticleWithRelations;
  priceLabel: string;
  cancelledPriceTooltip?: string;
  showAmountBeforeTaxes: boolean;
}) => {
  return (
    <CardItem>
      <IconPay payIn style={{ fontSize: '1rem' }} />
      <span>
        {priceLabel}
        <Trans id="_general.colon">:</Trans>
      </span>
      <BaseMoneyCell
        price={article.snapshot.price?.amountPerCurrency[0]}
        cancelledPriceTooltip={cancelledPriceTooltip}
        showAmountBeforeTaxes={showAmountBeforeTaxes}
        size="x-small"
      />
    </CardItem>
  );
};

export default BaseMoneyCell;

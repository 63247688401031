import { useLingui } from '@lingui/react/macro';

import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import { useUpdateArticle } from '@/models/article';
import { useProductOptions } from '@/models/product';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';

export const ProductCategoryL3 = () => {
  const { t } = useLingui();

  const { request, article, errors } = useArticleContext();

  const { mutate: updateArticle } = useUpdateArticle({
    articleId: article.id,
    requestId: request.id,
  });

  const { productL3Options } = useProductOptions(request.organization, article.productL1);

  return (
    <InputSelect
      variant="select"
      label={t({ id: 'article.form.material.label', message: 'Material' })}
      placeholder={t({
        id: 'article.form.material.placeholder',
        message: 'Select a material',
      })}
      isDisabled={!article.productL1 || productL3Options.length === 0}
      value={
        productL3Options.find((productL3Option) => article.productL3 === productL3Option.id) ?? null
      }
      isSearchable={false}
      options={productL3Options}
      getOptionValue={(productL3Option) => productL3Option.id}
      getOptionLabel={(productL3Option) => productL3Option.text}
      onChange={(productL3Option) => {
        if (productL3Option) {
          updateArticle({
            data: {
              productL3: productL3Option.id,
            },
          });
        }
      }}
      error={
        errors.details?.productL3
          ? t({
              id: 'requests.articles.form.error.productL3',
              message: 'Please select a material',
            })
          : undefined
      }
      style={{ flex: 1 }}
    />
  );
};

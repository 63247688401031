import { Trans, useLingui } from '@lingui/react/macro';

import DueAtType from '@/components/DueAtType';
import Box from '@/design_system/Box';
import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import Stack from '@/design_system/Stack';
import {
  RequestWithRelations,
  useUpdateDraftRequest,
  useUpdateRequestType,
} from '@/models/request';
import { Workflow } from '@/models/workflow';
import { useCurrentSession } from '@/services/auth';

const SettingsForm = ({
  request,
  workflow,
}: {
  request: RequestWithRelations;
  workflow?: Workflow;
}) => {
  const { t } = useLingui();
  const { currentSession } = useCurrentSession();

  const canCreateClientRequest = currentSession?.hasPermission('create_client_request', {
    organizationId: request.organizationId,
  });

  const canCreateStoreRequest = currentSession?.hasPermission('create_store_request', {
    organizationId: request.organizationId,
  });

  const { mutate: updateRequest } = useUpdateDraftRequest(request.id);
  const { mutate: updateRequestType } = useUpdateRequestType(request.id);

  const type = request.client ? 'client' : 'store';

  const dueAtConfig = workflow?.config.requestDueAt;

  const requestTypeOptions = [
    canCreateClientRequest
      ? {
          id: 'client',
          text: t({ id: 'requests.new.requestor-type.client', message: 'Client' }),
        }
      : null,
    canCreateStoreRequest
      ? {
          id: 'store',
          text: t({ id: 'requests.new.requestor-type.store', message: 'Store-damaged' }),
        }
      : null,
  ].filter((requestType) => !!requestType);

  return (
    <Stack gap="0.5rem">
      <h2 className="headline-400-bold">
        <Trans id="requests.new.settings.title">Request Settings</Trans>
      </h2>

      <Box padding="16px" gap="1rem" style={{ flex: 'initial' }}>
        <Stack gap="1rem">
          <InputSelect
            variant="select"
            label={t({ id: 'requests.new.requestor-type.label', message: 'Type' })}
            isDisabled={!request.draft}
            isSearchable={false}
            value={requestTypeOptions.find(
              (requestTypeOption) => requestTypeOption.id === (request.client ? 'client' : 'store')
            )}
            options={requestTypeOptions}
            getOptionValue={(requestTypeOption) => requestTypeOption.text}
            getOptionLabel={(requestTypeOption) => requestTypeOption.text}
            onChange={(requestTypeOption) => {
              if (!!requestTypeOption && requestTypeOption.id !== type) {
                updateRequestType({ type: requestTypeOption.id as 'client' | 'store' });
              }
            }}
            style={{ flex: 1 }}
          />

          {dueAtConfig && (
            <InputSelect
              variant="select"
              label={t({ id: 'requests.new.due-date.label', message: 'Due Date' })}
              isDisabled={dueAtConfig.length === 1}
              isSearchable={false}
              value={dueAtConfig.find((dueAtOption) => dueAtOption.type === request.dueAtType)}
              options={dueAtConfig}
              getOptionValue={(dueAtOption) => dueAtOption.type}
              getOptionLabel={(dueAtOption) => dueAtOption.type}
              formatOptionLabel={(dueAtOption) => (
                <DueAtType type={dueAtOption.type} days={dueAtOption.days} />
              )}
              hideSelectedOptions={false}
              onChange={(dueAtOption) => {
                if (dueAtOption) {
                  updateRequest({ dueAtType: dueAtOption.type });
                }
              }}
              size="medium"
              style={{ flex: 1 }}
            />
          )}
        </Stack>
      </Box>
    </Stack>
  );
};

export default SettingsForm;

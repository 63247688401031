import { DropEvent } from 'react-aria';
import { Button as AriaButton, DropZone, FileTrigger } from 'react-aria-components';
import { Plural, Trans } from '@lingui/react/macro';

import IconCamera from '@/icons/Camera.svg';
import IconUpload from '@/icons/Upload.svg';
import { createBEMClasses } from '@/utils/classname';
import useViewPort from '@/utils/useViewport';

import './InputFile.css';

const { block, element } = createBEMClasses('input-file');

export type InputFileProps = {
  icon?: React.ReactNode;
  prompt?: React.ReactNode;
  ariaLabel: string;
  type?: 'photo' | 'file';
  onUpload?: (files: File[]) => void;
  allowsMultiple?: boolean;
  theme?: 'default' | 'brand';
  variant?: 'default' | 'inline' | 'square';
  size?: 'medium' | 'small';
  isInvalid?: boolean;
  isDisabled?: boolean;
};

const InputFile = ({
  prompt,
  ariaLabel,
  type = 'file',
  onUpload,
  allowsMultiple = true,
  theme = 'default',
  variant = 'default',
  size = 'medium',
  icon,
  isInvalid,
  isDisabled,
}: InputFileProps) => {
  const { isMobile, isTablet } = useViewPort();
  const isMobileOrTablet = isMobile || isTablet;

  const handleDropEvent = async (e: DropEvent) => {
    const files = await Promise.all(
      e.items
        .filter((item) => item.kind === 'file')
        .filter((item) => ACCEPTED_FILE_TYPES[type].includes(item.type))
        .map(async (item) => await item.getFile())
    );

    if (files.length) {
      if (allowsMultiple) {
        onUpload?.(files);
      } else {
        onUpload?.([files[0]]);
      }
    }
  };

  const handleSelectEvent = (fileList: FileList | null) => {
    const files = Array.from(fileList ?? []);

    if (files.length) {
      onUpload?.(files);
    }
  };

  return (
    <DropZone
      className={block({ theme, variant, size, invalid: isInvalid })}
      onDrop={(e) => {
        handleDropEvent(e);
      }}
      /**
       * For unknown reason, Safari returns an empty `types` array when a file is dropped.
       * Therefore, we cannot use this method as a reliable way to determine if all the dropped files are valid.
       * It is still an helpful visual helper for the user that works in most cases.
       *
       * Note: We keep `.some` instead of `.every` to handle the cases where the user uploads a mix of valid and invalid files.
       * In such cases, they will be filtered in the `handleDropEvent` method.
       */
      getDropOperation={(types) =>
        ACCEPTED_FILE_TYPES[type].some((type) => types.has(type)) ? 'copy' : 'cancel'
      }
      isDisabled={isDisabled}
    >
      <FileTrigger
        onSelect={handleSelectEvent}
        acceptedFileTypes={ACCEPTED_FILE_TYPES[type]}
        allowsMultiple={allowsMultiple}
      >
        <AriaButton aria-label={ariaLabel} isDisabled={isDisabled}>
          {icon ?? (isMobileOrTablet ? <IconCamera /> : <IconUpload />)}
          {prompt !== null ? (
            <p className={element('prompt', {}, 'paragraph-100-regular text-primary')}>
              {prompt ??
                (isMobileOrTablet ? (
                  <Trans id="design-system.input-file.mobile.label">
                    Take a photo or browse files
                  </Trans>
                ) : type === 'file' ? (
                  <Trans id="design-system.input-file.file.label">
                    Drop <Plural value={allowsMultiple ? 5 : 1} one="file" other="files" /> or{' '}
                    <span className="paragraph-100-medium">browse computer</span>
                  </Trans>
                ) : (
                  <Trans id="design-system.input-file.photo.label">
                    Drop <Plural value={allowsMultiple ? 100 : 1} one="photo" other="photos" /> or{' '}
                    <span className="paragraph-100-medium">browse computer</span>
                  </Trans>
                ))}
            </p>
          ) : null}
        </AriaButton>
      </FileTrigger>
    </DropZone>
  );
};

const ACCEPTED_FILE_TYPES = {
  photo: ['image/jpeg', 'image/png', 'image/webp', 'image/jfif'],
  file: ['application/pdf', 'image/jpeg', 'image/png', 'image/webp'],
};

export default InputFile;

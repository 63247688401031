import { useEffect, useState } from 'react';
import { GridList, GridListItem } from 'react-aria-components';
import { Trans, useLingui } from '@lingui/react/macro';

import { ArticlePhotoGroup } from '@/components/ArticlePhotoGroup';
import { FiltersButton, FiltersDrawer, hasFilterBeenUpdated } from '@/components/ListFilters';
import { TableQueryWrapper } from '@/components/TableQueryWrapper';
import Box from '@/design_system/Box';
import InputSearch from '@/design_system/InputSearch';
import Pagination from '@/design_system/Pagination';
import { PaginationFooter } from '@/design_system/Pagination/Pagination';
import Stack from '@/design_system/Stack';
import { Body, Cell, Column, Header, Row, Table } from '@/design_system/Table/Table';
import { RequestorType, RequestWithRelations, useRequests } from '@/models/request';
import { useUsers } from '@/models/user';
import { type UserWithRelations } from '@/models/user';
import { RequestsEmptyState } from '@/routes/Requests/components/RequestsEmptyState';
import { RequestsNoResults } from '@/routes/Requests/components/RequestsNoResults';
import { useCurrentSession } from '@/services/auth';
import { ErrorBoundary } from '@/services/sentry';
import { formatDate, fromNow } from '@/utils/date';
import useViewPort from '@/utils/useViewport';

import { CollaboratorsCell } from './components/CollaboratorsCell';
import { RequestDisplayedSteps } from './components/RequestDisplayedSteps';
import {
  ArticlesCardItem,
  CollaboratorSelect,
  RequestNameCardItem,
  RequestNameCell,
  RequestorTypeSelect,
  REQUESTS_PER_PAGE,
} from './common';

const DoneTab = ({
  search,
  debouncedSearch,
  debouncedSetSearch,
  page,
  setPage,
  collaborators,
  setCollaborators,
  requestorTypes,
  setRequestorTypes,
}: {
  search: string;
  debouncedSearch: string;
  debouncedSetSearch: (ref: string) => void;
  page: number;
  setPage: (page: number) => void;
  collaborators: string[];
  setCollaborators: (collaborators: string[]) => void;
  requestorTypes: RequestorType[];
  setRequestorTypes: (requestorTypes: RequestorType[]) => void;
}) => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();
  const { currentSession } = useCurrentSession();

  const {
    data: { requests, meta } = {},
    isLoading,
    isError,
  } = useRequests({
    limit: REQUESTS_PER_PAGE,
    offset: (page - 1) * REQUESTS_PER_PAGE,
    search: debouncedSearch || undefined,
    collaborators,
    requestorTypes,
    tab: 'archived',
  });

  const { data: { users } = {} } = useUsers();

  const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = useState(false);

  const hasActiveFilters = !!collaborators.length || !!requestorTypes.length;

  return (
    <Stack gap="1rem" style={{ height: '100%' }}>
      <Stack row gap="1rem">
        <ErrorBoundary>
          <InputSearch
            placeholder={t({
              id: 'requests.search.placeholder',
              message: 'Search for a reference, a client, an item, a store…',
            })}
            ariaLabel={t({
              id: 'requests.search.placeholder',
              message: 'Search for a reference, a client, an item, a store…',
            })}
            style={{ flex: 1, minWidth: 175 }}
            value={search}
            onChange={debouncedSetSearch}
            size="medium"
          />
          {isMobile ? (
            <>
              <FiltersButton
                hasActiveFilters={hasActiveFilters}
                isFiltersDrawerOpen={isFiltersDrawerOpen}
                setIsFiltersDrawerOpen={setIsFiltersDrawerOpen}
              />

              <RequestMobileFilters
                isOpen={isFiltersDrawerOpen}
                onOpenChange={setIsFiltersDrawerOpen}
                setPage={setPage}
                collaborators={collaborators}
                users={users}
                setCollaborators={setCollaborators}
                requestorTypes={requestorTypes}
                setRequestorTypes={setRequestorTypes}
              />
            </>
          ) : (
            <>
              <RequestorTypeSelect
                selectedKeys={requestorTypes}
                onSelectionChange={(keys) => {
                  setRequestorTypes(keys);
                  setPage(1);
                }}
              />
              <CollaboratorSelect
                selectedKeys={collaborators}
                onSelectionChange={(keys) => {
                  setCollaborators([...keys] as string[]);
                  setPage(1);
                }}
                users={users}
              />
            </>
          )}
        </ErrorBoundary>
      </Stack>

      <TableQueryWrapper isLoading={isLoading} isError={isError}>
        {!requests?.length ? (
          search || hasActiveFilters ? (
            <RequestsNoResults />
          ) : (
            <RequestsEmptyState
              subtitle={
                <Trans id="requests.empty-state.no-request-subtitle">No requests to display</Trans>
              }
            />
          )
        ) : (
          <>
            {!isMobile && (
              <Table
                ariaLabel={t({ id: 'requests.table.label', message: 'Requests' })}
                columnWidths={[
                  '104px',
                  '1.5fr',
                  !currentSession?.workshop?.external && '1fr',
                  '1fr',
                  '1.5fr',
                  '1fr',
                  '104px',
                ]}
              >
                <Header>
                  <Row>
                    <Column>
                      <Trans id="requests.table.column.reference.title">Reference</Trans>
                    </Column>

                    <Column>
                      <Trans id="requests.table.column.requestor.title">Applicant</Trans>
                    </Column>

                    {!currentSession?.workshop?.external && (
                      <Column>
                        <Trans id="requests.table.column.request-creation-date.title">
                          Creation date
                        </Trans>
                      </Column>
                    )}

                    <Column>
                      <Trans id="requests.table.column.articles.title">Items</Trans>
                    </Column>

                    <Column>
                      <Trans id="requests.table.column.step.title">Step</Trans>
                    </Column>

                    <Column justifyContent="center">
                      <Trans id="requests.table.column.archivedAt.title">Archived</Trans>
                    </Column>

                    <Column>
                      <Trans id="requests.table.column.collaborators.title">Collaborators</Trans>
                    </Column>
                  </Row>
                </Header>

                <Body>
                  {requests?.map((request) => (
                    <RequestRow key={'a' + request.id} request={request} />
                  ))}
                </Body>

                <PaginationFooter
                  page={page}
                  itemsPerPage={REQUESTS_PER_PAGE}
                  count={meta?.count}
                  onPageChange={setPage}
                />
              </Table>
            )}

            {isMobile && (
              <>
                <GridList
                  aria-label={t({ id: 'requests.table.label', message: 'Requests' })}
                  style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
                >
                  {requests?.map((request) => <RequestCard key={request.id} request={request} />)}
                </GridList>
                <Pagination
                  page={page}
                  itemsPerPage={REQUESTS_PER_PAGE}
                  count={meta?.count}
                  onPageChange={setPage}
                />
              </>
            )}
          </>
        )}
      </TableQueryWrapper>
    </Stack>
  );
};

const RequestRow = ({ request }: { request: RequestWithRelations }) => {
  const { currentSession } = useCurrentSession();

  return (
    <Row to={`/requests/${request.id}`}>
      <Cell isLink>{request.reference}</Cell>

      <RequestNameCell request={request} />

      {!currentSession?.workshop?.external && (
        <Cell>{formatDate(request.createdAtDate, { dateStyle: 'medium', year: undefined })}</Cell>
      )}

      <Cell>
        <ArticlePhotoGroup articles={request.allArticles} />
      </Cell>

      <Cell>
        <RequestDisplayedSteps request={request} />
      </Cell>

      <Cell justifyContent="center">
        {request.archivedAtDate && (
          <p
            title={formatDate(request.archivedAtDate, {
              dateStyle: 'long',
              timeStyle: 'short',
            })}
          >
            {fromNow(request.archivedAtDate)}
          </p>
        )}
      </Cell>

      <Cell>
        <CollaboratorsCell collaborators={request.collaborators} variant="row" />
      </Cell>
    </Row>
  );
};

const RequestCard = ({ request }: { request: RequestWithRelations }) => {
  const requestLink = request.isInDraftStep
    ? `/requests/new/${request.id}`
    : `/requests/${request.id}`;

  return (
    <GridListItem href={requestLink} textValue={request.reference}>
      <Box padding="16px" role="row">
        <Stack row justifyContent="space-between">
          <RequestNameCardItem request={request} />
        </Stack>

        <Stack row gap="0.5rem" alignItems="center" style={{ marginTop: '0.75rem' }}>
          <ArticlesCardItem request={request} />
          <RequestDisplayedSteps request={request} />
          <CollaboratorsCell collaborators={request.collaborators} variant="card" />
        </Stack>
      </Box>
    </GridListItem>
  );
};

const RequestMobileFilters = ({
  isOpen,
  onOpenChange,
  setPage,
  collaborators,
  users,
  setCollaborators,
  requestorTypes,
  setRequestorTypes,
}: {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  setPage: (page: number) => void;
  collaborators: string[];
  users?: UserWithRelations[];
  setCollaborators: (supervisor: string[]) => void;
  requestorTypes: RequestorType[];
  setRequestorTypes: (requestorTypes: RequestorType[]) => void;
}) => {
  const [newRequestorTypes, setNewRequestorTypes] = useState<RequestorType[]>([]);
  const [newCollaborators, setNewCollaborators] = useState<string[]>([]);

  const handleClearFilters = () => {
    setNewRequestorTypes([]);
    setNewCollaborators([]);
  };

  const handleApplyFilters = () => {
    const hasRequestorTypesFilterBeenUpdated = hasFilterBeenUpdated(
      requestorTypes,
      newRequestorTypes
    );
    const hasCollaboratorsFilterBeenUpdated = hasFilterBeenUpdated(collaborators, newCollaborators);

    if (hasRequestorTypesFilterBeenUpdated) {
      setRequestorTypes(newRequestorTypes);
    }

    if (hasCollaboratorsFilterBeenUpdated) {
      setCollaborators(newCollaborators);
    }

    if (hasRequestorTypesFilterBeenUpdated || hasCollaboratorsFilterBeenUpdated) {
      setPage(1);
    }

    onOpenChange(false);
  };

  // Those useEffect aim to fill the local state of the drawer with the already applied filters
  useEffect(() => {
    setNewRequestorTypes(requestorTypes);
  }, [requestorTypes, isOpen]);

  useEffect(() => {
    setNewCollaborators(collaborators);
  }, [collaborators, isOpen]);

  return (
    <FiltersDrawer
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      handleClearFilters={handleClearFilters}
      handleApplyFilters={handleApplyFilters}
    >
      <>
        <RequestorTypeSelect
          selectedKeys={newRequestorTypes}
          onSelectionChange={(keys) => {
            setNewRequestorTypes(keys);
          }}
        />
        <CollaboratorSelect
          selectedKeys={newCollaborators}
          onSelectionChange={(keys) => {
            setNewCollaborators([...keys] as string[]);
          }}
          users={users}
        />
      </>
    </FiltersDrawer>
  );
};

export default DoneTab;

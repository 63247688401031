import { Trans, useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import { useDeleteArticle } from '@/models/article';

const DeleteArticleModal = ({
  lastArticle,
  isOpen,
  onOpenChange,
  onDelete,
  articleId,
}: {
  lastArticle: boolean;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onDelete: () => void;
  articleId: string;
}) => {
  const { t } = useLingui();

  const { mutateAsync: deleteArticle, isPending: isPendingDeleteArticle } = useDeleteArticle();

  const handleDelete = async () => {
    await deleteArticle(articleId);

    onDelete();
  };

  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      title={t({
        id: 'client.new.actions.article.delete.modal.title',
        message: 'Delete your item',
      })}
      style={{ maxWidth: '28rem' }}
    >
      <main style={{ paddingTop: 0 }}>
        <p className="paragraph-100-regular">
          {lastArticle ? (
            <Trans id="client.new.actions.article.delete.modal.text.last">
              You are about to delete your item. Your changes and your cart will be lost. Are you
              sure?
            </Trans>
          ) : (
            <Trans id="client.new.actions.article.delete.modal.text">
              You are about to delete your item. Are you sure?
            </Trans>
          )}
        </p>
      </main>
      <footer>
        <Button variant="secondary-brand" size="small" onPress={() => onOpenChange(false)}>
          <Trans id="client.new.actions.article.delete.modal.cancel">Cancel</Trans>
        </Button>
        <Button
          variant="danger"
          size="small"
          onPress={() => {
            handleDelete();
          }}
          isLoading={isPendingDeleteArticle}
        >
          <Trans id="client.new.actions.article.delete.modal.delete">Delete</Trans>
        </Button>
      </footer>
    </Dialog>
  );
};

export default DeleteArticleModal;

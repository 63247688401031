import { useState } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';

import { UserAvatar } from '@/components/UserAvatar/UserAvatar';
import Button from '@/design_system/Button';
import Menu from '@/design_system/Menu';
import { DangerMenuItem, MenuItem } from '@/design_system/Menu/Menu';
import Stack from '@/design_system/Stack';
import TextArea from '@/design_system/TextArea';
import Tooltip from '@/design_system/Tooltip';
import IconEdit from '@/icons/Edit.svg';
import IconMore from '@/icons/More.svg';
import IconTrash from '@/icons/Trash.svg';
import IconWorld from '@/icons/World.svg';
import { Article, useArticleName } from '@/models/article';
import { Comment as CommentModel } from '@/models/comment/comment';
import { Product } from '@/models/product';
import { createBEMClasses } from '@/utils/classname';
import { formatDate, fromNow } from '@/utils/date';
import useViewPort from '@/utils/useViewport';

import './Comment.css';

const { block, element } = createBEMClasses('comment');

interface Props {
  comment: CommentModel;
  isReadOnly?: boolean;
  isOwnComment?: boolean;
  displayArticleName?: boolean;
  onCommentUpdated: (editedComment: string) => Promise<void>;
  onCommentDeleted: () => Promise<void>;
}

export const Comment = ({
  comment,
  isReadOnly,
  isOwnComment,
  displayArticleName,
  onCommentUpdated,
  onCommentDeleted,
}: Props) => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();
  const [isEditing, setIsEditing] = useState(false);
  const hasBeenEdited = comment.updatedAt > comment.createdAt;

  return (
    <div className={block()}>
      <Stack row gap="0.75rem" justifyContent="space-between" flexWrap="nowrap">
        {!isMobile && (
          <UserAvatar user={comment.creator} variant={isOwnComment ? 'primary' : undefined} />
        )}
        <div className={element('content')}>
          <Stack row alignItems="center" justifyContent="space-between">
            <Stack row alignItems="center" gap="0.25rem">
              <p className="paragraph-100-medium">{comment.creator.name}</p>
              {displayArticleName && comment.article && <ArticleName article={comment.article} />}
            </Stack>
            <Stack row alignItems="center" gap="0.5rem">
              <Stack row alignItems="center" gap="0.25rem">
                <small
                  className="paragraph-200-regular text-disabled"
                  title={formatDate(comment.createdAtDate, {
                    dateStyle: 'long',
                    timeStyle: 'short',
                  })}
                >
                  {fromNow(comment.createdAtDate)}
                </small>
                {hasBeenEdited && !isEditing && (
                  <HasBeenEdited updatedAtDate={comment.updatedAtDate} />
                )}
                {comment.visibility === 'public' && !isEditing && <PublicIcon />}
              </Stack>

              {!isReadOnly && !isEditing && (
                <Tooltip
                  content={t({ id: 'comment.viewer.other-options', message: 'Other options' })}
                  hideOnTouchDevice
                >
                  <Menu
                    trigger={
                      <Button
                        iconOnly={true}
                        size="small"
                        variant="secondary"
                        ariaLabel={t({
                          id: 'comment.viewer.other-options',
                          message: 'Other options',
                        })}
                      >
                        <IconMore />
                      </Button>
                    }
                    placement="bottom right"
                    onAction={(action: string) => {
                      if (action === 'edit') {
                        setIsEditing(true);
                      } else if (action === 'delete') {
                        onCommentDeleted();
                      }
                    }}
                  >
                    <MenuItem id="edit">
                      <Stack row gap="0.5rem" alignItems="center">
                        <IconEdit />
                        <Trans id="comment.viewer.edit">Edit</Trans>
                      </Stack>
                    </MenuItem>
                    <DangerMenuItem id="delete">
                      <Stack row gap="0.5rem" alignItems="center">
                        <IconTrash />
                        <Trans id="comment.viewer.delete">Delete</Trans>
                      </Stack>
                    </DangerMenuItem>
                  </Menu>
                </Tooltip>
              )}
            </Stack>
          </Stack>
          {!isEditing ? (
            <CommentViewer content={comment.content} />
          ) : (
            <CommentEditor
              initialContent={comment.content}
              onSaveComment={async (editedComment) => {
                await onCommentUpdated(editedComment);
                setIsEditing(false);
              }}
              onCancelEdition={() => setIsEditing(false)}
            ></CommentEditor>
          )}
        </div>
      </Stack>
    </div>
  );
};

const ArticleName = ({ article }: { article: Article & { product: Product | null } }) => {
  const articleName = useArticleName({ article });

  return (
    <>
      <small>•</small>
      <small className="paragraph-200-regular">
        <Trans id="comment.viewer.from">From</Trans>
      </small>
      <small className="paragraph-200-medium">{articleName}</small>
    </>
  );
};

/**
 * Simple comment viewer, just displays the content
 * @param content The comment content
 * @constructor
 */
const CommentViewer = ({ content }: { content: string }) => (
  <p className="paragraph-100-regular" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
    {content}
  </p>
);

/**
 * Comment editor, allows to edit the comment content
 * @param initialContent The initial comment content
 * @param onCancelEdition Callback when the user cancels the edition
 * @param onSaveComment Callback when the user saves the comment, contains the edited comment
 * @constructor
 */
const CommentEditor = ({
  initialContent,
  onCancelEdition,
  onSaveComment,
}: {
  initialContent: string;
  onCancelEdition: () => void;
  onSaveComment: (editedComment: string) => Promise<void>;
}) => {
  const [content, setContent] = useState(initialContent);
  const { t } = useLingui();

  return (
    <>
      <TextArea
        ariaLabel={t({
          id: 'comment.editor.input',
          message: 'Edit the comment',
        })}
        value={content ?? ''}
        rows={4}
        onChange={(evt) => setContent(evt.target.value)}
      />
      <Stack row alignItems="center" justifyContent="flex-end" gap="0.5rem">
        <Button
          size="small"
          variant="neutral"
          onPress={() => onCancelEdition()}
          ariaLabel={t({
            id: 'comment.editor.cancel',
            message: 'Cancel',
          })}
        >
          <Trans id="comment.editor.cancel">Cancel</Trans>
        </Button>
        <Button
          variant="neutral"
          size="small"
          disabled={content === initialContent}
          onPress={() => onSaveComment(content)}
          ariaLabel={t({ id: 'comment.editor.save', message: 'Save changes' })}
        >
          <Trans id="comment.editor.save">Save changes</Trans>
        </Button>
      </Stack>
    </>
  );
};

const PublicIcon = () => {
  const { t } = useLingui();
  return (
    <>
      <small className="paragraph-200-regular text-disabled">•</small>
      <Button
        ariaLabel={t({
          id: 'comment.viewer.public-comment.tooltip',
          message: 'Visible by everyone with access to this page',
        })}
        tooltip={t({
          id: 'comment.viewer.public-comment.tooltip',
          message: 'Visible by everyone with access to this page',
        })}
        variant="style-less"
      >
        <IconWorld />
      </Button>
    </>
  );
};

const HasBeenEdited = ({ updatedAtDate }: { updatedAtDate: Date }) => (
  <small
    className="paragraph-200-regular text-disabled"
    title={formatDate(updatedAtDate, {
      dateStyle: 'long',
      timeStyle: 'short',
    })}
  >
    <Trans id="comment.viewer.edition-flag">(edited)</Trans>
  </small>
);

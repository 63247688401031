import { useState } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import Stack from '@/design_system/Stack';
import TextArea from '@/design_system/TextArea';
import Toggle from '@/design_system/Toggle';
import IconAdd from '@/icons/Add.svg';
import { useCreateDefect, useUpdateDefect } from '@/models/article';
import { DefectTypeOrganizationWithRelations } from '@/models/defectType';
import { Medium, useDeleteMedium } from '@/models/medium';
import { ArticleDefectWithRelations } from '@/models/request';
import { useDatabaseTranslation } from '@/models/translation';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';

import FileUpload from '../FileUpload';

type CreateEditDefectDialogProps = {
  isOpen: boolean;
  onClose: () => void;
} & (
  | { defectTypeToCreate?: DefectTypeOrganizationWithRelations | 'custom'; defectToEdit?: never }
  | { defectToEdit?: ArticleDefectWithRelations; defectTypeToCreate?: never }
);

export const CreateEditDefectDialog = ({
  isOpen,
  onClose,
  defectTypeToCreate,
  defectToEdit,
}: CreateEditDefectDialogProps) => {
  const { t } = useLingui();

  const title = defectToEdit
    ? t({ id: 'create-defect-dialog.title.edit', message: 'Edit defect' })
    : defectTypeToCreate === 'custom'
      ? t({ id: 'create-defect-dialog.title.custom', message: 'Add a custom defect' })
      : t({ id: 'create-defect-dialog.title', message: 'Add a defect' });

  return (
    <Dialog title={title} isOpen={isOpen} onOpenChange={onClose}>
      <CreateEditDefectForm
        onClose={onClose}
        defectTypeToCreate={defectTypeToCreate}
        defectToEdit={defectToEdit}
      />
    </Dialog>
  );
};

const CreateEditDefectForm = ({
  onClose,
  defectTypeToCreate,
  defectToEdit,
}: Omit<CreateEditDefectDialogProps, 'isOpen'>) => {
  const { _db } = useDatabaseTranslation();

  const { request } = useRequestContext();
  const { article } = useArticleContext();

  const { mutateAsync: createDefect } = useCreateDefect({
    articleId: article.id,
    requestId: request.id,
  });

  const { mutateAsync: updateDefect } = useUpdateDefect({
    articleId: article.id,
    requestId: request.id,
  });

  const { mutate: deleteMedium } = useDeleteMedium();

  const defectType = defectToEdit
    ? (defectToEdit?.defectTypeOrganization ?? 'custom')
    : defectTypeToCreate;

  const [description, setDescription] = useState(defectToEdit?.customDescription ?? '');
  const [defectPhotos, setDefectPhotos] = useState(defectToEdit?.media ?? []);
  const [defectPhotosToDelete, setDefectPhotosToDelete] = useState<string[]>([]);
  const [toBeRepaired, setToBeRepaired] = useState(defectToEdit?.toBeRepaired ?? true);

  const save = async () => {
    const data = {
      description: defectType === 'custom' ? description : undefined,
      toBeRepaired,
      defectPhotoIds: defectPhotos.map((medium) => medium.id),
    };

    if (defectToEdit) {
      await updateDefect({
        defectId: defectToEdit.id,
        body: data,
      });
    } else {
      await createDefect({
        ...data,
        defectTypeOrganization: defectType === 'custom' ? undefined : defectType,
      });
    }

    defectPhotosToDelete.forEach((id) => deleteMedium(id));

    onClose();
  };

  if (!defectType) {
    return null;
  }

  return (
    <>
      <main>
        <Stack gap="1rem">
          {defectType === 'custom' ? (
            <Description description={description} setDescription={setDescription} />
          ) : (
            <p className="paragraph-100-regular">{_db(defectType?.defectType.name)}</p>
          )}
          <hr />
          <DefectPhotos
            defectPhotos={defectPhotos}
            onUpload={(newDefectPhoto) => setDefectPhotos((prev) => [...prev, newDefectPhoto])}
            onDelete={(idToDelete) => {
              setDefectPhotos((prev) => prev.filter(({ id }) => id !== idToDelete));
              setDefectPhotosToDelete((prev) => [...prev, idToDelete]);
            }}
          />
          <ToBeRepaired toBeRepaired={toBeRepaired} setToBeRepaired={setToBeRepaired} />
        </Stack>
      </main>
      <footer>
        <Button variant="primary" onPress={save}>
          {defectToEdit ? (
            <Trans id="create-defect-dialog.save">Save</Trans>
          ) : (
            <Trans id="create-defect-dialog.add">Add defect</Trans>
          )}
        </Button>
      </footer>
    </>
  );
};

const Description = ({
  description,
  setDescription,
}: {
  description: string;
  setDescription: (description: string) => void;
}) => {
  const { t } = useLingui();

  return (
    <TextArea
      label={t({
        id: 'create-defect-dialog.description.label',
        message: 'Description',
      })}
      placeholder={t({
        id: 'create-defect-dialog.description.placeholder',
        message: `Describe the item's defect...`,
      })}
      value={description}
      onChange={(e) => setDescription(e.target.value)}
    />
  );
};

const ToBeRepaired = ({
  toBeRepaired,
  setToBeRepaired,
}: {
  toBeRepaired: boolean;
  setToBeRepaired: (toBeRepaired: boolean) => void;
}) => {
  return (
    <Stack row justifyContent="space-between" className="paragraph-100-regular">
      <span>
        <Trans id="create-defect-dialog.to-be-repaired.label">To be repaired?</Trans>
      </span>
      <Toggle size="large" isSelected={toBeRepaired} onChange={setToBeRepaired} />
    </Stack>
  );
};

const DefectPhotos = ({
  defectPhotos,
  onUpload,
  onDelete,
}: {
  defectPhotos: Medium[];
  onUpload: (defectPhoto: Medium) => void;
  onDelete: (id: string) => void;
}) => {
  const { t } = useLingui();
  const { article } = useArticleContext();

  const mediumType =
    article.task?.type === 'analyze_article' ? 'requalification-defect-photo' : 'defect-photo';

  const label = t({ id: 'create-defect-dialog.photos.label', message: 'Add defect photos' });

  return (
    <FileUpload
      type="photo"
      variant="grid"
      size="small"
      uploadData={{ type: mediumType }}
      icon={<IconAdd />}
      ariaLabel={label}
      prompt={label}
      media={defectPhotos}
      onUpload={onUpload}
      onDelete={onDelete}
    />
  );
};

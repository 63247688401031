import { Trans } from '@lingui/react/macro';

import Stack from '@/design_system/Stack';
import { ArticleWithRelations } from '@/models/request';

import { BaseMessage } from './BaseMessage';
import { GoToShipment } from './GoToShipment';

export const PrepareShipmentTaskMessage = ({ article }: { article: ArticleWithRelations }) => {
  return (
    <BaseMessage
      article={article}
      title={<Trans id="article.task.prepare-shipment.title">Ship the package</Trans>}
      message={
        <Stack gap="0.5rem" alignItems="flex-start">
          <p>
            {article.toClient && (
              <Trans id="article.task.prepare-shipment.text.to-client">
                Finish creating the shipment to send the item to the client.
              </Trans>
            )}
            {!!article.toStoreId && (
              <Trans id="article.task.prepare-shipment.text.to-store">
                Finish creating the shipment to send the item to the store.
              </Trans>
            )}
            {!!article.toWorkshopId && !article.atWorkshopId && (
              <Trans id="article.task.prepare-shipment.text.to-workshop">
                Finish creating the shipment to send the item to the workshop.
              </Trans>
            )}
            {!!article.toWorkshopId &&
              !!article.atWorkshopId &&
              article.toWorkshopId !== article.atWorkshopId && (
                <Trans id="article.task.prepare-shipment.text.workshop-to-workshop">
                  Finish creating the shipment to send the item to another workshop.
                </Trans>
              )}
          </p>
          <GoToShipment article={article} />
        </Stack>
      }
    />
  );
};

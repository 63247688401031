import config from '@/config';
import { Organization } from '@/models/organization';
import { createBEMClasses } from '@/utils/classname';

import './OrganizationLogo.css';

const { block } = createBEMClasses('client-organization-logo');

const OrganizationLogo = ({
  organization,
  size = 'large',
}: {
  organization?: Organization | null;
  size?: 'small' | 'large';
}) => {
  return (
    <div className={block({ size, 'no-organization': !organization })}>
      {organization && (
        <img src={`${config.apiUrl}/organizations/${organization.slug}/logo`} alt="" />
      )}
    </div>
  );
};

export default OrganizationLogo;

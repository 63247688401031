import { useState } from 'react';

import {
  ActionTypeOrganizationWithRelations,
  PackActionTypeOrganizationWithRelations,
} from '@/models/actionType';

import { ActionTypeSearchDialog } from './ActionTypeSearchDialog';
import { CreateEditActionDialog } from './CreateEditActionDialog';

type ActionTypeOption =
  | PackActionTypeOrganizationWithRelations
  | ActionTypeOrganizationWithRelations;

export const CreateActionDialogs = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  const [actionType, setActionType] = useState<ActionTypeOption | 'custom'>();

  return (
    <>
      <ActionTypeSearchDialog
        isOpen={isOpen && !actionType}
        onClose={() => setIsOpen(false)}
        onChooseActionType={setActionType}
      />
      <CreateEditActionDialog
        isOpen={isOpen && !!actionType}
        actionTypeToCreate={actionType}
        onClose={() => {
          setIsOpen(false);
          setActionType(undefined);
        }}
      />
    </>
  );
};

import { Trans, useLingui } from '@lingui/react/macro';

import config from '@/config';
import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import IconReceipt from '@/icons/Receipt.svg';
import { RequestWithRelations } from '@/models/request';
import useViewPort from '@/utils/useViewport';

export const PrintDropOffModal = ({
  request,
  onClose,
}: {
  request: RequestWithRelations;
  onClose: () => void;
}) => {
  return (
    <Dialog
      isOpen
      title={<Trans id="requests.new.receipt-modal.title">Print a drop-off receipt?</Trans>}
      onOpenChange={() => onClose()}
    >
      <main>
        <p className="paragraph-100-regular">
          <Trans id="requests.new.receipt-modal.content">
            <b>Your request has been successfully created and saved as a draft.</b>
            <br />
            An email has been sent to the client with a link to the request.
            <br />
            Do you also want to print a drop-off receipt?
          </Trans>
        </p>
      </main>
      <footer>
        <Button
          variant="secondary"
          size="medium"
          onPress={() => onClose()}
          dataTrackingId="requests.new.receipt-modal.actions.no"
        >
          <Trans id="requests.new.receipt-modal.actions.no">No thanks</Trans>
        </Button>
        <Button
          variant="primary"
          size="medium"
          href={config.apiUrl + `/requests/${request.id}/deposit-slip`}
          target="_blank"
          onPress={() => {
            onClose();
          }}
          dataTrackingId="requests.new.receipt-modal.actions.print"
        >
          <IconReceipt />
          <Trans id="requests.new.receipt-modal.actions.print">Print drop-off receipt</Trans>
        </Button>
      </footer>
    </Dialog>
  );
};

export const PrintDropOffButton = ({ request }: { request: RequestWithRelations }) => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();

  return (
    <Button
      variant="secondary"
      size="medium"
      href={config.apiUrl + `/requests/${request.id}/deposit-slip`}
      target="_blank"
      style={isMobile ? { flex: 1 } : undefined}
      ariaLabel={
        isMobile
          ? t({
              id: 'request.edit.actions.print-drop-off-receipt',
              message: 'Print drop-off receipt',
            })
          : undefined
      }
    >
      <IconReceipt />
      {!isMobile && (
        <Trans id="request.edit.actions.print-drop-off-receipt">Print drop-off receipt</Trans>
      )}
    </Button>
  );
};

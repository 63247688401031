import { useEffect, useMemo } from 'react';
import { getLocalTimeZone, isWeekday, isWeekend, today } from '@internationalized/date';
import { Trans, useLingui } from '@lingui/react/macro';

import BasicRadioGroup from '@/design_system/BasicRadioGroup';
import Button from '@/design_system/Button';
import { Calendar } from '@/design_system/InputDate';
import InputText from '@/design_system/InputText';
import RadioGroup from '@/design_system/RadioGroup';
import Stack from '@/design_system/Stack';
import Tooltip from '@/design_system/Tooltip';
import IconInfo from '@/icons/Info.svg';
import { AddressWithRelations } from '@/models/address';
import {
  ArticleWithRelations,
  SHIPMENT_CARRIER_SERVICES,
  SHIPMENT_CARRIERS,
  SHIPMENT_HANDOVER,
  ShipmentCarrierService,
  ShipmentHandover,
  useAvailableCarriers,
} from '@/models/shipment';
import { createBEMClasses } from '@/utils/classname';
import useViewPort from '@/utils/useViewport';

import './CarrierSelect.css';

const { block, element } = createBEMClasses('carrier-select');

export const CarrierSelect = ({
  articles,
  origin,
  destination,
  carrierService,
  setCarrierService,
  handover,
  setHandover,
  pickupDate,
  setPickupDate,
  trackingId,
  setTrackingId,
}: {
  articles: ArticleWithRelations[];
  origin?: AddressWithRelations;
  destination?: AddressWithRelations;
  carrierService?: ShipmentCarrierService;
  setCarrierService: (carrier: ShipmentCarrierService) => void;
  handover?: ShipmentHandover;
  setHandover: (handover?: ShipmentHandover) => void;
  pickupDate?: string;
  setPickupDate: (pickupDate?: string) => void;
  trackingId?: string;
  setTrackingId: (trackingId?: string) => void;
}) => {
  const { i18n, t } = useLingui();
  const { isMobile } = useViewPort();

  const organizationId =
    origin?.client?.organizationId ??
    destination?.client?.organizationId ??
    articles[0]?.request.organizationId;
  const originId = origin?.id;
  const destinationId = destination?.id;

  const isOriginOrDestinationQNDWorkshop =
    (!!origin?.workshop && origin.workshop.nonDigitalized) ||
    (!!destination?.workshop && destination.workshop.nonDigitalized);

  const { data: { carriers } = { carriers: [] }, isLoading } = useAvailableCarriers(
    {
      organizationId,
      originId,
      destinationId,
    },
    {
      enabled: !isOriginOrDestinationQNDWorkshop,
    }
  );

  useEffect(() => {
    if (carriers.length > 0 && !carrierService) {
      setCarrierService(carriers[0].service);

      const handoverOptions = carriers[0]?.handover ?? [];

      setHandover(handoverOptions.length === 1 ? handoverOptions[0] : undefined);
    }
  }, [carrierService, carriers, setCarrierService, setHandover]);

  // Minimum date for pickup
  // If today is a weekend, the minimum date for pickup is Monday
  const minDate = useMemo(() => {
    const todayLocal = today(getLocalTimeZone());

    if (isWeekday(todayLocal, i18n.locale)) {
      return todayLocal;
    }

    const tomorrowLocal = todayLocal.add({ days: 1 });

    if (isWeekday(tomorrowLocal, i18n.locale)) {
      return tomorrowLocal;
    }

    return tomorrowLocal.add({ days: 1 });
  }, [i18n.locale]);

  if (!originId || !destinationId) {
    return (
      <>
        <p>
          <span className="paragraph-100-medium">
            <Trans id="shipments.new.carrier.label">Carrier</Trans>
          </span>
          <br />
          <span className="paragraph-100-regular text-secondary">
            <Trans id="shipments.new.carrier.select-origin-destination">
              Select an origin and destination before choosing the carrier
            </Trans>
          </span>
        </p>
      </>
    );
  }

  if (!organizationId && articles.length === 0) {
    return (
      <>
        <p>
          <span className="paragraph-100-medium">
            <Trans id="shipments.new.carrier.label">Carrier</Trans>
          </span>
          <br />
          <span className="paragraph-100-regular text-secondary">
            <Trans id="shipments.new.carrier.select-article">
              Select at least one item before choosing the carrier
            </Trans>
          </span>
        </p>
      </>
    );
  }

  if (isLoading) {
    return (
      <p className="paragraph-100-medium">
        <Trans id="shipments.new.carrier.label">Carrier</Trans>
      </p>
    );
  }

  const chosenCarrier = carriers.find((carrier) => carrier.service === carrierService);

  const options: { value: string; text: string; subText: string }[] = carriers.map((carrier) => {
    const carrierName =
      SHIPMENT_CARRIERS.find(({ id }) => id === carrier.name)?.name ?? carrier.name;
    const carrierServiceName =
      SHIPMENT_CARRIER_SERVICES.find(({ id }) => id === carrier.service)?.name ?? carrier.service;

    return {
      value: carrier.service,
      text: `${carrierName} (${carrierServiceName})`,
      subText: t({
        id: 'shipment.carrier.carrier-service.description',
        message: 'Automatic shipping label generation with tracking included.',
      }),
    };
  });

  if (isOriginOrDestinationQNDWorkshop) {
    options.push({
      value: 'self-handled',
      text: t({ id: 'shipment.carrier.self-handled', message: 'Self-Handled delivery' }),
      subText: t({
        id: 'shipment.carrier.self-handled.description',
        message:
          'Handle the delivery yourself or via a third party (e.g. the recipient picks up the package).',
      }),
    });
  } else {
    options.push({
      value: 'private-carrier',
      text: t({ id: 'shipment.carrier.private-carrier', message: 'Private carrier' }),
      subText: t({
        id: 'shipment.carrier.private-carrier.description',
        message:
          'Manually generate the shipping label. Then input the tracking number to follow your package within the app.',
      }),
    });
  }

  return (
    <Stack className={block()} gap={isMobile ? '1rem' : '1.5rem'}>
      <RadioGroup
        customGridSize
        optionsClassName={element('options')}
        label={t({ id: 'shipments.new.carrier.label', message: 'Carrier' })}
        options={options}
        value={carrierService}
        onChange={(carrierService) => {
          setCarrierService(carrierService as ShipmentCarrierService);

          const handoverOptions =
            carriers.find((carrier) => carrier.service === carrierService)?.handover ?? [];

          setHandover(handoverOptions.length === 1 ? handoverOptions[0] : undefined);
          setPickupDate(undefined);
        }}
      />
      {chosenCarrier && chosenCarrier.handover.length > 0 && (
        <BasicRadioGroup
          label={t({ id: 'shipments.new.handover.label', message: 'Handover option' })}
          options={SHIPMENT_HANDOVER.filter((handover) =>
            chosenCarrier.handover.includes(handover.id)
          ).map(({ id, name }) => ({ value: id, children: i18n._(name) }))}
          value={handover ?? null}
          onChange={(handover) => {
            setHandover(handover as ShipmentHandover);
            setPickupDate(undefined);
          }}
        />
      )}
      {chosenCarrier && handover === 'pickup' && (
        <Stack gap="0.5rem">
          <p className="paragraph-100-regular">
            <Trans id="shipments.new.handover.pickup-date">Select a pickup date</Trans>
          </p>
          <Calendar
            minValue={minDate}
            isDateUnavailable={(date) => isWeekend(date, i18n.locale)}
            value={pickupDate}
            onChange={setPickupDate}
          />
        </Stack>
      )}
      {carrierService === 'private-carrier' && (
        <InputText
          label={
            <Stack row gap="4px" alignItems="baseline">
              <Trans id="shipments.new.tracking-number.label">Tracking Number</Trans>
              <span className="paragraph-200-regular">
                <Trans id="shipments.new.tracking-number.optional">(Optional)</Trans>
              </span>
              <Tooltip
                content={
                  <Trans id="shipments.new.tracking-number.tooltip">
                    You can add it later if you haven&apos;t already created the shipment on the
                    courier site
                  </Trans>
                }
              >
                <Button variant="style-less">
                  <IconInfo style={{ fontSize: '1rem', bottom: '-3px', position: 'relative' }} />
                </Button>
              </Tooltip>
            </Stack>
          }
          placeholder={t({
            id: 'shipments.new.tracking-number.placeholder',
            message: 'Add courier tracking number',
          })}
          value={trackingId}
          onChange={setTrackingId}
        />
      )}
    </Stack>
  );
};

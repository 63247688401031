import { ArticleWithRelations, RequestWithRelations } from '@/models/request';
import { UserWithRelations } from '@/models/user';
import { Workflow } from '@/models/workflow';
import {
  RequestArticleContextData,
  RequestContextData,
} from '@/routes/Requests/contexts/interfaces';

export const computeChooseArticleServiceContext = ({
  workflow,
  article,
  view,
}: {
  request: RequestWithRelations;
  requestView: RequestContextData['view'];
  workflow?: Workflow;
  article: ArticleWithRelations;
  view: RequestArticleContextData['view'];
  currentSession: UserWithRelations;
}) => {
  view.warranty.editable = true;

  /**
   * SERVICES
   */
  view.services.defects.shown = !!workflow?.config.defectsEnabled;
  view.services.defects.editable = true;

  view.services.choice.shown = true;
  view.services.choice.editable = true;

  view.services.actions.shown = article.serviceChoice === 'care-repair';
  view.services.actions.editable = true;
  view.services.actions.canAddRemoveAction = true;
  view.services.actions.canEditDefects = true;
  view.services.actions.canEditCost = true;
  view.services.actions.canEditPrice = true;

  view.services.dispatch.shown = article.serviceChoice === 'care-repair';
  view.services.dispatch.editable = true;

  view.services.none.shown = false;
  view.services.exchange.shown = false;
  view.services.refund.shown = false;
};

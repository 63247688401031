import { useLingui } from '@lingui/react/macro';

import config from '@/config';
import Button from '@/design_system/Button/Button';
import { MenuItem } from '@/design_system/Menu/Menu';
import Stack from '@/design_system/Stack';
import IconPrint from '@/icons/Print.svg';
import IconQrCode from '@/icons/QrCode.svg';
import { ShipmentWithRelations } from '@/models/shipment';
import { useBlobUrl } from '@/utils/useBlobUrl';

export const usePrintActions = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const shippingLabelUrl: string | null = useBlobUrl(shipment.shippingLabel, 'application/pdf');

  return {
    printShippingLabel: () => {
      // Hacky way to download a file, any other solution is welcome
      const a = document.createElement('a');
      a.href =
        shippingLabelUrl ?? `${config.apiUrl}/shipments/${shipment.id}/internal-shipping-label`;
      a.download = `${shipment.reference}.pdf`;
      a.click();
      a.remove();
    },
    printItemsSheets: () => {
      window.open(`${config.apiUrl}/shipments/${shipment.id}/recap`, '_blank');
    },
  };
};

export const PrintShippingLabelButton = ({ onPress }: { onPress: () => void }) => {
  const { t } = useLingui();
  const label = t({ id: 'shipment.actions.print-shipping-label', message: 'Print shipping label' });
  return (
    <Button
      variant="secondary"
      size="large"
      iconOnly
      onPress={onPress}
      ariaLabel={label}
      tooltip={label}
    >
      <IconPrint />
    </Button>
  );
};

export const PrintShippingLabelMenuItem = () => {
  const { t } = useLingui();
  const label = t({ id: 'shipment.actions.print-shipping-label', message: 'Print shipping label' });
  return (
    <MenuItem id="print-shipping-label" size="large">
      <Stack row gap="0.5rem" alignItems="center" className="paragraph-100-regular">
        <IconPrint style={{ fontSize: '1.25rem' }} />
        <span>{label}</span>
      </Stack>
    </MenuItem>
  );
};

export const PrintItemsSheetsButton = ({ onPress }: { onPress: () => void }) => {
  const { t } = useLingui();
  const label = t({
    id: 'shipment.actions.print-item-sheet',
    message: 'Print all item sheets',
  });

  return (
    <Button
      variant="secondary"
      size="large"
      iconOnly
      onPress={onPress}
      ariaLabel={label}
      tooltip={label}
    >
      <IconQrCode />
    </Button>
  );
};

export const PrintItemsSheetsMenuItem = () => {
  const { t } = useLingui();
  const label = t({
    id: 'shipment.actions.print-item-sheet',
    message: 'Print all item sheets',
  });

  return (
    <MenuItem id="print-items-sheets" size="large">
      <Stack row gap="0.5rem" alignItems="center" className="paragraph-100-regular">
        <IconQrCode style={{ fontSize: '1.25rem' }} />
        <span>{label}</span>
      </Stack>
    </MenuItem>
  );
};

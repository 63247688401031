import { useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button/Button';
import Stack from '@/design_system/Stack';
import CloseIcon from '@/icons/Cross.svg';
import { createBEMClasses } from '@/utils/classname';

import './PageLayout.css';

const { block, element } = createBEMClasses('page-layout');

export const PageLayout = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return <Stack className={block({}, className)}>{children}</Stack>;
};

export const PageLayoutHeader = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return <Stack className={element('header', {}, className)}>{children}</Stack>;
};

export const PageLayoutSimpleHeader = ({
  title,
  onClose,
}: {
  title: string;
  onClose: () => void;
}) => {
  const { t } = useLingui();
  return (
    <PageLayoutHeader className="simple-header">
      <h1 className="headline-200-bold">{title}</h1>
      <Button
        onPress={onClose}
        ariaLabel={t({ id: 'full-page-layout.close', message: 'Close' })}
        iconOnly
        size="medium"
        variant="neutral"
      >
        <CloseIcon />
      </Button>
    </PageLayoutHeader>
  );
};

export const PageLayoutContent = ({ children }: { children: React.ReactNode }) => {
  return (
    <Stack row flexWrap="nowrap" className={element('content')}>
      {children}
    </Stack>
  );
};

export const PageLayoutLeftPart = ({ children }: { children: React.ReactNode }) => {
  return <Stack className={element('left-part')}>{children}</Stack>;
};

export const PageLayoutLeftPartContent = ({ children }: { children: React.ReactNode }) => {
  return <Stack className={element('left-part-content')}>{children}</Stack>;
};

export const PageLayoutLeftPartFooter = ({ children }: { children: React.ReactNode }) => {
  return <Stack className={element('left-part-footer')}>{children}</Stack>;
};

export const PageLayoutRightPart = ({ children }: { children: React.ReactNode }) => {
  return <Stack className={element('right-part')}>{children}</Stack>;
};

export const PageLayoutTopPart = ({ children }: { children: React.ReactNode }) => {
  return <Stack className={element('top-part')}>{children}</Stack>;
};

export const PageLayoutCenter = ({ children }: { children: React.ReactNode }) => {
  return (
    <Stack
      style={{ height: '100%' }}
      gap="1rem"
      alignItems="center"
      flexWrap="nowrap"
      justifyContent="center"
    >
      {children}
    </Stack>
  );
};

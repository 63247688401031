import { useState } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';

import { PaymentStepConfig } from '@/api';
import Button from '@/design_system/Button';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import { useMedia } from '@/models/medium';
import { RequestWithRelations, useValidateExternalPayment } from '@/models/request';
import { isExternalPaymentReferenceInvalid } from '@/routes/Requests/Request/isExternalReferenceInvalid';
import { useCurrentSession } from '@/services/auth';
import useViewPort from '@/utils/useViewport';

export const ValidatePaymentAction = ({
  request,
  onPaymentValidate,
}: {
  request: RequestWithRelations;
  onPaymentValidate: () => void;
}) => {
  const { currentSession } = useCurrentSession();

  const stepConfig = request.articles.find((article) => article.step?.step === 'payment')?.step
    ?.config as PaymentStepConfig['config'];

  if (!stepConfig?.externalPayment) {
    return null;
  }

  if (
    !currentSession?.hasPermission('validate_external_payment', {
      organizationId: request.organizationId,
      storeId: request.storeId,
    })
  ) {
    return null;
  }

  return (
    <ActionBar request={request} stepConfig={stepConfig} onPaymentValidate={onPaymentValidate} />
  );
};

const ActionBar = ({
  request,
  stepConfig,
  onPaymentValidate,
}: {
  request: RequestWithRelations;
  stepConfig: Extract<PaymentStepConfig['config'], { externalPayment: true }>;
  onPaymentValidate: () => void;
}) => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();

  const {
    mutateAsync: validateExternalPayment,
    isPending: isLoadingValidateExternalPayment,
    isSuccess: isSuccessValidateExternalPayment,
  } = useValidateExternalPayment();

  const { data: { media } = { media: [] }, isLoading } = useMedia({
    requestId: request.id,
    types: ['proof-of-external-payment'],
  });

  const [showError, setShowError] = useState(false);

  const noProofOfExternalPayment =
    (isLoading || !media.length) &&
    t({
      id: 'request.payment.error-proof',
      message: 'Please upload a receipt / invoice',
    });
  const noValidPaymentReference =
    (!request.externalPaymentReference ||
      isExternalPaymentReferenceInvalid(
        request.externalPaymentReference,
        stepConfig.externalPaymentReferenceFormat
      )) &&
    t({
      id: 'request.payment.error-reference',
      message: 'Please add a valid receipt / invoice number',
    });

  const error = noProofOfExternalPayment || noValidPaymentReference;

  return (
    <>
      {showError && !!error && <Message type="error">{error}</Message>}
      <Stack alignItems={isMobile ? 'stretch' : 'flex-end'}>
        <Button
          onPress={async () => {
            setShowError(true);

            if (error) {
              return;
            }

            await validateExternalPayment(request.id);
            onPaymentValidate();
          }}
          isLoading={isLoadingValidateExternalPayment || isSuccessValidateExternalPayment}
        >
          <Trans id="request.payment.validate">Validate client payment</Trans>
        </Button>
      </Stack>
    </>
  );
};

import { useState } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';
import { parseISO } from 'date-fns';

import { ProductL1, ProductL2, ProductL3 } from '@/api';
import FileUpload from '@/components/FileUpload';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconArrow from '@/icons/Arrow.svg';
import { useUpdateArticle } from '@/models/article';
import { Medium, useDeleteMedium } from '@/models/medium';
import { Product } from '@/models/product';
import {
  ClientArticleWithRelations,
  ClientRequestWithRelations,
  useCreateDraftGuestRequestArticle,
} from '@/models/request';
import { ProgressBar } from '@/routes/Brand/Requests/New/components/ProgressBar/ProgressBar';
import { ProductPhoto } from '@/routes/Requests/Request/Article/ProductPhoto';
import { createBEMClasses } from '@/utils/classname';
import useViewPort from '@/utils/useViewport';

import OptionalInfo from './components/OptionalInfo';
import ProductInfo, { HasReferenceOption } from './components/ProductInfo';

import './ArticleForm.css';

const { block } = createBEMClasses('client-article-form');

const ArticleForm = ({
  onSaveArticle,
  onDiscard,
  request,
  initialArticle,
  initialProofOfPurchase,
}: {
  onSaveArticle: () => void;
  onDiscard: () => void;
  request: ClientRequestWithRelations;
  initialArticle?: ClientArticleWithRelations;
  initialProofOfPurchase?: Medium[];
}) => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();

  const initialArticlePhotos = initialArticle?.media.filter(
    (medium) => medium.type === 'article-photo'
  );

  const [hasReference, setHasReference] = useState<HasReferenceOption | undefined>(
    initialArticle ? (initialArticle.product ? 'yes' : 'no') : undefined
  );
  const [product, setProduct] = useState<Product | undefined>(initialArticle?.product ?? undefined);
  const [productL1, setProductL1] = useState<ProductL1 | undefined>(
    initialArticle?.productL1 ?? undefined
  );
  const [productL2, setProductL2] = useState<ProductL2 | undefined>(
    initialArticle?.productL2 ?? undefined
  );
  const [productL3, setProductL3] = useState<ProductL3 | undefined>(
    initialArticle?.productL3 ?? undefined
  );
  const [articlePhotos, setArticlePhotos] = useState<Medium[]>(initialArticlePhotos ?? []);
  const [color, setColor] = useState<string | undefined>(initialArticle?.data.color);
  const [size, setSize] = useState<string | undefined>(initialArticle?.data.size);
  const [purchaseDate, setPurchaseDate] = useState<string | undefined>(
    initialArticle?.purchaseDate ?? undefined
  );
  const [warranty, setWarranty] = useState<boolean>(initialArticle?.warranty ?? false);
  const [proofOfPurchase, setProofOfPurchase] = useState<Medium[]>(initialProofOfPurchase ?? []);

  const [mediaToDelete, setMediaToDelete] = useState<string[]>([]);

  const { mutate: deleteMedium } = useDeleteMedium();

  const { mutateAsync: createArticle, isPending: isPendingCreateArticle } =
    useCreateDraftGuestRequestArticle();

  const handleCreateArticle = async () => {
    mediaToDelete.forEach((id) => deleteMedium(id));

    await createArticle({
      id: request.id,
      articleData: {
        productId: product?.id,
        productL1,
        productL2,
        productL3,
        data: {
          color,
          size,
        },
        purchaseDate: request.client ? purchaseDate : undefined,
        warranty: request.client ? warranty : undefined,
        proofOfPurchaseIds: request.client ? proofOfPurchase.map(({ id }) => id) : undefined,
        defectPhotoIds: articlePhotos.map(({ id }) => id),
      },
    });

    onSaveArticle();
  };

  const { mutateAsync: updateArticle, isPending: isPendingUpdateArticle } = useUpdateArticle({
    articleId: initialArticle?.id ?? '',
    requestId: request.id,
  });

  const handleUpdateArticle = async () => {
    mediaToDelete.forEach((id) => deleteMedium(id));

    await updateArticle({
      data: {
        productId: product?.id ?? null,
        productL1: productL1 ?? null,
        productL2: productL2 ?? null,
        productL3: productL3 ?? null,
        data: {
          color,
          size,
        },
        purchaseDate: request.client ? purchaseDate : undefined,
        warranty: request.client ? warranty : undefined,
        proofOfPurchaseIds: request.client ? proofOfPurchase.map(({ id }) => id) : undefined,
        defectPhotoIds: articlePhotos.map(({ id }) => id),
      },
    });

    onSaveArticle();
  };

  const handleDiscard = () => {
    articlePhotos
      .filter(({ id }) => !initialArticlePhotos?.map(({ id }) => id)?.includes(id))
      .forEach(({ id }) => deleteMedium(id));

    proofOfPurchase
      .filter(({ id }) => !initialProofOfPurchase?.map(({ id }) => id)?.includes(id))
      .forEach(({ id }) => deleteMedium(id));

    onDiscard();
  };

  const disableSave =
    (!product && !productL2) ||
    articlePhotos.length === 0 ||
    (!!purchaseDate && parseISO(purchaseDate) > new Date());

  const progress = hasReference
    ? productL1
      ? productL2
        ? articlePhotos.length > 0
          ? 45
          : 35
        : 25
      : 15
    : 10;

  return (
    <div className={block()}>
      <main>
        <Stack gap="1.5rem">
          <h3 className="headline-300-bold">
            <Trans id="client.new.article.form.title">Tell us which item needs attention</Trans>
          </h3>
          <Stack gap="3rem">
            <ProductInfo
              hasReference={hasReference}
              setHasReference={(hasReference) => {
                setHasReference(hasReference);
                setColor(undefined);
                setSize(undefined);
              }}
              request={request}
              product={product}
              setProduct={setProduct}
              productL1={productL1}
              setProductL1={setProductL1}
              productL2={productL2}
              setProductL2={setProductL2}
              productL3={productL3}
              setProductL3={setProductL3}
              disabled={!!initialArticle && initialArticle.hasDefects}
            />

            {(!!product || !!productL2) && (
              <FileUpload
                uploadData={{ type: 'article-photo' }}
                type="photo"
                label={t({
                  id: 'client.new.article.form.photo.label',
                  message: 'Please upload a full-view photo of your item',
                })}
                maxNumberOfMedia={1}
                allowsMultiple={false}
                media={articlePhotos}
                onChange={setArticlePhotos}
                onDelete={(id) => setMediaToDelete([...mediaToDelete, id])}
                size="large"
                theme="brand"
                onMount={() => {
                  window.scroll({
                    top: document.body.scrollHeight,
                    behavior: 'smooth',
                  });
                }}
              />
            )}

            {!!product?.data?.image && (
              <ProductPhoto productName={product.name} productImage={product.data.image} />
            )}

            {(!!product || !!productL2) && articlePhotos.length > 0 && (
              <OptionalInfo
                product={product}
                productL1={productL1}
                color={color}
                setColor={setColor}
                size={size}
                setSize={setSize}
                purchaseDate={purchaseDate}
                setPurchaseDate={setPurchaseDate}
                warranty={warranty}
                setWarranty={setWarranty}
                proofOfPurchase={proofOfPurchase}
                setProofOfPurchase={setProofOfPurchase}
                onDeleteMedium={(id) => setMediaToDelete([...mediaToDelete, id])}
                request={request}
              />
            )}
          </Stack>
        </Stack>
      </main>
      <footer>
        {!initialArticle && <ProgressBar progress={progress} />}
        <Stack row gap={isMobile ? '1rem' : '2rem'}>
          <Button
            size="large"
            variant="secondary-brand"
            style={{ flex: 1 }}
            onPress={handleDiscard}
          >
            <IconArrow left />
            <Trans id="client.new.article.form.action.back">Back</Trans>
          </Button>
          <Button
            size="large"
            variant="brand"
            style={{ flex: 1 }}
            disabled={disableSave}
            onPress={() => {
              if (initialArticle) {
                handleUpdateArticle();
              } else {
                handleCreateArticle();
              }
            }}
            isLoading={isPendingCreateArticle || isPendingUpdateArticle}
          >
            <Trans id="client.new.article.form.action.continue">Continue</Trans>
            <IconArrow right />
          </Button>
        </Stack>
      </footer>
    </div>
  );
};

export default ArticleForm;

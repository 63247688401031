import { msg } from '@lingui/core/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import { BaseActivity } from '@/components/activities/BaseActivity/BaseActivity';
import IconFileNew from '@/icons/FileNew.svg';

export const RequestCreatedActivity = ({
  activity,
}: {
  activity: ActivityOfType<'request_created'>;
}) => {
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      icon={<IconFileNew />}
      type="primary"
      creatorName={
        activity.creator?.name ??
        activity.storeCreator?.name ??
        msg({
          id: 'activities.request_created.client',
          message: 'The client',
        })
      }
      message={msg({
        id: 'activities.request_created.message',
        message: 'created the request',
      })}
    />
  );
};

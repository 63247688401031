import { createContext, useContext, useState } from 'react';
import { useLingui } from '@lingui/react/macro';

import { Medium, useMedia } from '@/models/medium';
import { ArticleWithRelations, RequestWithRelations } from '@/models/request';
import { UserWithRelations } from '@/models/user';
import { Workflow } from '@/models/workflow';
import { useCurrentSession } from '@/services/auth';

import { computeCreationContext } from './steps/creation';
import { computeAcceptRequalificationContext } from './tasks/accept_requalification';
import { computeChooseArticleServiceContext } from './tasks/choose_article_service';
import { computeRequestErrors, recursivelyFilterErrors, RequestErrorsFilter } from './errors';
import { RequestArticleContextData, RequestContextData } from './interfaces';

export const RequestContext = createContext({} as RequestContextData);

export const RequestContextProvider = ({
  request,
  workflow,
  children,
}: {
  request: RequestWithRelations;
  workflow: Workflow | undefined;
  children: React.ReactNode;
}) => {
  const value = useComputedRequestContext({ request, workflow });

  return <RequestContext value={value}>{children}</RequestContext>;
};

export const useRequestContext = () => useContext(RequestContext);

function useComputedRequestContext({
  request,
  workflow,
}: {
  request: RequestWithRelations;
  workflow: Workflow | undefined;
}) {
  const { t } = useLingui();
  const { currentSession, isWorkshop } = useCurrentSession();
  const { data: { media: proofOfPurchaseMedia } = { media: [] } } = useMedia({
    articleId: request.allArticles.map((article) => article.id),
    types: ['proof-of-purchase'],
  });

  // Keep this variables for now because it's still unclear if we need to show the VAT info
  // or not based on the user roles, the type of request, etc.
  const showCostAmountBeforeTaxes = isWorkshop;
  const showPriceAmountBeforeTaxes = isWorkshop;

  const costLabel = t({
    id: 'request-context.cost',
    message: 'Internal cost',
  });
  const priceLabel = isWorkshop
    ? t({
        id: 'request-context.price.workshop',
        message: 'Price',
      })
    : t({
        id: 'request-context.price.organization',
        message: 'Client price',
      });
  const requestView: RequestContextData['view'] = {
    workshop: {
      enabled: false,
    },
    cost: {
      enabled: false,
      label: `${costLabel} (${
        showCostAmountBeforeTaxes
          ? t({ id: '_general.excl-vat', message: 'excl. VAT' })
          : t({ id: '_general.incl-vat', message: 'incl. VAT' })
      })`,
      labelWithoutVATInfo: costLabel,
      showAmountBeforeTaxes: showCostAmountBeforeTaxes,
    },
    price: {
      enabled: false,
      label: `${priceLabel} (${
        showPriceAmountBeforeTaxes
          ? t({ id: '_general.excl-vat', message: 'excl. VAT' })
          : t({ id: '_general.incl-vat', message: 'incl. VAT' })
      })`,
      labelWithoutVATInfo: priceLabel,
      showAmountBeforeTaxes: showPriceAmountBeforeTaxes,
    },
  };

  const firstActiveArticle = request.articles[0];

  if (firstActiveArticle?.step?.step === 'creation') {
    const creationConfig = firstActiveArticle.step.config;

    requestView.workshop.enabled = !!creationConfig?.requireServiceChoice;
    requestView.cost.enabled = !!creationConfig?.requireCost;
    requestView.price.enabled = !!creationConfig?.requirePrice && request.requestorType !== 'store';
  } else {
    requestView.workshop.enabled = !isWorkshop;
    requestView.cost.enabled = !isWorkshop;
    requestView.price.enabled = isWorkshop || (!isWorkshop && request.requestorType !== 'store');
  }

  const articles = request.allArticles.map((article) =>
    computeArticleContext({
      request,
      requestView,
      article,
      workflow,
      currentSession: currentSession!,
      proofOfPurchaseMedia: proofOfPurchaseMedia.filter((media) => media.articleId === article.id),
    })
  );

  const [errorsFilter, setErrorsFilter] = useState<RequestErrorsFilter | null>(null);

  const unfilteredErrors = computeRequestErrors({
    request,
    currentSession: currentSession!,
    proofOfPurchaseMedia,
  });

  const errors = recursivelyFilterErrors(unfilteredErrors, errorsFilter ?? {});

  return {
    request,
    workflow,
    view: requestView,
    articles,
    unfilteredErrors,
    errors,
    hasError: errors.hasError ?? false,
    state: {
      errors: {
        check(errorsFilter: RequestErrorsFilter | null) {
          setErrorsFilter(errorsFilter);

          // Directly returns the filtered errors, as the context hasn't re-rendered yet, so the state is not updated
          return recursivelyFilterErrors(unfilteredErrors, errorsFilter ?? {});
        },
      },
    },
  };
}

function computeArticleContext({
  request,
  requestView,
  workflow,
  article,
  currentSession,
  proofOfPurchaseMedia,
}: {
  request: RequestWithRelations;
  requestView: RequestContextData['view'];
  workflow: Workflow | undefined;
  article: ArticleWithRelations;
  currentSession: UserWithRelations;
  proofOfPurchaseMedia: Medium[];
}) {
  // 1. This represents the "base" view
  const view: RequestArticleContextData['view'] = {
    /**
     * WARRANTY
     */
    warranty: {
      shown: !!request.client && !!workflow?.config.allowWarranty,
      editable: false,
    },
    /**
     * SERVICES
     */
    services: {
      defects: {
        shown: !!workflow?.config.defectsEnabled,
        editable: false,
      },
      choice: {
        shown: false,
        editable: false,
      },
      actions: {
        shown: article.serviceChoice === 'care-repair',
        editable: false,
        canAddRemoveAction: false,
        canEditDefects: false,
        canEditCost: false,
        canEditPrice: false,
      },
      dispatch: {
        shown: !currentSession.workshop && article.serviceChoice === 'care-repair',
        editable: false,
      },
      none: {
        shown: !!article.serviceChoice && article.serviceChoice === 'none',
      },
      exchange: {
        shown: !!article.serviceChoice && article.serviceChoice === 'exchange',
      },
      refund: {
        shown: !!article.serviceChoice && article.serviceChoice === 'refund',
      },
    },
  };

  const computeArticleContextFromSteps = {
    creation: computeCreationContext,
  };

  // 2. We edit the base view, depending on the step
  computeArticleContextFromSteps[
    article.step?.step as keyof typeof computeArticleContextFromSteps
  ]?.({
    request,
    requestView,
    workflow,
    // @ts-ignore --- Trust me, I'm an engineer
    article,
    view,
    currentSession,
  });

  const computeArticleContextFromTasks = {
    accept_requalification: computeAcceptRequalificationContext,
    choose_article_service: computeChooseArticleServiceContext,
  };

  // 3. We edit the base+step view, depending on the task
  computeArticleContextFromTasks[
    article.task?.type as keyof typeof computeArticleContextFromTasks
  ]?.({
    request,
    requestView,
    workflow,
    article,
    view,
    currentSession,
  });

  return {
    articleId: article.id,
    view,
    proofOfPurchaseMedia,
  };
}

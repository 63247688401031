import { Trans, useLingui } from '@lingui/react/macro';

import IconCalendarReminder from '@/icons/CalendarReminder.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import type { TNotificationOfType } from '@/models/notification';

export const PendingClientPickupNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'pending_client_pickup'>;
}) => {
  const { t } = useLingui();

  return (
    <BaseNotification
      notificationLabel={t({
        id: 'notification.pending-client-pickup.label',
        message: `The request ${notification.request.reference} is pending client pickup`,
      })}
      icon={<IconCalendarReminder />}
      type="new"
      notification={notification}
      notificationHeader={
        <Trans id="notification.pending-client-pickup">
          The request is waiting for the client to pick up the items
        </Trans>
      }
    />
  );
};

import { msg } from '@lingui/core/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import { BaseActivity } from '@/components/activities/BaseActivity/BaseActivity';
import IconCheck from '@/icons/Check.svg';

export const RequestCompletedActivity = ({
  activity,
}: {
  activity: ActivityOfType<'request_completed'>;
}) => {
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="primary"
      icon={<IconCheck />}
      creatorName=""
      message={msg({
        id: 'activities.request_completed.message',
        message: 'The request has been completed and archived',
      })}
    />
  );
};

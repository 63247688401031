import { useState } from 'react';
import { useLingui } from '@lingui/react/macro';

import { VisibilitySelector } from '@/components/comments/VisibilitySelector/VisibilitySelector';
import { UserAvatar } from '@/components/UserAvatar/UserAvatar';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import TextArea from '@/design_system/TextArea';
import IconSend from '@/icons/Send.svg';
import { CommentVisibility } from '@/models/comment/comment';
import { Organization } from '@/models/organization';
import { User } from '@/models/user';
import { createBEMClasses } from '@/utils/classname';
import useViewPort from '@/utils/useViewport';

import './NewComment.css';

const { block, element } = createBEMClasses('new-comment');

export const NewComment = ({
  creator,
  currentOrganization,
  onCommentCreated,
}: {
  creator: User;
  currentOrganization: Organization | null;
  onCommentCreated: (data: { content: string; visibility: CommentVisibility }) => Promise<void>;
}) => {
  const { isMobile } = useViewPort();
  const { t } = useLingui();
  const [content, setContent] = useState('');

  const shouldDisplayActionsBar = content !== '';
  const externalWorkshop = creator.workshop?.external ? creator.workshop : null;

  const [visibility, setVisibility] = useState<CommentVisibility>(
    externalWorkshop ? 'public' : 'organization'
  );

  return (
    <div className={block()}>
      <Stack row gap="0.75rem" flexWrap="nowrap">
        {!isMobile && <UserAvatar user={creator} variant="primary" />}
        <Stack gap="0.5rem" className={element('container')}>
          <TextArea
            textAreaStyle={{
              padding: '8px 12px',
            }}
            ariaLabel={t({ id: 'new-comment.write-comment', message: 'Write a comment...' })}
            value={content ?? ''}
            rows={shouldDisplayActionsBar ? 4 : 1}
            onChange={(evt) => setContent(evt.target.value)}
            placeholder={t({ id: 'new-comment.write-comment', message: 'Write a comment...' })}
          />
          {shouldDisplayActionsBar && (
            <Stack
              row
              gap="0.5rem"
              justifyContent="space-between"
              alignItems="center"
              flexWrap="nowrap"
            >
              <VisibilitySelector
                visibility={visibility}
                setVisibility={setVisibility}
                currentOrganization={currentOrganization}
                currentExternalWorkshop={externalWorkshop}
              />

              <Button
                ariaLabel={t({ id: 'new-comment.add-comment', message: 'Add comment' })}
                tooltip={t({ id: 'new-comment.add-comment', message: 'Add comment' })}
                size="medium"
                onPress={() => {
                  onCommentCreated({ content, visibility }).then(() => setContent(''));
                }}
                iconOnly
              >
                <IconSend />
              </Button>
            </Stack>
          )}
        </Stack>
      </Stack>
    </div>
  );
};

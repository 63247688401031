import { useLingui } from '@lingui/react/macro';

import { InputMultiSelect } from '@/design_system/InputMultiSelect/InputMultiSelect';
import { SHIPMENT_CARRIERS, ShipmentCarrier } from '@/models/shipment';

export const CarrierSelect = ({
  selectedKeys,
  onSelectionChange,
}: {
  selectedKeys: ShipmentCarrier[];
  onSelectionChange: (keys: ShipmentCarrier[]) => void;
}) => {
  const { t } = useLingui();

  return (
    <InputMultiSelect
      variant="select"
      aria-label={t({ id: 'shipments.filter.carrier.label', message: 'Search by carrier' })}
      placeholder={t({ id: 'shipments.filter.carrier.placeholder', message: 'Carrier: All' })}
      value={SHIPMENT_CARRIERS.filter((shipmentCarrier) =>
        selectedKeys.includes(shipmentCarrier.id)
      )}
      options={SHIPMENT_CARRIERS}
      getOptionValue={(shipmentCarrier) => shipmentCarrier.id}
      getOptionLabel={(shipmentCarrier) => shipmentCarrier.name}
      onChange={(shipmentCarriers) => {
        const shipmentCarriersIds = shipmentCarriers.map(({ id }) => id);
        onSelectionChange([...shipmentCarriersIds]);
      }}
      onRemoveValue={(shipmentCarrierId) => {
        onSelectionChange(selectedKeys.filter((selectedKey) => selectedKey !== shipmentCarrierId));
      }}
      style={{ minWidth: '175px' }}
    />
  );
};

import { Trans, useLingui } from '@lingui/react/macro';

import {
  CustomDefectDropdownItem,
  DefectTypeDropdownItem,
} from '@/components/DefectsTable/DefectTypeDropdownItem';
import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import InputSearch from '@/design_system/InputSearch';
import Stack from '@/design_system/Stack';
import { DefectTypeOrganizationWithRelations, useDefectTypes } from '@/models/defectType';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';
import useDebouncedState from '@/utils/useDebouncedState';

export const DefectTypeSearchDialog = ({
  isOpen,
  onClose,
  onChooseDefectType,
}: {
  isOpen: boolean;
  onClose: () => void;
  onChooseDefectType: (defectType: DefectTypeOrganizationWithRelations | 'custom') => void;
}) => {
  const { t } = useLingui();
  const { workflow } = useRequestContext();
  const { article } = useArticleContext();
  const [query, debouncedQuery, setQuery] = useDebouncedState<string>('', 500);
  const { data: defectTypes = [], isFetching } = useDefectTypes(
    {
      articleId: article.id,
      query: debouncedQuery ?? undefined,
    },
    {
      keepPreviousData: true,
    }
  );

  const allowCustomDefects = !!workflow?.config.allowCustomDefects;

  return (
    <Dialog
      title={t({ id: 'defect-type-search-dialog.add-defect.label', message: 'Add a defect' })}
      isOpen={isOpen}
      onOpenChange={onClose}
      fullHeight
    >
      <main>
        <Stack gap="0.5rem">
          <InputSearch
            aria-label={t({
              id: 'defect-type-search-dialog.add-defect.label',
              message: 'Add a defect',
            })}
            placeholder={t({
              id: 'defect-type-search-dialog.add-defect.placeholder',
              message: 'Search a defect to add...',
            })}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            isLoading={isFetching || query !== debouncedQuery}
            value={query}
            onChange={setQuery}
          />

          {defectTypes.length === 0 && (
            <span className="paragraph-100-regular text-secondary" style={{ padding: '0.5rem 0' }}>
              <Trans id="defect-type-search-dialog.no-results">
                No results matching your search
              </Trans>
            </span>
          )}

          <DefectTypesMenu
            defectTypeOptions={defectTypes}
            onChooseDefectType={onChooseDefectType}
          />
        </Stack>
      </main>

      {allowCustomDefects && (
        <footer>
          <Button variant="secondary" onPress={() => onChooseDefectType('custom')}>
            <CustomDefectDropdownItem style={{ display: 'flex', justifyContent: 'center' }} />
          </Button>
        </footer>
      )}
    </Dialog>
  );
};

const DefectTypesMenu = ({
  defectTypeOptions,
  onChooseDefectType,
}: {
  defectTypeOptions: DefectTypeOrganizationWithRelations[];
  onChooseDefectType: (defectType: DefectTypeOrganizationWithRelations) => void;
}) => {
  return (
    <Stack>
      {defectTypeOptions.map((defectTypeOption) => (
        <DefectTypeMenuItem
          key={defectTypeOption.id}
          defectTypeOption={defectTypeOption}
          onChooseDefectType={onChooseDefectType}
        />
      ))}
    </Stack>
  );
};

const DefectTypeMenuItem = ({
  defectTypeOption,
  onChooseDefectType,
}: {
  defectTypeOption: DefectTypeOrganizationWithRelations;
  onChooseDefectType: (defectType: DefectTypeOrganizationWithRelations) => void;
}) => {
  return (
    <Button
      style={{ padding: '0.5rem 1rem' }}
      variant="style-less"
      onPress={() => onChooseDefectType(defectTypeOption)}
    >
      <DefectTypeDropdownItem defect={defectTypeOption} />
    </Button>
  );
};

import { useLingui } from '@lingui/react/macro';

import { InputMultiSelect } from '@/design_system/InputMultiSelect/InputMultiSelect';
import { SHIPMENT_STEPS, ShipmentStep } from '@/models/shipment';

export const ShipmentStepSelect = ({
  selectedKeys,
  onSelectionChange,
}: {
  selectedKeys: ShipmentStep[];
  onSelectionChange: (keys: ShipmentStep[]) => void;
}) => {
  const { t, i18n } = useLingui();

  return (
    <InputMultiSelect
      variant="select"
      aria-label={t({ id: 'shipments.filter.status.label', message: 'Search by status' })}
      placeholder={t({ id: 'shipments.filter.status.placeholder', message: 'Status: All' })}
      value={SHIPMENT_STEPS.filter((shipmentStep) => selectedKeys.includes(shipmentStep.id))}
      options={SHIPMENT_STEPS}
      getOptionValue={(shipmentStep) => shipmentStep.id}
      getOptionLabel={(shipmentStep) => i18n._(shipmentStep.name)}
      onChange={(shipmentSteps) => {
        const shipmentStepsIds = shipmentSteps.map(({ id }) => id);
        onSelectionChange([...shipmentStepsIds]);
      }}
      onRemoveValue={(shipmentStepId) => {
        onSelectionChange(selectedKeys.filter((selectedKey) => selectedKey !== shipmentStepId));
      }}
      style={{ minWidth: '175px' }}
    />
  );
};

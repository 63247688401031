import React from 'react';
import { msg } from '@lingui/core/macro';
import { useLingui } from '@lingui/react';
import cn from 'classnames';

import Box from '@/design_system/Box';
import IconCancel from '@/icons/Cancel.svg';
import IconDone from '@/icons/Done.svg';
import IconZendesk from '@/icons/Zendesk.svg';
import {
  ARTICLE_ARCHIVAL_TYPES,
  ARTICLE_CANCELLATION_TYPES,
  ARTICLE_DISPLAYED_STEPS,
} from '@/models/article';
import { RequestWithRelations } from '@/models/request';
import useViewPort from '@/utils/useViewport';

import './RequestDisplayedSteps.css';

type Step = {
  label: string;
  icon?: React.ReactNode;
  variant?: 'success' | 'warning' | 'danger';
};

const useRequestDisplayedSteps = (request: RequestWithRelations): Step[] => {
  const { _ } = useLingui();

  const steps = request.allArticles
    .map((article) => {
      const { archivalDetail, cancellationDetail, displayedStep, hasIssue } = article;
      const isArchived = !!article.archivedAt;
      const archivalType = ARTICLE_ARCHIVAL_TYPES.find(
        (articleArchivalType) => articleArchivalType.id === archivalDetail?.type
      );
      const cancellationType = ARTICLE_CANCELLATION_TYPES.find(
        (articleCancellationType) => articleCancellationType.id === cancellationDetail?.type
      );
      const articleDisplayedStep = ARTICLE_DISPLAYED_STEPS.find(({ id }) => displayedStep === id);
      const articleExportedToZendesk = article.archivalDetail?.exportedTo === 'zendesk';

      if (isArchived && cancellationType) {
        const label = articleDisplayedStep
          ? `${_(cancellationType.name)}: ${_(articleDisplayedStep.name)}`
          : _(cancellationType.name);

        return {
          label,
          icon: <IconCancel />,
          variant: 'danger',
        };
      }

      if (isArchived && archivalType?.id === 'completed') {
        const label = articleDisplayedStep
          ? `${_(archivalType.name)}: ${_(articleDisplayedStep.name)}`
          : _(archivalType.name);

        return {
          label,
          icon: <IconDone />,
          variant: 'success',
        };
      }

      if (articleExportedToZendesk) {
        return {
          label: _(msg({ id: 'article.archival-type.zendesk', message: 'Transferred to Zendesk' })),
          icon: <IconZendesk />,
        };
      }

      if (isArchived && archivalType) {
        const label = articleDisplayedStep
          ? `${_(archivalType.name)}: ${_(articleDisplayedStep.name)}`
          : _(archivalType.name);

        return {
          label,
          icon: <IconCancel />,
          variant: 'danger',
        };
      }

      if (hasIssue) {
        const troubleshootingLabel = msg({
          id: 'article.issue.troubleshooting',
          message: 'Troubleshooting',
        });

        const label = articleDisplayedStep
          ? `${_(troubleshootingLabel)}: ${_(articleDisplayedStep.name)}`
          : _(troubleshootingLabel);

        return {
          label,
          variant: 'danger',
        };
      }

      if (articleDisplayedStep) {
        return { label: _(articleDisplayedStep.name) };
      }
    })
    .filter((step) => !!step) as Step[];

  return groupStepsByQuantity(steps);
};

/**
 *
 * Formats a list of steps so that steps of the same label are merged into a single step, with the number of duplicates in the label.
 * Example: [{ label: 'Archived: Service Choice (x2)' }]
 */

const groupStepsByQuantity = (steps: Step[]): Step[] => {
  const stepsWithQuantity = steps.reduce<(Step & { quantity: number })[]>((acc, step) => {
    const existingStep = acc.find((accStep) => areStepsDuplicates(step, accStep));

    if (!existingStep) {
      acc.push(
        Object.assign(step, {
          quantity: 1,
        })
      );
    } else {
      existingStep.quantity = existingStep.quantity + 1;
    }

    return acc;
  }, []);

  return formatStepLabelsWithQuantity(stepsWithQuantity);
};

/**
 *
 * @returns Returns true if both steps have the same label
 */
const areStepsDuplicates = (step1: Step, step2: Step): boolean => {
  return step1.label === step2.label;
};

/**
 *
 * @returns Steps with their label formatted if they have duplicates. Example: [{ label: 'Archived: Service Choice (x2)' }]
 */
const formatStepLabelsWithQuantity = (
  stepsWithQuantity: (Step & { quantity: number })[]
): Step[] => {
  return stepsWithQuantity.map((stepWithQuantity) => {
    const newLabel =
      stepWithQuantity.quantity > 1
        ? `${stepWithQuantity.label} (x${stepWithQuantity.quantity})`
        : stepWithQuantity.label;
    return { ...stepWithQuantity, label: newLabel };
  });
};

export const RequestDisplayedSteps = ({ request }: { request: RequestWithRelations }) => {
  const displayedSteps = useRequestDisplayedSteps(request);
  const { isMobile } = useViewPort();

  if (isMobile) {
    return (
      <>
        {displayedSteps.map((step, index) => {
          return (
            <Box padding="0.25rem 0.5rem" style={{ flex: 'none' }} key={index}>
              <div
                className={cn('request-displayed-step paragraph-200-regular', {
                  'has-icon': !!step.icon,
                  'text-success': step.variant === 'success',
                  'text-warning': step.variant === 'warning',
                  'text-danger': step.variant === 'danger',
                })}
              >
                {step.icon}
                {step.label}
              </div>
            </Box>
          );
        })}
      </>
    );
  }

  // Display step icons if all steps have an icon otherwise display bullets to avoid icon misalignment
  const displayStepIcons = displayedSteps.every((step) => !!step.icon);

  return (
    <ul
      style={
        displayedSteps.length <= 1 || displayStepIcons
          ? { listStyle: 'none', padding: 0 }
          : undefined
      }
    >
      {!displayedSteps.length && <li>-</li>}
      {!!displayedSteps.length &&
        displayedSteps.map((step, index) => (
          <li
            key={index}
            className={cn('request-displayed-step', {
              'has-icon': displayStepIcons && !!step.icon,
              'text-success': step.variant === 'success',
              'text-warning': step.variant === 'warning',
              'text-danger': step.variant === 'danger',
            })}
          >
            {displayStepIcons && step.icon}
            {step.label}
          </li>
        ))}
    </ul>
  );
};

import { Trans } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import IconReceipt from '@/icons/Receipt.svg';
import { ClientRequestWithRelations, useRequestInvoice } from '@/models/request';

const Invoice = ({ request }: { request: ClientRequestWithRelations }) => {
  const hasBeenPaid = request.articles.some((article) => article.paidAt);

  const {
    data: invoice,
    isLoading,
    isError,
  } = useRequestInvoice(request.id, { enabled: hasBeenPaid });

  if (!hasBeenPaid) {
    return null;
  }

  // We do not show the download button if this is not an invoice (e.g: external payment proof)
  if (isLoading || invoice?.type !== 'invoice') {
    return null;
  }

  return (
    <div style={{ alignSelf: 'flex-end' }}>
      <Button
        size="small"
        variant="secondary-brand"
        isLoading={isLoading}
        disabled={isLoading || isError}
        href={invoice?.url}
        download="invoice.pdf"
        target="_blank"
      >
        <Trans id="client.request.repair.download-invoice">Download invoice</Trans>
        <IconReceipt />
      </Button>
    </div>
  );
};

export default Invoice;

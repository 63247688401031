import { useLingui } from '@lingui/react/macro';

import InputSearch from '@/design_system/InputSearch';

export const TransactionSearch = ({
  search,
  debouncedSetSearch,
}: {
  search: string;
  debouncedSetSearch: (value: string) => void;
}) => {
  const { t } = useLingui();

  return (
    <InputSearch
      placeholder={t({
        id: 'accounting.transactions.search.placeholder',
        message: 'Search transaction...',
      })}
      ariaLabel={t({
        id: 'accounting.transactions.search.placeholder',
        message: 'Search transaction...',
      })}
      style={{ flex: 1, minWidth: 175 }}
      value={search}
      onChange={debouncedSetSearch}
      size="medium"
    />
  );
};

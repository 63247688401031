import { lazy, Suspense, useRef, useState } from 'react';
import { Button as AriaButton, Dialog, Modal, ModalOverlay } from 'react-aria-components';
import { Trans, useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import InputText from '@/design_system/InputText';
import IconCross from '@/icons/Cross.svg';
import IconError from '@/icons/Error.svg';
import IconKeyboard from '@/icons/Keyboard.svg';
import IconScan from '@/icons/Scan.svg';
import { createBEMClasses } from '@/utils/classname';
import useDebouncedState from '@/utils/useDebouncedState';

const Scanner = lazy(() => import('./Scanner'));

const { block, element } = createBEMClasses('scan');

const Mobile = ({
  onClose,
  onResult,
  resultStatus,
}: {
  onClose: () => void;
  onResult: (code: string) => void;
  resultStatus: 'idle' | 'loading' | 'success' | 'error';
}) => {
  const { t } = useLingui();
  const [state, setState] = useState<'scan' | 'input' | 'error'>('scan');
  const inputRef = useRef<HTMLInputElement>(null);
  const [text, , setText] = useDebouncedState<string>('', 1000, onResult);

  return (
    <ModalOverlay
      className={block({
        mobile: true,
        status: resultStatus,
        state,
      })}
      isDismissable
      isOpen
      onOpenChange={onClose}
    >
      <Modal className={element('modal')}>
        <Dialog
          aria-label={t({ id: 'scan.mobile.title', message: 'Scanning' })}
          className={element('modal__dialog')}
        >
          <Suspense
            fallback={
              <div>
                <Trans id="_general.loading">Loading...</Trans>
              </div>
            }
          >
            <Scanner
              onInitializationFail={() => setState('error')}
              onResult={onResult}
              resultStatus={resultStatus}
            />
          </Suspense>
          <Button
            className={element('modal__dialog__close')}
            variant="secondary"
            iconOnly
            size="large"
            onPress={onClose}
          >
            <IconCross />
          </Button>
          <InputText
            className={element('modal__dialog__input')}
            aria-label={t({ id: 'scan.mobile.input.label', message: 'Manual reference input' })}
            ref={inputRef}
            value={text}
            onChange={setText}
            size="large"
            isInvalid={resultStatus === 'error'}
            isSuccess={resultStatus === 'success'}
            messageType={
              resultStatus === 'error'
                ? 'error'
                : resultStatus === 'success'
                  ? 'success'
                  : undefined
            }
            messageText={
              resultStatus === 'error'
                ? t({
                    id: 'scan.mobile.input.message.error',
                    message: 'Reference not recognized',
                  })
                : resultStatus === 'success'
                  ? t({ id: 'scan.mobile.input.message.success', message: 'Redirecting...' })
                  : undefined
            }
          />
          <AriaButton
            className={element('modal__dialog__switch')}
            onPress={() => {
              if (state === 'scan') {
                setState('input');
                inputRef.current?.focus();
              } else {
                setState('scan');
              }
            }}
          >
            {state === 'scan' && (
              <>
                <IconKeyboard />
                <Trans id="scan.mobile.button.scan">Enter reference</Trans>
              </>
            )}
            {state === 'input' && (
              <>
                <IconScan />
                <Trans id="scan.mobile.button.input">Scan reference</Trans>
              </>
            )}
          </AriaButton>
          {state === 'error' && (
            <div className={element('fail-state')}>
              <IconError />
              <h3 className="paragraph-50-medium">
                <Trans id="scan.mobile.error.title">Device unauthorized</Trans>
              </h3>
              <p className="paragraph-100-regular">
                <Trans id="scan.mobile.error.text">
                  Please verify that you have a camera and that you have authorized the use of it
                  for this website.
                </Trans>
              </p>
            </div>
          )}
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
};

export default Mobile;

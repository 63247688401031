import { useState } from 'react';

import { DefectTypeOrganizationWithRelations } from '@/models/defectType';

import { CreateEditDefectDialog } from './CreateEditDefectDialog';
import { DefectTypeSearchDialog } from './DefectTypeSearchDialog';

export const CreateDefectDialogs = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  const [defectType, setDefectType] = useState<DefectTypeOrganizationWithRelations | 'custom'>();

  return (
    <>
      <DefectTypeSearchDialog
        isOpen={isOpen && !defectType}
        onClose={() => setIsOpen(false)}
        onChooseDefectType={setDefectType}
      />
      <CreateEditDefectDialog
        isOpen={isOpen && !!defectType}
        defectTypeToCreate={defectType}
        onClose={() => {
          setIsOpen(false);
          setDefectType(undefined);
        }}
      />
    </>
  );
};

import { useEffect } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import { useShowToast } from '@/design_system/Toast';
import IconChevron from '@/icons/Chevron.svg';
import { getBrowserLocale, Locale } from '@/services/i18n';

const formIdByLocale: { [key in Locale]: string } = {
  fr: 'nrAANv',
  en: 'wMRYzM',
};
const widgetScriptSrc = 'https://tally.so/widgets/embed.js';

const loadTally = () => {
  const load = () => {
    // Load Tally embeds
    if (typeof window.Tally !== 'undefined') {
      window.Tally.loadEmbeds();
    }
  };

  // If Tally is already loaded, load the embeds
  if (typeof window.Tally !== 'undefined') {
    load();
    return;
  }

  // If the Tally widget script is not loaded yet, load it
  if (document.querySelector(`script[src="${widgetScriptSrc}"]`) === null) {
    const script = document.createElement('script');
    script.src = widgetScriptSrc;
    script.onload = load;
    script.onerror = load;
    document.body.appendChild(script);
    return;
  }
};

/**
 * Hook to open/close the Help & Feedback form created with Tally
 */
export const useHelpFormPopup = () => {
  const { t } = useLingui();
  const showToast = useShowToast();

  useEffect(() => {
    loadTally();
  }, []);

  const openForm = () => {
    if (window.Tally) {
      window.Tally.openPopup(formIdByLocale[getBrowserLocale()], {
        layout: 'modal',
        width: 800,
        autoClose: 0,
      });
    } else {
      showToast({
        type: 'error',
        text: t({
          id: 'help-form.error.tally-not-loaded',
          message: 'Unable to load the Help & Feedback form. Please try again later.',
        }),
      });
    }
  };

  const closeForm = () => {
    if (window.Tally) {
      window.Tally.closePopup(formIdByLocale[getBrowserLocale()]);
    }
  };

  return { openForm, closeForm };
};

/**
 * Component to display the Help & Feedback form created with Tally as a "fullscreen" component
 */
export const HelpForm = () => {
  useEffect(() => {
    loadTally();
  }, []);

  return (
    <Stack style={{ height: '100%', width: '100%' }}>
      <Stack row alignItems="center" gap="1rem">
        <Button iconOnly variant="secondary" to="/account">
          <IconChevron left />
        </Button>
        <h1 className="headline-200-bold-mobile">
          <Trans id="help-form.title">Help & Feedback</Trans>
        </h1>
      </Stack>
      <iframe
        style={{ border: 0, height: '100%', margin: '0 -16px -16px -16px', flexGrow: 1 }}
        title="Help & Feedback"
        src={`https://tally.so/r/${formIdByLocale[getBrowserLocale()]}?hideTitle=1`}
      ></iframe>
    </Stack>
  );
};

import { Trans } from '@lingui/react/macro';

import Stack from '@/design_system/Stack';
import { useArticleName } from '@/models/article';
import { useColorName } from '@/models/product';
import { ClientArticleWithRelations } from '@/models/request';
import { createBEMClasses } from '@/utils/classname';

import './ArticleSummary.css';

const { block, element } = createBEMClasses('article-summary');

export const ArticleSummary = ({
  article,
  size = 'medium',
}: {
  article: ClientArticleWithRelations;
  size?: 'small' | 'medium';
}) => {
  const articleName = useArticleName({ article, type: 'short' });
  const colorName = useColorName(article.data.color);

  return (
    <Stack row gap="0.5rem" alignItems="center" className={block()}>
      <img className={element('photo', { size })} src={article.articlePhoto?.url} alt="" />
      <Stack>
        <p className="paragraph-100-medium paragraph-50-medium-mobile">{articleName}</p>
        <p className="paragraph-200-regular paragraph-100-regular-mobile">
          {colorName}
          {colorName && article.data.size && ' • '}
          {article.data.size && (
            <Trans id="client.components.article-summary.size">Size {article.data.size}</Trans>
          )}
        </p>
      </Stack>
    </Stack>
  );
};

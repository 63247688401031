import { msg } from '@lingui/core/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import { BaseActivity } from '@/components/activities/BaseActivity/BaseActivity';
import IconPaymentValidation from '@/icons/PaymentValidation.svg';

export const PaymentCompletedActivity = ({
  activity,
}: {
  activity: ActivityOfType<'payment_completed'>;
}) => {
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="primary"
      icon={<IconPaymentValidation />}
      creatorName={msg({
        id: 'activities.payment_completed.client',
        message: 'The client',
      })}
      message={msg({
        id: 'activities.payment_completed.message',
        message: 'paid the quote',
      })}
    />
  );
};

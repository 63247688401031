import { Trans } from '@lingui/react/macro';

import Stack from '@/design_system/Stack';
import { ArticleWithRelations } from '@/models/request';

import { BaseMessage } from './BaseMessage';

export const AcceptRequalificationTaskMessage = ({
  article,
}: {
  article: ArticleWithRelations;
}) => {
  return (
    <BaseMessage
      title={
        <Trans id="article.task.accept-requalification.title">
          Review the re-qualification proposal
        </Trans>
      }
      message={
        <Stack gap="0.25rem" style={{ flex: 1 }}>
          <p>
            <Trans id="article.task.accept-requalification.message">
              Please review the re-qualification proposed by the workshop.
            </Trans>
          </p>
          {!!article.requalificationComment && (
            <div
              style={{
                borderRadius: '8px',
                padding: '8px 12px',
                backgroundColor: 'var(--color-warning-100)',
                whiteSpace: 'pre-wrap',
              }}
            >
              &quot;{article.requalificationComment}&quot;
            </div>
          )}
        </Stack>
      }
      article={article}
    />
  );
};

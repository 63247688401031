import { Trans } from '@lingui/react/macro';

import ContactDetails from '@/components/ContactDetails';
import { UpdateStore } from '@/components/Store/UpdateStore';
import AlertBar from '@/design_system/AlertBar';
import Box from '@/design_system/Box';
import Stack from '@/design_system/Stack';
import { Store, useUpdateExternalStore } from '@/models/store';

export const ExternalStoreInfo = ({ store }: { store: Store }) => {
  const { mutateAsync: updateExternalStore, isPending } = useUpdateExternalStore();

  return (
    <Stack gap="1rem">
      <Box gap="0.75rem" padding="12px 16px">
        <Stack row justifyContent="space-between" alignItems="center">
          <p className="paragraph-50-medium">
            <Trans id="client.new.welcome.store-info.label">Store information</Trans>
          </p>
          <UpdateStore
            store={store}
            isPending={isPending}
            updateStore={async (store) => {
              await updateExternalStore(store);
            }}
            variant="brand"
            size="large"
          />
        </Stack>
        <hr />
        <ContactDetails
          type="store"
          name={store.name}
          email={store.address?.contactEmail}
          address={store.address}
          phone={store.phone}
        />
      </Box>
      <AlertBar
        type="warning"
        size="large"
        title={
          <p className="paragraph-100-regular text-secondary">
            <Trans id="client.new.welcome.store-info.warning">
              Please make sure that all your store details are correct. This information is used by
              the carrier when picking up and delivering the items.
            </Trans>
          </p>
        }
      />
    </Stack>
  );
};

/**
 * Check if two arrays are similar by comparing their items.
 * If a key is provided, the comparison is done on the value of that key, otherwise the items are compared directly.
 *
 * @param array1 The first array to compare.
 * @param array2 The second array to compare.
 * @param key The key to use for comparison. If not provided, the items are compared directly.
 */
export function areSimilar<T>(array1: T[], array2: T[], key?: keyof T): boolean {
  if (array1.length !== array2.length) {
    return false;
  }

  const areAllItemsOfArray1InArray2 = array1.every((item1) => {
    const item2 = array2.find((item2) => (key ? item2[key] === item1[key] : item2 === item1));

    return !!item2;
  });

  const areAllItemsOfArray2InArray1 = array2.every((item2) => {
    const item1 = array1.find((item1) => (key ? item1[key] === item2[key] : item1 === item2));

    return !!item1;
  });

  return areAllItemsOfArray1InArray2 && areAllItemsOfArray2InArray1;
}

/**
 * Returns a new array with all duplicates removed, based on the key provided.
 * The first occurrence of each duplicate is kept.
 * If no key is provided, the array elements themselves are used to determine uniqueness.
 *
 * @param arr The array to remove duplicates from.
 * @param key The key to use to determine uniqueness.
 */
export function unique<T extends object | string>(arr: T[] | Readonly<T[]>, key?: keyof T): T[] {
  const seen = new Set();
  return arr.filter((item) => {
    const k = key ? item[key] : item;
    if (seen.has(k)) {
      return false;
    } else {
      seen.add(k);
      return true;
    }
  });
}

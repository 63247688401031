import { MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/core/macro';

import { PriceAmountWithDetails, PriceFeeDetail } from '@/api';

type Row = {
  label: MessageDescriptor;
  amount: number;
  highlight?: boolean;
  type?: 'fee';
  subType?: 'cancellation' | 'shipping-management';
};

export function usePriceDetailsRows(
  price: PriceAmountWithDetails | undefined | null,
  options: { showAmountBeforeTaxes?: boolean; showTaxDetails?: boolean }
): Row[][] {
  if (!price) {
    return [];
  }

  const showTaxDetails = options.showTaxDetails;
  const showAmountBeforeTaxes =
    options.showTaxDetails === true ? false : options.showAmountBeforeTaxes;
  const sections: Row[][] = [];

  const base = price.details.find((detail) => detail.type === 'base');
  const fees = price.details.filter((detail) => detail.type === 'fee');
  const hasFees = fees.some((fee) => fee.amount > 0);

  // Add the services section
  if (base) {
    // 1. Add the services row
    const rows: Row[] = [
      {
        label: msg({ id: 'price-with-details.rows.services', message: 'Services' }),
        amount: showAmountBeforeTaxes || showTaxDetails ? base.amountBeforeTaxes : base.amount,
        highlight: !showTaxDetails,
      },
    ];

    // 2. Deal with discounts
    const discount = price.details
      .filter((detail) => detail.type === 'discount' && detail.subType !== 'refashion')
      .reduce(
        (acc, detail) => {
          acc.amount += detail.amount;
          acc.amountBeforeTaxes += detail.amountBeforeTaxes;

          detail.taxes.forEach((tax) => {
            if (!acc.taxes.find((t) => t.rate === tax.rate)) {
              acc.taxes.push({
                rate: tax.rate,
                amount: 0,
              });
            }

            acc.taxes.find((t) => t.rate === tax.rate)!.amount += tax.amount;
          });

          return acc;
        },
        {
          amount: 0,
          amountBeforeTaxes: 0,
          currency: price.currency,
          taxes: [] as PriceAmountWithDetails['taxes'],
        }
      );
    const hasDiscount = !!discount.amountBeforeTaxes;
    if (hasDiscount) {
      rows.push({
        label: msg({ id: 'price-with-details.rows.discount', message: 'Discount' }),
        amount:
          showAmountBeforeTaxes || showTaxDetails ? discount.amountBeforeTaxes : discount.amount,
      });

      if (showTaxDetails) {
        rows.push({
          label: hasFees
            ? showAmountBeforeTaxes || showTaxDetails
              ? msg({
                  id: 'price-with-details.rows.total-services--with-discount',
                  message: 'Total services with discount',
                })
              : msg({
                  id: 'price-with-details.rows.total-services-with-discount-incl-vat',
                  message: 'Total services with discount (incl. VAT)',
                })
            : showAmountBeforeTaxes || showTaxDetails
              ? msg({
                  id: 'price-with-details.rows.total-with-discount',
                  message: 'Total with discount',
                })
              : msg({
                  id: 'price-with-details.rows.total-with-discount-incl-vat',
                  message: 'Total with discount (incl. VAT)',
                }),
          amount:
            showAmountBeforeTaxes || showTaxDetails
              ? base.amountBeforeTaxes + discount.amountBeforeTaxes
              : base.amount + discount.amount,
          highlight: !showTaxDetails,
        });
      }
    }

    // 3. Add the taxes and total if we want all the details
    if (showTaxDetails) {
      const baseAndDiscountTaxes = [...base.taxes, ...discount.taxes].reduce(
        (acc, tax) => {
          if (!acc.find((t) => t.rate === tax.rate)) {
            acc.push({
              rate: tax.rate,
              amount: 0,
            });
          }

          acc.find((t) => t.rate === tax.rate)!.amount += tax.amount;

          return acc;
        },
        [] as PriceAmountWithDetails['taxes']
      );

      baseAndDiscountTaxes.forEach((tax) => {
        // We display 0% VAT to make it clear that the price is tax-inclusive
        if (tax.amount > 0 || tax.rate === 0) {
          rows.push({
            label: msg({ id: 'price-with-details.rows.vat-rate', message: `VAT ${tax.rate}%` }),
            amount: tax.amount,
          });
        }
      });

      if (hasDiscount) {
        rows.push({
          label: hasFees
            ? msg({
                id: 'price-with-details.rows.total-services-with-discount-incl-vat',
                message: 'Total services with discount (incl. VAT)',
              })
            : msg({
                id: 'price-with-details.rows.total-with-discount-incl-vat',
                message: 'Total with discount (incl. VAT)',
              }),
          amount: base.amount + discount.amount,
          highlight: true,
        });
      } else {
        rows.push({
          label: hasFees
            ? msg({
                id: 'price-with-details.rows.total-services-incl-vat',
                message: 'Total services (incl. VAT)',
              })
            : msg({
                id: 'price-with-details.rows.total-incl-vat',
                message: 'Total (incl. VAT)',
              }),
          amount: base.amount,
          highlight: true,
        });
      }
    }

    // 4. Deal with refashion bonus
    const refashion = price.details.find(
      (detail) => detail.type === 'discount' && detail.subType === 'refashion'
    );
    const hasRefashion = !!refashion?.amount;
    if (hasRefashion) {
      rows.push({
        label: msg({ id: 'price-with-details.rows.refashion-bonus', message: 'Refashion bonus' }),
        amount: refashion.amount,
      });

      if (showTaxDetails && hasDiscount) {
        rows.push({
          label: hasFees
            ? msg({
                id: 'price-with-details.rows.total-services-with-discount-and-bonus-incl-vat',
                message: 'Total services with discount and bonus (incl. VAT)',
              })
            : msg({
                id: 'price-with-details.rows.total-with-discount-and-bonus-incl-vat',
                message: 'Total with discount and bonus (incl. VAT)',
              }),
          amount: base.amount + discount.amount + refashion.amount,
          highlight: true,
        });
      } else if (showTaxDetails) {
        rows.push({
          label: hasFees
            ? msg({
                id: 'price-with-details.rows.total-services-with-bonus-incl-vat',
                message: 'Total services with bonus (incl. VAT)',
              })
            : msg({
                id: 'price-with-details.rows.total-with-bonus-incl-vat',
                message: 'Total with bonus (incl. VAT)',
              }),
          amount: base.amount + discount.amount + refashion.amount,
          highlight: true,
        });
      }
    }

    sections.push(rows);
  }

  // Add the fees section
  if (hasFees) {
    const feesGroupedByType = fees.reduce(
      (acc, currentFee) => {
        const fee = acc.get(currentFee.subType) ?? {
          type: currentFee.subType,
          amount: 0,
          amountBeforeTaxes: 0,
          taxes: [] as PriceAmountWithDetails['taxes'],
        };

        fee.amount += currentFee.amount;
        fee.amountBeforeTaxes += currentFee.amountBeforeTaxes;

        currentFee.taxes.forEach((tax) => {
          if (!fee.taxes.find((t) => t.rate === tax.rate)) {
            fee.taxes.push({
              rate: tax.rate,
              amount: 0,
            });
          }

          fee.taxes.find((t) => t.rate === tax.rate)!.amount += tax.amount;
        });

        acc.set(currentFee.subType, fee);

        return acc;
      },
      new Map<
        string,
        {
          type: PriceFeeDetail['subType'];
          amount: number;
          amountBeforeTaxes: number;
          taxes: PriceAmountWithDetails['taxes'];
        }
      >()
    );

    const rows: Row[] = [];

    feesGroupedByType.forEach((fee) => {
      const label = {
        'shipping-management': msg({
          id: 'price-with-details.rows.shipping-management',
          message: 'Shipping management fees',
        }),
        cancellation: msg({
          id: 'price-with-details.rows.cancellation',
          message: 'Cancellation fees',
        }),
      }[fee.type];

      rows.push({
        label,
        amount: showAmountBeforeTaxes || showTaxDetails ? fee.amountBeforeTaxes : fee.amount,
        type: 'fee',
        subType: fee.type,
        highlight: feesGroupedByType.size === 1 && !showTaxDetails,
      });

      if (showTaxDetails) {
        fee.taxes.forEach((tax) => {
          // We display 0% VAT to make it clear that the price is tax-inclusive
          if (tax.amount > 0 || tax.rate === 0) {
            rows.push({
              label: msg({ id: 'price-with-details.rows.vat-rate', message: `VAT ${tax.rate}%` }),
              amount: tax.amount,
            });
          }
        });
      }
    });

    // Add the total fees row only if there are multiple fees or if we want all the details
    if (fees.length > 1 || showTaxDetails) {
      rows.push({
        label:
          showAmountBeforeTaxes && !showTaxDetails
            ? msg({
                id: 'price-with-details.rows.total-fees',
                message: 'Total fees',
              })
            : msg({
                id: 'price-with-details.rows.total-fees-incl-vat',
                message: 'Total fees (incl. VAT)',
              }),
        amount: fees.reduce(
          (acc, fee) =>
            acc + (showAmountBeforeTaxes && !showTaxDetails ? fee.amountBeforeTaxes : fee.amount),
          0
        ),
        highlight: true,
      });
    }

    sections.push(rows);
  }

  // Add the total section
  const rows: Row[] = [
    {
      label:
        showAmountBeforeTaxes || showTaxDetails
          ? msg({
              id: 'price-with-details.rows.total',
              message: 'Total',
            })
          : msg({ id: 'price-with-details.rows.total-incl-vat', message: 'Total (incl. VAT)' }),
      amount: showAmountBeforeTaxes || showTaxDetails ? price.amountBeforeTaxes : price.amount,
      highlight: !showTaxDetails,
    },
  ];

  if (showTaxDetails) {
    price.taxes.forEach((tax) => {
      // We display 0% VAT to make it clear that the price is tax-inclusive
      if (tax.amount > 0 || tax.rate === 0) {
        rows.push({
          label: msg({ id: 'price-with-details.rows.vat-rate', message: `VAT ${tax.rate}%` }),
          amount: tax.amount,
        });
      }
    });

    rows.push({
      label: msg({ id: 'price-with-details.rows.total-incl-vat', message: 'Total (incl. VAT)' }),
      amount: price.amount,
      highlight: true,
    });
  }

  sections.push(rows);

  return sections;
}

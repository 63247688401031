import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';

import { Organization, useOrganization } from '@/models/organization';
import { UserWithRelations } from '@/models/user';
import { useLocalStorageState } from '@/services/localStorage';

export const ORGANIZATION_LOCAL_STORAGE_KEY = 'current-organization';

export const useCurrentOrganizationFromSession = (
  currentSession?: UserWithRelations | null
): [Organization | null, (organization: Organization | null) => void] => {
  const [slug, setSlug] = useLocalStorageState<string | null>(ORGANIZATION_LOCAL_STORAGE_KEY, null);

  const setCurrentOrganization = useCallback(
    (organization: Organization | null) => {
      if (!organization) {
        setSlug(null);
      } else {
        setSlug(organization.slug);
      }

      // Redirect to homepage once organization is changed, this also makes sure we have fresh data
      window.location.href = '/';
    },
    [setSlug]
  );

  return useMemo(() => {
    if (!currentSession) {
      return [null, setCurrentOrganization];
    }

    if (slug) {
      const currentOrganization = currentSession.organizations.find(
        (organization) => organization.slug === slug
      );

      if (currentOrganization) {
        return [currentOrganization, setCurrentOrganization];
      }
    }

    const defaultOrganization = currentSession.organizations?.[0] ?? null;

    if (slug !== defaultOrganization?.slug) {
      setSlug(defaultOrganization?.slug ?? null);
    }

    return [defaultOrganization, setCurrentOrganization];
  }, [slug, setSlug, currentSession, setCurrentOrganization]);
};

export const useCurrentOrganizationFromUrl = () => {
  const { organization: slug } = useParams();
  const { data: organization, isError } = useOrganization(slug);

  return { organization: organization ?? null, isInvalidOrganization: isError };
};

import { Trans } from '@lingui/react/macro';

import { ArticleWithRelations } from '@/models/request';
import { useArticleShipmentWithIssue } from '@/models/shipment';
import { useCurrentSession } from '@/services/auth';

import { BaseMessage } from './BaseMessage';

export const HandleShipmentIssueTaskMessage = ({ article }: { article: ArticleWithRelations }) => {
  const { currentSession } = useCurrentSession();
  const { shipment } = useArticleShipmentWithIssue(article);

  const canVerifyReception = shipment?.canReceptionBeVerifiedBy(currentSession);
  const canArchive = currentSession?.hasPermission('archive_article', {
    organizationId: article.organizationId,
  });

  const issue = shipment?.articles.find(({ articleId }) => articleId === article.id)?.issue;

  return (
    <BaseMessage
      article={article}
      title={
        <Trans id="article.task.handle-shipment-issue.title">
          A shipment issue has been reported
        </Trans>
      }
      message={
        <div>
          <p>
            {canVerifyReception ? (
              <Trans id="article.task.handle-shipment-issue.text.receiver">
                We have forwarded the report to the sender. If they find a solution, you will be
                able to resolve the issue yourself.
              </Trans>
            ) : (
              <>
                <Trans id="article.task.handle-shipment-issue.text.not-receiver">
                  The receiver has reported a shipment issue.
                </Trans>
                {canArchive && (
                  <>
                    {' '}
                    <Trans id="article.task.handle-shipment-issue.text.archive">
                      If no solution is found, please archive the item.
                    </Trans>
                  </>
                )}
              </>
            )}
          </p>
          {!!issue && <p>&quot;{issue}&quot;</p>}
        </div>
      }
    />
  );
};

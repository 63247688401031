import { forwardRef, ReactNode, Ref, useEffect, useRef } from 'react';
import {
  Label,
  Radio as AriaRadio,
  RadioGroup as AriaRadioGroup,
  RadioGroupProps as AriaRadioGroupProps,
  RadioProps as AriaRadioProps,
} from 'react-aria-components';
import { msg } from '@lingui/core/macro';

import { createBEMClasses } from '@/utils/classname';

import './RadioGroup.css';

const { block, element } = createBEMClasses('radio-group');

interface RadioGroupProps extends AriaRadioGroupProps {
  label?: ReactNode;
  options: RadioProps[];
  theme?: 'default' | 'brand';
  variant?: 'text' | 'image-row' | 'image-column' | 'icon';
  grid?: 'small' | 'medium' | 'large';
  gap?: string;
  className?: string;
  optionsClassName?: string;
  customGridSize?: boolean;
  scrollToOnMount?: boolean;
}

interface RadioProps extends AriaRadioProps {
  imageUrl?: string;
  icon?: ReactNode;
  text?: ReactNode;
  subText?: ReactNode;
  className?: string;
  scrollToOnMount?: boolean;
}

const RadioGroup = forwardRef(function RadioGroup(
  {
    label,
    options,
    theme = 'default',
    variant = 'text',
    grid: gridProp,
    gap,
    className,
    scrollToOnMount,
    optionsClassName,
    customGridSize,
    ...props
  }: RadioGroupProps,
  refProp: Ref<HTMLDivElement>
) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (refProp && 'current' in refProp) {
      refProp.current?.scrollIntoView({ behavior: 'smooth' });
    } else {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [refProp]);

  const grid =
    gridProp ??
    (variant === 'text'
      ? 'small'
      : variant === 'image-column' || variant === 'icon'
        ? 'medium'
        : 'large');

  return (
    <AriaRadioGroup
      {...props}
      className={block({ variant, theme }, className)}
      ref={scrollToOnMount ? (refProp ?? ref) : null}
    >
      {!!label && (
        <Label className={theme === 'default' ? 'label-100' : 'paragraph-50-medium'}>{label}</Label>
      )}
      <div
        className={element(
          'options',
          { variant, grid: customGridSize ? undefined : grid },
          optionsClassName
        )}
        style={{ gap }}
      >
        {options.map(
          ({ imageUrl, icon, text, subText, className: optionClassName, ...option }, index) => (
            <AriaRadio
              key={index}
              className={element('option', { variant, hasSubtext: !!subText }, optionClassName)}
              {...option}
            >
              {(variant === 'image-row' || variant === 'image-column') && (
                <div className={element('option__image')}>
                  {imageUrl && <img src={imageUrl} alt="" />}
                </div>
              )}
              {variant === 'icon' && !!icon && (
                <div className={element('option__icon')}>{icon}</div>
              )}
              <div
                className={element(
                  'option__text',
                  undefined,
                  theme === 'default'
                    ? 'paragraph-100-medium'
                    : 'paragraph-50-regular paragraph-100-regular-mobile'
                )}
              >
                {text}
              </div>
              {(variant === 'text' || variant === 'icon') && subText && (
                <p
                  className={element(
                    'option__subtext',
                    undefined,
                    'paragraph-200-regular paragraph-300-regular-mobile text-secondary'
                  )}
                >
                  {subText}
                </p>
              )}
            </AriaRadio>
          )
        )}
      </div>
    </AriaRadioGroup>
  );
});

export const yesNoRadioOptions = [
  { value: 'yes', text: msg({ id: 'design-system.radio-group.yes-no.yes', message: 'Yes' }) },
  { value: 'no', text: msg({ id: 'design-system.radio-group.yes-no.no', message: 'No' }) },
];

export default RadioGroup;

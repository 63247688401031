import { useQuery } from '@tanstack/react-query';

import { Endpoints } from '@/api';
import { useFetch } from '@/utils/fetch';
import { Currency } from '@/utils/number';

import { Client } from './client';
import { Model } from './model';
import { Organization } from './organization';
import { OrganizationCountry } from './organizationCountry';
import { Workshop } from './workshop';

export class Transaction extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  id!: string;

  type!: TransactionType;
  status!: TransactionStatus;

  amount!: number;
  currency!: Currency;

  startedAt!: string | null;
  succeededAt!: string | null;

  createdAt!: string;
  updatedAt!: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TRANSACTION_TYPES = [
  'client_payment_in',
  'brand_payment_in',
  'workshop_payment_out',
  'brand_payment_out',
  'prolong_payment_out',
] as const;
export type TransactionType = (typeof TRANSACTION_TYPES)[number];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TRANSACTION_STATUS = ['unstarted', 'pending', 'succeeded'] as const;
export type TransactionStatus = (typeof TRANSACTION_STATUS)[number];

export const instanciateTransactionWithRelations = (
  transaction: Endpoints['GET /transactions']['response']['transactions'][number]
) => {
  const instanciatedTransaction = new Transaction(transaction)
    .with('sourceClient', transaction.sourceClient ? new Client(transaction.sourceClient) : null)

    .with(
      'onBehalfOfOrganizationCountry',
      transaction.onBehalfOfOrganizationCountry
        ? new OrganizationCountry(transaction.onBehalfOfOrganizationCountry).with(
            'organization',
            new Organization(transaction.onBehalfOfOrganizationCountry.organization)
          )
        : null
    )

    .with(
      'destinationWorkshop',
      transaction.destinationWorkshop ? new Workshop(transaction.destinationWorkshop) : null
    );

  return instanciatedTransaction;
};

export type TransactionWithRelations = ReturnType<typeof instanciateTransactionWithRelations>;

export const useTransactions = (params?: Endpoints['GET /transactions']['query']) => {
  const fetch = useFetch<Endpoints['GET /transactions']>();

  return useQuery({
    queryKey: ['transactions', params],
    queryFn: () =>
      fetch('/transactions', params).then(({ transactions, meta }) => ({
        transactions: transactions.map(instanciateTransactionWithRelations),
        meta,
      })),
  });
};

export const useTransactionsOrigins = (
  params?: Endpoints['GET /transactions/origins']['query']
) => {
  const fetch = useFetch<Endpoints['GET /transactions/origins']>();

  return useQuery({
    queryKey: ['transactions-origins', params],
    queryFn: () =>
      fetch('/transactions/origins', params).then(({ organizationCountries, client }) => ({
        organizationCountries: organizationCountries.map((organizationCountry) =>
          new OrganizationCountry(organizationCountry).with(
            'organization',
            new Organization(organizationCountry.organization)
          )
        ),
        client,
      })),
  });
};

export const useTransactionsDestinations = (
  params?: Endpoints['GET /transactions/destinations']['query']
) => {
  const fetch = useFetch<Endpoints['GET /transactions/destinations']>();

  return useQuery({
    queryKey: ['transactions-destinations', params],
    queryFn: () =>
      fetch('/transactions/destinations', params).then(({ workshops, organizationCountries }) => ({
        workshops: workshops.map((workshop) => new Workshop(workshop)),
        organizationCountries: organizationCountries.map((organizationCountry) =>
          new OrganizationCountry(organizationCountry).with(
            'organization',
            new Organization(organizationCountry.organization)
          )
        ),
      })),
  });
};

import { useNavigate } from 'react-router';
import { Trans } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import { ArticleWithRelations } from '@/models/request';
import { useArticleOngoingShipment } from '@/models/shipment';

export const GoToShipment = ({ article }: { article: ArticleWithRelations }) => {
  const navigate = useNavigate();

  const { shipment } = useArticleOngoingShipment(article);

  if (!shipment) {
    return null;
  }

  return (
    <Button variant="secondary" size="medium" onPress={() => navigate(`/shipments/${shipment.id}`)}>
      <Trans id="article.task.go-to-shipment">Go to shipment</Trans>
    </Button>
  );
};

import { useCallback, useMemo, useState } from 'react';
import { useLingui } from '@lingui/react/macro';

import { OrganizationConfig } from '@/api';
import AddressForm, {
  getAddressError,
  isAddressValid,
  useAddressState,
} from '@/components/AddressForm';
import { InputLocale } from '@/components/InputLocale/InputLocale';
import InputPhone, { isPhoneValid, usePhoneState } from '@/components/InputPhone/InputPhone';
import { SendcloudMap } from '@/components/SendcloudMap/SendcloudMap';
import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import InputText from '@/design_system/InputText';
import Stack from '@/design_system/Stack';
import Toggle from '@/design_system/Toggle';
import { AddressRaw } from '@/models/address';
import { DeliveryOption } from '@/models/client';
import Phone from '@/models/partials/phone';
import { RequestWithRelations } from '@/models/request';
import { useWorkflow } from '@/models/workflow';
import { useGeoIpCountry } from '@/services/auth';
import { Locale } from '@/services/i18n';
import { isEmailValid } from '@/utils/email';

export const useClientState = (
  initialClient?: RequestWithRelations['client'],
  initialVip?: boolean,
  onChange?: (data: {
    name: string;
    vip: boolean;
    email: string;
    address: Partial<AddressRaw> | null;
    billingAddress: Partial<AddressRaw> | null;
    phone: Phone;
    deliveryOption: DeliveryOption;
    sendcloudServicePointId: string | null;
    locale: Locale;
  }) => void
) => {
  const [name, setName] = useState(initialClient?.name ?? '');
  const [vip, setVip] = useState(initialVip ?? false);
  const [email, setEmail] = useState(initialClient?.email ?? '');
  const geoIpCountry = useGeoIpCountry();
  const [address, setAddress] = useAddressState(
    initialClient?.address ?? { country: geoIpCountry }
  );
  const [sameAddress, setSameAddress] = useState(
    initialClient?.billingAddress
      ? initialClient.address?.formatted === initialClient.billingAddress?.formatted
      : true
  );
  const [billingAddress, setBillingAddress] = useAddressState(
    initialClient?.billingAddress ?? { country: geoIpCountry }
  );
  const [phone, setPhone] = usePhoneState(initialClient?.phone);
  const [deliveryOption, setDeliveryOption] = useState<DeliveryOption>(
    initialClient?.deliveryOption ?? 'store'
  );
  const [sendcloudServicePointId, setSendcloudServicePointId] = useState<string | null>(
    initialClient?.sendcloudServicePointId ?? null
  );
  const [locale, setLocale] = useState<Locale>(initialClient?.locale ?? 'fr');

  const data = useMemo(
    () => ({
      name,
      vip,
      email,
      address,
      billingAddress,
      deliveryOption,
      sendcloudServicePointId,
      phone,
      locale,
    }),
    [
      address,
      billingAddress,
      deliveryOption,
      email,
      locale,
      name,
      phone,
      vip,
      sendcloudServicePointId,
    ]
  );

  // Required because AddressForm and InputPhone are slow to update
  const memoizedSetAddress = useCallback(
    (address: Partial<AddressRaw> | null) => {
      setAddress(address);

      if (sameAddress) {
        setBillingAddress(address);
      }

      onChange?.({ ...data, address, billingAddress: sameAddress ? address : billingAddress });
    },
    [billingAddress, data, onChange, sameAddress, setAddress, setBillingAddress]
  );

  const memoizedSetBillingAddress = useCallback(
    (address: Partial<AddressRaw> | null) => {
      setBillingAddress(address);
      onChange?.({ ...data, billingAddress: address });
    },
    [data, onChange, setBillingAddress]
  );

  const memoizedSetPhone = useCallback(
    (phone: Phone) => {
      setPhone(phone);
      onChange?.({ ...data, phone });
    },
    [data, onChange, setPhone]
  );

  return {
    name,
    setName: (name: string) => {
      setName(name);
      onChange?.({ ...data, name });
    },
    vip,
    setVip: (vip: boolean) => {
      setVip(vip);
      onChange?.({ ...data, vip });
    },
    email,
    setEmail: (email: string) => {
      setEmail(email);
      onChange?.({ ...data, email });
    },
    address,
    setAddress: memoizedSetAddress,
    sameAddress,
    setSameAddress: (sameAddress: boolean) => {
      setSameAddress(sameAddress);
      if (sameAddress) {
        setBillingAddress(address);
        onChange?.({ ...data, billingAddress: address });
      }
    },
    billingAddress,
    setBillingAddress: memoizedSetBillingAddress,
    deliveryOption,
    setDeliveryOption: (deliveryOption: DeliveryOption) => {
      setDeliveryOption(deliveryOption);
      setSendcloudServicePointId(null);
      onChange?.({ ...data, deliveryOption, sendcloudServicePointId: null });
    },
    sendcloudServicePointId,
    setSendcloudServicePointId: (sendcloudServicePointId: string | null) => {
      setSendcloudServicePointId(sendcloudServicePointId);
      onChange?.({ ...data, sendcloudServicePointId });
    },
    phone,
    setPhone: memoizedSetPhone,
    locale,
    setLocale: (locale: Locale) => {
      setLocale(locale);
      onChange?.({ ...data, locale });
    },
  };
};

export type ClientState = ReturnType<typeof useClientState>;

export const getClientError = (
  client: ClientState | RequestWithRelations['client'],
  hideBillingAddress: boolean = false
) => {
  const nameError = !client?.name;
  const emailError = !client?.email || !isEmailValid(client.email);
  const addressError =
    client?.deliveryOption === 'store' && hideBillingAddress
      ? false
      : !isAddressValid(client?.address);
  const billingAddressError = hideBillingAddress ? false : !isAddressValid(client?.billingAddress);
  const phoneError = client?.phone ? !isPhoneValid(client.phone) : true;

  return {
    nameError,
    emailError,
    addressError,
    billingAddressError,
    phoneError,
    hasError: nameError || emailError || addressError || billingAddressError || phoneError,
  };
};

export type ClientFormProps = {
  request: {
    id: string;
    workflowId: string | null;
    organization: {
      config: OrganizationConfig;
    };
  };
  state: ClientState;
  size?: 'medium' | 'large';
  variant?: 'default' | 'brand';
  disableEmailEdit?: boolean;
  hideVip?: boolean;
  hideLanguage?: boolean;
  hideDeliveryOption?: boolean;
  hideBillingAddress?: boolean;
  showErrors?: boolean;
};

const ClientForm = ({
  request,
  state,
  size = 'medium',
  variant = 'default',
  disableEmailEdit = false,
  hideVip = false,
  hideLanguage = false,
  hideDeliveryOption = false,
  hideBillingAddress = false,
  showErrors = false,
}: ClientFormProps) => {
  const { t } = useLingui();

  const {
    name,
    setName,
    vip,
    setVip,
    email,
    setEmail,
    address,
    setAddress,
    sameAddress,
    setSameAddress,
    billingAddress,
    setBillingAddress,
    deliveryOption,
    setDeliveryOption,
    sendcloudServicePointId,
    setSendcloudServicePointId,
    phone,
    setPhone,
    locale,
    setLocale,
  } = state;

  const { nameError, emailError, addressError, billingAddressError, phoneError } = getClientError(
    state,
    hideBillingAddress
  );

  const { data: workflow } = useWorkflow(request.workflowId);

  const allowedDeliveryOptions = workflow?.config.deliveryOptions ?? [];

  const deliveryOptions = [
    allowedDeliveryOptions.includes('store') && {
      id: 'store',
      text: t({
        id: 'components.client-form.delivery-option.store.label',
        message: 'In store',
      }),
    },
    allowedDeliveryOptions.includes('home') && {
      id: 'home',
      text: t({
        id: 'components.client-form.delivery-option.home.label',
        message: 'At home',
      }),
    },
    allowedDeliveryOptions.includes('pickupPoint_mondialRelay') && {
      id: 'pickupPoint_mondialRelay',
      text: t({
        id: 'components.client-form.delivery-option.pickup-point-mondial-relay.label',
        message: 'Pickup point (Mondial Relay)',
      }),
    },
  ].filter((deliveryOption) => !!deliveryOption);

  const organizationAllowsVip = request.organization.config.allowVip;

  return (
    <Stack gap="1rem">
      <Stack gap="4px">
        <InputText
          label={t({ id: 'components.client-form.name.label', message: 'Full name*' })}
          placeholder={t({
            id: 'components.client-form.name.placeholder',
            message: 'i.e: Jane Doe',
          })}
          size={size}
          value={name}
          onChange={setName}
          error={
            showErrors && nameError
              ? t({
                  id: 'components.client-form.name.error',
                  message: 'Please add a full name',
                })
              : undefined
          }
        />
        {organizationAllowsVip && !hideVip && (
          <div style={{ display: 'flex' }}>
            <Toggle
              variant={variant}
              size="large"
              alignment="right"
              label={t({ id: 'components.client-form.vip.label', message: 'This is a VIP client' })}
              isSelected={vip}
              onChange={setVip}
            />
          </div>
        )}
      </Stack>
      <InputText
        label={t({ id: 'components.client-form.email.label', message: 'Email*' })}
        placeholder={t({
          id: 'components.client-form.email.placeholder',
          message: 'i.e: jane.doe@mail.com',
        })}
        size={size}
        value={email}
        onChange={setEmail}
        type="email"
        isDisabled={disableEmailEdit}
        error={
          showErrors && emailError
            ? email
              ? t({
                  id: 'components.client-form.email-invalid.error',
                  message: 'Please add a valid email',
                })
              : t({
                  id: 'components.client-form.email.error',
                  message: 'Please add an email',
                })
            : undefined
        }
      />
      <Stack gap="4px">
        <AddressForm
          label={
            t({ id: 'components.client-form.address.label', message: 'Address' }) +
            (deliveryOption === 'store' && hideBillingAddress ? '' : '*')
          }
          value={address}
          onChange={setAddress}
          contact={false}
          variant={variant}
          size={size}
          error={showErrors && addressError ? getAddressError(address) : undefined}
        />
        {!hideBillingAddress && (
          <div style={{ display: 'flex' }}>
            <Toggle
              variant={variant}
              size="large"
              alignment="right"
              label={t({
                id: 'components.client-form.same-address.label',
                message: 'Use the same address for billing',
              })}
              isSelected={sameAddress}
              onChange={setSameAddress}
            />
          </div>
        )}
      </Stack>
      {!sameAddress && (
        <AddressForm
          label={t({
            id: 'components.client-form.billing-address.label',
            message: 'Billing address*',
          })}
          value={billingAddress}
          onChange={setBillingAddress}
          contact={false}
          variant={variant}
          size={size}
          error={showErrors && billingAddressError ? getAddressError(billingAddress) : undefined}
        />
      )}
      <InputPhone
        label={t({ id: 'components.client-form.phone.label', message: 'Phone*' })}
        value={phone}
        onChange={setPhone}
        variant={variant}
        size={size}
        isInvalid={showErrors ? phoneError : undefined}
      />
      {(!hideDeliveryOption || !hideLanguage) && (
        <Stack gap="1rem">
          {!hideDeliveryOption && (
            <InputSelect
              variant="select"
              label={t({
                id: 'components.client-form.delivery-option.label',
                message: 'Shipping back preference',
              })}
              tooltip={t({
                id: 'components.client-form.delivery-option.tooltip',
                message: 'Place where the client would like to receive back their items',
              })}
              tooltipPlacement="top"
              isSearchable={false}
              value={deliveryOptions.find(
                (shippingBackOption) => shippingBackOption.id === deliveryOption
              )}
              options={deliveryOptions}
              getOptionValue={(deliveryOption) => deliveryOption.id}
              getOptionLabel={(deliveryOption) => deliveryOption.text}
              onChange={(deliveryOption) => {
                if (deliveryOption) {
                  setDeliveryOption(deliveryOption.id as DeliveryOption);
                }
              }}
              styleVariant={variant}
              size={size}
              style={{ flex: 1 }}
            />
          )}

          {!hideLanguage && (
            <InputLocale
              value={locale}
              onChange={setLocale}
              styleVariant={variant}
              size={size}
              style={{ flex: 1 }}
              menuPlacement="top"
            />
          )}
        </Stack>
      )}

      {deliveryOption === 'pickupPoint_mondialRelay' && (
        <SendcloudMap
          servicePointId={sendcloudServicePointId}
          setServicePointId={setSendcloudServicePointId}
          carrier="mondial_relay"
          address={address?.line1}
          postalCode={address?.zipCode}
          city={address?.city}
          country={address?.country}
        />
      )}
    </Stack>
  );
};

export default ClientForm;

import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { Trans, useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button/Button';
import Stack from '@/design_system/Stack/Stack';
import Tabs from '@/design_system/Tabs';
import { Tab, TabList, TabPanel } from '@/design_system/Tabs/Tabs';
import IconAdd from '@/icons/Add.svg';
import { ShipmentCarrier, ShipmentStep } from '@/models/shipment';
import { useCurrentSession } from '@/services/auth';
import { useResetSimpleBlocker } from '@/utils/navigation';
import { useDebouncedSearchParamState } from '@/utils/useDebouncedState';
import { useSearchParamState, useSyncSearchParams } from '@/utils/useSearchParams';

import DoneTab from './Tabs/DoneTab';
import OngoingTab from './Tabs/OngoingTab';
import PriorityTab from './Tabs/PriorityTab';

const Shipments = () => {
  const navigate = useNavigate();
  const { currentSession } = useCurrentSession();
  const { t } = useLingui();

  // Clear router blocker state after coming from shipments/new or shipment/edit
  useResetSimpleBlocker();

  const canAccessPriorityTab = !!currentSession?.stores?.length || !!currentSession?.workshop;

  const [tab, setTab, syncTab] = useSearchParamState<string>(
    'shipments',
    'tab',
    canAccessPriorityTab ? 'to-do' : 'waiting'
  );
  const [page, setPage, syncPage] = useSearchParamState<number>('shipments', 'page', 1);
  const [steps, setSteps, syncSteps] = useSearchParamState<ShipmentStep[]>(
    'shipments',
    'steps',
    []
  );
  const [carriers, setCarriers, syncCarriers] = useSearchParamState<ShipmentCarrier[]>(
    'shipments',
    'carriers',
    []
  );

  const resetPage = useCallback(() => setPage(1), [setPage]);
  const [search, debouncedSearch, debouncedSetSearch, syncSearch] =
    useDebouncedSearchParamState<string>('shipments', 'search', '', 500, resetPage);

  useSyncSearchParams([syncTab, syncPage, syncSearch, syncSteps, syncCarriers]);

  const canCreateShipments = currentSession?.canCreateShipments();

  return (
    <div className="container container--list">
      <Stack gap="24px" style={{ height: '100%' }}>
        <Stack row alignItems="center">
          <h1 className="headline-200-bold" style={{ flex: 1 }}>
            <Trans id="shipments.title">Shipments</Trans>
          </h1>
          {canCreateShipments && (
            <Button
              className="is-hidden-mobile"
              size="medium"
              onPress={() => navigate('/shipments/new')}
            >
              <IconAdd />
              <Trans id="shipments.new">New shipment</Trans>
            </Button>
          )}
          {canCreateShipments && (
            <Button
              className="is-hidden-from-tablet"
              size="large"
              iconOnly
              ariaLabel={t({ id: 'shipments.new', message: 'New shipment' })}
              onPress={() => navigate('/shipments/new')}
            >
              <IconAdd />
            </Button>
          )}
        </Stack>
        <Tabs
          gap="24px"
          selectedKey={tab}
          onSelectionChange={(tab) => {
            setTab(tab);
            setSteps([]);
            setCarriers([]);
            setPage(1);
          }}
          style={{ flex: 1, paddingBottom: 24 }}
        >
          <TabList>
            {canAccessPriorityTab && (
              <Tab id="to-do">
                <Trans id="shipments.tab.to-do">To do</Trans>
              </Tab>
            )}
            <Tab id="waiting">
              <Trans id="shipments.tab.waiting">Waiting</Trans>
            </Tab>
            <Tab id="archived">
              <Trans id="shipments.tab.archived">Archived</Trans>
            </Tab>
          </TabList>
          {canAccessPriorityTab && (
            <TabPanel id="to-do" style={{ flex: 1 }}>
              <PriorityTab
                {...{
                  search,
                  debouncedSearch,
                  debouncedSetSearch,
                  page,
                  setPage,
                  steps,
                  setSteps,
                  carriers,
                  setCarriers,
                }}
              />
            </TabPanel>
          )}
          <TabPanel id="waiting" style={{ flex: 1 }}>
            <OngoingTab
              {...{
                search,
                debouncedSearch,
                debouncedSetSearch,
                page,
                setPage,
                steps,
                setSteps,
                carriers,
                setCarriers,
              }}
            />
          </TabPanel>
          <TabPanel id="archived" style={{ flex: 1 }}>
            <DoneTab
              {...{
                search,
                debouncedSearch,
                debouncedSetSearch,
                page,
                setPage,
                carriers,
                setCarriers,
              }}
            />
          </TabPanel>
        </Tabs>
      </Stack>
    </div>
  );
};

export default Shipments;

import { Fragment } from 'react';
import { useLingui } from '@lingui/react';

import { PriceAmountWithDetails } from '@/api';
import Button from '@/design_system/Button';
import Tooltip from '@/design_system/Tooltip';
import { createBEMClasses } from '@/utils/classname';
import { formatCurrency } from '@/utils/number';

import { usePriceDetailsRows } from './usePriceDetailsRows';

import './PriceWithDetails.css';

const { element } = createBEMClasses('price-with-details');

export const PriceWithDetailsTooltip = ({
  price,
  size = 'medium',
  className,
  showAmountBeforeTaxes,
}: {
  price: PriceAmountWithDetails;
  size?: 'x-small' | 'small' | 'medium';
  className?: string;
  showAmountBeforeTaxes: boolean;
}) => {
  const { _ } = useLingui();

  let sections = usePriceDetailsRows(price, {
    showAmountBeforeTaxes,
    showTaxDetails: true,
  });

  // If there is only one section + total, we remove the total as it's not useful in this tooltip
  if (sections.length === 2) {
    sections = sections.slice(0, -1);
  }

  return (
    <Tooltip
      variant="light"
      size="medium"
      hasOverlayArrow={false}
      textAlign="start"
      content={
        <table className={element('table')}>
          <tbody>
            {sections.map((section, sectionIndex) => (
              <Fragment key={sectionIndex}>
                {sections.length > 1 && sectionIndex === sections.length - 1 && (
                  <tr className={element('separator')}>
                    <td colSpan={2} />
                  </tr>
                )}
                {section.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className={element('row', {
                      negative: row.amount < 0,
                      total: row.highlight,
                      'first-row-of-section': rowIndex === 0 && sectionIndex !== 0,
                      'last-section': sectionIndex === sections.length - 1,
                    })}
                  >
                    <td>{_(row.label)}</td>
                    <td>{formatCurrency(row.amount, price.currency)}</td>
                  </tr>
                ))}
              </Fragment>
            ))}
          </tbody>
        </table>
      }
    >
      <Button variant="style-less">
        <div
          className={element(
            'trigger',
            {
              size,
            },
            className
          )}
        >
          {formatCurrency(
            showAmountBeforeTaxes ? price.amountBeforeTaxes : price.amount,
            price.currency
          )}
        </div>
      </Button>
    </Tooltip>
  );
};

import { useLingui } from '@lingui/react/macro';

import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import { useUpdateArticle } from '@/models/article';
import { useProductOptions } from '@/models/product';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';

export const ProductCategoryL2 = () => {
  const { t } = useLingui();

  const { request, article, errors } = useArticleContext();

  const { mutate: updateArticle } = useUpdateArticle({
    articleId: article.id,
    requestId: request.id,
  });

  const { productL2Options } = useProductOptions(request.organization, article.productL1);

  return (
    <InputSelect
      variant="select"
      label={t({ id: 'article.form.category.label', message: 'Category' })}
      placeholder={t({
        id: 'article.form.category.placeholder',
        message: 'Select a category',
      })}
      isDisabled={!!article.product || !article.productL1}
      value={
        productL2Options.find((productL2Option) => article.productL2 === productL2Option.id) ?? null
      }
      isSearchable={false}
      options={productL2Options}
      getOptionValue={(productL2Option) => productL2Option.id}
      getOptionLabel={(productL2Option) => productL2Option.text}
      onChange={(productL2Option) => {
        if (productL2Option) {
          updateArticle({
            data: {
              productL2: productL2Option.id,
            },
          });
        }
      }}
      error={
        errors.details?.productL2
          ? t({
              id: 'article.form.category.error',
              message: 'Please select a category',
            })
          : undefined
      }
      style={{ flex: 1 }}
    />
  );
};

import { Trans } from '@lingui/react/macro';
import { TokenResult } from '@stripe/stripe-js';

import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconCheck from '@/icons/Check.svg';
import IconClock from '@/icons/Clock.svg';
import { OrganizationCountry, useOrganizationCountries } from '@/models/organizationCountry';
import {
  useOnboardingLink,
  useSetupDebitPaymentLink,
  useStripe,
  useStripeAccount,
} from '@/services/stripe';

const Countries = () => {
  const { data: { organizationCountries } = {} } = useOrganizationCountries();

  if (!organizationCountries) {
    return <Trans id="_general.loading">Loading...</Trans>;
  }

  return (
    <Stack gap="24px">
      <h2 className="headline-200-bold">
        <Trans id="settings.countries.title">Organization countries</Trans>
      </h2>
      <Stack gap="8px">
        {organizationCountries.map((organizationCountry) => (
          <Country key={organizationCountry.id} organizationCountry={organizationCountry} />
        ))}
      </Stack>
    </Stack>
  );
};

export default Countries;

const Country = ({ organizationCountry }: { organizationCountry: OrganizationCountry }) => {
  const { data: { id, accountStatus, setupIntentStatus } = {} } = useStripeAccount({
    type: 'organization-country',
    id: organizationCountry.id,
  });

  return (
    <Box>
      <Stack row gap="8px" justifyContent="space-between" alignItems="center">
        <b className="paragraph-100-medium">{organizationCountry.country.toLocaleUpperCase()}</b>
        {accountStatus === 'complete' && setupIntentStatus === 'succeeded' && (
          <Stack row gap="8px" alignItems="center" className="paragraph-100-regular">
            <IconCheck />
            <p>
              <Trans id="settings.countries.status.ready">Stripe Account: Ready</Trans>
            </p>
          </Stack>
        )}
        {accountStatus === 'complete' && setupIntentStatus !== 'succeeded' && (
          <SetupDebitPayments organizationCountry={organizationCountry} />
        )}
        {accountStatus === 'pending' && (
          <Stack row gap="8px" alignItems="center" className="paragraph-100-regular">
            <IconClock />
            <p>
              <Trans id="settings.countries.status.pending">
                Stripe Account: Pending verification
              </Trans>
            </p>
          </Stack>
        )}
        {(accountStatus === 'none' || accountStatus === 'onboarding') && (
          <StartOnboarding organizationCountry={organizationCountry} stripeId={id} />
        )}
      </Stack>
    </Box>
  );
};

const StartOnboarding = ({
  organizationCountry,
  stripeId,
}: {
  organizationCountry: OrganizationCountry;
  stripeId?: string;
}) => {
  const stripe = useStripe();
  const { mutateAsync: getOnboardingLink, isPending } = useOnboardingLink();

  return (
    <Button
      variant="primary"
      isLoading={!stripe || isPending}
      onPress={async () => {
        let stripeAccount: TokenResult | undefined = undefined;

        if (!stripeId) {
          stripeAccount = await stripe!.createToken('account', {
            business_type: 'company',
            tos_shown_and_accepted: true,
          });
        }

        const { url } = await getOnboardingLink({
          type: 'organization-country',
          id: organizationCountry.id,
          accountTokenId: stripeAccount?.token?.id,
        });

        window.location.href = url;
      }}
    >
      <Trans id="settings.countries.status.start-onboarding">
        Stripe Account: Start onboarding
      </Trans>
    </Button>
  );
};

export function SetupDebitPayments({
  organizationCountry,
}: {
  organizationCountry: OrganizationCountry;
}) {
  const stripe = useStripe();
  const { mutateAsync: getSetupDebitPaymentLink, isPending } = useSetupDebitPaymentLink();

  return (
    <Button
      variant="primary"
      isLoading={!stripe || isPending}
      onPress={async () => {
        const { url } = await getSetupDebitPaymentLink({
          id: organizationCountry.id,
        });

        window.location.href = url;
      }}
    >
      <Trans id="settings.countries.status.finish-onboarding">
        Stripe Account: Finish onboarding
      </Trans>
    </Button>
  );
}

import { useLingui } from '@lingui/react';

import Box from '@/design_system/Box';
import Stack from '@/design_system/Stack';
import { Stepper, StepperTooltip, StepProps } from '@/design_system/Stepper/Stepper';
import { SHIPMENT_STEPS, ShipmentWithRelations } from '@/models/shipment';
import FinalisationMessage from '@/routes/Shipments/Shipment/components/ShipmentStep/components/FinalisationMessage';
import HandoverMessage from '@/routes/Shipments/Shipment/components/ShipmentStep/components/HandoverMessage';
import InTransitMessage from '@/routes/Shipments/Shipment/components/ShipmentStep/components/InTransitMessage';
import PreparationMessage from '@/routes/Shipments/Shipment/components/ShipmentStep/components/PreparationMessage';
import ValidatedMessage from '@/routes/Shipments/Shipment/components/ShipmentStep/components/ValidatedMessage';
import VerificationMessage from '@/routes/Shipments/Shipment/components/ShipmentStep/components/VerificationMessage';
import { createBEMClasses } from '@/utils/classname';
import useViewPort from '@/utils/useViewport';

import './ShipmentStep.css';

const { block, element } = createBEMClasses('shipment-step');

export const useShipmentStepsToDisplay = (shipment: ShipmentWithRelations) => {
  const { _ } = useLingui();

  const currentStepIndex = SHIPMENT_STEPS.findIndex((step) => step.id === shipment.step);
  return SHIPMENT_STEPS.map((step, index) => {
    if ('hidden' in step) {
      return undefined;
    }

    if (
      'hiddenInDestinationClient' in step &&
      step.hiddenInDestinationClient &&
      shipment.destinationClientId
    ) {
      return undefined;
    }

    return {
      name: _(step.name),
      status:
        index < currentStepIndex ? 'done' : index === currentStepIndex ? 'in-progress' : 'pending',
    };
  }).filter((x) => x !== undefined) as StepProps[];
};

export const ShipmentStep = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const { isMobile } = useViewPort();

  const shipmentSteps = useShipmentStepsToDisplay(shipment);

  return (
    <Box padding="0" className={block()}>
      <Stack row={!isMobile} alignItems={isMobile ? 'stretch' : 'center'}>
        {shipment.carrierService !== 'self-handled' && (
          <Stack padding="1rem" alignItems="flex-start">
            <StepperTooltip steps={shipmentSteps}>
              <Stepper
                showStepCount={isMobile}
                variant="round"
                size={isMobile ? 'medium' : 'large'}
                steps={shipmentSteps}
              />
            </StepperTooltip>
          </Stack>
        )}
        {shipment.step === 'preparation' && (
          <PreparationMessage shipment={shipment} hideIcon className={element('message')} />
        )}

        {shipment.step === 'finalisation' && (
          <FinalisationMessage shipment={shipment} hideIcon className={element('message')} />
        )}

        {shipment.step === 'handover' && (
          <HandoverMessage shipment={shipment} hideIcon className={element('message')} />
        )}

        {shipment.step === 'in-transit' && (
          <InTransitMessage shipment={shipment} hideIcon className={element('message')} />
        )}

        {shipment.step === 'verification' && (
          <VerificationMessage shipment={shipment} hideIcon className={element('message')} />
        )}

        {shipment.step === 'validated' && (
          <ValidatedMessage shipment={shipment} hideIcon className={element('message')} />
        )}
      </Stack>
    </Box>
  );
};

import { useCallback, useState } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';

import { getStoreError, StoreForm, useStoreState } from '@/components/Store/StoreForm';
import { UpdateStore } from '@/components/Store/UpdateStore';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import InputSearch from '@/design_system/InputSearch';
import { PaginationFooter } from '@/design_system/Pagination/Pagination';
import Stack from '@/design_system/Stack';
import { Body, Cell, Column, Header, Row, Table } from '@/design_system/Table/Table';
import IconAdd from '@/icons/Add.svg';
import IconTrash from '@/icons/Trash.svg';
import { AddressRaw } from '@/models/address';
import { Store, useCreateStore, useDeleteStore, useStores, useUpdateStore } from '@/models/store';
import { useUsers } from '@/models/user';
import useDebouncedState from '@/utils/useDebouncedState';

const STORES_PER_PAGE = 10;

const Stores = () => {
  const { t } = useLingui();
  const [page, setPage] = useState(1);
  const resetPage = useCallback(() => setPage(1), [setPage]);
  const [search, debouncedSearch, setSearch] = useDebouncedState('', 300, resetPage);

  const { data: { stores, meta } = {} } = useStores({
    limit: STORES_PER_PAGE,
    offset: (page - 1) * STORES_PER_PAGE,
    search: debouncedSearch,
    internal: true,
  });

  const noData = stores && stores.length === 0;

  return (
    <Stack gap="40px">
      <Box padding="24px" gap="24px">
        <Stack row alignItems="center" justifyContent="space-between">
          <h2 className="headline-200-bold">
            <Trans id="settings.stores.title">Stores</Trans>
          </h2>
          <AddStore />
        </Stack>
        <InputSearch
          ariaLabel={t({ id: 'settings.stores.search.label', message: 'Search' })}
          placeholder={t({
            id: 'settings.stores.search.placeholder',
            message: 'Search by name, address, contact name, external id…',
          })}
          style={{ flex: 1, minWidth: 175, maxWidth: 500 }}
          size="medium"
          value={search}
          onChange={setSearch}
        />
        {noData && (
          <p className="paragraph-100-regular">
            <Trans id="settings.stores.empty">No items for now</Trans>
          </p>
        )}
        {!noData && (
          <>
            <Table
              ariaLabel={t({ id: 'settings.stores.table.label', message: 'Stores' })}
              columnWidths="minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr) 80px auto"
            >
              <Header>
                <Row>
                  <Column>
                    <Trans id="settings.stores.column.name">Name</Trans>
                  </Column>
                  <Column>
                    <Trans id="settings.stores.column.address">Address</Trans>
                  </Column>
                  <Column>
                    <Trans id="settings.stores.column.contact">Contact</Trans>
                  </Column>
                  <Column justifyContent="center">
                    <Trans id="settings.stores.column.users">Users</Trans>
                  </Column>
                  <Column
                    ariaLabel={t({
                      id: 'settings.stores.column.actions',
                      message: 'Actions',
                    })}
                  />
                </Row>
              </Header>
              <Body>{stores?.map((store) => <StoreRow key={store.id} store={store} />)}</Body>
              <PaginationFooter
                page={page}
                itemsPerPage={STORES_PER_PAGE}
                count={meta?.count}
                onPageChange={setPage}
              />
            </Table>
          </>
        )}
      </Box>
    </Stack>
  );
};

const StoreRow = ({ store }: { store: Store }) => {
  const { t } = useLingui();

  const { data: { meta } = {}, isLoading: isLoadingUsers } = useUsers({
    limit: 1,
    storeId: store.id,
  });

  const { mutateAsync: updateStore, isPending: isPendingUpdate } = useUpdateStore();
  const { mutateAsync: deleteStore, isPending: isPendingDelete } = useDeleteStore();

  const deleteLabel = t({ id: 'settings.stores.delete.tooltip', message: `Delete ${store.name}` });

  return (
    <Row>
      <Cell>
        <b>{store.name}</b>
      </Cell>
      <Cell>
        <p>
          {store.address?.formattedStreet ?? '-'}
          <br />
          {store.address?.formattedZip ?? '-'}
        </p>
      </Cell>
      <Cell>
        <p>
          {store.address?.contactName ?? '-'}
          <br />
          {store.address?.contactEmail ?? '-'}
          <br />
          {store.formattedPhone ?? '-'}
        </p>
      </Cell>
      <Cell justifyContent="center">{meta?.count}</Cell>
      <Cell justifyContent="flex-end">
        <Stack row gap="4px">
          <UpdateStore
            store={store}
            updateStore={async (data) => {
              await updateStore(data);
            }}
            isPending={isPendingUpdate}
          />
          <Button
            variant="secondary"
            iconOnly
            size="small"
            ariaLabel={deleteLabel}
            tooltip={deleteLabel}
            isLoading={isPendingDelete}
            disabled={isPendingDelete || isLoadingUsers}
            onPress={() => {
              if (!meta) {
                return;
              }

              if (meta.count >= 1) {
                alert(
                  t({
                    id: 'settings.stores.delete.cannot-delete-non-empty',
                    message: 'You cannot delete a store with users in it.',
                  })
                );
                return;
              }

              if (
                confirm(
                  t({
                    id: 'settings.stores.delete.confirm-dialog',
                    message: `Are you sure you want to delete the ${store.name} store from your organization?`,
                  })
                )
              ) {
                deleteStore(store.id).catch(console.error);
              }
            }}
          >
            <IconTrash />
          </Button>
        </Stack>
      </Cell>
    </Row>
  );
};

const AddStore = () => {
  const { t } = useLingui();
  const [open, setOpen] = useState(false);

  return (
    <Dialog
      isOpen={open}
      onOpenChange={setOpen}
      trigger={
        <Button variant="secondary" size="small">
          <IconAdd />
          <Trans id="settings.stores.add-modal.trigger">Add a store</Trans>
        </Button>
      }
      title={t({ id: 'settings.stores.add-modal.title', message: 'Add a store' })}
    >
      <AddStoreContent setOpen={setOpen} />
    </Dialog>
  );
};

const AddStoreContent = ({ setOpen }: { setOpen: (open: boolean) => void }) => {
  const { mutateAsync: createStore, isPending } = useCreateStore();

  const state = useStoreState();

  return (
    <>
      <main style={{ overflow: 'auto', maxHeight: '70vh' }}>
        <StoreForm state={state} />
      </main>
      <footer>
        <Button
          variant="primary"
          size="small"
          type="submit"
          isLoading={isPending}
          onPress={() => {
            if (getStoreError(state).hasError) {
              state.setShowErrors(true);
              return;
            }

            createStore({
              name: state.name,
              address: state.address as AddressRaw,
              phone: state.phone,
            })
              .then(() => {
                state.setShowErrors(false);
                setOpen(false);
              })
              .catch((err) => {
                console.error(err);
              });
          }}
        >
          <Trans id="settings.stores.add-modal.submit">Create store</Trans>
        </Button>
      </footer>
    </>
  );
};

export default Stores;

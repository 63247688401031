import { msg } from '@lingui/core/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import { BaseActivity } from '@/components/activities/BaseActivity/BaseActivity';
import IconTruck from '@/icons/Truck.svg';
import { SHIPMENT_CARRIERS } from '@/models/shipment';

export const ShipmentHandoverCompletedActivity = ({
  activity,
}: {
  activity: ActivityOfType<'shipment_handover_completed'>;
}) => {
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="primary"
      icon={<IconTruck />}
      creatorName={
        SHIPMENT_CARRIERS.find((c) => c.id === activity.shipment.carrier)?.name ??
        msg({
          id: 'activities.shipment_preparation_completed.private-carrier',
          message: 'A private carrier',
        })
      }
      message={msg({
        id: 'activities.shipment_handover_completed.message',
        message: 'handled the shipment',
      })}
    />
  );
};

import { ReactNode } from 'react';
import { useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import Menu from '@/design_system/Menu';
import { MenuItem } from '@/design_system/Menu/Menu';
import Stack from '@/design_system/Stack';
import IconBuilding from '@/icons/Building.svg';
import IconChevron from '@/icons/Chevron.svg';
import IconWorld from '@/icons/World.svg';
import { CommentVisibility } from '@/models/comment/comment';
import { Organization } from '@/models/organization';
import { Workshop } from '@/models/workshop';

interface Props {
  visibility: CommentVisibility;
  setVisibility: (visibility: CommentVisibility) => void;
  currentOrganization: Organization | null;
  currentExternalWorkshop: Workshop | null;
}

export const VisibilitySelector = ({
  visibility,
  setVisibility,
  currentOrganization,
  currentExternalWorkshop,
}: Props) => {
  const { t } = useLingui();

  const organizationName = currentOrganization?.name ?? '';
  const externalWorkshopName = currentExternalWorkshop?.name ?? '';

  const getVisibilityLabel = (key: CommentVisibility): string => {
    switch (key) {
      case 'public':
        return t({
          id: 'new-comment.visibility.public',
          message: 'Everyone with access to this page can see',
        });
      case 'organization':
        return t({
          id: 'new-comment.visibility.organization',
          message: `Only ${organizationName} users can see`,
        });
      case 'external-workshop':
        return t({
          id: 'new-comment.visibility.external-workshop',
          message: `Only ${externalWorkshopName} users can see`,
        });
    }
  };

  const getVisibilityIcon = (key: CommentVisibility): ReactNode => {
    switch (key) {
      case 'public':
        return <IconWorld />;
      case 'organization':
      case 'external-workshop':
        return <IconBuilding />;
    }
  };

  const VisibilityMenuItems = ({ visibility }: { visibility: CommentVisibility }) => {
    return (
      <MenuItem id={visibility} key={visibility}>
        <Stack row gap="0.25rem" alignItems="center" flexWrap="nowrap">
          {getVisibilityIcon(visibility)}
          {getVisibilityLabel(visibility)}
        </Stack>
      </MenuItem>
    );
  };

  return (
    <Menu
      trigger={
        <Button
          style={{ textAlign: 'justify' }}
          variant="basic"
          size="compact"
          ariaLabel={t({ id: 'new-comment.visibility', message: 'Visibility' })}
        >
          <Stack row gap="0.25rem" alignItems="center" flexWrap="nowrap">
            {getVisibilityIcon(visibility)}
            <span className="paragraph-200-regular">{getVisibilityLabel(visibility)}</span>
            <IconChevron down />
          </Stack>
        </Button>
      }
      onAction={(selectedVisibility) => {
        setVisibility(selectedVisibility as CommentVisibility);
      }}
    >
      <VisibilityMenuItems visibility="public" />
      {currentOrganization && <VisibilityMenuItems visibility="organization" />}
      {currentExternalWorkshop && <VisibilityMenuItems visibility="external-workshop" />}
    </Menu>
  );
};

import { Trans } from '@lingui/react/macro';

import Button from '@/design_system/Button';

import Dialog from '.';

const CloseConfirmation = ({
  title,
  content,
  confirm,
  onConfirm,
  onCancel,
  showDialog,
  setShowDialog,
  confirmButtonVariant = 'danger',
}: {
  title: React.ReactNode;
  content: React.ReactNode;
  confirm: React.ReactNode;
  onConfirm: () => void;
  onCancel?: () => void;
  showDialog: boolean;
  setShowDialog?: (show: boolean) => void;
  confirmButtonVariant?: 'primary' | 'danger';
}) => {
  if (!showDialog) {
    return undefined;
  }

  return (
    <Dialog
      isOpen
      onOpenChange={() => {
        setShowDialog?.(false);
      }}
      title={title}
      style={{ maxWidth: '25rem' }}
    >
      <main>
        <div className="paragraph-100-regular">{content}</div>
      </main>
      <footer>
        <Button
          variant="secondary"
          size="small"
          onPress={() => {
            setShowDialog?.(false);
            onCancel?.();
          }}
        >
          <Trans id="dialog.close-confirmation.cancel">Cancel</Trans>
        </Button>
        <Button
          variant={confirmButtonVariant}
          size="small"
          onPress={() => {
            setShowDialog?.(false);
            onConfirm?.();
          }}
        >
          {confirm}
        </Button>
      </footer>
    </Dialog>
  );
};

export default CloseConfirmation;

import { useState } from 'react';
import { Trans } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconArchive from '@/icons/Archive.svg';
import IconShipmentIssueResolved from '@/icons/ShipmentIssueResolved.svg';
import { useArticleShipmentWithIssue } from '@/models/shipment';
import { ArticleActionProps } from '@/routes/Requests/Request/Article/components/ArticleActions/ArticleActions';
import { useCurrentSession } from '@/services/auth';
import useViewPort from '@/utils/useViewport';

import ArchiveShipmentIssueDialog from './ArchiveShipmentIssueDialog';
import ResolveShipmentIssueDialog from './ResolveShipmentIssueDialog';

export const HandleShipmentIssueAction = ({
  article,
  request,
  onActionDone,
}: ArticleActionProps) => {
  const { currentSession } = useCurrentSession();
  const { isMobile } = useViewPort();

  const [isOpenResolveIssueDialog, setIsOpenResolveIssueDialog] = useState(false);
  const [isOpenArchiveIssueDialog, setIsOpenArchiveIssueDialog] = useState(false);

  const { shipment } = useArticleShipmentWithIssue(article);

  const shipmentArticle = shipment?.articles.find(({ articleId }) => articleId === article.id);

  const canVerifyReception = shipment?.canReceptionBeVerifiedBy(currentSession);
  const canCreateShipment = shipment?.canBeCreatedBy(currentSession);
  const canArchive = currentSession?.hasPermission('archive_article', {
    organizationId: article.organizationId,
  });

  if (!shipment || !shipmentArticle) {
    return;
  }

  if (!canVerifyReception && !canCreateShipment && !canArchive) {
    return null;
  }

  return (
    <Stack row={!isMobile} gap="0.5rem">
      {canArchive && (
        <>
          <Button
            variant="secondary-danger"
            size="medium"
            onPress={() => setIsOpenArchiveIssueDialog(true)}
          >
            <IconArchive />
            <Trans id="article.actions.handle_shipment_issue.archive">Archive item</Trans>
          </Button>
          <ArchiveShipmentIssueDialog
            shipmentArticle={shipmentArticle}
            request={request}
            isOpen={isOpenArchiveIssueDialog}
            setIsOpen={setIsOpenArchiveIssueDialog}
            onActionDone={onActionDone}
          />
        </>
      )}
      {(canVerifyReception || canCreateShipment) && (
        <>
          <Button variant="primary" size="medium" onPress={() => setIsOpenResolveIssueDialog(true)}>
            <IconShipmentIssueResolved />
            <Trans id="article.actions.handle_shipment_issue.resolve">Resolve shipment issue</Trans>
          </Button>
          <ResolveShipmentIssueDialog
            shipmentArticle={shipmentArticle}
            isOpen={isOpenResolveIssueDialog}
            setIsOpen={setIsOpenResolveIssueDialog}
            onActionDone={
              // On a workshop-to-workshop shipment after the requalification refused,
              // once the origin workshop resolves the shipment issue for the only item,
              // it won't be able to see the request anymore. So we navigate to the requests list
              () =>
                onActionDone({
                  navigateToRequests:
                    canCreateShipment &&
                    !!shipment.originWorkshopId &&
                    !!shipment.destinationWorkshopId &&
                    request.articles.length === 1,
                })
            }
          />
        </>
      )}
    </Stack>
  );
};

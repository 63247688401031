import { useEffect, useState } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';

import { FiltersButton, FiltersDrawer, hasFilterBeenUpdated } from '@/components/ListFilters';
import { TableQueryWrapper } from '@/components/TableQueryWrapper';
import InputSearch from '@/design_system/InputSearch';
import Pagination from '@/design_system/Pagination';
import Stack from '@/design_system/Stack';
import { ShipmentCarrier, ShipmentStep, useShipments } from '@/models/shipment';
import { ShipmentsEmptyState } from '@/routes/Shipments/components/ShipmentsEmptyState';
import { CarrierSelect } from '@/routes/Shipments/Tabs/components/CarrierSelect';
import {
  SHIPMENTS_PER_PAGE,
  ShipmentsList,
  ShipmentsTable,
} from '@/routes/Shipments/Tabs/components/ShipmentsTable';
import { ShipmentStepSelect } from '@/routes/Shipments/Tabs/components/ShipmentStepSelect';
import { ErrorBoundary } from '@/services/sentry';
import useViewport from '@/utils/useViewport';

const OngoingTab = ({
  search,
  debouncedSearch,
  debouncedSetSearch,
  page,
  setPage,
  steps,
  setSteps,
  carriers,
  setCarriers,
}: {
  search: string;
  debouncedSearch: string;
  debouncedSetSearch: (ref: string) => void;
  page: number;
  setPage: (page: number) => void;
  steps: ShipmentStep[];
  setSteps: (steps: ShipmentStep[]) => void;
  carriers: ShipmentCarrier[];
  setCarriers: (carriers: ShipmentCarrier[]) => void;
}) => {
  const { t } = useLingui();
  const { isMobile } = useViewport();

  const {
    data: { shipments, meta } = {},
    isLoading,
    isError,
  } = useShipments({
    limit: SHIPMENTS_PER_PAGE,
    offset: (page - 1) * SHIPMENTS_PER_PAGE,
    search: debouncedSearch || undefined,
    steps: steps?.length ? steps : undefined,
    carriers,
    tab: 'waiting',
  });

  const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = useState(false);
  const hasActiveFilters = !!steps.length || !!carriers.length;

  return (
    <Stack gap="1rem" style={{ height: '100%' }}>
      <Stack row gap="1rem">
        <ErrorBoundary>
          <InputSearch
            placeholder={t({
              id: 'shipments.search.placeholder',
              message: 'Search a reference, origin or destination...',
            })}
            ariaLabel={t({
              id: 'shipments.search.placeholder',
              message: 'Search a reference, origin or destination...',
            })}
            style={{ flex: 1, minWidth: 175 }}
            value={search}
            onChange={debouncedSetSearch}
            size="medium"
          />
          {isMobile ? (
            <>
              <FiltersButton
                hasActiveFilters={hasActiveFilters}
                isFiltersDrawerOpen={isFiltersDrawerOpen}
                setIsFiltersDrawerOpen={setIsFiltersDrawerOpen}
              />
              <ShipmentMobileFilters
                isOpen={isFiltersDrawerOpen}
                onOpenChange={setIsFiltersDrawerOpen}
                setPage={setPage}
                steps={steps}
                setSteps={setSteps}
                carriers={carriers}
                setCarriers={setCarriers}
              />
            </>
          ) : (
            <>
              <CarrierSelect
                selectedKeys={carriers}
                onSelectionChange={(keys) => {
                  setCarriers([...keys] as ShipmentCarrier[]);
                  setPage(1);
                }}
              />
              <ShipmentStepSelect
                selectedKeys={steps}
                onSelectionChange={(keys) => {
                  setSteps([...keys] as ShipmentStep[]);
                  setPage(1);
                }}
              />
            </>
          )}
        </ErrorBoundary>
      </Stack>

      <TableQueryWrapper isLoading={isLoading} isError={isError}>
        {!shipments?.length ? (
          <ShipmentsEmptyState
            subtitle={
              <Trans id="shipments.empty-state.no-shipment-subtitle">No shipments to display</Trans>
            }
          />
        ) : (
          <>
            {isMobile ? (
              <ShipmentsList
                shipments={shipments ?? []}
                showStatusDueAt
                pagination={
                  <Pagination
                    page={page}
                    itemsPerPage={SHIPMENTS_PER_PAGE}
                    count={meta?.count}
                    onPageChange={setPage}
                  />
                }
              />
            ) : (
              <ShipmentsTable
                shipments={shipments ?? []}
                showStatusDueAt
                pagination={
                  <Pagination
                    page={page}
                    itemsPerPage={SHIPMENTS_PER_PAGE}
                    count={meta?.count}
                    onPageChange={setPage}
                  />
                }
              />
            )}
          </>
        )}
      </TableQueryWrapper>
    </Stack>
  );
};

const ShipmentMobileFilters = ({
  isOpen,
  onOpenChange,
  setPage,
  steps,
  setSteps,
  carriers,
  setCarriers,
}: {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  setPage: (page: number) => void;
  steps: ShipmentStep[];
  setSteps: (steps: ShipmentStep[]) => void;
  carriers: ShipmentCarrier[];
  setCarriers: (carriers: ShipmentCarrier[]) => void;
}) => {
  const [newCarriers, setNewCarriers] = useState<ShipmentCarrier[]>([]);
  const [newSteps, setNewSteps] = useState<ShipmentStep[]>([]);

  const handleClearFilters = () => {
    setNewCarriers([]);
    setNewSteps([]);
  };

  const handleApplyFilters = () => {
    const hasStepsFilterBeenUpdated = hasFilterBeenUpdated(steps, newSteps);
    const hasCarriersFilterBeenUpdated = hasFilterBeenUpdated(carriers, newCarriers);

    if (hasStepsFilterBeenUpdated) {
      setSteps(newSteps);
    }

    if (hasCarriersFilterBeenUpdated) {
      setCarriers(newCarriers);
    }

    if (hasStepsFilterBeenUpdated || hasCarriersFilterBeenUpdated) {
      setPage(1);
    }

    onOpenChange(false);
  };

  // Those useEffect aim to fill the local state of the drawer with the already applied filters
  useEffect(() => {
    setNewCarriers(carriers);
  }, [carriers, isOpen]);

  useEffect(() => {
    setNewSteps(steps);
  }, [steps, isOpen]);

  return (
    <FiltersDrawer
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      handleClearFilters={handleClearFilters}
      handleApplyFilters={handleApplyFilters}
    >
      <CarrierSelect
        selectedKeys={newCarriers}
        onSelectionChange={(keys) => {
          setNewCarriers(keys);
        }}
      />

      <ShipmentStepSelect
        selectedKeys={newSteps}
        onSelectionChange={(keys) => {
          setNewSteps(keys);
        }}
      />
    </FiltersDrawer>
  );
};

export default OngoingTab;

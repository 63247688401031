import { Separator } from 'react-aria-components';
import { Trans, useLingui } from '@lingui/react/macro';
import cn from 'classnames';

import Accordion from '@/design_system/Accordion';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import Message from '@/design_system/Message/Message';
import PhotoCard from '@/design_system/PhotoCard';
import Stack from '@/design_system/Stack';
import IconCalendar from '@/icons/Calendar.svg';
import IconCloth from '@/icons/Cloth.svg';
import IconDownload from '@/icons/Download.svg';
import IconEdit from '@/icons/Edit.svg';
import IconInfo from '@/icons/Info.svg';
import IconValidation from '@/icons/Validation.svg';
import { useMedia } from '@/models/medium';
import {
  PRODUCT_CATEGORIES,
  PRODUCT_CATEGORIES_L2,
  PRODUCT_MATERIALS,
  useColorName,
  useColorOptions,
  useSizeOptions,
} from '@/models/product';
import { type ArticleWithRelations } from '@/models/request';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { ArticlePriceWithTitle } from '@/routes/Requests/Request/Article/ArticlePrice';
import { useCurrentSession } from '@/services/auth';
import { useCountries } from '@/services/i18n';
import { formatDate } from '@/utils/date';
import { formatCurrency } from '@/utils/number';
import useViewPort from '@/utils/useViewport';

import { ArticleInformationFormDrawer } from './ArticleInformationFormDrawer';
import { ArticleInformationFormModal } from './ArticleInformationFormModal';

export const ArticleInfoSidePanel = () => {
  const { t } = useLingui();
  const { currentSession } = useCurrentSession();
  const { article, view, state } = useArticleContext();

  const canEditArticleInfo = currentSession!.hasPermission('edit_article', {
    organizationId: article.organizationId,
  });

  return (
    <Stack gap="0.5rem">
      {article.task?.type !== 'choose_article_service' && (
        <>
          <Stack padding="0.5rem 1.5rem">
            <ArticlePriceWithTitle allowDiscount={false} />
          </Stack>
          <Separator />
        </>
      )}

      <Stack ariaLabel={t({ id: 'article.information.title', message: 'Information' })}>
        <Accordion
          openByDefault
          trigger={
            <Stack row gap="0.5rem" alignItems="center">
              <p className="paragraph-100-medium">
                <Trans id="article.information.title">Information</Trans>
              </p>
            </Stack>
          }
        >
          <ArticleDetails />
          {canEditArticleInfo && (
            <Button
              variant="secondary"
              onPress={() => state.infoPanel.setIsOpen(true)}
              size="small"
              ariaLabel={t({ id: 'article.edit.actions.edit-article', message: 'Edit' })}
            >
              <IconEdit />
              <Trans id="article.edit.actions.edit-article">Edit</Trans>
            </Button>
          )}
        </Accordion>

        <ArticleInformationFormDrawer />
      </Stack>
      {view.warranty.shown && !view.warranty.editable && (
        <>
          <Separator />

          <Stack>
            <Accordion
              openByDefault
              trigger={
                <Stack row gap="0.5rem" alignItems="center">
                  <p className="paragraph-100-medium">
                    <Trans id="article.warranty.title">Warranty</Trans>
                  </p>
                </Stack>
              }
            >
              <ArticleWarranty article={article} />
            </Accordion>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export const ArticleInfoTopPanel = () => {
  const { t } = useLingui();
  const { currentSession } = useCurrentSession();

  const { article, view, state } = useArticleContext();

  const canEditArticleInfo = currentSession!.hasPermission('edit_article', {
    organizationId: article.organizationId,
  });

  return (
    <Stack gap="1rem" className="paragraph-100-regular">
      {article.task?.type !== 'choose_article_service' && (
        <Stack>
          <ArticlePriceWithTitle allowDiscount={false} />
        </Stack>
      )}

      <Box padding="0">
        <Stack ariaLabel={t({ id: 'article.information.title', message: 'Information' })}>
          <Accordion
            trigger={
              <Stack row gap="0.5rem" alignItems="center" className="paragraph-100-regular">
                <IconCloth />
                <Trans id="article.information.title">Information</Trans>
              </Stack>
            }
          >
            <Stack gap="1rem">
              <ArticleDetails />
              {canEditArticleInfo && (
                <Button
                  variant="secondary"
                  size="small"
                  onPress={() => state.infoPanel.setIsOpen(true)}
                  ariaLabel={t({ id: 'article.information.edit', message: 'Edit' })}
                >
                  <IconEdit />
                  <Trans id="article.information.edit">Edit</Trans>
                </Button>
              )}
            </Stack>
          </Accordion>
        </Stack>

        <ArticleInformationFormModal />

        {view.warranty.shown && !view.warranty.editable && (
          <>
            <Separator />

            <Accordion
              trigger={
                <Stack row gap="0.5rem" alignItems="center" className="paragraph-100-regular">
                  <IconValidation />
                  <Trans id="article.warranty.title">Warranty</Trans>
                </Stack>
              }
            >
              <Stack gap="1rem">
                <ArticleWarranty article={article} />
              </Stack>
            </Accordion>
          </>
        )}
      </Box>
    </Stack>
  );
};

const ArticleDetails = () => {
  const { i18n, t } = useLingui();
  const { isMobile } = useViewPort();
  const countries = useCountries();

  const { request, article, errors } = useArticleContext();

  const l1Label = PRODUCT_CATEGORIES.find(({ id }) => id === article.productL1)?.label;
  const l2Label = PRODUCT_CATEGORIES_L2.find(({ id }) => id === article.productL2)?.label;
  const l3Label = PRODUCT_MATERIALS.find(({ id }) => id === article.productL3)?.label;
  const colorLabel = useColorName(article.data.color);

  const productName = article.product
    ? `${article.product.externalId} - ${article.product.name}` +
      (article.product.data.collection ? ` (${article.product.data.collection})` : '')
    : '-';

  const colorOptions = useColorOptions(article.product);
  const sizeOptions = useSizeOptions(article.product, article.productL1);

  const showCustomsFields = article.areCustomsExpected || article.wentThroughCustoms;
  const madein = article.product?.data.madein ?? article.data.madein;
  const customsValue = article.product?.data.customsValue ?? article.data.customsValue;

  return (
    <Stack gap="0.75rem">
      <Stack row gap="1rem" flexWrap="nowrap">
        {!!article.articlePhoto && !isMobile && (
          <PhotoCard
            url={article.articlePhoto.url}
            name={t({ id: 'article.information.article-photo', message: 'Item photo' })}
            alt={t({ id: 'article.information.article-photo', message: 'Item photo' })}
            imgStyle={{
              height: '7.5rem',
              width: '6.125rem',
            }}
          />
        )}
        {!!article.product?.data.image && (
          <div style={{ width: '100%' }}>
            {isMobile && (
              <span className="paragraph-200-regular text-secondary">
                <Trans id="article.information.product-photo">Product photo</Trans>
              </span>
            )}
            <PhotoCard
              url={article.product.data.image}
              name={article.product.data.image}
              alt={article.product.data.image}
              imgStyle={{
                height: '7.5rem',
                width: isMobile ? '100%' : '6.125rem',
              }}
            />
          </div>
        )}
      </Stack>

      <Item
        label={t({ id: 'article.information.article-reference', message: 'Item reference' })}
        value={productName}
        error={
          errors.details?.product
            ? t({
                id: 'article.form.external-id.error',
                message: 'Please select a product reference or type',
              })
            : undefined
        }
      />
      <Item
        label={t({ id: 'article.information.product-reference', message: 'Product reference' })}
        value={article.product?.data.reference}
      />
      <Item
        label={t({ id: 'article.information.type', message: 'Type' })}
        value={l1Label ? i18n._(l1Label) : undefined}
        error={
          errors.details?.product
            ? t({
                id: 'article.form.external-id.error',
                message: 'Please select a product reference or type',
              })
            : undefined
        }
      />
      <Item
        label={t({ id: 'article.information.category', message: 'Category' })}
        value={l2Label ? i18n._(l2Label) : undefined}
        error={
          errors.details?.productL2
            ? t({
                id: 'article.form.category.error',
                message: 'Please select a category',
              })
            : undefined
        }
      />
      <Item
        label={t({ id: 'article.information.collection', message: 'Collection' })}
        value={article.product?.data.collection}
      />
      {!!request.organization.brands.length && (
        <Item
          label={t({ id: 'article.information.brand', message: 'Brand' })}
          value={article.data.brand?.name}
          error={
            errors.details?.otherBrandEmpty
              ? t({
                  id: 'article.form.brand.other.specify-name.error',
                  message: 'Please select a brand name',
                })
              : undefined
          }
        />
      )}
      <Item
        label={t({ id: 'article.information.material', message: 'Material' })}
        value={l3Label ? i18n._(l3Label) : undefined}
        error={
          errors.details?.productL3
            ? t({
                id: 'requests.articles.form.error.productL3',
                message: 'Please select a material',
              })
            : undefined
        }
      />
      <Item
        label={t({ id: 'article.information.color', message: 'Color' })}
        value={colorOptions.length > 0 ? colorLabel : t({ id: '_general.na', message: 'N/A' })}
      />
      <Item
        label={t({ id: 'article.information.size', message: 'Size' })}
        value={
          sizeOptions.length > 0 ? article.data.size : t({ id: '_general.na', message: 'N/A' })
        }
      />
      {showCustomsFields && (
        <>
          <Item
            label={t({ id: 'article.information.customs.tariff-code', message: 'Tariff code' })}
            value={article.product?.data.tariffCode ?? article.data.tariffCode}
            error={!(article.product?.data.tariffCode ?? article.data.tariffCode)}
          />
          <Item
            label={t({ id: 'article.information.customs.made-in', message: 'Made in' })}
            value={countries.find((country) => country.code === madein)?.name ?? '-'}
            error={!countries.find((country) => country.code === madein)}
          />
          <Item
            label={t({
              id: 'article.information.customs.manufacturer-code',
              message: 'Manufacturer code (MID)',
            })}
            value={article.product?.data.mid ?? article.data.mid}
            error={!(article.product?.data.mid ?? article.data.mid)}
          />
          <Item
            label={t({ id: 'article.information.customs.customs-value', message: 'Customs value' })}
            value={customsValue ? formatCurrency(customsValue.amount, customsValue.currency) : '-'}
            error={!customsValue}
          />
        </>
      )}
    </Stack>
  );
};

const ArticleWarranty = ({ article }: { article: ArticleWithRelations }) => {
  const { t } = useLingui();
  const { data: { media: proofOfPurchaseMedia } = { media: [] } } = useMedia({
    articleId: article.id,
    types: ['proof-of-purchase'],
    limit: 1,
  });

  const proofOfPurchase = proofOfPurchaseMedia?.[0];

  return (
    <Stack gap="0.75rem">
      <Item label={t({ id: 'article.information.purchase-date', message: 'Purchase date' })}>
        <Stack row gap="0.25rem" alignItems="center">
          <IconCalendar />
          <span
            className="paragraph-100-medium"
            aria-label={t({ id: 'article.information.purchase-date', message: 'Purchase date' })}
          >
            {article.purchaseDateDate
              ? formatDate(article.purchaseDateDate, { dateStyle: 'medium' })
              : '-'}
          </span>
        </Stack>
      </Item>
      <Item
        label={t({ id: 'article.information.proof-of-purchase', message: 'Proof of purchase' })}
      >
        {proofOfPurchase && (
          <Stack row flexWrap="nowrap" gap="0.25rem">
            <span className="paragraph-100-medium text-primary text-ellipsis">
              {proofOfPurchase.originalPath}
            </span>
            <Button
              href={proofOfPurchase.url}
              target="_blank"
              rel="noreferrer"
              variant="secondary"
              iconOnly
              size="small"
              tooltip={t({ id: 'article.information.download', message: 'Download' })}
            >
              <IconDownload className="text-secondary" />
            </Button>
          </Stack>
        )}
      </Item>

      <Item
        label={t({ id: 'article.information.warranty.label', message: 'Warranty' })}
        value={
          article.warranty
            ? t({ id: 'design-system.radio-group.yes-no.yes', message: 'Yes' })
            : t({ id: 'design-system.radio-group.yes-no.no', message: 'No' })
        }
      />
    </Stack>
  );
};

const Item = ({
  label,
  value,
  error,
  children,
}: {
  label: string;
  value?: React.ReactNode;
  error?: React.ReactNode;
  children?: React.ReactNode;
}) => {
  return (
    <Stack>
      <Stack
        row
        gap="4px"
        alignItems="center"
        className={cn('text-secondary', {
          'text-danger': !!error,
        })}
      >
        {!!error && <IconInfo style={{ fontSize: '1rem' }} />}
        <span className="paragraph-200-regular">{label}</span>
      </Stack>
      {!children && (
        <span className="paragraph-100-medium" aria-label={label}>
          {value || '-'}
        </span>
      )}
      {children}
      {!!error && error !== true && (
        <Message type="error" showMessageIcon={false}>
          {error}
        </Message>
      )}
    </Stack>
  );
};

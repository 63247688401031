import { useLingui } from '@lingui/react/macro';

import { InputSelect, InputSelectProps } from '@/design_system/InputSelect/InputSelect';
import { Locale, SUPPORTED_LOCALES } from '@/services/i18n';

type InputLocaleProps = {
  value?: Locale | null;
  onChange?: (value: Locale) => void;
} & Omit<InputSelectProps<{ id: Locale; text: string }, false>, 'value' | 'onChange'>;

export const InputLocale = ({ value, onChange, ...props }: InputLocaleProps) => {
  const { i18n, t } = useLingui();

  const languageOptions = SUPPORTED_LOCALES.map((locale) => ({
    id: locale.id,
    text: i18n._(locale.label),
  }));

  return (
    <InputSelect
      variant="select"
      label={t({ id: 'components.input-locale.label', message: 'Language' })}
      options={languageOptions}
      value={languageOptions.find((languageOption) => value === languageOption.id)}
      getOptionValue={(language) => language.text}
      getOptionLabel={(language) => language.text}
      onChange={(language) => {
        if (language) {
          onChange?.(language.id);
        }
      }}
      isSearchable={false}
      {...props}
    />
  );
};

import { useLingui } from '@lingui/react/macro';

import { EmptyState } from '@/components/EmptyState';

import IllustrationTransactionsEmptyState from './IllustrationTransactionsEmptyState';

export const TransactionsEmptyState = () => {
  const { t } = useLingui();

  return (
    <EmptyState
      subtitle={t({
        id: 'accounting.transactions.empty-state.subtitle',
        message: 'No transactions to display',
      })}
      illustration={<IllustrationTransactionsEmptyState />}
    />
  );
};

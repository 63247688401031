import { useEffect } from 'react';
import { Link } from 'react-aria-components';
import { Trans, useLingui } from '@lingui/react/macro';
import { useQueryClient } from '@tanstack/react-query';

import { ActivityOfType } from '@/components/activities/Activity';
import AlertBar from '@/design_system/AlertBar';
import { ArticleWithRelations, useActivities } from '@/models/request';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';

import { BaseMessage } from './BaseMessage';

export const ArticleArchivedMessage = ({ article }: { article: ArticleWithRelations }) => {
  if (!article.archivalDetail) {
    return null;
  }

  if (article.archivalDetail.type === 'completed') {
    if (article.cancellationDetail) {
      return <BaseMessage article={article} />;
    } else {
      return <CompletedMessage />;
    }
  }

  if (article.archivalDetail.type === 'manual') {
    return <ManuallyArchivedMessage />;
  }

  if (article.archivalDetail.type === 'automatic') {
    return <AutomaticallyArchivedMessage />;
  }

  return null;
};

const CompletedMessage = () => {
  return (
    <AlertBar
      type="success"
      size="large"
      hideIcon
      title={<Trans id="article.archived-message.completed.title">The item is completed</Trans>}
    />
  );
};

const ManuallyArchivedMessage = () => {
  const { t } = useLingui();
  const { article } = useArticleContext();

  // TODO: remove article.archivalDetail?.reason check once fully migrated to "exportedToZendesk"
  const exportedToZendesk = article.archivalDetail?.exportedTo === 'zendesk';
  const archivalReason = article.archivalDetail?.reason;
  const archivalReasonMessage = archivalReason
    ? t({
        id: 'article.archived-message.manual.reason',
        message: `Archival reason: "${archivalReason}"`,
      })
    : undefined;

  if (exportedToZendesk) {
    return <ExportedToZendesk type="manual" archivalReasonMessage={archivalReasonMessage} />;
  }

  if (article.hasIssue) {
    return (
      <AlertBar
        type="error"
        size="large"
        hideIcon
        title={
          <Trans id="article.archived-message.issue.title">
            This item has been archived after a shipment issue
          </Trans>
        }
      >
        {archivalReasonMessage}
      </AlertBar>
    );
  }

  return (
    <AlertBar
      type="error"
      size="large"
      hideIcon
      title={<Trans id="article.archived-message.manual.title">The item is archived</Trans>}
    >
      <p>
        <Trans id="article.archived-message.manual.text">
          The item has been manually archived before its completion.
        </Trans>
      </p>
      {archivalReasonMessage}
    </AlertBar>
  );
};

const AutomaticallyArchivedMessage = () => {
  const { article } = useArticleContext();

  const exportedToZendesk = article.archivalDetail?.exportedTo === 'zendesk';

  if (exportedToZendesk) {
    return <ExportedToZendesk type="automatic" />;
  }

  return (
    <AlertBar
      type="error"
      size="large"
      hideIcon
      title={
        <Trans id="article.archived-message.automatic.title">
          The item has been automatically archived
        </Trans>
      }
    />
  );
};

const ExportedToZendesk = ({
  type,
  archivalReasonMessage,
}: {
  type: 'manual' | 'automatic';
  archivalReasonMessage?: string;
}) => {
  const { request } = useRequestContext();
  const { article } = useArticleContext();

  const { data: { activities } = { activities: [] } } = useActivities(
    {
      requestId: request.id,
      types: ['request_sent_to_zendesk', 'article_sent_to_zendesk'],
    },
    {
      refetchInterval: (query) => (query.state.data?.meta.count === 0 ? 5000 : false),
    }
  );

  const articleActivity = activities.find(
    (activity) => activity.type === 'article_sent_to_zendesk' && activity.articleId === article.id
  ) as ActivityOfType<'article_sent_to_zendesk'> | undefined;

  const zendeskTicket = articleActivity
    ? {
        id: articleActivity.data.ticket.id,
        url: `${request.organization.config.integrations?.zendesk?.baseUrl}/agent/tickets/${articleActivity.data.ticket.id}`,
      }
    : null;

  const queryClient = useQueryClient();

  useEffect(() => {
    if (articleActivity) {
      queryClient.refetchQueries({
        queryKey: ['activities', article.id],
        exact: true,
      });
    }
  }, [queryClient, article.id, articleActivity]);

  if (activities.length === 0) {
    return (
      <AlertBar
        type="error"
        size="large"
        hideIcon
        title={
          type === 'automatic' ? (
            <Trans id="article.archived-message.automatic-zendesk.title.loading">
              This item has been automatically archived and is being transferred to Zendesk
            </Trans>
          ) : (
            <Trans id="article.archived-message.manual-zendesk.title.loading">
              This item has been manually archived and is being transferred to Zendesk
            </Trans>
          )
        }
      >
        {archivalReasonMessage}
      </AlertBar>
    );
  }

  return (
    <AlertBar
      type="error"
      size="large"
      hideIcon
      title={
        type === 'automatic' ? (
          <Trans id="article.archived-message.automatic-zendesk.title">
            This item has been automatically archived and transferred to Zendesk
          </Trans>
        ) : (
          <Trans id="article.archived-message.manual-zendesk.title">
            This item has been manually archived and transferred to Zendesk
          </Trans>
        )
      }
    >
      {archivalReasonMessage}

      {!!zendeskTicket && (
        <p>
          <Trans id="article.archived-message.zendesk.ticket">
            Please find attached the link to the related Zendesk ticket:
          </Trans>{' '}
          <Link target="_blank" href={zendeskTicket.url} style={{ color: 'inherit' }}>
            {zendeskTicket.id}
          </Link>
        </p>
      )}
    </AlertBar>
  );
};

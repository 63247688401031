import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Trans, useLingui } from '@lingui/react/macro';

import AlertBar from '@/design_system/AlertBar';
import Button from '@/design_system/Button';
import Checkbox from '@/design_system/Checkbox';
import Dialog from '@/design_system/Dialog';
import Stack from '@/design_system/Stack';
import IconShipmentNew from '@/icons/ShipmentNew.svg';
import { useAutoValidateTransit } from '@/models/article';
import { useWorkshop } from '@/models/workshop';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { useCurrentSession } from '@/services/auth';
import { useLocalStorageState } from '@/services/localStorage';
import useViewPort from '@/utils/useViewport';

export const CreateShipmentAction = () => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();
  const navigate = useNavigate();
  const { currentSession } = useCurrentSession();
  const { article } = useArticleContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [localStorageSkipModal, setLocalStorageSkipModal] = useLocalStorageState(
    'skip-auto-validate-transit-modal',
    false
  );
  const [error, setError] = useState<string | null>(null);

  const { data: toWorkshop } = useWorkshop(article?.toWorkshopId);

  const { mutateAsync: autoValidateTransit, isPending: isPendingAutoValidateTransit } =
    useAutoValidateTransit({ articleId: article?.id });

  const autoValidate = () => {
    setError(null);
    return autoValidateTransit().catch((err) => {
      setError(
        (err.message as string) ?? t({ id: '_general.error.unknown', message: 'Unknown error' })
      );
    });
  };

  if (!article) {
    return null;
  }

  let canAutoValidateTransit: boolean | undefined = false;

  if (article.atStoreId && toWorkshop?.nonDigitalized) {
    canAutoValidateTransit = currentSession?.hasPermission('create_shipment_from_origin', {
      storeId: article.atStoreId,
    });
  } else if (article.atWorkshop?.nonDigitalized && article.toStoreId) {
    canAutoValidateTransit = currentSession?.hasPermission(
      'act_on_behalf_of_non_digitalized_workshop',
      {
        storeId: article.toStoreId,
      }
    );
  }

  return (
    <Stack
      row={!isMobile}
      alignItems={isMobile ? 'stretch' : 'center'}
      style={{ flex: 1 }}
      justifyContent="flex-end"
      gap="1rem"
    >
      {error && <p className="paragraph-100-medium text-danger">{error}</p>}
      <Button
        variant={canAutoValidateTransit ? 'secondary' : 'primary'}
        size="medium"
        onPress={() =>
          navigate('/shipments/new', {
            state: { requestId: article.requestId, articleId: article.id },
          })
        }
      >
        <IconShipmentNew />
        <Trans id="article.actions.create-shipment">Create a shipment</Trans>
      </Button>
      {canAutoValidateTransit && (
        <>
          <Button
            disabled={isModalOpen || isPendingAutoValidateTransit}
            onPress={() => {
              if (localStorageSkipModal) {
                autoValidate();
              } else {
                setIsModalOpen(true);
              }
            }}
          >
            <Trans id="article.actions.auto-validate-transit">Auto-validate transit</Trans>
          </Button>
          <AutoValidateTransitModal
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            onConfirm={(skipModal) => {
              setLocalStorageSkipModal(skipModal);
              autoValidate().finally(() => {
                setIsModalOpen(false);
              });
            }}
          />
        </>
      )}
    </Stack>
  );
};

const AutoValidateTransitModal = ({
  isOpen,
  setIsOpen,
  onConfirm,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onConfirm: (skipModal: boolean) => void;
}) => {
  const { t } = useLingui();
  const [skipModal, setSkipModal] = useState(false);

  return (
    <Dialog
      isOpen={isOpen}
      title={t({
        id: 'article.auto-validate-transit-modal.title',
        message: 'Auto-validate transit',
      })}
      onOpenChange={setIsOpen}
      style={{ width: '32rem' }}
    >
      <main>
        <Stack gap="1rem">
          <AlertBar
            type="warning"
            size="large"
            title={
              <Trans id="article.auto-validate-transit-modal.text">
                By validating the transit of this item, you confirm that you have handled the
                delivery yourself or arranged for someone (e.g., the recipient) to pick it up.
              </Trans>
            }
          />
          <Checkbox isSelected={skipModal} onChange={setSkipModal}>
            <p className="paragraph-100-regular">
              <Trans id="article.auto-validate-transit-modal.input.skip-modal">
                Don&apos;t show this message again
              </Trans>
            </p>
          </Checkbox>
        </Stack>
      </main>
      <footer>
        <Button variant="secondary" size="medium" onPress={() => setIsOpen(false)}>
          <Trans id="article.auto-validate-transit-modal.actions.cancel">Cancel</Trans>
        </Button>
        <Button variant="primary" size="medium" onPress={() => onConfirm(skipModal)}>
          <Trans id="article.auto-validate-transit-modal.actions.confirm">Confirm</Trans>
        </Button>
      </footer>
    </Dialog>
  );
};

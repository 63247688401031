import { Trans } from '@lingui/react/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityDetailedContent,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconItemDelete from '@/icons/ItemDelete.svg';

export const ArticleRequalificationRefusedActivity = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityOfType<'article_requalification_refused'>;
  displayArticleName: boolean;
}) => {
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="danger"
      icon={<IconItemDelete />}
      creatorName={activity.creator.name}
      message={
        <>
          <BaseActivityText>
            <Trans id="activities.article_requalification_refused.message">
              refused the re-qualification for the item
            </Trans>
          </BaseActivityText>
          <BaseActivityArticleName activity={activity} displayArticleName={displayArticleName} />
        </>
      }
    >
      <BaseActivityDetailedContent>{activity.data.comment}</BaseActivityDetailedContent>
    </BaseActivity>
  );
};

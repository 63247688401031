import { type ReactNode, useCallback, useEffect, useState } from 'react';
import { Button as AriaButton } from 'react-aria-components';
import { useLocation } from 'react-router';
import { msg } from '@lingui/core/macro';
import { useLingui } from '@lingui/react';

import { useHelpFormPopup } from '@/components/HelpForm/HelpForm';
import OrganizationSwitch from '@/components/OrganizationSwitch';
import config from '@/config';
import Logo from '@/design_system/Logo';
import {
  SideNavigation,
  SideNavigationMenuItem,
  SideNavigationSeparator,
} from '@/design_system/SideNavigation/SideNavigation';
import Stack from '@/design_system/Stack';
import IconFile from '@/icons/File.svg';
import IconHelp from '@/icons/Help.svg';
import IconInvoice from '@/icons/Invoice.svg';
import IconMenu from '@/icons/Menu.svg';
import IconSearch from '@/icons/Search.svg';
import IconTruck from '@/icons/Truck.svg';
import Scan from '@/layouts/App/Scan';
import { useCurrentOrganization, useCurrentSession, useFlags } from '@/services/auth';
import { ErrorBoundary } from '@/services/sentry';
import { createBEMClasses } from '@/utils/classname';
import useViewport from '@/utils/useViewport';

import { NotificationMenuItem } from './NotificationMenuItem';
import { ProfileMenuItem } from './ProfileMenuItem';

import './Navbar.css';

const labels = {
  request: msg({ id: 'navbar.requests.label', message: 'Requests' }),
  shipments: msg({ id: 'navbar.shipments.label', message: 'Shipments' }),
  accounting: msg({ id: 'navbar.accounting.label', message: 'Accounting' }),
  search: msg({ id: 'navbar.search.label', message: 'Search' }),
  menu: msg({ id: 'navbar.menu.label', message: 'Menu' }),
  helpAndFeedback: msg({ id: 'navbar.help-feedback.label', message: 'Help & Feedback' }),
};

const { block } = createBEMClasses('navbar');

const Navbar = () => {
  const { isMobile } = useViewport();
  const { currentSession } = useCurrentSession();
  const location = useLocation();
  const [currentOrganization, setCurrentOrganization] = useCurrentOrganization();
  const [isScanOpen, setIsScanOpen] = useState(false);
  const [isNotificationCenterOpen, setIsNotificationCenterOpen] = useState(false);
  const { openForm } = useHelpFormPopup();

  useEffect(() => {
    setIsNotificationCenterOpen(false);
  }, [location]);

  const organization = currentSession?.workshop?.external ? undefined : currentOrganization;

  const workshop = currentSession?.workshop?.external ? currentSession.workshop : undefined;

  const canSwitchOrganizations =
    currentSession?.organizations?.length && currentSession.organizations.length > 1;

  const workshopLogo = (
    <img src={workshop?.logoSquare ?? workshop?.logo ?? '/workshop.png'} alt="" />
  );

  const organizationLogo = (
    <img
      src={`${config.apiUrl}/organizations/${organization?.slug}/logo?type=square`}
      alt=""
      style={{ borderRadius: '8px' }}
    />
  );

  const logo = workshop ? (
    workshopLogo
  ) : organization ? (
    canSwitchOrganizations ? (
      <OrganizationSwitch
        organizations={currentSession.organizations}
        onSelectOrganization={setCurrentOrganization}
      >
        <AriaButton>{organizationLogo}</AriaButton>
      </OrganizationSwitch>
    ) : (
      organizationLogo
    )
  ) : (
    <>
      <Logo size="large" className="is-hidden-up-to-desktop" />
      <Logo size="small" className="is-hidden-widescreen" />
    </>
  );

  const setIsScanOpenAndCloseNotificationCenter = useCallback((isOpen: boolean) => {
    setIsScanOpen(isOpen);
    if (isOpen) {
      setIsNotificationCenterOpen(false);
    }
  }, []);

  if (isMobile) {
    return (
      <NavbarMobile
        isScanOpen={isScanOpen}
        setIsScanOpen={setIsScanOpenAndCloseNotificationCenter}
        isNotificationCenterOpen={isNotificationCenterOpen}
        setIsNotificationCenterOpen={setIsNotificationCenterOpen}
      />
    );
  }

  return (
    <NavbarDesktop
      logo={logo}
      organizationName={organization?.name}
      workshopName={workshop?.name}
      isScanOpen={isScanOpen}
      setIsScanOpen={setIsScanOpenAndCloseNotificationCenter}
      isNotificationCenterOpen={isNotificationCenterOpen}
      setIsNotificationCenterOpen={setIsNotificationCenterOpen}
      openForm={openForm}
    />
  );
};

interface NavbarMobileProps {
  isScanOpen: boolean;
  setIsScanOpen: (boolean: boolean) => void;
  isNotificationCenterOpen: boolean;
  setIsNotificationCenterOpen: (boolean: boolean) => void;
}

const NavbarMobile = ({
  isScanOpen,
  setIsScanOpen,
  isNotificationCenterOpen,
  setIsNotificationCenterOpen,
}: NavbarMobileProps) => {
  const { _ } = useLingui();

  return (
    <SideNavigation
      poweredBy={false}
      collapsed={false}
      className={block({
        'notification-trigger-active-only': isNotificationCenterOpen,
      })}
    >
      <SideNavigationMenuItem icon={<IconFile />} label={_(labels.request)} to="/requests" />
      <SideNavigationMenuItem icon={<IconTruck />} label={_(labels.shipments)} to="/shipments" />
      <NotificationMenuItem
        isNotificationCenterOpen={isNotificationCenterOpen}
        setIsNotificationCenterOpen={setIsNotificationCenterOpen}
      />
      <SideNavigationMenuItem
        icon={<IconSearch />}
        label={_(labels.search)}
        onPress={() => setIsScanOpen(true)}
      />
      {isScanOpen && <Scan onClose={() => setIsScanOpen(false)} />}
      <SideNavigationMenuItem icon={<IconMenu />} label={_(labels.menu)} to="/menu" />
    </SideNavigation>
  );
};

interface NavbarDesktopProps {
  logo?: ReactNode;
  organizationName?: string;
  workshopName?: string;
  isScanOpen: boolean;
  setIsScanOpen: (boolean: boolean) => void;
  isNotificationCenterOpen: boolean;
  setIsNotificationCenterOpen: (boolean: boolean) => void;
  openForm: () => void;
}

const NavbarDesktop = ({
  logo,
  organizationName,
  workshopName,
  isScanOpen,
  setIsScanOpen,
  isNotificationCenterOpen,
  setIsNotificationCenterOpen,
  openForm,
}: NavbarDesktopProps) => {
  const { _ } = useLingui();

  const { currentSession } = useCurrentSession();
  const { flags } = useFlags();
  const [currentOrganization] = useCurrentOrganization();

  const canViewInvoice = currentSession?.hasPermission('view_invoice', {
    organizationId: currentOrganization?.id,
  });

  return (
    <SideNavigation
      logo={logo}
      title={organizationName ?? workshopName}
      poweredBy={false}
      collapsed={false}
      className={block({
        'notification-trigger-active-only': isNotificationCenterOpen,
      })}
    >
      <Stack gap="1rem">
        <ErrorBoundary>
          <Stack gap="0.25rem">
            <NotificationMenuItem
              isNotificationCenterOpen={isNotificationCenterOpen}
              setIsNotificationCenterOpen={setIsNotificationCenterOpen}
            />
            <SideNavigationMenuItem
              icon={<IconSearch />}
              label={_(labels.search)}
              onPress={() => {
                setIsScanOpen(true);
                setIsNotificationCenterOpen(false);
              }}
            />
          </Stack>

          <Stack gap="0.25rem">
            <SideNavigationMenuItem icon={<IconFile />} label={_(labels.request)} to="/requests" />
            <SideNavigationMenuItem
              icon={<IconTruck />}
              label={_(labels.shipments)}
              to="/shipments"
            />
            {canViewInvoice && flags['enable-accounting-section'] && (
              <SideNavigationMenuItem
                icon={<IconInvoice />}
                label={_(labels.accounting)}
                to="/accounting"
              />
            )}
          </Stack>
        </ErrorBoundary>
      </Stack>

      {isScanOpen && (
        <ErrorBoundary>
          <Scan onClose={() => setIsScanOpen(false)} />
        </ErrorBoundary>
      )}

      <hr />
      <ErrorBoundary>
        <SideNavigationMenuItem
          icon={<IconHelp />}
          label={_(labels.helpAndFeedback)}
          onPress={() => openForm()}
        />
        <SideNavigationSeparator />
        <ProfileMenuItem />
      </ErrorBoundary>
    </SideNavigation>
  );
};

export default Navbar;

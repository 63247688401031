import { Trans, useLingui } from '@lingui/react/macro';
import { parseISO } from 'date-fns';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconEdit from '@/icons/Edit.svg';
import { DEFAULT_COLORS } from '@/models/product';
import { formatDate } from '@/utils/date';

export const ArticlePropertyUpdatedActivity = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityOfType<'article_property_updated'>;
  displayArticleName: boolean;
}) => {
  const { i18n, t } = useLingui();
  let valueAfterTheChange: string | undefined | null;
  let articlePropertyUpdatedMessage: string | undefined;

  if (activity.data.property === 'data.color') {
    articlePropertyUpdatedMessage = t({
      id: 'activities.article_property_updated.data.color',
      message: 'the color',
    });
    const colorLabel = DEFAULT_COLORS.find(({ id }) => id === activity.data.after)?.label;

    valueAfterTheChange = colorLabel ? i18n._(colorLabel) : null;
  } else if (activity.data.property === 'data.size') {
    articlePropertyUpdatedMessage = t({
      id: 'activities.article_property_updated.data.size',
      message: 'the size',
    });
    valueAfterTheChange = activity.data.after;
  } else if (activity.data.property === 'data.brand') {
    articlePropertyUpdatedMessage = t({
      id: 'activities.article_property_updated.data.brand',
      message: 'the brand',
    });
    valueAfterTheChange = activity.data.after?.name;
  } else if (activity.data.property === 'actions' || activity.data.property === 'custom_actions') {
    articlePropertyUpdatedMessage = t({
      id: 'activities.article_property_updated.actions',
      message: 'the actions',
    });
  } else if (activity.data.property === 'priceManualDiscount') {
    articlePropertyUpdatedMessage = t({
      id: 'activities.article_property_updated.price_manual_discount',
      message: 'the discount applied on client price',
    });
    valueAfterTheChange = activity.data.after ? `-${activity.data.after}%` : '0%';
  } else if (activity.data.property === 'purchaseDate') {
    articlePropertyUpdatedMessage = t({
      id: 'activities.article_property_updated.purchase_date',
      message: 'the purchase date',
    });
    valueAfterTheChange = activity.data.after
      ? formatDate(parseISO(activity.data.after), {
          dateStyle: 'medium',
        })
      : '';
  } else if (activity.data.property === 'warranty') {
    articlePropertyUpdatedMessage = t({
      id: 'activities.article_property_updated.warranty',
      message: 'the warranty',
    });
    valueAfterTheChange = activity.data.after
      ? t({ id: 'activities.article_property_updated.warranty.yes', message: 'Under warranty' })
      : t({ id: 'activities.article_property_updated.warranty.no', message: 'Out-of-Warranty' });
  }

  // We don't want to display the activities related to the status as it's a legacy one
  if (activity.data.property === 'status' || !articlePropertyUpdatedMessage) return null;

  return (
    <BaseActivity
      key={activity.id}
      createdAt={activity.createdAt}
      icon={<IconEdit />}
      creatorName={activity.creator?.name ?? ''}
      message={
        <>
          <BaseActivityText>
            <Trans id="activities.article_property_updated.message">
              edited <span className="paragraph-100-medium">{articlePropertyUpdatedMessage}</span>{' '}
              of the item
            </Trans>
          </BaseActivityText>
          <BaseActivityArticleName activity={activity} displayArticleName={displayArticleName} />
          {valueAfterTheChange && (
            <span className="paragraph-100-medium">
              <Trans id="activities.article_property_updated.separator">:</Trans>{' '}
              {valueAfterTheChange}
            </span>
          )}
        </>
      }
    />
  );
};

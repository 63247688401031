import { Trans } from '@lingui/react/macro';

import Stack from '@/design_system/Stack';

export const ServiceExchange = () => {
  return (
    <Stack gap="0.5rem">
      <p className="label-100">
        <Trans id="article-services.service-exchange.label">Services</Trans>
      </p>
      <p className="paragraph-100-regular text-secondary">
        <Trans id="article-services.service-exchange.text">
          No service is applicable. An exchange has been proposed.
        </Trans>
      </p>
    </Stack>
  );
};

import { useEffect } from 'react';
import Select, { ClearIndicatorProps } from 'react-select';
import { Trans, useLingui } from '@lingui/react/macro';

import Hint from '@/design_system/Hint';
import Stack from '@/design_system/Stack';
import IconChevron from '@/icons/Chevron.svg';
import IconCross from '@/icons/Cross.svg';
import IconPlace from '@/icons/Place.svg';
import { Address, AddressWithRelations, useAddresses } from '@/models/address';
import { Client } from '@/models/client';
import { ShipmentCarrierService, useServicePoint } from '@/models/shipment';
import { Store, useStore } from '@/models/store';
import { useWorkshop, Workshop } from '@/models/workshop';
import { useCurrentOrganization, useCurrentSession } from '@/services/auth';
import { createBEMClasses } from '@/utils/classname';
import useDebouncedState from '@/utils/useDebouncedState';

import './PlaceSelect.css';

const { block, element } = createBEMClasses('place-select');

export const PlaceSelect = ({
  type,
  label,
  placeholder,
  selected,
  setSelected,
  prefillDefault,
  isDisabled,
  carrierService,
}: {
  type: 'origin' | 'destination';
  label: string;
  placeholder: string;
  selected?: AddressWithRelations;
  setSelected: (address?: AddressWithRelations) => void;
  prefillDefault?: boolean;
  isDisabled?: boolean;
  carrierService?: ShipmentCarrierService;
}) => {
  const { currentSession } = useCurrentSession();
  const { t } = useLingui();
  const [currentOrganization] = useCurrentOrganization();
  const [query, debouncedQuery, setQuery] = useDebouncedState<string>('', 500);

  const storeId = currentSession?.roles.find(
    (userRole) => userRole.storeId && userRole.organization?.id === currentOrganization?.id
  )?.storeId;
  const { data: store, isLoading: isLoadingStore } = useStore(storeId ?? undefined);

  const workshopId = currentSession?.workshop?.id;
  const { data: workshop, isLoading: isLoadingWorkshop } = useWorkshop(workshopId);

  useEffect(() => {
    if (!selected && store?.address && prefillDefault) {
      setSelected(newAddressWithRelations(store.address, null, store, null));
    }
  }, [selected, store, setSelected, prefillDefault]);

  useEffect(() => {
    if (!selected && workshop?.address && prefillDefault) {
      setSelected(newAddressWithRelations(workshop.address, null, null, workshop));
    }
  }, [selected, workshop, setSelected, prefillDefault]);

  const {
    data: { addresses } = { addresses: [] },
    isPending,
    isFetching,
  } = useAddresses(
    {
      limit: 10,
      search: debouncedQuery ?? '',
    },
    {
      keepPreviousData: true,
    }
  );

  const servicePoint = useDestinationPickupPoint({ selected, carrierService });

  const typeLabel = {
    origin: t({ id: 'shipments.new.origin.label', message: 'Origin' }),
    destination: t({ id: 'shipments.new.destination.label', message: 'Destination' }),
  }[type];

  return (
    <Stack gap="0.25rem" style={{ flex: 1, maxWidth: '100%' }} ariaLabel={typeLabel}>
      <div className="label-100">{label}</div>
      <Select
        classNamePrefix={block()}
        key={type}
        isDisabled={
          isDisabled ||
          isLoadingStore ||
          isLoadingWorkshop ||
          (prefillDefault && !!(storeId || workshopId))
        }
        components={{
          DropdownIndicator: () => (
            <div className={element('chevron')}>
              <IconChevron down />
            </div>
          ),
          ClearIndicator: ({
            innerProps: { ref, ...restInnerProps },
          }: ClearIndicatorProps<AddressWithRelations, false>) => (
            <div {...restInnerProps} ref={ref} className={element('clear')}>
              <IconCross />
            </div>
          ),
        }}
        isLoading={isPending || isFetching}
        placeholder={<Stack className="paragraph-100-regular text-disabled">{placeholder}</Stack>}
        isSearchable
        value={selected}
        onChange={(value) => setSelected(value ?? undefined)}
        inputValue={query}
        onInputChange={(query, event) => {
          if (!['input-blur', 'menu-close'].includes(event.action)) {
            setQuery(query);
          }
        }}
        getOptionValue={(address) => address.name ?? ''} // Based on https://github.com/JedWatson/react-select/issues/3869, adding this props removes the error 'A component is changing a controlled input to be uncontrolled.'
        unstyled
        isClearable
        filterOption={null} // Avoid react-select to filter options only based on their label
        options={addresses}
        noOptionsMessage={() => (
          <Stack
            className="paragraph-100-regular"
            padding="0.5rem"
            style={{ background: 'var(--color-neutral-0)' }}
          >
            <Trans id="shipments.new.address.no-results">No results</Trans>
          </Stack>
        )}
        loadingMessage={() => (
          <span
            className="paragraph-100-regular"
            style={{ padding: '0.5rem', background: 'var(--color-neutral-0)' }}
          >
            <Trans id="shipments.new.address.loading">Loading...</Trans>
          </span>
        )}
        formatOptionLabel={(value) => (
          <PlaceSelectInput id={value.id} name={value.name} formatted={value.formatted} />
        )}
      />

      {carrierService === 'point_relais' && servicePoint && (
        <Hint>
          <Stack row alignItems="center" gap="0.5rem" flexWrap="nowrap">
            <IconPlace
              start={type === 'origin'}
              end={type === 'destination'}
              style={{ fontSize: '1.25rem' }}
            />
            <>
              <Trans id="shipments.new.service_point.via-mondial-relay">(Via Mondial Relay)</Trans>{' '}
              {servicePoint.name} - {servicePoint.address.line1} {servicePoint.address.zipCode}{' '}
              {servicePoint.address.city}
            </>
          </Stack>
        </Hint>
      )}
    </Stack>
  );
};

const PlaceSelectInput = (address: { id: string; name?: string; formatted: string }) => {
  return (
    <p className={element('input', {}, 'text-ellipsis')}>
      <span className={element('input__name')}>{address.name}</span>{' '}
      <span className={element('input__address')}>({address.formatted})</span>
    </p>
  );
};

export const newAddressWithRelations = (
  address: any,
  client: Client | null,
  store: Store | null,
  workshop: Workshop | null
) => new Address(address).with('store', store).with('client', client).with('workshop', workshop);

const useDestinationPickupPoint = ({
  selected,
  carrierService,
}: {
  selected?: AddressWithRelations;
  carrierService?: ShipmentCarrierService;
}) => {
  let selectedPickupPoint = undefined;

  if (carrierService === 'point_relais') {
    if (selected?.store) {
      selectedPickupPoint = selected.store.pickupPoints?.sendcloud?.mondial_relay;
    } else if (selected?.workshop) {
      selectedPickupPoint = selected.workshop.pickupPoints?.sendcloud?.mondial_relay;
    } else if (selected?.client) {
      selectedPickupPoint =
        selected.client.deliveryOption === 'pickupPoint_mondialRelay'
          ? selected.client.sendcloudServicePointId
          : undefined;
    }
  }

  const { data: servicePoint } = useServicePoint(selectedPickupPoint ?? undefined);

  return servicePoint;
};

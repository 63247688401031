import { useLingui } from '@lingui/react/macro';

import { UserCard } from '@/components/UserCard/UserCard';
import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import { UserWithRelations } from '@/models/user';

export const SupervisorSelect = ({
  selectedKey,
  availableUsers,
  onSelectionChange,
}: {
  selectedKey: string;
  availableUsers?: UserWithRelations[];
  onSelectionChange: (key: string | undefined) => void;
}) => {
  const { t } = useLingui();

  const supervisorOptions = (availableUsers || []).filter((availableUser) => !!availableUser);

  return (
    <InputSelect
      variant="select"
      key="supervisor-select"
      aria-label={t({ id: 'requests.supervisor-select.label', message: 'Search by supervisor' })}
      placeholder={t({ id: 'requests.supervisor-select.placeholder', message: 'Supervisor: All' })}
      isSearchable={false}
      options={supervisorOptions}
      value={supervisorOptions.filter((supervisorOption) => selectedKey === supervisorOption.id)}
      getOptionValue={(supervisorOption) => supervisorOption?.id}
      getOptionLabel={(supervisorOption) => supervisorOption?.name}
      onChange={(newSupervisorOption) => {
        const currentSupervisorOption = supervisorOptions.find(
          (supervisorOption) => selectedKey === supervisorOption.id
        );

        // If clicking on already selected supervisor, clear the field
        if (newSupervisorOption?.id === currentSupervisorOption?.id) {
          onSelectionChange('');
          return;
        }

        onSelectionChange(newSupervisorOption?.id ?? '');
      }}
      formatOptionLabel={(supervisorOption) => {
        return <UserCard user={supervisorOption} size="small" />;
      }}
      style={{ minWidth: 185 }}
    />
  );
};

import { Trans } from '@lingui/react/macro';

import { ArticleWithRelations } from '@/models/request';
import { useCurrentSession } from '@/services/auth';

import { BaseMessage } from './BaseMessage';

export const RepairArticleTaskMessage = ({ article }: { article: ArticleWithRelations }) => {
  const { isWorkshop } = useCurrentSession();

  if (!isWorkshop) {
    return (
      <BaseMessage
        title={
          <Trans id="article.task.repair-article.on-behalf.title">Workshop repair required</Trans>
        }
        message={
          <Trans id="article.task.repair-article.on-behalf.message">
            Please contact the workshop to validate the repair job on its behalf.
          </Trans>
        }
        article={article}
      />
    );
  }

  return (
    <BaseMessage
      title={<Trans id="article.task.repair-article.title">Repair the item</Trans>}
      message={<Trans id="article.task.repair-article.message">You can now repair the item</Trans>}
      article={article}
    />
  );
};

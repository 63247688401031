import { useContext, useState } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import { InputMoney } from '@/design_system/InputNumber';
import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import RadioGroup, { yesNoRadioOptions } from '@/design_system/RadioGroup';
import Stack from '@/design_system/Stack';
import TextArea from '@/design_system/TextArea';
import IconAdd from '@/icons/Add.svg';
import {
  ActionTypeOrganizationWithRelations,
  getCustomPriceAmount,
  PackActionTypeOrganizationWithRelations,
} from '@/models/actionType';
import { useCreateAction, useUpdateAction } from '@/models/article';
import { computeDuplicateDefectNumber, useGetDefectName } from '@/models/defectType';
import { Medium, useDeleteMedium } from '@/models/medium';
import { ArticleActionWithRelations } from '@/models/request';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';
import { useCurrentSession } from '@/services/auth';

import FileUpload from '../FileUpload';

import ActionName from './ActionName';
import { ActionsTableContext } from './ActionsTable';
import PackActionName from './PackActionName';

type CreateEditActionDialogProps = {
  isOpen: boolean;
  onClose: () => void;
} & (
  | {
      actionTypeToCreate?:
        | ActionTypeOrganizationWithRelations
        | PackActionTypeOrganizationWithRelations
        | 'custom';
      actionToEdit?: never;
    }
  | { actionToEdit?: ArticleActionWithRelations; actionTypeToCreate?: never }
);

export const CreateEditActionDialog = ({
  isOpen,
  onClose,
  actionTypeToCreate,
  actionToEdit,
}: CreateEditActionDialogProps) => {
  const { t } = useLingui();

  const title = actionToEdit
    ? t({ id: 'create-action-dialog.title.edit', message: 'Edit action' })
    : actionTypeToCreate === 'custom'
      ? t({ id: 'create-action-dialog.title.custom', message: 'Add a custom action' })
      : t({ id: 'create-action-dialog.title', message: 'Add an action' });

  return (
    <Dialog title={title} isOpen={isOpen} onOpenChange={onClose}>
      <CreateEditActionForm
        onClose={onClose}
        actionTypeToCreate={actionTypeToCreate}
        actionToEdit={actionToEdit}
      />
    </Dialog>
  );
};

const CreateEditActionForm = ({
  onClose,
  actionTypeToCreate,
  actionToEdit,
}: Omit<CreateEditActionDialogProps, 'isOpen'>) => {
  const { isWorkshop } = useCurrentSession();
  const { request, view: requestView } = useRequestContext();
  const { article, view: articleView } = useArticleContext();
  const { showWarrantyCover } = useContext(ActionsTableContext);

  const { mutateAsync: createAction } = useCreateAction({
    articleId: article.id,
    requestId: request.id,
  });

  const { mutateAsync: updateAction } = useUpdateAction({
    articleId: article.id,
    requestId: request.id,
  });

  const { mutate: deleteMedium } = useDeleteMedium();

  const actionType = actionToEdit
    ? (actionToEdit?.actionTypeOrganization ?? actionToEdit?.packActionTypeOrganization ?? 'custom')
    : actionTypeToCreate;

  const showWorkshopPrice =
    (isWorkshop
      ? requestView.price.enabled && articleView.services.actions.canEditPrice
      : requestView.cost.enabled && articleView.services.actions.canEditCost) &&
    actionType === 'custom';
  const showOrganizationPrice =
    !isWorkshop &&
    requestView.price.enabled &&
    articleView.services.actions.canEditPrice &&
    actionType === 'custom';

  const showWorkshopAmountBeforeTaxes = isWorkshop
    ? requestView.price.showAmountBeforeTaxes
    : requestView.cost.showAmountBeforeTaxes;
  const showOrganizationAmountBeforeTaxes = requestView.price.showAmountBeforeTaxes;

  const showDefects =
    articleView.services.defects.shown && articleView.services.actions.canEditDefects;

  const showDefectPhotos = !articleView.services.defects.shown;

  const [description, setDescription] = useState(actionToEdit?.customDescription ?? '');
  const [defectIds, setDefectIds] = useState(
    actionToEdit?.articleDefects.map(({ id }) => id) ?? []
  );
  const [defectPhotos, setDefectPhotos] = useState(actionToEdit?.media ?? []);
  const [defectPhotosToDelete, setDefectPhotosToDelete] = useState<string[]>([]);
  const [brandResponsibility, setBrandResponsibility] = useState(
    actionToEdit?.brandResponsibility ?? false
  );
  const [workshopPrice, setWorkshopPrice] = useState<number | undefined>(
    getCustomPriceAmount({
      customPrice: actionToEdit?.customWorkshopPrice,
      showAmountBeforeTaxes: showWorkshopAmountBeforeTaxes,
    })
  );
  const [organizationPrice, setOrganizationPrice] = useState<number | undefined>(
    getCustomPriceAmount({
      customPrice: actionToEdit?.customOrganizationPrice,
      showAmountBeforeTaxes: showOrganizationAmountBeforeTaxes,
    })
  );

  const save = async () => {
    const data = {
      description: actionType === 'custom' ? description : undefined,
      defectIds: showDefects ? defectIds : undefined,
      defectPhotoIds: showDefectPhotos ? defectPhotos.map((medium) => medium.id) : undefined,
      brandResponsibility: showWarrantyCover ? brandResponsibility : undefined,
      customWorkshopPrice:
        showWorkshopPrice && workshopPrice !== undefined
          ? showWorkshopAmountBeforeTaxes
            ? { amountBeforeTaxes: workshopPrice }
            : { amount: workshopPrice }
          : undefined,
      customOrganizationPrice:
        showOrganizationPrice && organizationPrice !== undefined
          ? showOrganizationAmountBeforeTaxes
            ? { amountBeforeTaxes: organizationPrice }
            : { amount: organizationPrice }
          : undefined,
    };

    if (actionToEdit) {
      await updateAction({
        actionId: actionToEdit.id,
        body: data,
      });
    } else {
      await createAction({
        ...data,
        actionTypeOrganization:
          !!actionTypeToCreate &&
          actionTypeToCreate !== 'custom' &&
          !('actions' in actionTypeToCreate)
            ? actionTypeToCreate
            : undefined,
        packActionTypeOrganization:
          !!actionTypeToCreate && actionTypeToCreate !== 'custom' && 'actions' in actionTypeToCreate
            ? actionTypeToCreate
            : undefined,
      });
    }

    defectPhotosToDelete.forEach((id) => deleteMedium(id));

    onClose();
  };

  if (!actionType) {
    return null;
  }

  return (
    <>
      <main>
        <Stack gap="1rem">
          {actionType === 'custom' ? (
            <Description description={description} setDescription={setDescription} />
          ) : 'actions' in actionType ? (
            <PackActionName packActionType={actionType} />
          ) : (
            <ActionName actionType={actionType.actionType} />
          )}
          <hr />
          {showDefects && <Defects defectIds={defectIds} setDefectIds={setDefectIds} />}
          {showDefectPhotos && (
            <DefectPhotos
              defectPhotos={defectPhotos}
              onUpload={(newDefectPhoto) => setDefectPhotos((prev) => [...prev, newDefectPhoto])}
              onDelete={(idToDelete) => {
                setDefectPhotos((prev) => prev.filter(({ id }) => id !== idToDelete));
                setDefectPhotosToDelete((prev) => [...prev, idToDelete]);
              }}
            />
          )}
          {showWarrantyCover && (
            <BrandResponsibility
              brandResponsibility={brandResponsibility}
              setBrandResponsibility={setBrandResponsibility}
            />
          )}
          {showWorkshopPrice && (
            <WorkshopPrice workshopPrice={workshopPrice} setWorkshopPrice={setWorkshopPrice} />
          )}
          {showOrganizationPrice && (
            <OrganizationPrice
              organizationPrice={organizationPrice}
              setOrganizationPrice={setOrganizationPrice}
            />
          )}
        </Stack>
      </main>
      <footer>
        <Button variant="primary" onPress={save}>
          {actionToEdit ? (
            <Trans id="create-action-dialog.save">Save</Trans>
          ) : (
            <Trans id="create-action-dialog.add">Add action</Trans>
          )}
        </Button>
      </footer>
    </>
  );
};

const Description = ({
  description,
  setDescription,
}: {
  description: string;
  setDescription: (description: string) => void;
}) => {
  const { t } = useLingui();

  return (
    <TextArea
      label={t({
        id: 'create-action-dialog.description.label',
        message: 'Description',
      })}
      placeholder={t({
        id: 'create-action-dialog.description.placeholder',
        message: `Describe the action...`,
      })}
      value={description}
      onChange={(e) => setDescription(e.target.value)}
    />
  );
};

const Defects = ({
  defectIds,
  setDefectIds,
}: {
  defectIds: string[];
  setDefectIds: (defectIds: string[]) => void;
}) => {
  const { t } = useLingui();
  const { article } = useArticleContext();
  const getDefectName = useGetDefectName();

  const defectOptions = article.snapshot.articleDefects;

  return (
    <InputSelect
      variant="select"
      isMulti
      label={<Trans id="create-action-dialog.defects.label">Defects</Trans>}
      placeholder={t({ id: 'create-action-dialog.defects.placeholder', message: 'Add defects' })}
      isSearchable={false}
      options={defectOptions}
      value={defectOptions.filter((defectOption) => defectIds.includes(defectOption.id))}
      getOptionValue={(defectOption) => defectOption.id}
      getOptionLabel={(defectOption) => {
        const duplicateDefectNumber = computeDuplicateDefectNumber(defectOption, defectOptions);
        return `${getDefectName(defectOption)}${duplicateDefectNumber ? ` #${duplicateDefectNumber}` : ''}`;
      }}
      onChange={(defectOptions) =>
        setDefectIds(defectOptions.map((defectOption) => defectOption.id))
      }
      onRemoveValue={(defectOptionId) =>
        setDefectIds(defectIds.filter((defectId) => defectOptionId !== defectId))
      }
    />
  );
};

const DefectPhotos = ({
  defectPhotos,
  onUpload,
  onDelete,
}: {
  defectPhotos: Medium[];
  onUpload: (defectPhoto: Medium) => void;
  onDelete: (id: string) => void;
}) => {
  const { t } = useLingui();
  const { article } = useArticleContext();

  const mediumType =
    article.task?.type === 'analyze_article' ? 'requalification-defect-photo' : 'defect-photo';

  const label = t({ id: 'create-action-dialog.photos.label', message: 'Add defect photos' });

  return (
    <FileUpload
      type="photo"
      variant="grid"
      size="small"
      uploadData={{ type: mediumType }}
      icon={<IconAdd />}
      ariaLabel={label}
      prompt={label}
      media={defectPhotos}
      onUpload={onUpload}
      onDelete={onDelete}
    />
  );
};

const BrandResponsibility = ({
  brandResponsibility,
  setBrandResponsibility,
}: {
  brandResponsibility: boolean;
  setBrandResponsibility: (brandResponsibility: boolean) => void;
}) => {
  const { i18n } = useLingui();

  return (
    <RadioGroup
      label={
        <Trans id="create-action-dialog.brand-responsibility.label">Brand Responsibility</Trans>
      }
      options={yesNoRadioOptions
        .map((option) => ({ ...option, text: i18n._(option.text) }))
        .reverse()}
      value={brandResponsibility ? 'yes' : 'no'}
      onChange={(value) => setBrandResponsibility(value === 'yes')}
    />
  );
};

const WorkshopPrice = ({
  workshopPrice,
  setWorkshopPrice,
}: {
  workshopPrice: number | undefined;
  setWorkshopPrice: (price: number | undefined) => void;
}) => {
  const { t } = useLingui();
  const { view: requestView } = useRequestContext();
  const { isWorkshop } = useCurrentSession();
  const { customActionWorkshopPriceCurrency } = useContext(ActionsTableContext);

  const label = isWorkshop ? requestView.price.label : requestView.cost.label;
  const placeholder = isWorkshop
    ? t({ id: 'create-action-dialog.price.placeholder', message: 'Add a price...' })
    : t({ id: 'create-action-dialog.cost.placeholder', message: 'Add a cost...' });

  return (
    <InputMoney
      label={label}
      placeholder={placeholder}
      value={workshopPrice}
      onChange={setWorkshopPrice}
      currency={customActionWorkshopPriceCurrency}
    />
  );
};

const OrganizationPrice = ({
  organizationPrice,
  setOrganizationPrice,
}: {
  organizationPrice: number | undefined;
  setOrganizationPrice: (price: number | undefined) => void;
}) => {
  const { t } = useLingui();
  const { view: requestView } = useRequestContext();
  const { customActionOrganizationPriceCurrency } = useContext(ActionsTableContext);

  const label = requestView.price.label;
  const placeholder = t({
    id: 'create-action-dialog.client-price.placeholder',
    message: 'Add a client price...',
  });
  return (
    <InputMoney
      label={label}
      placeholder={placeholder}
      value={organizationPrice}
      onChange={setOrganizationPrice}
      currency={customActionOrganizationPriceCurrency}
    />
  );
};

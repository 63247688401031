import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

import { ArticleWithRelations, RequestWithRelations } from '@/models/request';
import { useCurrentSession } from '@/services/auth';

import {
  ArticleErrors,
  ArticleErrorsFilter,
  FilteredArticleErrors,
  recursivelyFilterErrors,
} from './errors';
import { RequestArticleContextData } from './interfaces';
import { useRequestContext } from './RequestContext';

export interface ArticleContextData {
  request: RequestWithRelations;
  article: ArticleWithRelations;
  view: RequestArticleContextData['view'];
  unfilteredErrors: ArticleErrors | null;
  errors: FilteredArticleErrors;
  hasError: boolean;
  state: {
    infoPanel: {
      isOpen: boolean;
      setIsOpen: Dispatch<SetStateAction<boolean>>;
    };
    errors: {
      check: (errorsFilter: ArticleErrorsFilter | null) => FilteredArticleErrors;
    };
  };
}

const ArticleContext = createContext({} as ArticleContextData);

export const ArticleContextProvider = ({
  article,
  children,
  isRequalification,
}: {
  article: ArticleWithRelations;
  children: ReactNode;
  isRequalification?: boolean;
}) => {
  const { request, articles, unfilteredErrors } = useRequestContext();
  const { isWorkshop } = useCurrentSession();

  const articleContextData = articles.find((a) => a.articleId === article.id)!;

  /**
   * Special case, the RequalificationDrawer creates a new ArticleContextProvider
   * with its own view data, state and filtered errors.
   */
  if (isRequalification) {
    const view = structuredClone(articleContextData.view);

    view.services.defects.editable = true;

    view.services.actions.shown = true;
    view.services.actions.editable = true;
    view.services.actions.canAddRemoveAction = true;
    view.services.actions.canEditDefects = true;
    view.services.actions.canEditCost = !isWorkshop;
    view.services.actions.canEditPrice = !!isWorkshop;

    articleContextData.view = view;
  }

  const [isInfoPanelOpen, setIsInfoPanelOpen] = useState(false);

  const [errorsFilter, setErrorsFilter] = useState<ArticleErrorsFilter | null>(null);

  const articleUnfilteredErrors =
    unfilteredErrors.articles.articles.find((a) => a.id === article.id) ?? null;

  const errors = articleUnfilteredErrors
    ? recursivelyFilterErrors(articleUnfilteredErrors, errorsFilter ?? {})
    : {
        hasError: false,
      };

  return (
    <ArticleContext
      value={{
        request,
        article,
        ...articleContextData,
        unfilteredErrors: articleUnfilteredErrors,
        errors,
        hasError: errors.hasError ?? false,
        state: {
          infoPanel: {
            isOpen: isInfoPanelOpen,
            setIsOpen: setIsInfoPanelOpen,
          },
          errors: {
            check(errorsFilter: ArticleErrorsFilter | null) {
              setErrorsFilter(errorsFilter);

              // Directly returns the filtered errors, as the context hasn't re-rendered yet, so the state is not updated
              return articleUnfilteredErrors
                ? recursivelyFilterErrors(articleUnfilteredErrors, errorsFilter ?? {})
                : {
                    hasError: false,
                  };
            },
          },
        },
      }}
    >
      {children}
    </ArticleContext>
  );
};

export const useArticleContext = () => useContext(ArticleContext);

import { useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import Tooltip from '@/design_system/Tooltip';

export type RefashionStatus = 'applied' | 'not-applied-warranty' | 'not-applied-discount';

const RefashionLogo = ({ status = 'applied' }: { status?: RefashionStatus }) => {
  const { t } = useLingui();

  const tooltipContent = {
    applied: t({ id: 'components.refashion', message: 'Refashion bonus' }),
    'not-applied-warranty': t({
      id: 'components.refashion.not-applied-warranty',
      message:
        'Bonus not applicable because the item is under warranty and the brand has been indicated as responsible',
    }),
    'not-applied-discount': t({
      id: 'components.refashion.not-applied-discount',
      message:
        'Bonus not applicable because, with the price reduction, the amount of the bonus would represent more than 60% of the price of the repair',
    }),
  } satisfies Record<RefashionStatus, string>;

  const label = tooltipContent[status];

  return (
    <Tooltip content={label}>
      <Button variant="style-less" style={{ verticalAlign: 'middle' }}>
        <img
          src={status === 'applied' ? '/refashion.png' : '/refashion-not-applied.png'}
          style={{ width: '1.875rem', minWidth: '1.875rem' }}
          alt={label}
        />
      </Button>
    </Tooltip>
  );
};

export default RefashionLogo;

import { ReactNode } from 'react';
import {
  Tab as AriaTab,
  TabList as AriaTabList,
  TabListProps as AriaTabListProps,
  TabPanel as AriaTabPanel,
  TabPanelProps as AriaTabPanelProps,
  TabProps as AriaTabProps,
  Tabs as AriaTabs,
  TabsProps as AriaTabsProps,
} from 'react-aria-components';

import { ErrorBoundary } from '@/services/sentry';
import { createBEMClasses } from '@/utils/classname';

import './Tabs.css';

const { block, element } = createBEMClasses('tabs');

interface TabsProps extends Omit<AriaTabsProps, 'className' | 'selectedKey' | 'onSelectionChange'> {
  selectedKey?: string;
  onSelectionChange?: (key: string) => void;
  gap?: string;
  children: React.ReactNode;
}

const Tabs = ({
  gap = '1rem',
  style,
  children,
  selectedKey,
  onSelectionChange,
  ...props
}: TabsProps) => (
  <ErrorBoundary>
    <AriaTabs
      {...props}
      className={block()}
      style={{ gap, ...style }}
      selectedKey={selectedKey}
      onSelectionChange={(key) => onSelectionChange?.(key as string)}
    >
      {children}
    </AriaTabs>
  </ErrorBoundary>
);

export default Tabs;

interface TabListProps<T> extends AriaTabListProps<T> {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export function TabList<T>({ children, style }: TabListProps<T>) {
  return (
    <AriaTabList className={element('list')} style={style}>
      {children}
    </AriaTabList>
  );
}

export const Tab = ({ children, ...props }: AriaTabProps) => (
  <AriaTab {...props} className={element('item')}>
    <div className={element('item_content')}>{children as ReactNode}</div>
  </AriaTab>
);

export const TabPanel = ({ children, ...props }: AriaTabPanelProps) => (
  <AriaTabPanel {...props} className={element('panel')}>
    {children}
  </AriaTabPanel>
);

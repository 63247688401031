import { createBEMClasses } from '@/utils/classname';

import './NotificationBadge.css';

const { block } = createBEMClasses('notification-badge');

interface NotificationBadgeProps {
  count: number;
  isDisabled?: boolean;
}

const NotificationBadge = ({ count, isDisabled }: NotificationBadgeProps) => {
  return <div className={block({ disabled: isDisabled })}>{count > 99 ? '99+' : count}</div>;
};

export default NotificationBadge;

import { Trans } from '@lingui/react/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconItemAdd from '@/icons/ItemAdd.svg';

export const ArticleAddedToShipmentActivity = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityOfType<'article_added_to_shipment'>;
  displayArticleName: boolean;
}) => {
  return (
    <BaseActivity
      key={activity.id}
      createdAt={activity.createdAt}
      icon={<IconItemAdd />}
      creatorName={activity.creator?.name ?? ''}
      message={
        <BaseActivityText>
          {activity.creator ? (
            <Trans id="activities.article_added_to_shipment.message">
              added the item{' '}
              <BaseActivityArticleName
                activity={activity}
                displayArticleName={displayArticleName}
              />{' '}
              to the shipment
            </Trans>
          ) : (
            <Trans id="activities.article_added_to_shipment.message.no-creator">
              The item{' '}
              <BaseActivityArticleName
                activity={activity}
                displayArticleName={displayArticleName}
              />{' '}
              was added to the shipment
            </Trans>
          )}
        </BaseActivityText>
      }
    />
  );
};

import { msg, plural } from '@lingui/core/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import { BaseActivity } from '@/components/activities/BaseActivity/BaseActivity';
import IconCloth from '@/icons/Cloth.svg';

export const ClientPickupCompletedActivity = ({
  activity,
  nbOfArticles,
}: {
  activity: ActivityOfType<'client_pickup_completed'>;
  nbOfArticles: number;
}) => {
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="primary"
      icon={<IconCloth />}
      creatorName={msg({
        id: 'activities.client_pickup_completed.client',
        message: 'The client',
      })}
      message={plural(nbOfArticles ?? 0, {
        one: 'picked up the item',
        other: 'picked up the items',
      })}
    />
  );
};

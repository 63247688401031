import { Trans } from '@lingui/react/macro';

import AlertBar from '@/design_system/AlertBar';
import IconTruck from '@/icons/Truck.svg';
import { ShipmentWithRelations } from '@/models/shipment';
import { ShipmentMessageTitle } from '@/routes/Shipments/Shipment/components/ShipmentStep/components/ShipmentMessageTitle';
import { useCurrentSession } from '@/services/auth';

const InTransitMessage = ({
  shipment,
  hideIcon,
  className,
}: {
  shipment: ShipmentWithRelations;
  hideIcon?: boolean;
  className?: string;
}) => {
  if (shipment.carrierService === 'self-handled') {
    return (
      <InSelfHandledTransitMessage shipment={shipment} hideIcon={hideIcon} className={className} />
    );
  }

  return (
    <AlertBar
      type="info"
      size="large"
      icon={<IconTruck />}
      hideIcon={hideIcon}
      className={className}
      title={
        <ShipmentMessageTitle shipment={shipment}>
          <Trans id="requests.transit.in-transit-message.title">The delivery is in progress</Trans>
        </ShipmentMessageTitle>
      }
    >
      <p>
        <Trans id="requests.transit.in-transit-message.text">
          The carrier has taken charge of the package.
        </Trans>
      </p>
    </AlertBar>
  );
};

const InSelfHandledTransitMessage = ({
  shipment,
  hideIcon,
  className,
}: {
  shipment: ShipmentWithRelations;
  hideIcon?: boolean;
  className?: string;
}) => {
  const { currentSession } = useCurrentSession();

  const canActOnBehalfOfWorkshop = currentSession?.hasPermission(
    'act_on_behalf_of_non_digitalized_workshop',
    {
      storeId: shipment.originStoreId ?? shipment.destinationStoreId,
    }
  );

  return (
    <AlertBar
      type={canActOnBehalfOfWorkshop ? 'warning' : 'info'}
      size="large"
      icon={<IconTruck />}
      hideIcon={hideIcon}
      className={className}
      title={
        <ShipmentMessageTitle shipment={shipment}>
          {canActOnBehalfOfWorkshop ? (
            shipment.destinationStore ? (
              <Trans id="requests.transit.in-transit-message.self-handled.pick-up-at-workshop.title">
                Pick up the package
              </Trans>
            ) : (
              <Trans id="requests.transit.in-transit-message.self-handled.deliver-to-workshop.title">
                Deliver the package
              </Trans>
            )
          ) : (
            <Trans id="requests.transit.in-transit-message.self-handled.title">
              The delivery is in progress
            </Trans>
          )}
        </ShipmentMessageTitle>
      }
    >
      <p>
        {canActOnBehalfOfWorkshop ? (
          shipment.destinationStore ? (
            <Trans id="requests.transit.in-transit-message.self-handled.pick-up-at-workshop.text">
              Pick up the package at the workshop
            </Trans>
          ) : (
            <Trans id="requests.transit.in-transit-message.self-handled.deliver-to-workshop.text">
              Give the package to the workshop
            </Trans>
          )
        ) : (
          <Trans id="requests.transit.in-transit-message.self-handled.text">
            The store is taking charge of the package.
          </Trans>
        )}
      </p>
    </AlertBar>
  );
};

export default InTransitMessage;

import { Trans } from '@lingui/react/macro';

import { ArticleWithRelations } from '@/models/request';
import { useCurrentSession } from '@/services/auth';

import { BaseMessage } from './BaseMessage';

export const AnalyzeArticleTaskMessage = ({ article }: { article: ArticleWithRelations }) => {
  const { isWorkshop } = useCurrentSession();

  if (!isWorkshop) {
    return (
      <BaseMessage
        title={
          <Trans id="article.task.analyze-article.on-behalf.title">An expertise is required</Trans>
        }
        message={
          <Trans id="article.task.analyze-article.on-behalf.message">
            Please contact the workshop to know if they determine that the required item&apos;s
            actions correspond to its needs.
          </Trans>
        }
        article={article}
      />
    );
  }

  return (
    <BaseMessage
      title={<Trans id="article.task.analyze-article.title">Analyze the item</Trans>}
      message={
        <Trans id="article.task.analyze-article.message">
          Please analyse the item to determine if the required actions correspond to its needs.
        </Trans>
      }
      article={article}
    />
  );
};

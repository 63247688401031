import { useState } from 'react';
import { Trans } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import CloseConfirmation from '@/design_system/Dialog/CloseConfirmation';
import Stack from '@/design_system/Stack';
import { RequestWithRelations, useCompletePendingClientPickup } from '@/models/request';
import useViewPort from '@/utils/useViewport';

const CompleteDeliveryPendingClientAction = ({
  request,
  onPickupCompleted,
}: {
  request: RequestWithRelations;
  onPickupCompleted: () => void;
}) => {
  const { isMobile } = useViewPort();
  const [showClientPickupConfirmation, setShowClientPickupConfirmation] = useState(false);

  const {
    mutateAsync: completePendingClientPickup,
    isPending,
    isSuccess,
  } = useCompletePendingClientPickup();

  return (
    <Stack alignItems={isMobile ? 'stretch' : 'flex-end'}>
      <Button
        variant="primary"
        onPress={() => setShowClientPickupConfirmation(true)}
        isLoading={isPending || isSuccess}
      >
        <Trans id="request.delivery-pending-client.conclude">Client pickup done</Trans>
      </Button>
      <CloseConfirmation
        title={
          <Trans id="request.delivery-pending-client.confirmation-modal.title">
            Client pickup done
          </Trans>
        }
        content={
          <Trans id="request.delivery-pending-client.confirmation-modal.content">
            By clicking this button, you confirm that the client has picked up their item. This will
            archive this request. This cannot be undone.
          </Trans>
        }
        confirm={
          <Trans id="request.delivery-pending-client.confirmation-modal.confirm">Pickup done</Trans>
        }
        onConfirm={() => {
          completePendingClientPickup(request.id).then(() => {
            onPickupCompleted();
          });
        }}
        showDialog={showClientPickupConfirmation}
        setShowDialog={setShowClientPickupConfirmation}
        confirmButtonVariant="primary"
      />
    </Stack>
  );
};

export default CompleteDeliveryPendingClientAction;

import { useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import { Variant } from '@/design_system/Button/Button';
import { MenuItem } from '@/design_system/Menu/Menu';
import Stack from '@/design_system/Stack';
import IconCommentNumber from '@/icons/CommentNumber.svg';

export const GoToCommentsButton = ({
  onCommentButtonPress,
  nbOfComments,
  size,
  variant = 'secondary',
}: {
  onCommentButtonPress: () => void;
  nbOfComments: number;
  size?: 'small' | 'medium' | 'large';
  variant?: Variant;
}) => {
  const { t } = useLingui();

  return (
    <div style={{ position: 'relative' }}>
      <Button
        variant={variant}
        size={size ?? 'large'}
        iconOnly
        onPress={onCommentButtonPress}
        ariaLabel={t({ id: 'actions.go-to-comments', message: 'See comments' })}
        tooltip={t({ id: 'actions.go-to-comments', message: 'See comments' })}
      >
        <IconCommentNumber />
        <span
          style={{
            position: 'absolute',
            margin: 'auto',
            fontWeight: 700,
          }}
          className="paragraph-200-medium text-secondary"
        >
          {Math.min(nbOfComments, 99)}
        </span>
      </Button>
    </div>
  );
};

export const GoToCommentsMenuItem = ({ nbOfComments }: { nbOfComments: number }) => {
  const { t } = useLingui();
  const label = t({ id: 'actions.go-to-comments', message: 'See comments' });

  return (
    <MenuItem id="go-to-comments">
      <Stack row gap="0.5rem" alignItems="center" className="paragraph-100-regular">
        <div style={{ position: 'relative' }}>
          <IconCommentNumber style={{ fontSize: '1.25rem' }} />
          <div
            style={{
              position: 'absolute',
              margin: 'auto',
              fontWeight: 700,
              top: '2px',
              left: '2px',
              width: '16px',
              textAlign: 'center',
            }}
            className="paragraph-200-medium text-secondary"
          >
            {Math.min(nbOfComments, 99)}
          </div>
        </div>
        <span>{label}</span>
      </Stack>
    </MenuItem>
  );
};

import { Plural, Trans } from '@lingui/react/macro';

import { CardItem } from '@/components/Card/Card';
import IconBandage from '@/icons/Bandage.svg';
import { ArticleWithRelations } from '@/models/request';

export const ArticleDefectsCell = ({ article }: { article: ArticleWithRelations }) => {
  const numberOfDefects = article.numberOfDefects;
  return (
    <span className="paragraph-200-regular text-secondary">
      <Trans id="request.articles.table.column.defects">
        {numberOfDefects} <Plural value={numberOfDefects} one="defect" other="defects" />
      </Trans>
    </span>
  );
};

export const ArticleDefectsCardItem = ({ article }: { article: ArticleWithRelations }) => {
  const numberOfDefects = article.numberOfDefects;
  return (
    <CardItem>
      <IconBandage style={{ fontSize: '1rem' }} />
      <Trans id="request.articles.table.column.defects">
        {numberOfDefects} <Plural value={numberOfDefects} one="defect" other="defects" />
      </Trans>
    </CardItem>
  );
};

import RefashionLogo, { RefashionStatus } from '@/components/RefashionLogo';
import Stack from '@/design_system/Stack';
import { ActionType } from '@/models/actionType';
import { useDatabaseTranslation } from '@/models/translation';

const ActionName = ({
  actionType,
  refashionStatus,
  duplicateActionNumber,
  style,
}: {
  actionType: ActionType;
  refashionStatus?: RefashionStatus | null;
  duplicateActionNumber?: number;
  style?: React.CSSProperties;
}) => {
  const { _db } = useDatabaseTranslation();

  return (
    <Stack style={style}>
      <p className="paragraph-100-regular">
        {_db(actionType.name)}
        {!!duplicateActionNumber && ` #${duplicateActionNumber}`}
        {!!refashionStatus && (
          <>
            {' '}
            <RefashionLogo status={refashionStatus} />
          </>
        )}
      </p>
    </Stack>
  );
};

export default ActionName;

import { useLingui } from '@lingui/react/macro';

import {
  CustomDefectDropdownItem,
  DefectTypeDropdownItem,
} from '@/components/DefectsTable/DefectTypeDropdownItem';
import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import { useCreateDefect } from '@/models/article';
import { useDefectTypes } from '@/models/defectType';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';
import useDebouncedState from '@/utils/useDebouncedState';

export const DefectTypeSearchSelect = ({ onBlur }: { onBlur: () => void }) => {
  const { t } = useLingui();
  const { workflow } = useRequestContext();
  const { article, request } = useArticleContext();

  const allowCustomDefects = !!workflow?.config.allowCustomDefects;

  const [query, debouncedQuery, setQuery] = useDebouncedState<string>('', 500);

  const { data: defectTypes = [], isFetching } = useDefectTypes(
    {
      articleId: article.id,
      query: debouncedQuery ?? undefined,
    },
    {
      keepPreviousData: true,
    }
  );

  // isStickyOption let InputSelect know this option should stick to the bottom of the option list
  const customDefectOption = { id: 'custom', defectType: undefined, isStickyOption: true } as const;

  const defectTypeAndCustomOptions = allowCustomDefects
    ? [...defectTypes, customDefectOption]
    : defectTypes;

  const { mutate: createDefect } = useCreateDefect({
    requestId: request.id,
    articleId: article.id,
  });

  return (
    <InputSelect
      variant="add"
      aria-label={t({ id: 'defect-type-search-select.add-defect.label', message: 'Add defect' })}
      placeholder={t({
        id: 'defect-type-search-select.add-defect.placeholder',
        message: 'Search a defect to add...',
      })}
      isLoading={isFetching || query !== debouncedQuery}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
      menuIsOpen
      value={null} // Allows to automatically clear the input when selecting a value
      inputValue={query}
      onInputChange={setQuery}
      filterOption={null} // Avoid react-select to filter options only based on their label
      onBlur={onBlur}
      options={defectTypeAndCustomOptions}
      formatOptionLabel={(defect) => {
        if (defect?.id === 'custom') {
          return <CustomDefectDropdownItem />;
        } else if (defect?.defectType) {
          return <DefectTypeDropdownItem defect={defect} />;
        }

        return null;
      }}
      onChange={(defect) => {
        if (defect?.id === 'custom') {
          createDefect({
            description: '',
          });
          onBlur();
          setQuery('');
        } else if (defect?.defectType) {
          createDefect({
            defectTypeOrganization: defect,
          });
          onBlur();
          setQuery('');
        }
      }}
    />
  );
};

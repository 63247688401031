import { useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button/Button';
import { MenuItem } from '@/design_system/Menu/Menu';
import Stack from '@/design_system/Stack';
import IconReceipt from '@/icons/Receipt.svg';
import { RequestWithRelations, useRequestInvoice } from '@/models/request';

export const useDownloadInvoiceActions = ({
  request,
  enabled = true,
}: {
  request: RequestWithRelations;
  enabled?: boolean;
}) => {
  const hasBeenPaid = request.articles.some((article) => article.paidAt);

  const {
    data: invoice,
    isLoading,
    isError,
  } = useRequestInvoice(request.id, {
    enabled: enabled && hasBeenPaid,
  });

  return {
    invoice,
    isLoading,
    isError,
    downloadInvoice: () => {
      window.open(invoice?.url, '_blank');
    },
  };
};

export const DownloadInvoiceButton = ({ request }: { request: RequestWithRelations }) => {
  const { t } = useLingui();
  const { invoice, downloadInvoice, isLoading, isError } = useDownloadInvoiceActions({ request });

  const label =
    invoice?.type === 'proof-of-external-payment'
      ? t({
          id: 'requests.edit.actions.download-payment-proof',
          message: 'Download receipt / invoice',
        })
      : t({ id: 'requests.edit.actions.download-invoice', message: 'Download invoice' });

  return (
    <Button
      variant="secondary"
      size="large"
      iconOnly
      isLoading={isLoading}
      disabled={isLoading || isError}
      onPress={downloadInvoice}
      ariaLabel={label}
      tooltip={label}
    >
      <IconReceipt />
    </Button>
  );
};

export const DownloadInvoiceMenuItem = ({ invoice }: { invoice?: { type: string } }) => {
  const { t } = useLingui();

  const label =
    invoice?.type === 'proof-of-external-payment'
      ? t({
          id: 'requests.edit.actions.download-payment-proof',
          message: 'Download receipt / invoice',
        })
      : t({ id: 'requests.edit.actions.download-invoice', message: 'Download invoice' });

  return (
    <MenuItem id="download-invoice">
      <Stack row gap="0.5rem" alignItems="center">
        <IconReceipt style={{ fontSize: '1.25rem' }} />
        <span className="paragraph-100-regular">{label}</span>
      </Stack>
    </MenuItem>
  );
};

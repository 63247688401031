import { msg } from '@lingui/core/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import { BaseActivity } from '@/components/activities/BaseActivity/BaseActivity';
import IconStoreClient from '@/icons/StoreClient.svg';

export const RequestClaimedActivity = ({
  activity,
}: {
  activity: ActivityOfType<'request_claimed'>;
}) => {
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      icon={<IconStoreClient />}
      type="secondary"
      creatorName={activity.storeCreator.name}
      message={msg({
        id: 'activities.request_claimed.message',
        message: 'took the request in charge',
      })}
    />
  );
};

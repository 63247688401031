import { Trans } from '@lingui/react/macro';

import Stack from '@/design_system/Stack';
import { ArticleWithRelations } from '@/models/request';

import { BaseMessage } from './BaseMessage';

export const CreateShipmentTaskMessage = ({ article }: { article: ArticleWithRelations }) => {
  return (
    <BaseMessage
      article={article}
      title={<Trans id="article.task.create-shipment.title">Ship the package</Trans>}
      message={
        <Stack gap="0.5rem" alignItems="flex-start">
          <p>
            {article.toClient && (
              <Trans id="article.task.create-shipment.text.to-client">
                Create a new shipment to send the item to the client.
              </Trans>
            )}
            {!!article.toStoreId && (
              <Trans id="article.task.create-shipment.text.to-store">
                Create a new shipment to send the item to the store.
              </Trans>
            )}
            {!!article.toWorkshopId && !article.atWorkshopId && (
              <Trans id="article.task.create-shipment.text.to-workshop">
                Create a new shipment to send the item to the workshop.
              </Trans>
            )}
            {!!article.toWorkshopId && !!article.atWorkshopId && (
              <Trans id="article.task.create-shipment.text.workshop-to-workshop">
                Create a new shipment to send the item to another workshop.
              </Trans>
            )}
          </p>
        </Stack>
      }
    />
  );
};

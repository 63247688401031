import { Trans } from '@lingui/react/macro';

import Stack from '@/design_system/Stack';
import { BrandWrapper, BrandWrapperCenter } from '@/layouts/Brand';
import { ClientRequestWithRelations } from '@/models/request';
import EstimateDisclaimer from '@/routes/Brand/components/EstimateDisclaimer';
import ClientArticlesTable from '@/routes/Brand/Requests/Request/components/shared/ClientArticlesTable';
import ClientInfo from '@/routes/Brand/Requests/Request/components/shared/ClientInfo';
import IconSuccess from '@/routes/Brand/Requests/Request/components/shared/IconSuccess';
import { ErrorBoundary } from '@/services/sentry';

const Estimation = ({ request }: { request: ClientRequestWithRelations }) => {
  const showPrice = !!request.articles[0]?.steps?.find((step) => step.step === 'creation')?.config
    ?.requirePrice;

  return (
    <BrandWrapper>
      <ErrorBoundary>
        <BrandWrapperCenter>
          <Stack gap="1rem">
            <Stack gap="0.25rem">
              <Stack row gap="0.5rem" alignItems="center">
                <IconSuccess />
                <p className="headline-200-bold headline-300-bold-mobile color-primary-800">
                  <Trans id="client.request.estimation.title.web-client">
                    Your request has been sent
                  </Trans>
                </p>
              </Stack>
              <p className="paragraph-50-regular paragraph-100-regular-mobile">
                {!request.client && (
                  <Trans id="store.request.estimation.message.store">
                    The request has been successfully received by our team. We will update you on
                    the next steps of the process.
                  </Trans>
                )}
                {!!request.client && (
                  <Trans id="store.request.estimation.message.client">
                    The request has been successfully received by our team. We will update you and
                    the client on the next steps of the process.
                  </Trans>
                )}
              </p>
            </Stack>
            <Stack gap="1rem">
              <ClientArticlesTable
                request={request}
                mode="defect"
                hideStepper
                showPrice={showPrice}
              />
              {!request.isArchived && !!request.client && (
                <>
                  <EstimateDisclaimer />
                  <ClientInfo request={request} />
                </>
              )}
            </Stack>
          </Stack>
        </BrandWrapperCenter>
      </ErrorBoundary>
    </BrandWrapper>
  );
};

export default Estimation;

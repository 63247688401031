import { Link } from 'react-router';
import { Trans } from '@lingui/react/macro';

import Stack from '@/design_system/Stack';
import { ShipmentWithRelations } from '@/models/shipment';

export const ShipmentBreadcrumb = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  return (
    <Stack row gap="0.5rem" alignItems="center">
      <div className="paragraph-100-regular text-disabled">
        <Link to={`/shipments`} className="reset-link">
          <Trans id="request.breadcrumb.shipments">Shipments</Trans>
        </Link>
      </div>

      <div className="paragraph-100-regular text-disabled">&gt;</div>
      <div className="paragraph-100-regular">{shipment.reference}</div>
    </Stack>
  );
};

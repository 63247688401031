import { useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button/Button';
import { MenuItem } from '@/design_system/Menu/Menu';
import Stack from '@/design_system/Stack';
import { useShowToast } from '@/design_system/Toast';
import IconNotification from '@/icons/Notification.svg';
import { useSubscribeToRequest, useUnsubscribeFromRequest } from '@/models/request';

export const useToggleSubscription = ({
  request,
}: {
  request: { hasSubscribed?: boolean; id: string };
}) => {
  const { t } = useLingui();
  const showToast = useShowToast();

  const { mutateAsync: subscribeToRequest, isPending: isSubscribePending } = useSubscribeToRequest(
    request.id
  );
  const { mutateAsync: unsubscribeFromRequest, isPending: isUnsubscribePending } =
    useUnsubscribeFromRequest(request.id);

  const toggleSubscription = async () => {
    if (request.hasSubscribed) {
      await unsubscribeFromRequest();
      showToast({
        type: 'success',
        text: t({
          id: 'requests.edit.actions.unsubscribe_success',
          message: "You won't receive notifications from this request anymore",
        }),
      });
    } else {
      await subscribeToRequest();
      showToast({
        type: 'success',
        text: t({
          id: 'requests.edit.actions.subscribe_success',
          message: 'You will now receive notifications from this request',
        }),
      });
    }
  };

  return { toggleSubscription, isSubscribePending, isUnsubscribePending };
};

export const SubscriptionButton = ({
  request,
  onPress,
  disabled,
}: {
  request: { hasSubscribed?: boolean; id: string };
  onPress: () => void;
  disabled?: boolean;
}) => {
  const { t } = useLingui();

  const label = request.hasSubscribed
    ? t({ id: 'requests.edit.actions.unsubscribe', message: 'Unsubscribe from notifications' })
    : t({ id: 'requests.edit.actions.subscribe', message: 'Subscribe to notifications' });

  return (
    <Button
      variant="secondary"
      size="large"
      iconOnly
      ariaLabel={label}
      tooltip={label}
      onPress={onPress}
      disabled={disabled}
    >
      <IconNotification
        className={request.hasSubscribed ? 'color-primary-700' : ''}
        plain={request.hasSubscribed}
      />
    </Button>
  );
};

export const SubscriptionMenuItem = ({
  request,
}: {
  request: { hasSubscribed?: boolean; id: string };
}) => {
  const { t } = useLingui();

  const label = request.hasSubscribed
    ? t({ id: 'requests.edit.actions.unsubscribe', message: 'Unsubscribe from notifications' })
    : t({ id: 'requests.edit.actions.subscribe', message: 'Subscribe to notifications' });

  return (
    <MenuItem id="toggle-subscription">
      <Stack
        row
        gap="0.5rem"
        alignItems="center"
        className="paragraph-100-regular"
        style={{ minWidth: '100px' }}
      >
        <IconNotification
          className={request.hasSubscribed ? 'color-primary-700' : ''}
          plain={request.hasSubscribed}
          style={{ fontSize: '1.25rem' }}
        />
        <span>{label}</span>
      </Stack>
    </MenuItem>
  );
};

import { Trans } from '@lingui/react/macro';

import config from '@/config';
import AlertBar from '@/design_system/AlertBar';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconPackage from '@/icons/Package.svg';
import IconPrint from '@/icons/Print.svg';
import IconTracking from '@/icons/Tracking.svg';
import { ShipmentWithRelations } from '@/models/shipment';
import { ShipmentMessageTitle } from '@/routes/Shipments/Shipment/components/ShipmentStep/components/ShipmentMessageTitle';
import { TrackingIdModal } from '@/routes/Shipments/Shipment/components/TrackingIdModal/TrackingIdModal';
import { useCurrentSession } from '@/services/auth';
import { useBlobUrl } from '@/utils/useBlobUrl';

const FinalisationMessage = ({
  shipment,
  hideIcon,
  className,
}: {
  shipment: ShipmentWithRelations;
  hideIcon?: boolean;
  className?: string;
}) => {
  const { currentSession } = useCurrentSession();

  const canCreateShipment = shipment.canBeCreatedBy(currentSession);

  const shippingLabelUrl = useBlobUrl(shipment.shippingLabel, 'application/pdf');

  if (!canCreateShipment) {
    return (
      <AlertBar
        type="info"
        size="large"
        icon={<IconPackage />}
        hideIcon={hideIcon}
        className={className}
        title={
          <ShipmentMessageTitle shipment={shipment}>
            <Trans id="requests.transit.transit-pending-message.title.finalisation.wait">
              Waiting for package finalisation
            </Trans>
          </ShipmentMessageTitle>
        }
      >
        {!!shipment.originStoreId && (
          <Trans id="requests.transit.transit-pending-message.text.finalisation.wait">
            The store is finishing the package for delivery to the workshop.
          </Trans>
        )}
        {!!shipment.originWorkshopId && !!shipment.destinationStoreId && (
          <Trans id="requests.delivery.delivery-pending-message.text.finalisation.wait">
            The workshop is finishing the package for delivery to the store.
          </Trans>
        )}
        {!!shipment.originWorkshopId && !!shipment.destinationClientId && (
          <Trans id="requests.delivery.delivery-pending-message.to-client.text.finalisation.wait">
            The workshop is finishing the package for delivery to the client.
          </Trans>
        )}
        {!!shipment.originWorkshopId && !!shipment.destinationWorkshopId && (
          <Trans id="requests.delivery.delivery-pending-message.to-workshop.text.finalisation.wait">
            The workshop is finishing the package for delivery to another workshop.
          </Trans>
        )}
      </AlertBar>
    );
  }

  return (
    <AlertBar
      type="warning"
      size="large"
      icon={<IconPackage />}
      hideIcon={hideIcon}
      className={className}
      title={
        <ShipmentMessageTitle shipment={shipment}>
          <Trans id="shipment.finalisation-message.title">Finalize the package</Trans>
        </ShipmentMessageTitle>
      }
    >
      <Stack gap="0.5rem" alignItems="flex-start">
        <p>
          <Trans id="shipment.finalisation-message.text">
            Print the shipping label and finalize your package for the handover.
          </Trans>
        </p>

        <Stack row gap="8px">
          {shipment.carrier === 'private-carrier' && (
            <TrackingIdModal shipment={shipment}>
              <Button variant="secondary" size="medium">
                <IconTracking />
                {shipment.trackingId ? (
                  <Trans id="shipment.finalisation-message.action.edit-tracking-id">
                    Edit tracking number
                  </Trans>
                ) : (
                  <Trans id="shipment.finalisation-message.action.add-tracking-id">
                    Add tracking number
                  </Trans>
                )}
              </Button>
            </TrackingIdModal>
          )}
          <Button
            variant="secondary"
            size="medium"
            href={
              shippingLabelUrl ??
              `${config.apiUrl}/shipments/${shipment.id}/internal-shipping-label`
            }
            target={shippingLabelUrl ? undefined : '_blank'}
            download={`${shipment.reference}.pdf`}
          >
            <IconPrint />
            {shippingLabelUrl ? (
              <Trans id="shipment.finalisation-message.action">Print shipping label</Trans>
            ) : (
              <Trans id="shipment.finalisation-message.action.internal">
                Print internal shipping label
              </Trans>
            )}
          </Button>
        </Stack>
      </Stack>
    </AlertBar>
  );
};

export default FinalisationMessage;

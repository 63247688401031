import { useLingui } from '@lingui/react';

import Stack from '@/design_system/Stack';
import { Stepper, StepperProps } from '@/design_system/Stepper/Stepper';
import {
  Article,
  ARTICLE_DISPLAYED_STEPS,
  ARTICLE_TASK_TYPES,
  getDisplayedStep,
} from '@/models/article';
import useViewPort from '@/utils/useViewport';

export const ArticleStepper = ({
  article,
  mode,
  isClient,
  ...props
}: {
  article: Article;
  mode: 'task' | 'step';
  isClient?: boolean;
} & Omit<StepperProps, 'steps'>) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();

  if (!article.steps) {
    return '-';
  }

  const steps = article.steps
    .map((step) => {
      const displayedStep = getDisplayedStep(step);

      if (!displayedStep) {
        return null;
      }

      const name = _(ARTICLE_DISPLAYED_STEPS.find(({ id }) => id === displayedStep)!.name);
      let label;

      if (mode === 'task' && article.task && step.status === 'in-progress') {
        label = (
          <Stack row alignItems="center" gap="0.25rem" flexWrap="nowrap">
            <span>{_(ARTICLE_TASK_TYPES.find(({ id }) => id === article.task?.type)!.label)}</span>
          </Stack>
        );
      }

      return {
        ...step,
        name,
        label,
      };
    })
    .filter((step) => !!step);

  const labelVariant = mode === 'task' ? 'pending' : isMobile ? 'awaiting' : undefined;

  return <Stepper steps={steps} labelVariant={isClient ? undefined : labelVariant} {...props} />;
};

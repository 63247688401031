import { Trans, useLingui } from '@lingui/react/macro';

import IconFile from '@/icons/File.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import type { TNotificationOfType } from '@/models/notification';

export const PendingServiceChoiceNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'pending_service_choice'>;
}) => {
  const { t } = useLingui();

  return (
    <BaseNotification
      icon={<IconFile />}
      type="new"
      notification={notification}
      notificationLabel={t({
        id: 'notification.pending-service-choice.label',
        message: `Pending service selection for request ${notification.request.reference}`,
      })}
      notificationHeader={
        <Trans id="notification.pending-service-choice">
          <span className="paragraph-100-medium">You</span> have a new request awaiting service
          selection
        </Trans>
      }
    />
  );
};

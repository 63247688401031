import { useNavigate } from 'react-router';
import { useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button/Button';
import { MenuItem } from '@/design_system/Menu/Menu';
import Stack from '@/design_system/Stack';
import IconEdit from '@/icons/Edit.svg';

export const useEditShipment = ({ shipment }: { shipment: { id: string } }) => {
  const navigate = useNavigate();

  return {
    editShipment: () => {
      navigate('/shipments/' + shipment.id + '/edit');
    },
  };
};

export const EditShipmentButton = ({ onPress }: { onPress: () => void }) => {
  const { t } = useLingui();
  const label = t({ id: 'shipment.edit', message: 'Edit shipment' });

  return (
    <Button
      variant="secondary"
      size="large"
      iconOnly
      ariaLabel={label}
      tooltip={label}
      onPress={onPress}
    >
      <IconEdit />
    </Button>
  );
};

export const EditShipmentMenuItem = () => {
  const { t } = useLingui();
  const label = t({ id: 'shipment.edit', message: 'Edit shipment' });

  return (
    <MenuItem id="edit-shipment" size="large">
      <Stack
        row
        gap="0.5rem"
        alignItems="center"
        className="paragraph-100-regular"
        style={{ minWidth: '100px' }}
      >
        <IconEdit style={{ fontSize: '1.25rem' }} />
        <span>{label}</span>
      </Stack>
    </MenuItem>
  );
};

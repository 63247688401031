import { useState } from 'react';
import { Plural, Trans, useLingui } from '@lingui/react/macro';
import { QRCodeSVG } from 'qrcode.react';

import { Endpoints } from '@/api';
import { Choices } from '@/components/ArticlesTableCells/ArticleChoiceCell';
import config from '@/config';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconArrow from '@/icons/Arrow.svg';
import IconPackage from '@/icons/Package.svg';
import IconPlace from '@/icons/Place.svg';
import { BrandWrapper, BrandWrapperCenter, BrandWrapperFooter } from '@/layouts/Brand';
import { useArticleName } from '@/models/article';
import {
  ClientRequestWithRelations,
  useUpdateRequestClient,
  useValidationChoice,
} from '@/models/request';
import { useWorkflow } from '@/models/workflow';
import ClientArticlesTable from '@/routes/Brand/Requests/Request/components/shared/ClientArticlesTable';
import ClientInfo from '@/routes/Brand/Requests/Request/components/shared/ClientInfo';
import IconSuccess from '@/routes/Brand/Requests/Request/components/shared/IconSuccess';
import { PickupPointInfo } from '@/routes/Brand/Requests/Request/components/shared/PickupPointInfo/PickupPointInfo';
import { useClientToken, useCurrentOrganization } from '@/services/auth';
import { createBEMClasses } from '@/utils/classname';
import { formatCurrency } from '@/utils/number';

import './Validation.css';

const { block, element } = createBEMClasses('client-request-validation');

const Validation = ({ request }: { request: ClientRequestWithRelations }) => {
  const {
    mutateAsync: sendValidationChoice,
    isPending: isPendingSendValidationChoice,
    isSuccess: isSuccessSendValidationChoice,
  } = useValidationChoice();

  const [choices, setChoices] = useState<Choices>(
    request.articles
      .filter((article) => article.step?.step === 'validation' && !article.cancelledAt)
      .reduce(
        (acc, article) => ({
          ...acc,
          [article.id]: {
            value: null,
            reason: null,
            otherReason: null,
          },
        }),
        {}
      )
  );
  const [showChoicesError, setShowChoicesError] = useState(false);
  const choiceMissing = Object.values(choices).some((choice) => !choice.value);
  const reasonMissing = Object.values(choices).some(
    (choice) =>
      choice.value === 'refused' &&
      (!choice.reason || (choice.reason === 'other' && !choice.otherReason))
  );
  const shouldShowChoices = !request.articles.every(
    (article) => article.quoteAcceptedAt || article.quoteRefusedAt
  );

  const [error, setError] = useState<string | null>(null);

  const atLeastOneQuoteAcceptedArticle = request.articles.some(
    (article) => !!article.quoteAcceptedAt
  );
  const showShippingOptions =
    atLeastOneQuoteAcceptedArticle &&
    !!request.client &&
    request.client.shippingChoice === null &&
    !request.store;

  function onSubmit() {
    if (choiceMissing || reasonMissing) {
      setShowChoicesError(true);
      return;
    }

    setError(null);

    sendValidationChoice({
      id: request.id,
      choices: choices as Endpoints['POST /requests/:id/validation-choice']['body']['choices'],
    }).catch((error) => {
      setError(error.message as string);
    });
  }

  return (
    <BrandWrapper className={block()}>
      <BrandWrapperCenter>
        <Stack gap="1rem">
          {!showShippingOptions && <ValidationHeader request={request} />}
          {showShippingOptions && <ShippingOrDropOffHeader request={request} />}
          <PickupPointInfo request={request} />
          <ClientArticlesTable
            request={request}
            hideStepper
            showPrice
            showArticleComment
            choices={shouldShowChoices ? choices : undefined}
            setChoices={shouldShowChoices ? setChoices : undefined}
            showChoicesError={shouldShowChoices ? showChoicesError : undefined}
          />
          <ClientInfo request={request} />
        </Stack>
      </BrandWrapperCenter>
      {!showShippingOptions && (
        <BrandWrapperFooter>
          <Stack gap="0.5rem" style={{ flex: 1 }}>
            {showChoicesError && (choiceMissing || reasonMissing || error) && (
              <p className="paragraph-100-medium text-center text-danger">
                {choiceMissing ? (
                  <Trans id="client.request.validation.error.choice-missing">
                    Please accept or refuse the proposal for each item
                  </Trans>
                ) : reasonMissing ? (
                  <Trans id="client.request.validation.error.reason-missing">
                    Please select a reason for each refusal
                  </Trans>
                ) : (
                  error
                )}
              </p>
            )}
            <Button
              variant="brand"
              size="large"
              onPress={onSubmit}
              isLoading={isPendingSendValidationChoice || isSuccessSendValidationChoice}
              disabled={isPendingSendValidationChoice || isSuccessSendValidationChoice}
            >
              <Trans id="client.request.validation.continue">Confirm & continue</Trans>
              <IconArrow right />
            </Button>
          </Stack>
        </BrandWrapperFooter>
      )}
    </BrandWrapper>
  );
};

const ValidationHeader = ({ request }: { request: ClientRequestWithRelations }) => {
  const { t } = useLingui();
  const firstArticleName = useArticleName({ article: request.articles[0], type: 'short' });

  return (
    <Stack gap="0.5rem" style={{ marginBottom: '1rem' }} alignItems="center">
      <h1 className="headline-200-bold headline-300-bold-mobile text-center">
        <Trans id="client.request.validation.entry.title">Care & Repair service estimate</Trans>
      </h1>
      <p className="paragraph-50-regular paragraph-100-regular-mobile text-center">
        <Trans id="client.request.validation.entry.description">
          Our experts have an estimate for{' '}
          <Plural
            value={request.articles.length}
            one={
              <span>
                your{' '}
                <span className="paragraph-50-medium paragraph-100-medium-mobile">
                  {firstArticleName}
                </span>
              </span>
            }
            other={
              <span>
                your{' '}
                <span className="paragraph-50-medium paragraph-100-medium-mobile"># items</span>
              </span>
            }
          />
        </Trans>
        :
      </p>
      <Stack row gap="0.5rem" alignItems="center">
        <p
          className="headline-100-bold headline-200-bold-mobile text-center"
          aria-label={t({ id: 'client.request.validation.entry.price', message: 'Estimate price' })}
        >
          {formatCurrency(
            request.cost?.amountPerCurrency[0]?.amount,
            request.cost?.amountPerCurrency[0]?.currency
          )}
        </p>
      </Stack>
    </Stack>
  );
};

const ShippingOrDropOffHeader = ({ request }: { request: ClientRequestWithRelations }) => {
  const clientToken = useClientToken();
  const [organization] = useCurrentOrganization();
  const { data: workflow } = useWorkflow(request.workflowId);
  const shippingOptions = workflow?.config.shippingOptions ?? [];
  const allowDropOff = shippingOptions.includes('store') ?? false;
  const allowSelfShipment = shippingOptions.includes('home') ?? false;

  const { mutateAsync: updateRequestClient, isPending: isPendingRequestClient } =
    useUpdateRequestClient();

  return (
    <Stack gap="1rem">
      <Stack row gap="0.5rem" alignItems="center" flexWrap="nowrap">
        <div>
          <IconSuccess />
        </div>
        <p className="headline-200-bold headline-300-bold-mobile color-primary-800">
          <Trans id="client.request.validation.pending.title">
            Your care & repair service is confirmed
          </Trans>
        </p>
      </Stack>
      <Stack row gap="0.25rem">
        <p className="paragraph-50-regular paragraph-100-regular-mobile">
          <Trans id="client.request.validation.pending.shipment-options.title">
            It&apos;s time to ship us your{' '}
            <Plural value={request.articles.length} one="item" other="items" />!
          </Trans>
          {shippingOptions.length > 1 && (
            <>
              {' '}
              <Trans id="client.request.validation.pending.shipment-options.title2">
                You have 2 options:
              </Trans>
            </>
          )}
        </p>
      </Stack>
      <div className={element('shipment-options')}>
        {allowDropOff && (
          <Stack gap="1rem">
            <Stack row gap="1rem" flexWrap="nowrap" alignItems="center">
              <QRCodeSVG
                className={element('shipment-options__qr-code')}
                size={92}
                value={`${config.appUrl}/requests/claim?id=${request.id}&clientToken=${clientToken}`}
              />
              <Stack>
                <div className="paragraph-50-medium paragraph-100-medium-mobile">
                  <Trans id="client.request.validation.pending.shipment-option.store.title">
                    <Plural value={shippingOptions.length} one="" other="Option 1 - " /> Bring it to
                    a store
                  </Trans>
                </div>
                <div className="paragraph-50-regular paragraph-100-regular-mobile">
                  <Trans id="client.request.validation.pending.shipment-option.store.label">
                    Go to the nearest {organization?.name} store with this QR code to arrange
                    shipment.
                  </Trans>
                </div>
              </Stack>
            </Stack>
            <Button
              variant="brand"
              size="large"
              href={organization?.config.storesUrl}
              target="_blank"
            >
              <IconPlace start />
              <Trans id="client.request.validation.pending.shipment-option.store.action">
                Find a store
              </Trans>
            </Button>
          </Stack>
        )}
        {shippingOptions.length > 1 && (
          <span className="paragraph-50-medium">
            <Trans id="_general.or">or</Trans>
          </span>
        )}
        {allowSelfShipment && (
          <Stack gap="1rem">
            <Stack row gap="1rem" flexWrap="nowrap" alignItems="center">
              <img src="/shipment-label.png" alt="" />
              <Stack>
                <div className="paragraph-50-medium paragraph-100-medium-mobile">
                  <Trans id="client.request.validation.pending.shipment-option.client.title">
                    <Plural value={shippingOptions.length} one="" other="Option 2 - " /> Send it
                    yourself
                  </Trans>
                </div>
                <div className="paragraph-50-regular paragraph-100-regular-mobile">
                  <Trans id="client.request.validation.pending.shipment-option.client.label">
                    Print the shipment label and stick it on the package, visible and affixed to a
                    flat surface.
                  </Trans>
                </div>
              </Stack>
            </Stack>
            <Button
              variant="brand"
              size="large"
              disabled={isPendingRequestClient}
              onPress={() =>
                updateRequestClient({
                  id: request.id,
                  body: {
                    client: {
                      shippingChoice: 'home',
                    },
                  },
                })
              }
            >
              <IconPackage />
              <Trans id="client.request.validation.pending.shipment-option.client.action">
                Send it myself
              </Trans>
            </Button>
          </Stack>
        )}
      </div>
    </Stack>
  );
};

export default Validation;

import { Trans, useLingui } from '@lingui/react/macro';

import { TableQueryWrapper } from '@/components/TableQueryWrapper';
import InputDateRange, { InputDateRangeValue } from '@/design_system/InputDate/InputDateRange';
import { PaginationFooter } from '@/design_system/Pagination/Pagination';
import Stack from '@/design_system/Stack';
import { Body, Cell, Column, Header, Row, Table } from '@/design_system/Table/Table';
import IconBuilding from '@/icons/Building.svg';
import IconPerson from '@/icons/User.svg';
import { TransactionWithRelations, useTransactions } from '@/models/transaction';
import { useCurrentSession } from '@/services/auth';
import { ErrorBoundary } from '@/services/sentry';
import { formatDate } from '@/utils/date';
import { formatCurrency } from '@/utils/number';

import {
  TransactionDestination,
  TransactionDestinationSelect,
} from './components/filters/TransactionDestinationSelect';
import {
  TransactionOrigin,
  TransactionOriginSelect,
} from './components/filters/TransactionOriginSelect';
import { TransactionSearch } from './components/filters/TransactionSearch';
import { TransactionsEmptyState } from './components/TransactionsEmptyState';

export const TRANSACTIONS_PER_PAGE = 20;

// TODO: mobile
export const Transactions = ({
  page,
  setPage,
  search,
  debouncedSearch,
  debouncedSetSearch,
  transactionOrigin,
  setTransactionOrigin,
  transactionDestination,
  setTransactionDestination,
  dateRange,
  setDateRange,
}: {
  page: number;
  setPage: (page: number) => void;
  search: string;
  debouncedSearch: string;
  debouncedSetSearch: (value: string) => void;
  transactionOrigin: TransactionOrigin;
  setTransactionOrigin: (transactionOrigin: TransactionOrigin) => void;
  transactionDestination: TransactionDestination;
  setTransactionDestination: (transactionDestination: TransactionDestination) => void;
  dateRange: InputDateRangeValue;
  setDateRange: (dateRange: InputDateRangeValue) => void;
}) => {
  const { t } = useLingui();

  const {
    data: { transactions, meta } = { transactions: [] },
    isLoading,
    isError,
  } = useTransactions({
    limit: TRANSACTIONS_PER_PAGE,
    offset: (page - 1) * TRANSACTIONS_PER_PAGE,
    search: debouncedSearch || undefined,
    sourceClient: transactionOrigin.type === 'client',
    sourceOrganizationCountry:
      transactionOrigin.type === 'organization-country' ? transactionOrigin.id : undefined,
    destinationOrganizationCountry:
      transactionDestination.type === 'organization-country'
        ? transactionDestination.id
        : undefined,
    destinationWorkshop:
      transactionDestination.type === 'workshop' ? transactionDestination.id : undefined,
    startedAtFrom: dateRange.start,
    startedAtTo: dateRange.end,
  });

  const columnWidths = {
    origin: '1fr',
    destination: '1fr',
    operationDate: '1fr',
    method: '1fr',
    amount: '1fr',
  };

  return (
    <Stack gap="1rem" style={{ height: '100%' }}>
      <Stack row gap="1rem">
        <ErrorBoundary>
          <TransactionSearch search={search} debouncedSetSearch={debouncedSetSearch} />
          <TransactionOriginSelect
            setPage={setPage}
            transactionOrigin={transactionOrigin}
            setTransactionOrigin={setTransactionOrigin}
          />
          <TransactionDestinationSelect
            setPage={setPage}
            transactionDestination={transactionDestination}
            setTransactionDestination={setTransactionDestination}
          />
          <InputDateRange
            value={dateRange}
            onChange={(newRange) => {
              setDateRange(newRange);
              setPage(1);
            }}
            style={{ flex: 1 }}
          />
        </ErrorBoundary>
      </Stack>

      <TableQueryWrapper isLoading={isLoading} isError={isError}>
        {!transactions.length ? (
          <TransactionsEmptyState />
        ) : (
          <Table
            ariaLabel={t({
              id: 'accounting.transactions.table.label',
              message: 'Transactions list',
            })}
            columnWidths={[
              columnWidths.origin,
              columnWidths.destination,
              columnWidths.operationDate,
              columnWidths.method,
              columnWidths.amount,
            ]}
          >
            <Header>
              <Row>
                <Column>
                  <Trans id="accounting.transactions.table.column.origin.label">Origin</Trans>
                </Column>

                <Column>
                  <Trans id="accounting.transactions.table.column.destination.label">
                    Destination
                  </Trans>
                </Column>

                <Column>
                  <Trans id="accounting.transactions.table.column.operation-date.label">
                    Operation Date
                  </Trans>
                </Column>

                <Column>
                  <Trans id="accounting.transactions.table.column.method.label">Method</Trans>
                </Column>

                <Column justifyContent="end">
                  <Trans id="accounting.transactions.table.column.amount.label">Amount</Trans>
                </Column>
              </Row>
            </Header>

            <Body>
              {transactions.map((transaction) => (
                <TransactionRow key={transaction.id} transaction={transaction} />
              ))}
            </Body>

            <PaginationFooter
              page={page}
              itemsPerPage={TRANSACTIONS_PER_PAGE}
              count={meta?.count}
              onPageChange={setPage}
            />
          </Table>
        )}
      </TableQueryWrapper>
    </Stack>
  );
};

const TransactionRow = ({ transaction }: { transaction: TransactionWithRelations }) => {
  return (
    <Row>
      <OriginCell transaction={transaction} />
      <DestinationCell transaction={transaction} />
      <OperationDateCell transaction={transaction} />
      <MethodCell />
      <AmountCell transaction={transaction} />
    </Row>
  );
};

const OriginCell = ({ transaction }: { transaction: TransactionWithRelations }) => {
  const origin =
    transaction.type === 'client_payment_in'
      ? transaction.sourceClient?.name
      : transaction.type === 'workshop_payment_out'
        ? transaction.onBehalfOfOrganizationCountry?.name
        : undefined;

  return (
    <Cell>
      <Stack
        gap="0.5rem"
        row
        justifyContent="center"
        flexWrap="nowrap"
        className="paragraph-100-regular"
      >
        {transaction.type === 'client_payment_in' && <IconPerson style={{ fontSize: '1.25rem' }} />}
        {transaction.type === 'workshop_payment_out' && (
          <IconBuilding style={{ fontSize: '1.25rem' }} />
        )}
        {origin ?? '-'}
      </Stack>
    </Cell>
  );
};

const DestinationCell = ({ transaction }: { transaction: TransactionWithRelations }) => {
  const destination =
    transaction.type === 'client_payment_in'
      ? transaction.onBehalfOfOrganizationCountry?.name
      : transaction.type === 'workshop_payment_out'
        ? transaction.destinationWorkshop?.name
        : undefined;

  return <Cell>{destination ?? '-'}</Cell>;
};

const OperationDateCell = ({ transaction }: { transaction: TransactionWithRelations }) => {
  const operationDate = transaction.startedAt
    ? formatDate(new Date(transaction.startedAt), { dateStyle: 'medium' })
    : undefined;

  return <Cell>{operationDate ?? '-'}</Cell>;
};

const MethodCell = () => {
  return (
    <Cell>
      <Trans id="accounting.transactions.table.column.method.via-prolong-stripe">
        Via Prolong (Stripe)
      </Trans>
    </Cell>
  );
};

const AmountCell = ({ transaction }: { transaction: TransactionWithRelations }) => {
  const { isWorkshop } = useCurrentSession();

  const showPlus =
    (isWorkshop && transaction.type === 'workshop_payment_out') ||
    (!isWorkshop && transaction.type === 'client_payment_in');

  return (
    <Cell justifyContent="end">
      <div className={showPlus ? 'color-primary-800' : undefined}>
        {showPlus ? '+' : '-'} {formatCurrency(transaction.amount, transaction.currency)}
      </div>
    </Cell>
  );
};

import { useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import { useShowToast } from '@/design_system/Toast';
import IconSend from '@/icons/Send.svg';
import { useResendInvitation } from '@/models/userRole';

export const ResendInvitation = ({ email }: { email: string }) => {
  const { t } = useLingui();
  const showToast = useShowToast();

  const { mutateAsync: resendInvitation } = useResendInvitation();

  return (
    <Button
      variant="secondary"
      iconOnly
      size="small"
      ariaLabel={t({
        id: 'settings.users.resend-invitation.trigger',
        message: `Resend invitation to ${email}`,
      })}
      tooltip={
        <p className="sentry-mask">
          {t({
            id: 'settings.users.resend-invitation.trigger',
            message: `Resend invitation to ${email}`,
          })}
        </p>
      }
      onPress={() => {
        resendInvitation({ email })
          .then(() => {
            showToast({
              text: t({
                id: 'settings.users.invitation.success',
                message: `Invitation sent to ${email}`,
              }),
              type: 'success',
            });
          })
          .catch((err) =>
            showToast({
              text: err.message,
              type: 'error',
            })
          );
      }}
    >
      <IconSend />
    </Button>
  );
};

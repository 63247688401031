import { useState } from 'react';
import { MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/core/macro';
import { useLingui } from '@lingui/react/macro';

import Box from '@/design_system/Box';
import InputSearch from '@/design_system/InputSearch';
import Stack from '@/design_system/Stack';
import { AddressWithRelations } from '@/models/address';
import { useShipmentArticles } from '@/models/article';
import { ArticleWithRelations } from '@/models/shipment';
import { newAddressWithRelations } from '@/routes/Shipments/Shipment/components/ShipmentForm/components/PlaceSelect';
import { createBEMClasses } from '@/utils/classname';
import useDebouncedState from '@/utils/useDebouncedState';

import { AddedArticlesSection } from './components/AddedArticlesSection';
import { ShippableArticlesSection } from './components/ShippableArticlesSection';

import './ArticleSelect.css';

const { block, element } = createBEMClasses('article-select');

export const ArticleSelect = ({
  label,
  ariaLabel,
  addedArticles,
  setAddedArticles,
  origin,
  setOrigin,
  destination,
  setDestination,
}: {
  label: React.ReactNode;
  ariaLabel: string;
  addedArticles: ArticleWithRelations[];
  setAddedArticles: (articles: ArticleWithRelations[]) => void;
  origin?: AddressWithRelations;
  setOrigin: (address?: AddressWithRelations) => void;
  destination?: AddressWithRelations;
  setDestination: (address?: AddressWithRelations) => void;
}) => {
  const { t } = useLingui();

  const [query, debouncedQuery, setQuery] = useDebouncedState<string>('', 500);
  const [originalArticles] = useState<ArticleWithRelations[]>(addedArticles);

  const [removedArticles, setRemovedArticles] = useState<ArticleWithRelations[]>([]);

  const {
    data: { articles: fetchedArticles } = { articles: [] },
    isPending,
    isFetching,
  } = useShipmentArticles(
    {
      limit: 100,
      search: debouncedQuery,
      originClient: origin?.client?.id,
      originStore: origin?.store?.id,
      originWorkshop: origin?.workshop?.id,
      destinationClient: destination?.client?.id,
      destinationStore: destination?.store?.id,
      destinationWorkshop: destination?.workshop?.id,
    },
    {
      keepPreviousData: true,
    }
  );

  const unaddedArticles = [...fetchedArticles, ...removedArticles]
    // Remove duplicates, article both in fetched and removed articles
    .filter((article, index, self) => self.findIndex((a) => a.id === article.id) === index)
    // Remove articles already added
    .filter((article) => !addedArticles.find((addedArticle) => addedArticle.id === article.id));

  const onAddArticle = (key: string) => {
    const article = unaddedArticles.find((article) => article.id === key);

    if (article) {
      setRemovedArticles(
        removedArticles.filter((removedArticle) => removedArticle.id !== article.id)
      );
      setAddedArticles([...addedArticles, article]);

      const clientAddress = newAddressWithRelations(
        article.request.client?.address,
        article.request.client,
        null,
        null
      );

      const storeAddress = newAddressWithRelations(
        article.request.store?.address,
        null,
        article.request.store,
        null
      );

      const workshopAddress = newAddressWithRelations(
        article.workshop?.address,
        null,
        null,
        article.workshop
      );

      if (!origin) {
        if (article.atClient) {
          setOrigin(clientAddress);
        } else if (article.atStoreId) {
          setOrigin(storeAddress);
        } else if (article.atWorkshopId) {
          setOrigin(workshopAddress);
        }
      }

      if (!destination) {
        if (article.toClient) {
          setDestination(clientAddress);
        } else if (article.toStoreId) {
          setDestination(storeAddress);
        } else if (article.toWorkshopId) {
          setDestination(workshopAddress);
        }
      }
    }
  };

  return (
    <Stack className={block()} gap="8px">
      {label}
      <InputSearch
        style={{ flex: 1 }}
        ariaLabel={ariaLabel}
        placeholder={t({
          id: 'shipments.new.article.placeholder',
          message: 'Search by item name or request reference...',
        })}
        value={query}
        onChange={setQuery}
        isLoading={isPending || isFetching}
      />

      <Box className={element('articles')} padding="0">
        <AddedArticlesSection
          addedArticles={addedArticles}
          unaddedArticles={unaddedArticles}
          setAddedArticles={setAddedArticles}
          setRemovedArticles={(article) => {
            if (originalArticles.find((a) => a.id === article.id)) {
              setRemovedArticles([...removedArticles, article]);
            }
          }}
        />

        <ShippableArticlesSection
          unaddedArticles={unaddedArticles}
          noItemsMessage={noItemsMessage({
            query,
            fetchedArticles,
            unaddedArticles,
            addedArticles,
          })}
          onAddArticle={onAddArticle}
          isFetching={isFetching}
        />
      </Box>
    </Stack>
  );
};

const noItemsMessage = ({
  query,
  fetchedArticles,
  unaddedArticles,
  addedArticles,
}: {
  query: string;
  fetchedArticles: ArticleWithRelations[];
  unaddedArticles: ArticleWithRelations[];
  addedArticles: ArticleWithRelations[];
}): MessageDescriptor | null => {
  if (Boolean(query) && !fetchedArticles.length) {
    return msg({
      id: 'shipments.new.articles.shippable.search-no-results',
      message: 'No items match your search. Please try with different keywords.',
    });
  }

  if (!unaddedArticles.length) {
    if (addedArticles.length) {
      return msg({
        id: 'shipments.new.articles.shippable.empty.1',
        message: 'No other shippable items match this destination.',
      });
    } else {
      return msg({
        id: 'shipments.new.articles.shippable.empty.2',
        message: 'No shippable items match this destination.',
      });
    }
  }

  return null;
};

import { useLingui } from '@lingui/react/macro';

import Dialog from '@/design_system/Dialog';
import Stack from '@/design_system/Stack';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { ArticlePhotoUpload } from '@/routes/Requests/New/components/ArticlePhotoUpload';
import { useCurrentSession } from '@/services/auth';
import { ErrorBoundary } from '@/services/sentry';

import { CustomsFields } from './components/CustomsFields';
import {
  ProductCategoryL1,
  ProductCategoryL2,
  ProductCategoryL3,
} from './components/ProductCategories';
import ColorSize from './ColorSize';
import { Brand } from './components';
import ProductInfo from './ProductInfo';

export const ArticleInformationFormModal = () => {
  const { t } = useLingui();
  const { currentSession } = useCurrentSession();
  const { request, article, state } = useArticleContext();

  return (
    <Dialog
      isOpen={state.infoPanel.isOpen}
      onOpenChange={() => state.infoPanel.setIsOpen(false)}
      title={t({ id: 'article.edit.title', message: 'Edit information' })}
    >
      <main>
        <ErrorBoundary>
          <Stack gap="1rem">
            <ArticlePhotoUpload />

            {!currentSession?.workshop?.external && <ProductInfo row={false} showReference />}

            <ProductCategoryL1 />

            <ProductCategoryL2 />

            {!!request.organization.brands.length && <Brand />}

            <ProductCategoryL3 />

            <ColorSize request={request} article={article} />

            <CustomsFields request={request} article={article} />
          </Stack>
        </ErrorBoundary>
      </main>
    </Dialog>
  );
};

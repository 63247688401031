import { Trans, useLingui } from '@lingui/react/macro';

import IconAnalysis from '@/icons/Analysis.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import type { TNotificationOfType } from '@/models/notification';

export const PendingAnalysisNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'pending_analysis'>;
}) => {
  const { t } = useLingui();

  return (
    <BaseNotification
      notificationLabel={
        notification.data?.onBehalfOfWorkshop
          ? t({
              id: 'notification.pending-analysis.label.on-behalf-workshop',
              message: `New workshop analysis to validate on behalf of workshop ${notification.data?.onBehalfOfWorkshop?.name} for request ${notification.request.reference}`,
            })
          : t({
              id: 'notification.pending-analysis.label',
              message: `New analysis to provide for request ${notification.request.reference}`,
            })
      }
      icon={<IconAnalysis />}
      type="new"
      notification={notification}
      notificationHeader={
        notification.data?.onBehalfOfWorkshop ? (
          <Trans id="notification.pending-analysis.on-behalf-workshop">
            <span className="paragraph-100-medium">You</span> have a new analysis to validate on
            behalf of workshop{' '}
            <span className="paragraph-100-medium">
              {notification.data?.onBehalfOfWorkshop?.name}
            </span>
          </Trans>
        ) : (
          <Trans id="notification.pending-analysis">
            <span className="paragraph-100-medium">You</span> have a new analysis to provide
          </Trans>
        )
      }
    />
  );
};

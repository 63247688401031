import { Trans, useLingui } from '@lingui/react/macro';

import IconTools from '@/icons/Tools.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import type { TNotificationOfType } from '@/models/notification';

export const PendingRepairNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'pending_repair'>;
}) => {
  const { t } = useLingui();

  const requestReference = notification.request.reference;
  const workshopName = notification.data.onBehalfOfWorkshop?.name;

  return (
    <BaseNotification
      notificationLabel={
        notification.data.onBehalfOfWorkshop
          ? t({
              id: 'notification.pending-repair.label.on-behalf',
              message: `Pending repair for request ${requestReference} on behalf of ${workshopName}`,
            })
          : t({
              id: 'notification.pending-repair.label',
              message: `Pending repair for request ${requestReference}`,
            })
      }
      icon={<IconTools />}
      type="new"
      notification={notification}
      notificationHeader={
        notification.data.onBehalfOfWorkshop ? (
          <Trans id="notification.pending-repair.on-behalf">
            <span className="paragraph-100-medium">You</span> may notify the workshop{' '}
            <span className="paragraph-100-medium">{workshopName}</span> that the repair can now
            begin.
          </Trans>
        ) : (
          <Trans id="notification.pending-repair">
            <span className="paragraph-100-medium">You</span> have a new repair to do
          </Trans>
        )
      }
    />
  );
};

import { Trans } from '@lingui/react/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconAnalysisValidation from '@/icons/AnalysisValidation.svg';

export const ArticleAnalysisCompletedActivity = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityOfType<'article_analysis_completed'>;
  displayArticleName: boolean;
}) => {
  const workshopName = activity.workshop.name;

  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="neutral"
      icon={<IconAnalysisValidation />}
      creatorName={activity.creator?.name ?? ''}
      message={
        <>
          <BaseActivityText>
            {activity.data?.onBehalfOfWorkshop ? (
              <Trans id="activities.article_analysis_completed.message.on-behalf">
                completed the analysis of the item{' '}
                <BaseActivityArticleName
                  activity={activity}
                  displayArticleName={displayArticleName}
                />{' '}
                on behalf of <span className="paragraph-100-medium">{workshopName}</span>
              </Trans>
            ) : (
              <Trans id="activities.article_analysis_completed.message">
                completed the analysis of the item{' '}
                <BaseActivityArticleName
                  activity={activity}
                  displayArticleName={displayArticleName}
                />
              </Trans>
            )}
          </BaseActivityText>
        </>
      }
    />
  );
};

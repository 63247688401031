import { msg } from '@lingui/core/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityDetailedContent,
} from '@/components/activities/BaseActivity/BaseActivity';
import Stack from '@/design_system/Stack';
import IconArchive from '@/icons/Archive.svg';

export const RequestManuallyArchivedActivity = ({
  activity,
}: {
  activity: ActivityOfType<'request_manually_archived'>;
}) => {
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="danger"
      icon={<IconArchive />}
      creatorName={activity.creator.name ?? ''}
      message={msg({
        id: 'activities.request_manually_archived.message',
        message: 'archived the request',
      })}
    >
      {!!activity.data.reason && (
        <Stack style={{ flex: 1, paddingTop: '0.25rem' }}>
          <BaseActivityDetailedContent>{activity.data.reason}</BaseActivityDetailedContent>
        </Stack>
      )}
    </BaseActivity>
  );
};

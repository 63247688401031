import { msg } from '@lingui/core/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import { BaseActivity } from '@/components/activities/BaseActivity/BaseActivity';
import IconArchive from '@/icons/Archive.svg';

export const RequestAutomaticallyArchivedActivity = ({
  activity,
}: {
  activity: ActivityOfType<'request_automatically_archived'>;
}) => {
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="danger"
      icon={<IconArchive />}
      creatorName={''}
      message={msg({
        id: 'activities.request_automatically_archived.message',
        message: 'The request has been automatically archived',
      })}
    />
  );
};

import { GridList, GridListItem } from 'react-aria-components';
import { MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/core/macro';
import { useLingui } from '@lingui/react';
import { Trans } from '@lingui/react/macro';
import SimpleBar from 'simplebar-react';

import Loader from '@/components/Loader';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconAdd from '@/icons/Add.svg';
import IconStoreClient from '@/icons/StoreClient.svg';
import { useArticleName } from '@/models/article';
import { getRequestorTypeLabel } from '@/models/request';
import { ArticleWithRelations } from '@/models/shipment';
import { ArticleNameCell } from '@/routes/Shipments/Shipment/components/ShipmentForm/components/ArticleSelect/components/ArticleNameCell';
import { useCurrentSession } from '@/services/auth';
import { createBEMClasses } from '@/utils/classname';
import useViewPort from '@/utils/useViewport';

import './ShippableArticlesSection.css';

const { block, element } = createBEMClasses('shippable-articles-section');

export const ShippableArticlesSection = ({
  unaddedArticles,
  noItemsMessage,
  onAddArticle,
  isFetching,
}: {
  unaddedArticles: ArticleWithRelations[];
  noItemsMessage: MessageDescriptor | null;
  onAddArticle: (articleId: string) => void;
  isFetching: boolean;
}) => {
  const { _ } = useLingui();

  if (isFetching) {
    return (
      <Stack alignItems="center" justifyContent="center">
        <Loader style={{ height: '40px', width: '40px' }} delay={0} />
      </Stack>
    );
  }

  return (
    <Stack className={block()}>
      <div className={element('label', undefined, 'paragraph-100-regular')}>
        <Trans id="shipments.new.articles.shippable.label">Shippable</Trans>
      </div>
      <SimpleBar className={element('scrollbar')} autoHide={false}>
        {noItemsMessage ? (
          <p className="paragraph-100-regular text-secondary" style={{ paddingLeft: '0.5rem' }}>
            {_(noItemsMessage)}
          </p>
        ) : (
          <GridList
            aria-label={_(
              msg({
                id: 'shipments.new.articles.shippable.label',
                message: 'Shippable',
              })
            )}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {unaddedArticles.map((article) => (
              <ShippableArticleRow key={article.id} article={article} onAdd={onAddArticle} />
            ))}
          </GridList>
        )}
      </SimpleBar>
    </Stack>
  );
};

const ShippableArticleRow = ({
  article,
  onAdd,
}: {
  article: ArticleWithRelations;
  onAdd: (articleId: string) => void;
}) => {
  const { isTablet } = useViewPort();
  const articleName = useArticleName({ article });

  return (
    <GridListItem
      className={element('shippable-article-row')}
      textValue={articleName}
      id={article.id}
    >
      <ArticleNameCell
        article={article}
        photoSize={isTablet ? 'large' : 'small'}
        showRequestDetails={!!isTablet}
      />

      <RequestDetailsCell article={article} />

      <AddArticleButton articleId={article.id} onAdd={onAdd} />
    </GridListItem>
  );
};

const AddArticleButton = ({
  articleId,
  onAdd,
}: {
  articleId: string;
  onAdd: (articleId: string) => void;
}) => {
  const { _ } = useLingui();

  return (
    <Button
      size="medium"
      variant="secondary"
      iconOnly
      onPress={() => onAdd(articleId)}
      ariaLabel={_(msg({ id: 'shipments.new.articles.add', message: 'Add item' }))}
      style={{
        marginLeft: 'auto',
        marginRight: '0.5rem',
      }}
    >
      <IconAdd />
    </Button>
  );
};

const RequestDetailsCell = ({ article }: { article: ArticleWithRelations }) => {
  const { _ } = useLingui();
  const { currentSession } = useCurrentSession();

  return (
    <Stack
      className={element('request-details-cell', undefined, 'is-hidden-up-to-tablet')}
      ariaLabel={`${article.request.reference} ${article.request.client?.name}`}
    >
      <IconStoreClient style={{ marginRight: '0.25rem' }} />
      <p className="paragraph-100-regular text-ellipsis">
        [{article.request.reference}]{' '}
        {currentSession?.workshop?.external && article.organization ? (
          <>
            {article.organization.name} ({_(getRequestorTypeLabel(article.request.requestorType))})
          </>
        ) : (
          <>{article.request.client?.name}</>
        )}
      </p>
    </Stack>
  );
};

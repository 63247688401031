import { RequestWithRelations } from '@/models/request';
import { UserWithRelations } from '@/models/user';
import { Workflow } from '@/models/workflow';
import {
  ArticleWithRelationsInStep,
  RequestArticleContextData,
  RequestContextData,
} from '@/routes/Requests/contexts/interfaces';

export const computeCreationContext = ({
  requestView,
  article,
  workflow,
  view,
}: {
  request: RequestWithRelations;
  requestView: RequestContextData['view'];
  workflow?: Workflow;
  article: ArticleWithRelationsInStep<'creation'>;
  view: RequestArticleContextData['view'];
  currentSession: UserWithRelations;
}) => {
  /**
   * WARRANTY
   */
  view.warranty.shown = !!workflow?.config.allowWarranty;
  view.warranty.editable = true;

  /**
   * SERVICES
   */
  view.services.defects.shown = !!workflow?.config.defectsEnabled;
  view.services.defects.editable = true;

  view.services.choice.shown = !!article.step.config.requireServiceChoice;
  view.services.choice.editable = true;

  view.services.actions.shown = article.serviceChoice === 'care-repair';
  view.services.actions.editable = true;
  view.services.actions.canAddRemoveAction = true;
  view.services.actions.canEditDefects = view.services.defects.shown;
  view.services.actions.canEditCost = requestView.cost.enabled;
  view.services.actions.canEditPrice = requestView.price.enabled;

  view.services.dispatch.shown = article.serviceChoice === 'care-repair';
  view.services.dispatch.editable = true;

  view.services.none.shown = false;
};

import { useNavigate } from 'react-router';
import { Trans } from '@lingui/react/macro';

import { UserAvatar } from '@/components/UserAvatar/UserAvatar';
import { DangerMenuItem, Header, MenuItem, Section, Separator } from '@/design_system/Menu/Menu';
import { SideNavigationProfileMenuItem } from '@/design_system/SideNavigation/SideNavigation';
import Stack from '@/design_system/Stack';
import IconLogout from '@/icons/Logout.svg';
import IconSettings from '@/icons/Settings.svg';
import IconPerson from '@/icons/User.svg';
import { useDeleteCurrentSession, useProfilePhoto } from '@/models/user';
import { useCanSeeSettings } from '@/routes/Menu/Menu';
import { useCurrentSession } from '@/services/auth';

export const ProfileMenuItem = () => {
  const { currentSession } = useCurrentSession();
  const { data: avatarUrl } = useProfilePhoto(currentSession?.id);
  const { mutateAsync: deleteCurrentSession } = useDeleteCurrentSession();

  const canSeeSettings = useCanSeeSettings();

  const navigate = useNavigate();
  const name = currentSession?.name ?? '';
  return (
    <SideNavigationProfileMenuItem
      label={name}
      avatarUrl={avatarUrl}
      onMenuAction={(id) => {
        switch (id) {
          case 'profile':
            navigate('/profile');
            break;
          case 'settings':
            navigate('/settings');
            break;
          case 'logout':
            deleteCurrentSession();
            break;
        }
      }}
    >
      <Section>
        <Header>
          <Stack
            row
            style={{
              padding: '4px 16px',
              minWidth: '14rem',
            }}
            gap="0.5rem"
          >
            <UserAvatar user={currentSession} size="large" />
            <Stack className="sentry-mask">
              <span
                title={name}
                className="paragraph-100-medium text-ellipsis"
                style={{ maxWidth: '220px' }}
              >
                {name}
              </span>
              <span className="paragraph-300-regular">{currentSession?.email}</span>
            </Stack>
          </Stack>
        </Header>
      </Section>
      <Separator />
      <Section>
        <MenuItem id="profile" size="large">
          <Stack row gap="0.5rem" alignItems="center">
            <IconPerson />
            <Trans id="navbar.profile.label">Profile</Trans>
          </Stack>
        </MenuItem>
        {canSeeSettings && (
          <MenuItem id="settings" size="large">
            <Stack row gap="0.5rem" alignItems="center">
              <IconSettings />
              <Trans id="navbar.settings.label">Settings</Trans>
            </Stack>
          </MenuItem>
        )}
      </Section>
      <Separator />
      <Section>
        <DangerMenuItem id="logout" size="large">
          <Stack row gap="0.5rem" alignItems="center">
            <IconLogout />
            <Trans id="navbar.logout.label">Log out</Trans>
          </Stack>
        </DangerMenuItem>
      </Section>
    </SideNavigationProfileMenuItem>
  );
};

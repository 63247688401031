import { useEffect } from 'react';
import { Label } from 'react-aria-components';
import { Trans, useLingui } from '@lingui/react/macro';

import FileUpload from '@/components/FileUpload';
import InputText from '@/design_system/InputText';
import { PhotoCardGrid } from '@/design_system/PhotoCard';
import Stack from '@/design_system/Stack';
import { useDeleteMedium } from '@/models/medium';
import { useMedia } from '@/models/medium';
import { type UserWithRelations, useUpdateUser } from '@/models/user';
import useDebouncedState from '@/utils/useDebouncedState';

export const InformationTab = ({
  currentSession,
  setCurrentSession,
}: {
  currentSession?: UserWithRelations | null;
  setCurrentSession: (session: UserWithRelations | null) => void;
}) => {
  const { t } = useLingui();
  const { mutate: deleteMedium } = useDeleteMedium();
  const { data: { media: profilePhotoMedia } = { media: [] } } = useMedia({
    userId: currentSession?.id,
    types: ['profile-photo'],
    limit: 1,
  });
  const { mutate: updateUser } = useUpdateUser();

  const [name, debouncedName, setName] = useDebouncedState<string>(currentSession?.name ?? '', 500);

  useEffect(() => {
    if (currentSession?.id && debouncedName && debouncedName !== currentSession.name) {
      updateUser({ id: currentSession.id, data: { name: debouncedName } });
      currentSession.name = debouncedName;
      setCurrentSession(currentSession);
    }
  }, [debouncedName, currentSession, setCurrentSession, updateUser]);

  return (
    <Stack gap="16px">
      <Stack gap="4px">
        <Label className={profilePhotoMedia ? 'paragraph-50-medium' : 'paragraph-100-medium'}>
          <Trans id="profile.avatar.label">Avatar</Trans>
        </Label>
        <div
          className="profile-photo-upload"
          style={{ maxWidth: profilePhotoMedia?.[0]?.url ? '160px' : '300px' }}
        >
          {profilePhotoMedia?.[0]?.url ? (
            <PhotoCardGrid media={profilePhotoMedia} onDelete={deleteMedium} />
          ) : (
            <FileUpload
              uploadData={{
                type: 'profile-photo',
                userId: currentSession?.id,
              }}
              ariaLabel={t({
                id: 'profile.picture.upload',
                message: 'Upload profile picture',
              })}
              type="photo"
              allowsMultiple={false}
              maxNumberOfMedia={1}
            />
          )}
        </div>
      </Stack>
      <InputText
        label={t({ id: 'profile.name.label', message: 'Name' })}
        placeholder={t({ id: 'profile.name.placeholder', message: 'Enter your full name' })}
        value={name}
        onChange={setName}
        style={{ maxWidth: '300px' }}
      />
      <InputText
        label={t({ id: 'profile.email.label', message: 'Email' })}
        value={currentSession?.email}
        isDisabled={true}
        style={{ maxWidth: '300px' }}
      />
      {currentSession?.workshop?.name && (
        <InputText
          label={t({ id: 'profile.workshop.label', message: 'Workshop' })}
          value={currentSession?.workshop?.name}
          isDisabled={true}
          style={{ maxWidth: '300px' }}
        />
      )}
    </Stack>
  );
};

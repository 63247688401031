import { Trans, useLingui } from '@lingui/react/macro';

import { PriceAmountWithDetails } from '@/api';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import Tooltip from '@/design_system/Tooltip';
import IconInfo from '@/icons/Info.svg';
import { Currency, formatCurrency } from '@/utils/number';
import useViewPort from '@/utils/useViewport';

export const ClientRequestPrice = ({
  price,
  label,
}: {
  price: PriceAmountWithDetails | undefined | null;
  label?: string;
}) => {
  const { isMobile } = useViewPort();
  const { t } = useLingui();

  const hasRefashionDiscount = !!price?.details.find(
    (detail) => detail.type === 'discount' && detail.subType === 'refashion' && detail.amount !== 0
  );
  const hasRateDiscounts = !!price?.details.find(
    (detail) => detail.type === 'discount' && detail.subType !== 'refashion' && detail.amount !== 0
  );

  return (
    <Stack
      className="bg-neutral-300"
      gap="0.5rem"
      padding="0.75rem 1rem"
      style={{ borderRadius: '0.5rem' }}
    >
      {label && <span className="paragraph-100-medium text-primary">{label}</span>}

      <Stack>
        <Stack gap="2px">
          <Stack
            row
            flexWrap="nowrap"
            justifyContent="space-between"
            className="paragraph-200-regular text-secondary"
          >
            <p>
              {isMobile ? (
                <Trans id="client.articles.table.footer.subtotal">Subtotal</Trans>
              ) : hasRateDiscounts && hasRefashionDiscount ? (
                <Trans id="client.articles.table.footer.subtotal-bonus-and-discount">
                  Subtotal with discount and Refashion bonus
                </Trans>
              ) : hasRateDiscounts ? (
                <Trans id="client.articles.table.footer.subtotal-discount">
                  Subtotal with discount
                </Trans>
              ) : hasRefashionDiscount ? (
                <Trans id="client.articles.table.footer.subtotal-bonus">
                  Subtotal with Refashion bonus
                </Trans>
              ) : (
                <Trans id="client.articles.table.footer.subtotal">Subtotal</Trans>
              )}
            </p>
            <p style={{ whiteSpace: 'nowrap' }}>
              {!!price && (hasRefashionDiscount || hasRateDiscounts) && (
                <>
                  <DiscountDetail details={price.details} />{' '}
                </>
              )}
              {formatCurrency(price?.amount, price?.currency)}
            </p>
          </Stack>
          <Stack
            row
            flexWrap="nowrap"
            justifyContent="space-between"
            className="paragraph-200-regular text-secondary"
          >
            <p>
              <Trans id="client.articles.table.total.shipment.label">Shipping fees</Trans>
            </p>
            <p>
              <Trans id="client.articles.table.total.shipment.offered">Offered</Trans>
            </p>
          </Stack>
        </Stack>
        <Stack
          row
          flexWrap="nowrap"
          justifyContent="space-between"
          className="paragraph-100-medium text-primary"
          ariaLabel={
            label ?? t({ id: 'client.articles.table.footer.total-price', message: 'Total price' })
          }
        >
          <p>
            <Trans id="client.articles.table.footer.total-simple">Total</Trans>
          </p>
          <p>{formatCurrency(price?.amount, price?.currency)}</p>
        </Stack>
      </Stack>
    </Stack>
  );
};

const DiscountDetail = ({ details }: { details: PriceAmountWithDetails['details'] }) => {
  const { t } = useLingui();

  const groupedDiscounts = details.reduce<
    {
      type: 'refashion' | 'rate';
      amount: number;
      currency: Currency;
    }[]
  >((acc, detail) => {
    if (detail.type === 'discount' && detail.amount !== 0) {
      const type = detail.subType === 'refashion' ? 'refashion' : 'rate';
      const existingDiscount = acc.find((discount) => discount.type === type);

      if (existingDiscount) {
        existingDiscount.amount += detail.amount;
      } else {
        acc.push({
          type,
          amount: detail.amount,
          currency: detail.currency,
        });
      }
    }

    return acc;
  }, []);

  const labelForType = {
    refashion: t({ id: 'article-money-cell.refashion', message: 'Bonus amount' }),
    rate: t({ id: 'article-money-cell.discount', message: 'Discount amount' }),
  };

  return (
    <Tooltip
      content={
        <Stack>
          {groupedDiscounts?.map((discount) => (
            <p key={discount.type}>
              {labelForType[discount.type]}: {formatCurrency(discount.amount, discount.currency)}
            </p>
          ))}
        </Stack>
      }
    >
      <Button variant="style-less" style={{ verticalAlign: 'text-top' }}>
        <IconInfo style={{ fontSize: '1rem' }} />
      </Button>
    </Tooltip>
  );
};

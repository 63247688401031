import { Trans } from '@lingui/react/macro';

import Stack from '@/design_system/Stack';

export const ServiceNone = () => {
  return (
    <Stack gap="0.5rem">
      <p className="label-100">
        <Trans id="article-services.service-none.label">Services</Trans>
      </p>
      <p className="paragraph-100-regular text-secondary">
        <Trans id="article-services.service-none.text">No service is applicable</Trans>
      </p>
    </Stack>
  );
};

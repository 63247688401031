import { Trans, useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import InputSearch from '@/design_system/InputSearch';
import Stack from '@/design_system/Stack';
import {
  type ActionTypeOrganizationWithRelations,
  type PackActionTypeOrganizationWithRelations,
  useActionTypes,
} from '@/models/actionType';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';
import useDebouncedState from '@/utils/useDebouncedState';

import { ActionDropdownItem, CustomActionDropdownItem } from './ActionDropdownItem';
import { PackActionDropdownItem } from './PackActionDropdownItem';

type ActionTypeOption =
  | PackActionTypeOrganizationWithRelations
  | ActionTypeOrganizationWithRelations;

export const ActionTypeSearchDialog = ({
  isOpen,
  onClose,
  onChooseActionType,
}: {
  isOpen: boolean;
  onClose: () => void;
  onChooseActionType: (actionType: ActionTypeOption | 'custom') => void;
}) => {
  const { t } = useLingui();
  const { workflow } = useRequestContext();
  const { article } = useArticleContext();
  const [query, debouncedQuery, setQuery] = useDebouncedState<string>('', 500);

  const {
    data: { actionTypes, packActionTypes } = { actionTypes: [], packActionTypes: [] },
    isFetching,
  } = useActionTypes(
    {
      articleId: article.id,
      query: debouncedQuery || undefined,
    },
    {
      keepPreviousData: true,
    }
  );

  const actionTypeOptions = [...packActionTypes, ...actionTypes];
  const allowCustomActions = !!workflow?.config.allowCustomActions;

  return (
    <Dialog
      title={t({ id: 'action-type-search-dialog.actions.add', message: 'Add an action' })}
      isOpen={isOpen}
      onOpenChange={onClose}
      fullHeight
    >
      <main>
        <Stack gap="0.5rem">
          <InputSearch
            aria-label={t({
              id: 'action-type-search-dialog.add-action.label',
              message: 'Add an action',
            })}
            placeholder={t({
              id: 'action-type-search-dialog.actions.placeholder',
              message: 'Search a care or repair action...',
            })}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            isLoading={isFetching || query !== debouncedQuery}
            value={query}
            onChange={setQuery}
          />

          {actionTypeOptions.length === 0 && (
            <span className="paragraph-100-regular text-secondary" style={{ padding: '0.5rem 0' }}>
              <Trans id="action-type-search-dialog.actions.no-results">
                No results matching your search
              </Trans>
            </span>
          )}

          <ActionTypesMenu
            actionTypeOptions={actionTypeOptions}
            onChooseActionType={onChooseActionType}
          />
        </Stack>
      </main>

      {allowCustomActions && (
        <footer>
          <Button variant="secondary" onPress={() => onChooseActionType('custom')}>
            <CustomActionDropdownItem style={{ display: 'flex', justifyContent: 'center' }} />
          </Button>
        </footer>
      )}
    </Dialog>
  );
};

const ActionTypesMenu = ({
  actionTypeOptions,
  onChooseActionType,
}: {
  actionTypeOptions: ActionTypeOption[];
  onChooseActionType: (actionType: ActionTypeOption) => void;
}) => {
  return (
    <Stack>
      {actionTypeOptions.map((actionTypeOption) => (
        <ActionTypeMenuItem
          key={actionTypeOption.id}
          actionTypeOption={actionTypeOption}
          onChooseActionType={onChooseActionType}
        />
      ))}
    </Stack>
  );
};

const ActionTypeMenuItem = ({
  actionTypeOption,
  onChooseActionType,
}: {
  actionTypeOption: ActionTypeOption;
  onChooseActionType: (actionType: ActionTypeOption) => void;
}) => {
  return (
    <Button
      style={{ padding: '0.5rem 1rem' }}
      variant="style-less"
      onPress={() => onChooseActionType(actionTypeOption)}
    >
      {'actions' in actionTypeOption ? (
        <PackActionDropdownItem packAction={actionTypeOption} />
      ) : (
        <ActionDropdownItem action={actionTypeOption} />
      )}
    </Button>
  );
};

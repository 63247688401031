import { useLingui } from '@lingui/react/macro';
import { OTPInput } from 'input-otp';

import { createBEMClasses } from '@/utils/classname';

import './InputOTP.css';

const { block, element } = createBEMClasses('input-otp');

const InputOTP = ({
  value,
  onChange,
  onComplete,
}: {
  value: string;
  onChange: (value: string) => void;
  onComplete: () => void;
}) => {
  const { t } = useLingui();

  return (
    <OTPInput
      maxLength={6}
      value={value}
      onChange={onChange}
      onComplete={onComplete}
      aria-label={t({
        id: 'components.input-otp.label',
        message: 'Verification code',
      })}
      render={({ slots }) => (
        <div className={block()}>
          {slots.map((slot, index) => (
            <div key={index} className={element('slot', { isActive: slot.isActive })}>
              {slot.char}
              {slot.hasFakeCaret && (
                <div className={element('slot__caret')}>
                  <div />
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    />
  );
};

export default InputOTP;

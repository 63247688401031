import { Trans, useLingui } from '@lingui/react/macro';

import IconUserRefusal from '@/icons/UserRefusal.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import type { TNotificationOfType } from '@/models/notification';

export const FinalQuoteRefusedNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'final_quote_refused'>;
}) => {
  const { t } = useLingui();

  return (
    <BaseNotification
      icon={<IconUserRefusal />}
      type="danger"
      notification={notification}
      notificationLabel={t({
        id: 'notification.final-quote-refused.label',
        message: `Payment refused for request ${notification.request.reference}`,
      })}
      notificationHeader={
        notification.data?.areAllArticlesRefused === false ? (
          <Trans id="notification.final-quote-refused.some-items">
            <span className="paragraph-100-medium">The client</span> refused the payment for some
            items
          </Trans>
        ) : (
          <Trans id="notification.final-quote-refused">
            <span className="paragraph-100-medium">The client</span> refused the payment
          </Trans>
        )
      }
    />
  );
};

import { useEffect, useState } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';

import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconClock from '@/icons/Clock.svg';
import IconEdit from '@/icons/Edit.svg';
import IconEmail from '@/icons/Email.svg';
import IconPhone from '@/icons/Phone.svg';
import IconPlace from '@/icons/Place.svg';
import IconStore from '@/icons/Store.svg';
import { ServicePoint, useServicePoint } from '@/models/shipment';
import { usePublicKeys } from '@/services/auth';
import useViewPort from '@/utils/useViewport';

import './SendcloudMap.css';

export const SendcloudMap = ({
  servicePointId,
  setServicePointId,
  carrier,
  address,
  postalCode,
  city,
  country,
  isClientView,
  isEditable = true,
}: {
  servicePointId: string | null;
  setServicePointId: (servicePointId: string) => void;
  carrier: string;
  address?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  isClientView?: boolean;
  isEditable?: boolean;
}) => {
  const {
    publicKeys: { sendcloudPublicKey },
  } = usePublicKeys();

  const { isMobile, isTablet } = useViewPort();
  const { t } = useLingui();

  const isFullScreen = isMobile || isTablet;
  const [isOpen, setIsOpen] = useState(!servicePointId && !isFullScreen);
  const [initialServicePointData, setInitialServicePointData] = useState<ServicePoint>();

  const servicePointIdAsNumber = servicePointId ? parseInt(servicePointId) : undefined;

  useEffect(() => {
    function receiveMessage(event: MessageEvent) {
      if (event.origin !== 'https://servicepoints.sendcloud.sc') {
        return;
      }

      if (event.data.type === 'servicePointSelected') {
        setInitialServicePointData({
          id: event.data.point.id,
          code: event.data.point.code,
          name: event.data.point.name,
          address: {
            line1: event.data.point.street,
            zipCode: event.data.point.postal_code,
            city: event.data.point.city,
            country: event.data.point.country,
          },
          email: event.data.point.email ?? null,
          phone: event.data.point.phone ?? null,
          openingTimes: event.data.point.formatted_opening_times,
          openTomorrow: event.data.point.open_tomorrow,
        });
        setServicePointId((event.data.point.id as number).toString());
        setIsOpen(false);
        return;
      }

      if (event.data.type === 'servicePointClose') {
        setIsOpen(false);
        return;
      }

      console.log('unknown-sendcloud-message', event.data);
    }

    window.addEventListener('message', receiveMessage);

    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, [setServicePointId]);

  const { data: servicePoint } = useServicePoint(
    servicePointIdAsNumber,
    initialServicePointData?.id === servicePointIdAsNumber ? initialServicePointData : undefined
  );

  if (!sendcloudPublicKey) {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    return 'Cannot load Sendcloud map without public key.';
  }

  if (isOpen) {
    return (
      <Stack gap="8px">
        <div className="sendcloud-map-overlay" />
        <p className={isClientView ? 'sendcloud-map-client-label' : 'sendcloud-map-label'}>
          {isClientView ? (
            <Trans id="sendcloud-map.client-label">
              Please, choose a shipping back pickup point:
            </Trans>
          ) : (
            <Trans id="sendcloud-map.label">Select a pickup point for the client</Trans>
          )}
        </p>
        <SendcloudMapIframe
          sendcloudPublicKey={sendcloudPublicKey}
          isFullScreen={isFullScreen}
          carrier={carrier}
          address={address}
          postalCode={postalCode}
          city={city}
          country={country}
          servicePointId={servicePointId}
          servicePoint={servicePoint}
        />
      </Stack>
    );
  }

  if (servicePointId) {
    if (isClientView) {
      return (
        <Box gap="0.75rem" padding="12px 16px">
          <Stack row justifyContent="space-between" alignItems="center">
            <p className="paragraph-50-medium">
              <Trans id="client.request.pickup-point">Shipping back pickup point</Trans>
            </p>
            {isEditable && (
              <Button
                iconOnly
                variant="secondary-brand"
                size="medium"
                onPress={() => setIsOpen(true)}
                ariaLabel={t({ id: 'client.request.pickup-point.edit.label', message: 'Edit' })}
                tooltip={t({ id: 'client.request.pickup-point.edit.label', message: 'Edit' })}
              >
                <IconEdit />
              </Button>
            )}
          </Stack>
          <hr />
          <Stack gap="0.25rem">
            <Stack row gap="0.5rem" alignItems="center">
              <IconStore style={{ fontSize: '1.25rem' }} />
              <p className="paragraph-100-medium">{servicePoint?.name}</p>
            </Stack>
            <Stack row flexWrap="nowrap" gap="0.5rem">
              <IconPlace end style={{ fontSize: '1.25rem', paddingTop: '0.125rem' }} />
              <div>
                <p className="paragraph-100-regular">{servicePoint?.address?.line1}</p>
                <p className="paragraph-100-regular">
                  {servicePoint?.address?.zipCode} {servicePoint?.address?.city}{' '}
                  {servicePoint?.address?.country}
                </p>
              </div>
            </Stack>
            {servicePoint?.phone && (
              <Stack row flexWrap="nowrap" gap="0.5rem" alignItems="center">
                <IconPhone style={{ fontSize: '1.25rem' }} />
                <p className="paragraph-100-regular">{servicePoint?.phone}</p>
              </Stack>
            )}
            {servicePoint?.email && (
              <Stack row flexWrap="nowrap" gap="0.5rem" alignItems="center">
                <IconEmail style={{ fontSize: '1.25rem' }} />
                <p className="paragraph-100-regular">{servicePoint?.email}</p>
              </Stack>
            )}
            {servicePoint?.openTomorrow && (
              <Stack row flexWrap="nowrap" gap="0.5rem" alignItems="center">
                <IconClock style={{ fontSize: '1.25rem' }} />
                <p className="paragraph-100-regular">
                  <Trans id="sendcloud-map.open-tomorrow">Opened tomorrow:</Trans>{' '}
                  {servicePoint?.openingTimes[
                    (new Date().getDay() + 1) as keyof typeof servicePoint.openingTimes
                  ].join(', ')}
                </p>
              </Stack>
            )}
          </Stack>
        </Box>
      );
    }

    return (
      <Stack row gap="12px" alignItems="center">
        <p>
          <span className="paragraph-100-medium">
            <Trans id="sendcloud-map.selected-pickup-point">Selected pickup point:</Trans>
          </span>
          &nbsp;
          <span className="paragraph-100-regular">{servicePoint?.name}</span>
        </p>
        {isEditable && (
          <Button
            variant="secondary"
            onPress={() => setIsOpen(true)}
            size="medium"
            iconOnly
            ariaLabel={t({
              id: 'sendcloud-map.edit-button',
              message: 'Edit selected pickup point',
            })}
          >
            <IconEdit />
          </Button>
        )}
      </Stack>
    );
  }

  return (
    <Stack gap="8px">
      <p className={isClientView ? 'sendcloud-map-client-label' : 'sendcloud-map-label'}>
        {isClientView ? (
          <Trans id="sendcloud-map.client-label">
            Please, choose a shipping back pickup point:
          </Trans>
        ) : (
          <Trans id="sendcloud-map.label">Select a pickup point for the client</Trans>
        )}
      </p>
      <div>
        <Button variant="secondary" onPress={() => setIsOpen(true)}>
          <Trans id="sendcloud-map.open-button">Select pickup point</Trans>
        </Button>
      </div>
    </Stack>
  );
};

const SendcloudMapIframe = ({
  sendcloudPublicKey,
  isFullScreen,
  carrier,
  address,
  postalCode,
  city,
  country,
  servicePointId,
  servicePoint,
}: {
  sendcloudPublicKey: string;
  isFullScreen: boolean;
  carrier: string;
  address?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  servicePointId: string | null;
  servicePoint?: ServicePoint;
}) => {
  const { i18n } = useLingui();

  /**
   * Sendcloud map automatically focuses on the search input if an address / postal code is provided,
   * as well as whenever those values changes. This makes it impossible to update form values as it
   * automatically take the focus back on the search input.
   * To fix this, we don't update the address/postalcode/city once the map is opened.
   */
  const [initialAddressAndPostalCode] = useState(
    address || postalCode ? [address, postalCode].filter(Boolean).join(' ') : null
  );
  const [initialCity] = useState(city);

  return (
    <iframe
      title="sendcloud-map"
      src={
        'https://servicepoints.sendcloud.sc/embed/v3/service-point-picker/' +
        `?api-key=${sendcloudPublicKey}` +
        `&language=${i18n.locale}` +
        `&carrier=${carrier}` +
        (country ? `&country=${country}` : '') +
        (initialAddressAndPostalCode
          ? `&postal-code=${encodeURIComponent(initialAddressAndPostalCode)}`
          : '') +
        (initialCity ? `&city=${encodeURIComponent(initialCity)}` : '') +
        (servicePointId ? `&id=${servicePointId}` : '') +
        (isFullScreen || servicePoint ? '&close-button=1' : '')
      }
    />
  );
};

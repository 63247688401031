import { MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/core/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import { BaseActivity } from '@/components/activities/BaseActivity/BaseActivity';
import IconEdit from '@/icons/Edit.svg';

export const RequestPropertyUpdatedActivity = ({
  activity,
}: {
  activity: ActivityOfType<'request_property_updated'>;
}) => {
  let requestPropertyUpdatedMessage: MessageDescriptor | undefined;
  if (activity.data.property === 'priority') {
    requestPropertyUpdatedMessage = msg({
      id: 'activities.request_property_updated.priority',
      message: 'edited the priority',
    });
  } else if (activity.data.property === 'supervisor' && activity.data.after === null) {
    requestPropertyUpdatedMessage = msg({
      id: 'activities.request_property_updated.supervisor.null',
      message: 'removed the supervisor of the request',
    });
  } else if (activity.data.property === 'supervisor') {
    requestPropertyUpdatedMessage = msg({
      id: 'activities.request_property_updated.supervisor.new',
      message: 'assigned a new supervisor to the request',
    });
  } else if (activity.data.property === 'client_info') {
    requestPropertyUpdatedMessage = msg({
      id: 'activities.request_property_updated.client_info',
      message: 'edited the client info',
    });
  }
  if (!requestPropertyUpdatedMessage) return null;

  return (
    <BaseActivity
      key={activity.id}
      createdAt={activity.createdAt}
      icon={<IconEdit />}
      creatorName={
        activity.creator
          ? activity.creator.name
          : activity.clientCreator
            ? msg({
                id: 'activities.request_property_updated.client',
                message: 'The client',
              })
            : activity.storeCreator
              ? msg({ id: 'activities.request_property_updated.store', message: 'The store' })
              : ''
      }
      message={requestPropertyUpdatedMessage}
    />
  );
};

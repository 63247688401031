import { Trans, useLingui } from '@lingui/react/macro';

import IconItemDelete from '@/icons/ItemDelete.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import { useArticleName } from '@/models/article';
import type { TNotificationOfType } from '@/models/notification';

export const ArticleNoneServiceChoiceNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'article_none_service_choice'>;
}) => {
  const { t } = useLingui();

  const articleName = useArticleName({ article: notification.article });

  return (
    <BaseNotification
      notificationLabel={t({
        id: 'notification.article-none-service-choice.label',
        message: `No service can be provided for the item ${articleName} of request ${notification.request.reference}`,
      })}
      icon={<IconItemDelete />}
      type="danger"
      notification={notification}
      notificationHeader={
        <Trans id="notification.article-none-service-choice">
          No service can be provided for <span className="paragraph-100-medium">{articleName}</span>
        </Trans>
      }
    />
  );
};

import { Trans } from '@lingui/react/macro';

import { ArticleServiceChoice } from '@/api';
import AlertBar from '@/design_system/AlertBar';
import Message from '@/design_system/Message';
import RadioGroup from '@/design_system/RadioGroup';
import Stack from '@/design_system/Stack';
import { useUpdateServiceChoice } from '@/models/article';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';

export const ServiceChoice = () => {
  let serviceOptions;
  const { article, view, errors } = useArticleContext();

  const { mutateAsync: updateArticle } = useUpdateServiceChoice({
    articleId: article.id,
    requestId: article.requestId,
  });

  if (!article.step) {
    return null;
  }

  if ('serviceOptions' in article.step.config) {
    serviceOptions = article.step.config.serviceOptions;
  } else if (
    'requireServiceChoice' in article.step.config &&
    article.step.config.requireServiceChoice &&
    'serviceOptions' in article.step.config.requireServiceChoice
  ) {
    serviceOptions = article.step.config.requireServiceChoice.serviceOptions;
  }

  if (!serviceOptions || (serviceOptions.length <= 1 && article.serviceChoice)) {
    return null;
  }

  return (
    <>
      <Stack gap="0.5rem">
        <RadioGroup
          isDisabled={!view.services.choice.editable}
          label={<Trans id="service-choice.radio.label">What service can be provided?</Trans>}
          options={[
            {
              value: 'care-repair',
              text: <Trans id="service-choice.radio.care-repair.label">Care & Repair</Trans>,
              subText: (
                <Trans id="service-choice.radio.care-repair.sub-text">
                  Address and fix the item&apos;s defect
                </Trans>
              ),
            },
            {
              value: 'exchange',
              text: <Trans id="service-choice.radio.exchange.label">Exchange</Trans>,
              subText: (
                <Trans id="service-choice.radio.exchange.sub-text">
                  Provide a new or equivalent item
                </Trans>
              ),
            },
            {
              value: 'refund',
              text: <Trans id="service-choice.radio.refund.label">Refund</Trans>,
              subText: (
                <Trans id="service-choice.radio.refund.sub-text">
                  Issue a full or partial refund
                </Trans>
              ),
            },
            {
              value: 'none',
              text: <Trans id="service-choice.radio.none.label">None</Trans>,
              subText: (
                <Trans id="service-choice.radio.none.sub-text">No service can be provided</Trans>
              ),
            },
          ].filter((option) => serviceOptions.includes(option.value))}
          value={article.serviceChoice}
          onChange={(value) => {
            updateArticle({
              serviceChoice: value as ArticleServiceChoice,
            });
          }}
        />
        {errors.services?.choice?.noChoice && (
          <Message type="error">
            <Trans id="service-choice.radio.error.no-choice">Please select a service type.</Trans>
          </Message>
        )}
      </Stack>
      {(article.serviceChoice === 'exchange' || article.serviceChoice === 'refund') && (
        <ServiceExchangeRefundMessage />
      )}
      {article.serviceChoice === 'none' && <ServiceNoneMessage />}
    </>
  );
};

const ServiceNoneMessage = () => {
  const { request, workflow } = useRequestContext();
  const { article } = useArticleContext();

  const currentNode = workflow?.nodes.find((node) => node.id === article.step?.id);

  if (!currentNode || !('step' in currentNode) || currentNode.step !== 'service-choice') {
    return null;
  }

  const nextNodeIfChoiceNone = workflow!.nodes.find((node) => node.id === currentNode.next.none);

  if (!nextNodeIfChoiceNone || !('step' in nextNodeIfChoiceNone)) {
    if (request.client) {
      return (
        <AlertBar type="info">
          <Trans id="service-choice.none.message.archival.comment">
            You can add a comment for the client in the section below.
          </Trans>
        </AlertBar>
      );
    } else {
      return null;
    }
  }

  const nextStep = nextNodeIfChoiceNone;

  if (nextStep?.step === 'transit') {
    if (nextStep.config.destinationType === 'client') {
      if (nextStep.config.clientPickup) {
        return (
          <AlertBar type="info">
            <Trans id="service-choice.none.message.transit.store-client">
              The client will be informed to come pick their item up at the store. You can add a
              comment for them in the section below.
            </Trans>
          </AlertBar>
        );
      } else {
        return (
          <AlertBar type="info">
            <Trans id="service-choice.none.message.transit.workshop-client">
              The item will be sent back to the client. You can add a comment for them in the
              section below.
            </Trans>
          </AlertBar>
        );
      }
    }

    if (nextStep.config.originType === 'workshop' && nextStep.config.destinationType === 'store') {
      return (
        <AlertBar type="info">
          <Trans id="service-choice.none.message.transit.workshop-store">
            The item will be sent back to the store.
          </Trans>{' '}
          {request.client && (
            <Trans id="service-choice.none.message.transit.workshop-store.comment">
              You can add a comment for the client in the section below.
            </Trans>
          )}
        </AlertBar>
      );
    }
  }

  if (nextStep?.step === 'archival') {
    return (
      <AlertBar type="info">
        {request.client && (
          <Trans id="service-choice.none.message.archival.comment">
            You can add a comment for the client in the section below.
          </Trans>
        )}{' '}
        {request.articles.length === 1 ? (
          <Trans id="service-choice.none.message.archival.item-and-request">
            The item and the request will be automatically archived.
          </Trans>
        ) : (
          <Trans id="service-choice.none.message.archival.item">
            The item will be automatically archived.
          </Trans>
        )}
      </AlertBar>
    );
  }

  return null;
};

const ServiceExchangeRefundMessage = () => {
  const { request, article } = useArticleContext();

  return (
    <AlertBar type="warning">
      {article.serviceChoice === 'exchange' ? (
        <Trans id="service-choice.exchange.message.not-available">
          The exchange option through Prolong is not yet activated. However, you can validate this
          service choice and send the item and request information to Zendesk.
        </Trans>
      ) : (
        <Trans id="service-choice.refund.message.not-available">
          The refund option through Prolong is not yet activated. However, you can validate this
          service choice and send the item and request information to Zendesk.
        </Trans>
      )}{' '}
      {request.articles.length === 1 ? (
        <Trans id="service-choice.exchange-refund.message.archival.request-and-item">
          This will also automatically archive the request and the item in Prolong.
        </Trans>
      ) : (
        <Trans id="service-choice.exchange-refund.message.archival.item">
          This will also automatically archive the item in Prolong.
        </Trans>
      )}
    </AlertBar>
  );
};

import { Trans } from '@lingui/react/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconRepairValidation from '@/icons/RepairValidation.svg';

export const ArticleRepairCompletedActivity = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityOfType<'article_repair_completed'>;
  displayArticleName: boolean;
}) => {
  const workshopName = activity.workshop.name;

  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="neutral"
      icon={<IconRepairValidation />}
      creatorName={activity.creator?.name ?? ''}
      message={
        <>
          <BaseActivityText>
            {activity.data.onBehalfOfWorkshop ? (
              <Trans id="activities.article_repair_completed.message.on-behalf">
                completed the care & repair job on behalf of{' '}
                <span className="paragraph-100-medium">{workshopName}</span> for the item
              </Trans>
            ) : (
              <Trans id="activities.article_repair_completed.message">
                completed the care & repair job of the item
              </Trans>
            )}
          </BaseActivityText>
          <BaseActivityArticleName activity={activity} displayArticleName={displayArticleName} />
        </>
      }
    />
  );
};

import { useState } from 'react';
import { useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import IconEdit from '@/icons/Edit.svg';

import { UserDialog, UserWithRoles } from './UserDialog';

export const UpdateUserRoles = ({ user }: { user: UserWithRoles }) => {
  const { t } = useLingui();
  const [isOpen, setIsOpen] = useState(false);

  const label = t({
    id: 'settings.users.update-modal.trigger',
    message: `Manage ${user.name ?? user.email}`,
  });

  return (
    <>
      <Button
        variant="secondary"
        iconOnly
        size="small"
        ariaLabel={label}
        tooltip={<p className="sentry-mask">{label}</p>}
        onPress={() => setIsOpen(true)}
      >
        <IconEdit />
      </Button>
      <UserDialog isOpen={isOpen} setIsOpen={setIsOpen} user={user} />
    </>
  );
};

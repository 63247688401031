import { Trans, useLingui } from '@lingui/react/macro';

import {
  GoToCommentsButton,
  GoToCommentsMenuItem,
} from '@/components/comments/GoToCommentsButton/GoToCommentsButton';
import Button from '@/design_system/Button/Button';
import Menu from '@/design_system/Menu';
import Stack from '@/design_system/Stack';
import IconArrow from '@/icons/Arrow.svg';
import IconMore from '@/icons/More.svg';
import { ShipmentWithRelations, useCarrierName, useComments } from '@/models/shipment';
import {
  EditShipmentButton,
  EditShipmentMenuItem,
  useEditShipment,
} from '@/routes/Shipments/Shipment/components/ShipmentHeader/EditShipmentButton';
import {
  PrintItemsSheetsButton,
  PrintItemsSheetsMenuItem,
  PrintShippingLabelButton,
  PrintShippingLabelMenuItem,
  usePrintActions,
} from '@/routes/Shipments/Shipment/components/ShipmentHeader/PrintsButton';
import { useCurrentSession } from '@/services/auth';
import useViewPort from '@/utils/useViewport';

export const ShipmentHeader = ({
  shipment,
  onCommentButtonPress,
}: {
  shipment: ShipmentWithRelations;
  onCommentButtonPress: () => void;
}) => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();
  const { currentSession } = useCurrentSession();
  const { data: { comments } = { comments: [] } } = useComments({
    shipmentId: shipment.id,
  });

  const canEditShipment = shipment.canBeCreatedBy(currentSession) && shipment.status === 'draft';

  const { editShipment } = useEditShipment({ shipment });
  const { printShippingLabel, printItemsSheets } = usePrintActions({ shipment });

  let carrier = useCarrierName(shipment);
  const isPrivateCarrier = shipment?.carrier === 'private-carrier';

  if (isPrivateCarrier) {
    carrier = t({ id: 'shipment.header.carrier.private', message: 'private carrier' });
  }

  const isSelfHandled = shipment?.carrierService === 'self-handled';

  return (
    <>
      {isMobile && (
        <Button variant="secondary" size="large" to="/shipments" iconOnly noBorder>
          <IconArrow left />
        </Button>
      )}

      <h2 className="headline-300-bold paragraph-100-medium-mobile" style={{ flex: 1 }}>
        [{shipment.reference}]{' '}
        <span style={{ textTransform: isMobile ? 'capitalize' : undefined }}>
          {isSelfHandled ? (
            <Trans id="shipment.header.self-handled">Self-Handled Delivery</Trans>
          ) : isMobile ? (
            carrier
          ) : (
            <Trans id="shipment.header.carrier">Shipment via {carrier}</Trans>
          )}
        </span>
      </h2>

      {!isMobile && (
        <Stack row gap="0.5rem">
          {canEditShipment && <EditShipmentButton onPress={editShipment} />}
          <GoToCommentsButton
            onCommentButtonPress={onCommentButtonPress}
            nbOfComments={comments.length}
          />
          {shipment.status !== 'draft' && <PrintShippingLabelButton onPress={printShippingLabel} />}
          {shipment.step === 'preparation' && <PrintItemsSheetsButton onPress={printItemsSheets} />}
        </Stack>
      )}
      {isMobile && (
        <Menu
          placement="bottom end"
          trigger={
            <Button variant="secondary" size="large" iconOnly noBorder>
              <IconMore />
            </Button>
          }
          onAction={(id) => {
            switch (id) {
              case 'edit-shipment':
                editShipment();
                break;
              case 'go-to-comments':
                onCommentButtonPress();
                break;
              case 'print-shipping-label':
                printShippingLabel();
                break;
              case 'print-items-sheets':
                printItemsSheets();
                break;
              default:
                break;
            }
          }}
        >
          <EditShipmentMenuItem />
          <GoToCommentsMenuItem nbOfComments={comments.length} />
          {shipment.status !== 'draft' && <PrintShippingLabelMenuItem />}
          {shipment.step === 'preparation' && <PrintItemsSheetsMenuItem />}
        </Menu>
      )}
    </>
  );
};

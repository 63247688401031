import { Trans } from '@lingui/react/macro';

import { ArticleWithRelations } from '@/models/request';

import { BaseMessage } from './BaseMessage';

export const ChooseArticleServiceTaskMessage = ({ article }: { article: ArticleWithRelations }) => {
  return (
    <BaseMessage
      title={<Trans id="article.task.choose-article-service.title">Choose a service</Trans>}
      message={
        <Trans id="article.task.choose-article-service.message">
          Please review the item&apos;s details and needs, then select the most suitable service and
          actions to provide.
        </Trans>
      }
      article={article}
    />
  );
};

import { Plural, Trans } from '@lingui/react/macro';

import Stack from '@/design_system/Stack';
import IconAnalysis from '@/icons/Analysis.svg';
import { BrandWrapper, BrandWrapperCenter } from '@/layouts/Brand';
import { ClientRequestWithRelations } from '@/models/request';
import ClientArticlesTable from '@/routes/Brand/Requests/Request/components/shared/ClientArticlesTable';
import ClientInfo from '@/routes/Brand/Requests/Request/components/shared/ClientInfo';
import ClientStepper from '@/routes/Brand/Requests/Request/components/shared/ClientStepper';

const Analysis = ({ request }: { request: ClientRequestWithRelations }) => {
  const activeArticlesInAnalysisOrTransitWorkshopToWorkshop = request.articles.filter(
    (article) =>
      !article.cancelledAt &&
      (article.step?.step === 'analysis' ||
        (article.step?.step === 'transit' &&
          article.step.config.originType === 'workshop' &&
          article.step.config.destinationType === 'workshop'))
  );

  return (
    <BrandWrapper>
      <BrandWrapperCenter>
        <Stack gap="1.5rem">
          <ClientStepper request={request} />
          <Stack row gap="0.5rem">
            <IconAnalysis className="color-tertiary-700" style={{ fontSize: '24px' }} />
            <p className="paragraph-50-regular paragraph-100-regular-mobile">
              <Trans id="store.request.analysis.text">
                The{' '}
                <Plural
                  value={activeArticlesInAnalysisOrTransitWorkshopToWorkshop.length}
                  one="item is"
                  other="items are"
                />{' '}
                being analyzed by the workshop to see if the required actions correspond to{' '}
                <Plural
                  value={activeArticlesInAnalysisOrTransitWorkshopToWorkshop.length}
                  one="its"
                  other="their"
                />{' '}
                needs.
              </Trans>
            </p>
          </Stack>
          <Stack gap="1rem">
            <ClientArticlesTable
              request={request}
              showPrice={!!request.client}
              showArticleComment
            />
            {!!request.client && <ClientInfo request={request} />}
          </Stack>
        </Stack>
      </BrandWrapperCenter>
    </BrandWrapper>
  );
};

export default Analysis;

import { useLingui } from '@lingui/react/macro';

import config from '@/config';
import Button from '@/design_system/Button';
import IconQrCode from '@/icons/QrCode.svg';
import useViewPort from '@/utils/useViewport';

export const ArticlePrintArticleSheetActionCell = ({ article }: { article: { id: string } }) => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();

  const label = t({ id: 'article.print-item-sheet.message', message: 'Print' });

  return (
    <Button
      size="medium"
      variant="secondary"
      iconOnly={!isMobile}
      href={config.apiUrl + `/articles/${article.id}/recap`}
      target="_blank"
      ariaLabel={label}
      tooltip={label}
      style={{ flex: 1 }}
    >
      <IconQrCode />
      {isMobile && <div>{label}</div>}
    </Button>
  );
};

import { Trans } from '@lingui/react/macro';

import Stack from '@/design_system/Stack';

export const ServiceRefund = () => {
  return (
    <Stack gap="0.5rem">
      <p className="label-100">
        <Trans id="article-services.service-refund.label">Services</Trans>
      </p>
      <p className="paragraph-100-regular text-secondary">
        <Trans id="article-services.service-refund.text">
          No service is applicable. A refund has been proposed.
        </Trans>
      </p>
    </Stack>
  );
};

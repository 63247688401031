import { Trans, useLingui } from '@lingui/react/macro';

import IconArchive from '@/icons/Archive.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import { useArticleName } from '@/models/article';
import type { TNotificationOfType } from '@/models/notification';

export const ShipmentIssueArchivedNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'shipment_issue_archived'>;
}) => {
  const articleName = useArticleName({ article: notification.article });
  const { t } = useLingui();

  return (
    <BaseNotification
      icon={<IconArchive />}
      type="danger"
      notification={notification}
      notificationLabel={t({
        id: 'notification.shipment-issue-archived.label',
        message: `Item ${articleName} of request ${notification.request.reference} archived`,
      })}
      notificationHeader={
        <Trans id="notification.shipment-issue-archived">
          The item <span className="paragraph-100-medium">{articleName}</span> has been archived
        </Trans>
      }
    />
  );
};

import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-aria-components';
import { Plural, Trans } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconPackage from '@/icons/Package.svg';
import IconPrint from '@/icons/Print.svg';
import IconTruck from '@/icons/Truck.svg';
import { BrandWrapper, BrandWrapperCenter } from '@/layouts/Brand';
import { ClientArticleWithRelations, ClientRequestWithRelations } from '@/models/request';
import { SHIPMENT_CARRIERS, useShipments, useShipShipment } from '@/models/shipment';
import ClientArticlesTable from '@/routes/Brand/Requests/Request/components/shared/ClientArticlesTable';
import ClientInfo from '@/routes/Brand/Requests/Request/components/shared/ClientInfo';
import ClientStepper from '@/routes/Brand/Requests/Request/components/shared/ClientStepper';
import IconSuccess from '@/routes/Brand/Requests/Request/components/shared/IconSuccess';
import { PickupPointInfo } from '@/routes/Brand/Requests/Request/components/shared/PickupPointInfo/PickupPointInfo';
import { useCurrentOrganization } from '@/services/auth';
import { useBlobUrl } from '@/utils/useBlobUrl';

const Transit = ({ request }: { request: ClientRequestWithRelations }) => {
  const activeArticlesInTransit = request.articles.filter(
    (article) => article.step?.step === 'transit' && !article.cancelledAt
  );
  const isPendingTransit = activeArticlesInTransit.some(
    (article) => article.atClient || !!article.atStoreId
  );

  return (
    <BrandWrapper>
      <BrandWrapperCenter>
        <Stack gap="1rem">
          <ClientStepper request={request} />
          {isPendingTransit && (
            <PendingTransitHeader request={request} articlesInTransit={activeArticlesInTransit} />
          )}
          {!isPendingTransit && <InTransitHeader articlesInTransit={activeArticlesInTransit} />}
          <PickupPointInfo request={request} />
          <ClientArticlesTable request={request} showPrice showArticleComment />
          <ClientInfo request={request} />
        </Stack>
      </BrandWrapperCenter>
    </BrandWrapper>
  );
};

const PendingTransitHeader = ({
  request,
  articlesInTransit,
}: {
  request: ClientRequestWithRelations;
  articlesInTransit: ClientArticleWithRelations[];
}) => {
  const [organization] = useCurrentOrganization();
  const afterSalesServiceEmail = organization?.config.afterSalesServiceEmail;
  const ref = useRef<HTMLButtonElement>(null);
  const { data: { shipments } = { shipments: [] }, isLoading: isLoadingShipment } = useShipments({
    requestId: request.id,
  });
  const [showError, setShowError] = useState(false);
  const {
    mutateAsync: shipShipment,
    isPending: isPendingShipShipment,
    isSuccess: isSuccessShipShipment,
    reset,
  } = useShipShipment();

  const shipment = shipments[0];
  const shipmentCarrier = SHIPMENT_CARRIERS.find(
    (carrier) => carrier.id === shipment?.carrier
  )?.name;
  const shippingLabelUrl = useBlobUrl(shipment?.shippingLabel, 'application/pdf');

  useEffect(() => {
    // Automatically download the shipping label if needed
    if (isSuccessShipShipment && shippingLabelUrl) {
      ref.current?.click();
      reset();
    }
  }, [isSuccessShipShipment, shippingLabelUrl, ref, reset]);

  return (
    <Stack gap="1rem">
      <Stack gap="0.25rem">
        {request.store && (
          <>
            <Stack row gap="0.5rem" alignItems="center" flexWrap="nowrap">
              <div>
                <IconSuccess />
              </div>
              <p className="headline-200-bold headline-300-bold-mobile color-primary-800">
                <Trans id="client.request.transit.pending.title">
                  Your care & repair service is confirmed
                </Trans>
              </p>
            </Stack>
            <p className="paragraph-50-regular paragraph-100-regular-mobile">
              <Trans id="client.request.transit.pending.store-shipment">
                Your <Plural value={articlesInTransit.length} one="item" other="items" /> will be
                sent to the repair workshop by the {request.store.name} store.
              </Trans>
            </p>
          </>
        )}
        {!request.store && (
          <Stack row gap="0.5rem" flexWrap="nowrap">
            <div>
              <IconPackage className="headline-200-medium color-tertiary-700" />
            </div>
            <p className="paragraph-50-regular paragraph-100-regular-mobile">
              {shipment?.handover === 'dropoff' && (
                <Trans id="client.request.transit.pending.shipment-options.title.dropoff">
                  Please drop off your package at the nearest{' '}
                  <span className="paragraph-50-medium paragraph-100-medium-mobile">
                    {shipmentCarrier}
                  </span>{' '}
                  pickup point.
                </Trans>
              )}
              {shipment?.handover === 'pickup' && (
                <Trans id="client.request.transit.pending.shipment-options.title.pickup">
                  <span className="paragraph-50-medium paragraph-100-medium-mobile">
                    {shipmentCarrier}
                  </span>{' '}
                  will pick up your package at home.
                </Trans>
              )}
              {(!shipment || !shipment.handover) && (
                <Trans id="client.request.transit.pending.shipment-options.title">
                  Please send your package
                </Trans>
              )}
            </p>
          </Stack>
        )}
      </Stack>
      {!request.store && (
        <Button
          ref={ref}
          variant="secondary-brand"
          size="large"
          disabled={!shipment || isLoadingShipment || isPendingShipShipment}
          href={shippingLabelUrl ?? undefined}
          download={`${shipment?.reference}.pdf`}
          onPress={() => {
            if (!shippingLabelUrl) {
              setShowError(false);
              shipShipment({ id: shipment.id }).catch(() => {
                setShowError(true);
              });
            }
          }}
        >
          <IconPrint />
          <Trans id="client.request.transit.pending.shipment-option.client.action">
            Print shipping label
          </Trans>
        </Button>
      )}
      {showError && (
        <span className="text-danger paragraph-100-regular">
          {afterSalesServiceEmail ? (
            <Trans id="client.request.transit.error.support-email">
              An error occurred, please contact support at{' '}
              <Link
                className="color-tertiary-700"
                href={`mailto:${afterSalesServiceEmail}`}
                target="_blank"
              >
                {afterSalesServiceEmail}
              </Link>
            </Trans>
          ) : (
            <Trans id="client.request.transit.error.support">
              An error occurred, please contact support
            </Trans>
          )}
        </span>
      )}
    </Stack>
  );
};

const InTransitHeader = ({
  articlesInTransit,
}: {
  articlesInTransit: ClientArticleWithRelations[];
}) => {
  return (
    <>
      <Stack row gap="0.5rem">
        <IconTruck className="color-tertiary-700" style={{ fontSize: '24px' }} />
        <p className="paragraph-50-regular paragraph-100-regular-mobile">
          <Trans id="client.request.transit.in-transit.store-shipment">
            Your{' '}
            <Plural
              value={articlesInTransit.length}
              one="item is on its"
              other="items are on their"
            />{' '}
            way to the workshop to be taken care of.
          </Trans>
        </p>
      </Stack>
    </>
  );
};

export default Transit;

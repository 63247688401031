import { Trans } from '@lingui/react/macro';

import { ArticleWithRelations } from '@/models/request';

import { BaseMessage } from './BaseMessage';

export const AnalysisStepMessage = ({ article }: { article: ArticleWithRelations }) => {
  if (
    article.requalificationRefusedAt ||
    article.requalificationValidatedAt ||
    article.analyzedAt
  ) {
    return;
  }

  if (article.requalifiedAt) {
    return (
      <BaseMessage
        title={
          <Trans id="article.analysis.requalification.waiting-message.title">
            Waiting for requalification validation
          </Trans>
        }
        message={
          <p>
            <Trans id="article.analysis.requalification.waiting-message.text">
              The requalification proposal is pending analysis.
            </Trans>
          </p>
        }
        article={article}
      />
    );
  }

  return (
    <BaseMessage
      title={
        <Trans id="article.analysis.waiting-message.title">The item is undergoing analysis</Trans>
      }
      message={
        <p>
          <Trans id="article.analysis.waiting-message.text">
            This item is being analysed by the workshop to see if the required actions correspond to
            its needs.
          </Trans>
        </p>
      }
      article={article}
    />
  );
};

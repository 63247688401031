import { useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import IconTrash from '@/icons/Trash.svg';
import { useDeleteUserRole } from '@/models/userRole';

import { UserWithRoles } from './UserDialog';

export const RemoveUser = ({ user }: { user: UserWithRoles }) => {
  const { t } = useLingui();

  const { mutateAsync: deleteInvitation, isPending: isDeletingInvitation } = useDeleteUserRole();

  const label = user.name
    ? t({
        id: 'settings.users.remove-user.tooltip',
        message: `Remove ${user.name} from your organization`,
      })
    : t({
        id: 'settings.users.invited.uninvite.tooltip',
        message: `Uninvite ${user.email}`,
      });
  const confirmLabel = user.name
    ? t({
        id: 'settings.users.remove-user.confirm-dialog',
        message: `Are you sure you want to remove ${user.name} from your organization?`,
      })
    : t({
        id: 'settings.users.invited.uninvite.confirm-dialog',
        message: `Are you sure you want to delete the invitation for ${user.email}?`,
      });
  return (
    <Button
      variant="secondary"
      iconOnly
      size="small"
      ariaLabel={label}
      tooltip={<p className="sentry-mask">{label}</p>}
      disabled={isDeletingInvitation}
      onPress={() => {
        if (confirm(confirmLabel)) {
          deleteInvitation(user.id ? { userId: user.id } : { email: user.email }).catch(
            console.error
          );
        }
      }}
    >
      <IconTrash />
    </Button>
  );
};

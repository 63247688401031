import { createContext, useContext, useState } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';
import cn from 'classnames';

import { BaseMoneyCell } from '@/components/ArticlesTableCells';
import { DefectPhotos } from '@/components/DefectPhotos';
import Badge from '@/design_system/Badge';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import {
  Body,
  Cell,
  Column,
  Footer,
  Header,
  InputMoneyCell,
  Row,
  Table,
  TextAreaCell,
} from '@/design_system/Table/Table';
import IconAdd from '@/icons/Add.svg';
import IconAfterSales from '@/icons/AfterSales.svg';
import IconBandage from '@/icons/Bandage.svg';
import IconPay from '@/icons/Pay.svg';
import {
  ArticleAction,
  computeDuplicateActionNumber,
  getCustomPriceAmount,
  getPackActionTypeOrganizationWithRefashionStatus,
  useActionName,
} from '@/models/actionType';
import { TEMPORARY_ID_PREFIX, useUpdateAction } from '@/models/article';
import { computeDuplicateDefectNumber, useGetDefectName } from '@/models/defectType';
import { type ArticleActionWithRelations, type ArticleWithRelations } from '@/models/request';
import { useWorkshops } from '@/models/workshop';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { RequestContextData } from '@/routes/Requests/contexts/interfaces';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';
import { useCurrentSession } from '@/services/auth';
import { areSimilar } from '@/utils/array';
import { createBEMClasses } from '@/utils/classname';
import { Currency, formatCurrency } from '@/utils/number';
import useViewPort from '@/utils/useViewport';

import { PriceWithDetailsTooltip } from '../PriceWithDetails/PriceWithDetails';

import ActionName from './ActionName';
import { ActionTypeSearchSelect } from './ActionTypeSearchSelect';
import { BrandResponsibilityCell, DeleteButton, DeleteCell, EditButton } from './Cells';
import { CreateActionDialogs } from './CreateActionDialogs';
import PackActionName from './PackActionName';

import './ActionsTable.css';

const { block, element } = createBEMClasses('actions-table');

interface ActionsTableContextData {
  actions: ArticleActionWithRelations[];
  previousActions: ArticleActionWithRelations[];
  showWarrantyCover?: boolean;
  isRequalification?: boolean;
  customActionWorkshopPriceCurrency: Currency;
  customActionOrganizationPriceCurrency: Currency;
}

export const ActionsTableContext = createContext({} as ActionsTableContextData);

export const ActionsTable = () => {
  const { t } = useLingui();
  const { currentSession } = useCurrentSession();
  const { isMobile } = useViewPort();

  const { request, view: requestView } = useRequestContext();
  const { article, view: articleView } = useArticleContext();

  const actions = article.snapshot.articleActions;
  const previousActions = article.previousSnapshot?.articleActions ?? [];

  const addedActions = actions.filter(
    (action) => !previousActions.some((prevAction) => prevAction.stableId === action.stableId)
  );

  const [showAddActionOptions, setShowAddActionOptions] = useState(false);

  const showWarrantyCover =
    !(article.step?.step === 'creation' && !article.step?.config.requirePrice) &&
    articleView.warranty.shown &&
    !currentSession?.workshop?.external;

  const isRequalification = article.task?.type === 'analyze_article';

  const { data: { workshops: [internalWorkshop] } = { workshops: [] } } = useWorkshops(
    {
      internal: true,
      limit: 1,
    },
    {
      enabled: !article.workshopId,
    }
  );

  const customActionWorkshopPriceCurrency =
    article.workshop?.currency ?? internalWorkshop?.currency ?? 'EUR';
  const customActionOrganizationPriceCurrency = request.defaultPriceCurrency ?? 'EUR';

  const contextValue = {
    actions,
    previousActions,
    showWarrantyCover,
    isRequalification,
    customActionWorkshopPriceCurrency,
    customActionOrganizationPriceCurrency,
  } satisfies ActionsTableContextData;

  const columnWidths = {
    actionName: 'minmax(250px, 1fr)',
    defects: articleView.services.defects.shown && 'minmax(250px, 1.5fr)',
    defectPhotos: !articleView.services.defects.shown && 'minmax(250px, 1fr)',
    warrantyCover: showWarrantyCover && '120px',
    cost: requestView.cost.enabled && '120px',
    price: requestView.price.enabled && '120px',
    deleteColumn: articleView.services.actions.canAddRemoveAction && 'auto',
  };

  return (
    <ActionsTableContext value={contextValue}>
      <Stack gap="0.25rem" className={block()}>
        {!articleView.services.choice.shown && (
          <p className="label-100 text-primary">
            {<Trans id="article.form.actions.label">Care & repair actions</Trans>}
          </p>
        )}

        {isMobile ? (
          <Stack gap="0.5rem">
            {[...previousActions, ...actions].length === 0 ? (
              <span className="paragraph-100-regular text-secondary">
                <Trans id="article.form.actions.table.no-actions-added">No actions added yet</Trans>
              </span>
            ) : (
              <>
                {previousActions.map((previousAction) => {
                  const action = actions.find(
                    (action) => action.stableId === previousAction.stableId
                  );

                  return (
                    <ActionCard
                      key={previousAction.id}
                      action={action}
                      previousAction={previousAction}
                      duplicateActionNumber={
                        action ? computeDuplicateActionNumber(action, actions) : undefined
                      }
                      isDeleted={!action}
                    />
                  );
                })}
                {addedActions.map((action) => (
                  <ActionCard
                    key={action.id}
                    action={action}
                    duplicateActionNumber={computeDuplicateActionNumber(action, actions)}
                    isNew={previousActions.length !== 0}
                  />
                ))}
              </>
            )}
            {articleView.services.actions.canAddRemoveAction && (
              <>
                <AddActionButton setShowAddActionsOptions={setShowAddActionOptions} />
                <CreateActionDialogs
                  isOpen={showAddActionOptions}
                  setIsOpen={setShowAddActionOptions}
                />
              </>
            )}
          </Stack>
        ) : (
          <Table
            ariaLabel={t({
              id: 'article.form.actions.table',
              message: 'Actions',
            })}
            columnWidths={[
              columnWidths.actionName,
              columnWidths.defects,
              columnWidths.defectPhotos,
              columnWidths.warrantyCover,
              columnWidths.cost,
              columnWidths.price,
              columnWidths.deleteColumn,
            ]}
            bordered
            variant="grid"
          >
            <Header>
              <Row>
                {[...previousActions, ...actions].length === 0 ? (
                  <Cell isWholeRow>
                    <span className="paragraph-200-regular">
                      <Trans id="article.form.actions.table.no-actions-added">
                        No actions added yet
                      </Trans>
                    </span>
                  </Cell>
                ) : (
                  <>
                    <Column>
                      <Trans id="article.form.actions.table.column.name.title.action">Action</Trans>
                    </Column>

                    {articleView.services.defects.shown && (
                      <Column>
                        <Trans id="article.form.actions.table.column.defects.title">Defects</Trans>
                      </Column>
                    )}

                    {!articleView.services.defects.shown && (
                      <Column>
                        <Trans id="article.form.actions.table.column.defect-photos.title">
                          Defect photos
                        </Trans>
                      </Column>
                    )}

                    {showWarrantyCover && (
                      <Column justifyContent="center">
                        <Trans id="article.form.actions.table.column.warranty-cover.title">
                          Warranty cover?
                        </Trans>
                      </Column>
                    )}
                    {requestView.cost.enabled && (
                      <Column justifyContent="end">{requestView.cost.label}</Column>
                    )}
                    {requestView.price.enabled && (
                      <Column justifyContent="end">{requestView.price.label}</Column>
                    )}
                    {articleView.services.actions.canAddRemoveAction && (
                      <Column justifyContent="flex-end" />
                    )}
                  </>
                )}
              </Row>
            </Header>

            <Body>
              {previousActions.map((previousAction) => {
                const action = actions.find(
                  (action) => action.stableId === previousAction.stableId
                );

                return (
                  <ActionRow
                    key={previousAction.id}
                    action={action}
                    previousAction={previousAction}
                    duplicateActionNumber={
                      action ? computeDuplicateActionNumber(action, actions) : undefined
                    }
                    isDeleted={!action}
                  />
                );
              })}
              {addedActions.map((action) => (
                <ActionRow
                  key={action.id}
                  action={action}
                  duplicateActionNumber={computeDuplicateActionNumber(action, actions)}
                  isNew={previousActions.length !== 0}
                />
              ))}
            </Body>
            {articleView.services.actions.canAddRemoveAction && (
              <Footer>
                <Row>
                  <Cell isWholeRow>
                    {!showAddActionOptions ? (
                      <AddActionButton setShowAddActionsOptions={setShowAddActionOptions} />
                    ) : (
                      <ActionTypeSearchSelect onBlur={() => setShowAddActionOptions(false)} />
                    )}
                  </Cell>
                </Row>
              </Footer>
            )}
          </Table>
        )}

        <ActionsTableErrors />
      </Stack>
    </ActionsTableContext>
  );
};

const ActionCard = ({
  action,
  previousAction,
  duplicateActionNumber,
  isNew,
  isDeleted,
}: {
  action?: ArticleActionWithRelations;
  previousAction?: ArticleActionWithRelations;
  duplicateActionNumber?: number;
  isNew?: boolean;
  isDeleted?: boolean;
}) => {
  const { view: requestView } = useRequestContext();
  const { view: articleView, errors } = useArticleContext();
  const { showWarrantyCover } = useContext(ActionsTableContext);

  const actionOrPreviousAction = action ?? previousAction!;

  const actionName = useActionName({ action: actionOrPreviousAction });

  if (!action && !previousAction) {
    return null;
  }

  const hasError = !!errors.services?.actions?.actions?.find((act) => act.id === action?.id)
    ?.hasError;

  const showEditButton = action && articleView.services.actions.editable;
  const showDeleteButton = action && articleView.services.actions.canAddRemoveAction;

  return (
    <Box
      padding="0"
      ariaLabel={actionName}
      style={{
        // eslint-disable-next-line lingui/no-unlocalized-strings
        border: hasError ? '1px solid var(--color-danger-700)' : undefined,
      }}
      className={element('card', {
        new: isNew,
        deleted: isDeleted,
      })}
    >
      <Stack padding="16px" gap="0.75rem" style={{ borderRadius: '8px' }}>
        {isDeleted && (
          <span className="visually-hidden">
            <Trans id="article.form.actions.deleted-action">Deleted action</Trans>
          </span>
        )}

        <CardActionName
          action={action}
          previousAction={previousAction}
          duplicateActionNumber={duplicateActionNumber}
        />

        <hr />

        <Stack gap="0.5rem">
          {!articleView.services.defects.shown && (
            <CardDefectPhotos action={action} previousAction={previousAction} />
          )}

          <Stack gap="0.5rem" row>
            {articleView.services.defects.shown && (
              <CardDefects action={action} previousAction={previousAction} />
            )}

            {showWarrantyCover && (
              <CardBrandResponsibility action={action} previousAction={previousAction} />
            )}

            {requestView.cost.enabled && (
              <CardPrice type="cost" action={action} previousAction={previousAction} />
            )}

            {requestView.price.enabled && (
              <CardPrice type="price" action={action} previousAction={previousAction} />
            )}
          </Stack>
        </Stack>

        {(showEditButton || showDeleteButton) && (
          <>
            <Stack gap="0.5rem">
              {showEditButton && <EditButton action={action} />}
              {showDeleteButton && <DeleteButton action={action} />}
            </Stack>
          </>
        )}
      </Stack>
    </Box>
  );
};

const AddActionButton = ({
  setShowAddActionsOptions,
}: {
  setShowAddActionsOptions: (show: boolean) => void;
}) => {
  return (
    <Button
      size="medium"
      variant="secondary"
      // eslint-disable-next-line lingui/no-unlocalized-strings
      style={{ flex: '0 auto' }}
      onPress={() => setShowAddActionsOptions(true)}
    >
      <IconAdd />
      <Trans id="actions-table.column.footer.add-action">Add an action</Trans>
    </Button>
  );
};

const CardDefects = ({
  action,
  previousAction,
}: {
  action?: ArticleActionWithRelations;
  previousAction?: ArticleActionWithRelations;
}) => {
  const { article } = useArticleContext();
  const isEdited =
    action &&
    previousAction &&
    !areSimilar(action.articleDefects, previousAction.articleDefects, 'stableId');

  const getDefectName = useGetDefectName();

  const actionDefects = [
    ...article.snapshot.articleDefects,
    ...(article.previousSnapshot?.articleDefects ?? []),
  ].filter((defect) =>
    (action ?? previousAction!).articleDefects.find((actionDefect) => actionDefect.id === defect.id)
  );

  return actionDefects.map((defect) => {
    const duplicateDefectNumber = computeDuplicateDefectNumber(
      defect,
      article.snapshot.articleDefects
    );

    return (
      <Badge
        key={defect.id}
        hasBorder
        color="neutral"
        borderRadius="soft"
        size="large"
        className={element('card-badge', {
          edited: isEdited,
        })}
      >
        <Stack row alignItems="center" gap="4px" flexWrap="nowrap">
          <IconBandage className="text-secondary" style={{ fontSize: '1rem' }} />
          <span className="text-ellipsis">
            {getDefectName(defect)}
            {duplicateDefectNumber ? ` #${duplicateDefectNumber}` : ''}
          </span>
        </Stack>
      </Badge>
    );
  });
};

const CardBrandResponsibility = ({
  action,
  previousAction,
}: {
  action?: ArticleActionWithRelations;
  previousAction?: ArticleActionWithRelations;
}) => {
  const isEdited =
    action && previousAction && action.brandResponsibility !== previousAction.brandResponsibility;

  return (
    <Badge
      hasBorder
      color="neutral"
      borderRadius="soft"
      size="large"
      className={element('card-badge', { edited: isEdited })}
    >
      <Stack row alignItems="center" gap="4px">
        <IconAfterSales className="text-secondary" style={{ fontSize: '1rem' }} />
        <span className="paragraph-200-regular">
          {(action ?? previousAction!).brandResponsibility ? (
            <Trans id="article.form.actions.card.brand-res.yes">Brand resp: Yes</Trans>
          ) : (
            <Trans id="article.form.actions.card.brand-res.no">Brand resp: No</Trans>
          )}
        </span>
      </Stack>
    </Badge>
  );
};

const ActionRow = ({
  action,
  previousAction,
  duplicateActionNumber,
  isNew,
  isDeleted,
}: {
  action?: ArticleActionWithRelations;
  previousAction?: ArticleActionWithRelations;
  duplicateActionNumber?: number;
  isNew?: boolean;
  isDeleted?: boolean;
}) => {
  const { view: requestView } = useRequestContext();
  const { view: articleView } = useArticleContext();
  const { showWarrantyCover } = useContext(ActionsTableContext);

  const actionOrPreviousAction = action ?? previousAction!;

  return (
    <Row
      className={element('row', {
        new: isNew,
        deleted: isDeleted,
      })}
      ariaDisabled={isDeleted}
    >
      <ActionNameCell
        action={action}
        previousAction={previousAction}
        duplicateActionNumber={duplicateActionNumber}
        isDeleted={isDeleted}
      />

      {articleView.services.defects.shown && (
        <DefectsCell action={action} previousAction={previousAction} />
      )}

      {!articleView.services.defects.shown && (
        <DefectPhotosCell action={action} previousAction={previousAction} />
      )}

      {showWarrantyCover && (
        <Cell
          justifyContent="center"
          className={element('cell', {
            edited:
              action &&
              previousAction &&
              action.brandResponsibility !== previousAction.brandResponsibility,
          })}
        >
          <BrandResponsibilityCell
            action={actionOrPreviousAction}
            isDisabled={!action || !articleView.services.actions.editable}
          />
        </Cell>
      )}

      {requestView.cost.enabled && (
        <PriceCell type="cost" action={action} previousAction={previousAction} />
      )}

      {requestView.price.enabled && (
        <PriceCell type="price" action={action} previousAction={previousAction} />
      )}

      {articleView.services.actions.canAddRemoveAction && (
        <Cell justifyContent="flex-end" style={{ gap: '0.5rem' }}>
          {action && <DeleteCell action={action} />}
        </Cell>
      )}
    </Row>
  );
};

const DefectsCell = ({
  action,
  previousAction,
}: {
  action?: ArticleActionWithRelations;
  previousAction?: ArticleActionWithRelations;
}) => {
  const { view: articleView } = useArticleContext();

  const isEdited =
    action &&
    previousAction &&
    !areSimilar(action.articleDefects, previousAction.articleDefects, 'stableId');

  return (
    <Cell
      isEditable
      className={element('cell', {
        edited: !!isEdited,
      })}
    >
      <Defects
        action={action ?? previousAction}
        isDisabled={
          !articleView.services.actions.editable ||
          !articleView.services.actions.canEditDefects ||
          !action ||
          action.id.startsWith(TEMPORARY_ID_PREFIX)
        }
      />
    </Cell>
  );
};

const Defects = ({
  action,
  isDisabled,
}: {
  action?: ArticleActionWithRelations;
  isDisabled?: boolean;
}) => {
  const { t } = useLingui();
  const { article, request } = useArticleContext();

  const { mutate: updateAction } = useUpdateAction({
    articleId: article.id,
    requestId: request.id,
  });

  const getDefectName = useGetDefectName();

  const defectOptions = isDisabled
    ? [...article.snapshot.articleDefects, ...(article.previousSnapshot?.articleDefects ?? [])]
    : article.snapshot.articleDefects.filter(
        (defect) => !defect.id.startsWith(TEMPORARY_ID_PREFIX)
      );

  const actionDefectIds = action?.articleDefects.map((defect) => defect.id);

  const addDefectsLabel = t({
    id: 'article.form.actions.defects.add-defects.label',
    message: 'Add defects',
  });
  const noDefectsLabel = t({
    id: 'article.form.actions.defects.no-defects.label',
    message: 'No defects associated',
  });
  return (
    <InputSelect
      className={element('defects-select')}
      variant="select"
      isMulti
      aria-label={!isDisabled ? addDefectsLabel : undefined}
      placeholder={!isDisabled ? addDefectsLabel : noDefectsLabel}
      isSearchable={false}
      isDisabled={isDisabled}
      options={defectOptions}
      value={defectOptions.filter((defectOption) => actionDefectIds?.includes(defectOption.id))}
      getOptionValue={(defectOption) => defectOption.id}
      getOptionLabel={(defectOption) => {
        const duplicateDefectNumber = computeDuplicateDefectNumber(
          defectOption,
          article.snapshot.articleDefects
        );
        return `${getDefectName(defectOption)}${duplicateDefectNumber ? ` #${duplicateDefectNumber}` : ''}`;
      }}
      onChange={(defectOptions) => {
        if (!action) {
          return;
        }

        updateAction({
          actionId: action.id,
          body: {
            defectIds: defectOptions.map((defectOption) => defectOption.id),
          },
        });
      }}
      onRemoveValue={(defectOptionId) => {
        if (!action) {
          return;
        }

        updateAction({
          actionId: action.id,
          body: {
            defectIds: actionDefectIds?.filter(
              (actionDefectId) => defectOptionId !== actionDefectId
            ),
          },
        });
      }}
    />
  );
};

const ActionNameCell = ({
  action,
  previousAction,
  duplicateActionNumber,
  isDeleted,
}: {
  action?: ArticleActionWithRelations;
  previousAction?: ArticleActionWithRelations;
  duplicateActionNumber?: number;
  isDeleted?: boolean;
}) => {
  const { currentSession } = useCurrentSession();
  const { t } = useLingui();
  const { request, article, view, errors } = useArticleContext();

  const actionOrPreviousAction = action ?? previousAction!;

  const refashionStatus = currentSession?.workshop
    ? undefined
    : currentSession
      ? actionOrPreviousAction.priceRefashionStatus
      : actionOrPreviousAction.costRefashionStatus;

  const packActionType = currentSession?.workshop
    ? actionOrPreviousAction.packActionTypeOrganization
    : currentSession
      ? getPackActionTypeOrganizationWithRefashionStatus(
          actionOrPreviousAction,
          actionOrPreviousAction.price
        )
      : getPackActionTypeOrganizationWithRefashionStatus(
          actionOrPreviousAction,
          actionOrPreviousAction.cost
        );

  const { mutateAsync: updateAction } = useUpdateAction({
    articleId: article.id,
    requestId: request.id,
  });

  const [customDescription, setCustomDescription] = useState(
    actionOrPreviousAction.customDescription ?? ''
  );
  const trimmedCustomDescription = customDescription.trim();

  return (
    <TextAreaCell
      cellProps={{
        isReadonly:
          !action ||
          !action.isCustom ||
          !view.services.actions.editable ||
          action.id.startsWith(TEMPORARY_ID_PREFIX),
        isInvalid: errors.services?.actions?.actions?.find((act) => act.id === action?.id)
          ?.missingDescription,
        className: element('cell', {
          edited:
            action &&
            previousAction &&
            action.customDescription !== previousAction.customDescription,
        }),
      }}
      ariaLabel={t({
        id: 'article.form.actions.custom-description',
        message: 'Description of custom action',
      })}
      onChange={(event) => setCustomDescription(event.target.value)}
      value={customDescription}
      onBlur={() => {
        if (!action) {
          return;
        }

        const currentDescription = action.customDescription;
        updateAction({
          actionId: action.id,
          body: {
            description: trimmedCustomDescription,
          },
        }).catch(() => setCustomDescription(currentDescription ?? ''));
      }}
    >
      {isDeleted && (
        <span className="visually-hidden">
          <Trans id="article.form.actions.deleted-action">Deleted action</Trans>
        </span>
      )}
      {!!actionOrPreviousAction.actionTypeOrganization && (
        <ActionName
          actionType={actionOrPreviousAction.actionTypeOrganization.actionType}
          refashionStatus={refashionStatus}
          duplicateActionNumber={duplicateActionNumber}
        />
      )}
      {!!packActionType && (
        <PackActionName
          packActionType={packActionType}
          duplicateActionNumber={duplicateActionNumber}
        />
      )}
      {actionOrPreviousAction.isCustom &&
        (trimmedCustomDescription ? (
          <span>
            {trimmedCustomDescription} {!!duplicateActionNumber && ` #${duplicateActionNumber}`}
          </span>
        ) : (
          <span className="text-disabled">
            <Trans id="defects-table.column.defect.custom-placeholder">
              Write a description...
            </Trans>
          </span>
        ))}
    </TextAreaCell>
  );
};

const CardActionName = ({
  action,
  previousAction,
  duplicateActionNumber,
}: {
  action?: ArticleActionWithRelations;
  previousAction?: ArticleActionWithRelations;
  duplicateActionNumber?: number;
}) => {
  const { isWorkshop, currentSession } = useCurrentSession();
  const { errors } = useArticleContext();

  const actionOrPreviousAction = action ?? previousAction!;

  const refashionStatus = isWorkshop
    ? undefined
    : currentSession
      ? actionOrPreviousAction.priceRefashionStatus
      : actionOrPreviousAction.costRefashionStatus;

  const packActionType = isWorkshop
    ? actionOrPreviousAction.packActionTypeOrganization
    : currentSession
      ? getPackActionTypeOrganizationWithRefashionStatus(
          actionOrPreviousAction,
          actionOrPreviousAction.price
        )
      : getPackActionTypeOrganizationWithRefashionStatus(
          actionOrPreviousAction,
          actionOrPreviousAction.cost
        );

  const trimmedCustomDescription = actionOrPreviousAction.customDescription?.trim();

  const isMissingDescription = !!errors.services?.actions?.actions?.find(
    (act) => act.id === action?.id
  )?.missingDescription;

  return (
    <div
      className={element('card-name', {
        edited:
          action?.isCustom &&
          previousAction?.isCustom &&
          action.customDescription !== previousAction.customDescription,
      })}
    >
      {!!actionOrPreviousAction.actionTypeOrganization && (
        <ActionName
          actionType={actionOrPreviousAction.actionTypeOrganization.actionType}
          refashionStatus={refashionStatus}
          duplicateActionNumber={duplicateActionNumber}
        />
      )}

      {!!packActionType && (
        <PackActionName
          packActionType={packActionType}
          duplicateActionNumber={duplicateActionNumber}
        />
      )}

      {actionOrPreviousAction.isCustom &&
        (trimmedCustomDescription ? (
          <p className="paragraph-100-regular">
            {trimmedCustomDescription}
            {!!duplicateActionNumber && ` #${duplicateActionNumber}`}
          </p>
        ) : (
          <p
            className="paragraph-100-regular"
            style={isMissingDescription ? { color: 'var(--color-danger-700)' } : undefined}
          >
            -
          </p>
        ))}
    </div>
  );
};

const DefectPhotosCell = ({
  action,
  previousAction,
}: {
  action?: ArticleActionWithRelations;
  previousAction?: ArticleActionWithRelations;
}) => {
  const { errors, view: articleView } = useArticleContext();
  const { isRequalification } = useContext(ActionsTableContext);

  const isEdited =
    action && previousAction && !areSimilar(action.media, previousAction.media, 'id');

  return (
    <Cell
      stretch
      className={element('cell', {
        edited: !!isEdited,
      })}
    >
      <DefectPhotos
        action={action ?? previousAction}
        size="small"
        isDisabled={!action || !articleView.services.actions.editable}
        isInvalid={
          action &&
          errors.services?.actions?.actions?.find((act) => act.id === action.id)?.missingPhotos
        }
        isRequalification={isRequalification}
      />
    </Cell>
  );
};

const CardDefectPhotos = ({
  action,
  previousAction,
}: {
  action?: ArticleActionWithRelations;
  previousAction?: ArticleActionWithRelations;
}) => {
  const { errors } = useArticleContext();
  const { isRequalification } = useContext(ActionsTableContext);

  const isEdited =
    action && previousAction && !areSimilar(action.media, previousAction.media, 'id');

  return (
    <div
      className={element('card-photos', {
        edited: isEdited,
      })}
    >
      <DefectPhotos
        action={action ?? previousAction}
        size="medium"
        isDisabled
        isInvalid={
          action &&
          errors.services?.actions?.actions?.find((act) => act.id === action?.id)?.missingPhotos
        }
        isRequalification={isRequalification}
      />
    </div>
  );
};

const CardPrice = ({
  type,
  action,
  previousAction,
}: {
  type: 'cost' | 'price';
  action?: ArticleAction;
  previousAction?: ArticleAction;
}) => {
  const { article, errors } = useArticleContext();
  const { view: requestView } = useRequestContext();
  const { isWorkshop } = useCurrentSession();

  const actionOrPreviousAction = action ?? previousAction!;

  const { price, customFieldAmount, isEdited, showAmountBeforeTaxes } = computePriceFromAction({
    action,
    previousAction,
    type,
    article,
    requestView,
    isWorkshop,
  });

  const isInvalid =
    type === 'cost'
      ? errors.services?.actions?.actions?.find((act) => act.id === actionOrPreviousAction.id)
          ?.missingCost
      : errors.services?.actions?.actions?.find((act) => act.id === actionOrPreviousAction.id)
          ?.missingPrice;

  return (
    <Badge
      hasBorder
      color="neutral"
      borderRadius="soft"
      size="large"
      className={element('card-badge', {
        edited: isEdited,
        error: isInvalid,
      })}
    >
      <Stack row alignItems="center" gap="4px">
        <IconPay
          payIn={type === 'price'}
          payOut={type === 'cost'}
          className={cn({
            'text-secondary': !isInvalid,
            'text-danger': isInvalid,
          })}
          style={{ fontSize: '1rem' }}
        />
        <span
          className={cn('paragraph-200-regular', {
            'text-primary': !isInvalid,
            'text-danger': isInvalid,
          })}
        >
          {type === 'price' && <Trans id="article.form.actions.card.price.label">Price:</Trans>}
          {type === 'cost' && <Trans id="article.form.actions.card.cost.label">Cost:</Trans>}{' '}
          {actionOrPreviousAction.isCustom && !price && customFieldAmount !== undefined ? (
            <span className="paragraph-200-regular text-disabled">
              {formatCurrency(customFieldAmount, 'EUR')}
            </span>
          ) : price ? (
            <PriceWithDetailsTooltip
              price={price}
              showAmountBeforeTaxes={showAmountBeforeTaxes}
              size="x-small"
            />
          ) : (
            '-'
          )}
        </span>
      </Stack>
    </Badge>
  );
};

const PriceCell = ({
  type,
  action,
  previousAction,
}: {
  type: 'cost' | 'price';
  action?: ArticleAction;
  previousAction?: ArticleAction;
}) => {
  const { isWorkshop } = useCurrentSession();
  const { view: requestView } = useRequestContext();
  const { article, view: articleView, errors } = useArticleContext();

  const { mutate: updateAction } = useUpdateAction({
    articleId: article.id,
    requestId: article.requestId,
  });

  const actionOrPreviousAction = action ?? previousAction!;

  const { price, customFieldAmount, isEdited, customField, showAmountBeforeTaxes } =
    computePriceFromAction({
      action,
      previousAction,
      type,
      article,
      requestView,
      isWorkshop,
    });

  const canEdit =
    (type === 'cost'
      ? articleView.services.actions.canEditCost
      : articleView.services.actions.canEditPrice) &&
    action &&
    !action.id.startsWith(TEMPORARY_ID_PREFIX) &&
    action.isCustom;

  if (!canEdit || !action) {
    return (
      <Cell
        justifyContent="flex-end"
        className={element('cell', {
          edited: isEdited,
        })}
      >
        <BaseMoneyCell price={price} showAmountBeforeTaxes={showAmountBeforeTaxes} />
      </Cell>
    );
  }

  const isInvalid =
    type === 'cost'
      ? errors.services?.actions?.actions?.find((act) => act.id === action?.id)?.missingCost
      : errors.services?.actions?.actions?.find((act) => act.id === action?.id)?.missingPrice;

  return (
    <InputMoneyCell
      ariaLabel={requestView[type].label}
      style={{
        textAlign: 'right',
      }}
      currency={price?.currency ?? 'EUR'}
      defaultValue={customFieldAmount}
      cellProps={{
        justifyContent: 'right',
        isInvalid,
        className: element('cell', {
          edited: isEdited,
        }),
      }}
      onChange={(value) => {
        // The reason we are not using a state:
        // onBlur is called just after onChange, but before the state is updated
        // Therefore, we do not have the most recent value in onBlur
        action[customField] = isNaN(value)
          ? null
          : showAmountBeforeTaxes
            ? { amountBeforeTaxes: value }
            : { amount: value };
      }}
      onBlur={() => {
        if (!action) {
          return;
        }

        updateAction({
          actionId: action.id,
          body: {
            [customField]: action[customField],
          },
        });
      }}
    >
      {actionOrPreviousAction.isCustom && !price && customFieldAmount !== undefined ? (
        <span className="text-disabled">{formatCurrency(customFieldAmount, 'EUR')}</span>
      ) : (
        <BaseMoneyCell price={price} showAmountBeforeTaxes={showAmountBeforeTaxes} />
      )}
    </InputMoneyCell>
  );
};

const ActionsTableErrors = () => {
  const { errors } = useArticleContext();

  const tableErrors = [];

  if (errors.services?.actions?.noActions)
    tableErrors.push(
      <Trans id="article.form.actions.table.errors.no-actions">
        Please add at least one action.
      </Trans>
    );

  if (errors.services?.actions?.actions?.some((action) => action.missingDescription)) {
    tableErrors.push(
      <Trans id="article.form.actions.table.errors.missing-description">
        Please add a description for each action.
      </Trans>
    );
  }

  if (errors.services?.actions?.actions?.some((action) => action.missingPhotos)) {
    tableErrors.push(
      <Trans id="article.form.actions.table.errors.missing-photos">
        Please add at least one defect photo per action.
      </Trans>
    );
  }

  if (errors.services?.actions?.actions?.some((action) => action.missingCost)) {
    tableErrors.push(
      <Trans id="article.form.actions.table.errors.missing-cost">
        Please add the cost for each action.
      </Trans>
    );
  }

  if (errors.services?.actions?.actions?.some((action) => action.missingPrice)) {
    tableErrors.push(
      <Trans id="article.form.actions.table.errors.missing-price">
        Please add the price for each action.
      </Trans>
    );
  }

  if (tableErrors.length === 0) {
    return null;
  }

  return (
    <Stack gap="0.25rem" style={{ paddingTop: '0.25rem' }}>
      {tableErrors.map((error, index) => (
        <Message key={index} type="error">
          {error}
        </Message>
      ))}
    </Stack>
  );
};

const computePriceFromAction = ({
  action,
  previousAction,
  type,
  article,
  requestView,
  isWorkshop,
}: {
  action?: ArticleAction;
  previousAction?: ArticleAction;
  type: 'cost' | 'price';
  article: ArticleWithRelations;
  requestView: RequestContextData['view'];
  isWorkshop: boolean;
}) => {
  const price =
    type === 'cost'
      ? action?.cost?.amountPerEntity.find((amount) => amount.entityId === article.workshopId)
      : action?.price?.amountPerEntity[0];

  const previousPrice =
    type === 'cost'
      ? previousAction?.cost?.amountPerEntity.find(
          (amount) => amount.entityId === article.workshopId
        )
      : previousAction?.price?.amountPerEntity[0];

  const customField: 'customWorkshopPrice' | 'customOrganizationPrice' =
    type === 'cost'
      ? 'customWorkshopPrice'
      : isWorkshop
        ? 'customWorkshopPrice'
        : 'customOrganizationPrice';

  const showAmountBeforeTaxes =
    type === 'cost'
      ? requestView.cost.showAmountBeforeTaxes
      : requestView.price.showAmountBeforeTaxes;

  const customFieldAmount = getCustomPriceAmount({
    customPrice: action?.[customField],
    showAmountBeforeTaxes,
  });

  const previousCustomFieldAmount = getCustomPriceAmount({
    customPrice: previousAction?.[customField],
    showAmountBeforeTaxes,
  });

  const isEdited =
    action &&
    previousAction &&
    (price?.amount !== previousPrice?.amount || customFieldAmount !== previousCustomFieldAmount);

  return {
    price: price ?? previousPrice,
    customField,
    customFieldAmount: customFieldAmount ?? previousCustomFieldAmount,
    isEdited,
    showAmountBeforeTaxes,
  };
};

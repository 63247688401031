import { CSSProperties } from 'react';
import { Button as AriaButton } from 'react-aria-components';

import { createBEMClasses } from '@/utils/classname';

import './Badge.css';

const { block, element } = createBEMClasses('badge');

export interface BadgeProps {
  size?: 'x-small' | 'small' | 'large';
  color?: 'neutral' | 'primary' | 'danger' | 'secondary' | 'warning' | 'tertiary';
  hasBorder?: boolean;
  borderRadius?: 'soft' | 'full';
  iconOnly?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;
}

const Badge = ({
  size = 'small',
  color = 'neutral',
  hasBorder = false,
  borderRadius = 'full',
  iconOnly = false,
  onClick,
  children,
  className: additionalClassName,
  style,
}: BadgeProps) => {
  const className = block(
    {
      size,
      color,
      'has-border': hasBorder,
      'border-radius': borderRadius,
      'icon-only': iconOnly,
      clickable: !!onClick,
    },
    additionalClassName
  );

  if (onClick) {
    return (
      <AriaButton id="" className={className} onPress={onClick} style={style}>
        <div className={element('content')}>{children}</div>
      </AriaButton>
    );
  }

  return (
    <div className={className} style={style}>
      <div className={element('content')}>{children}</div>
    </div>
  );
};

export default Badge;

import { Trans } from '@lingui/react/macro';

import Stack from '@/design_system/Stack';
import { ArticleWithRelations } from '@/models/request';
import {
  ShipmentWithRelations,
  useArticleOngoingShipment,
  useArticleShipmentWithIssue,
  useCarrierName,
} from '@/models/shipment';
import { formatDate } from '@/utils/date';

import { BaseMessage } from './BaseMessage';
import { GoToShipment } from './GoToShipment';

export const TransitStepMessage = ({ article }: { article: ArticleWithRelations }) => {
  const { shipment, isLoading: isLoadingShipment } = useArticleOngoingShipment(article);
  const { shipment: shipmentWithIssue, isLoading: isLoadingShipmentWithIssue } =
    useArticleShipmentWithIssue(article);

  if (isLoadingShipment || isLoadingShipmentWithIssue) {
    return null;
  }

  if (
    !!article.atStoreId &&
    article.toClient &&
    article.step?.step === 'transit' &&
    article.step.config.clientPickup
  ) {
    return <ClientPickupInStore article={article} />;
  }

  if (!shipment && !shipmentWithIssue) {
    return <ShipmentInPreparation article={article} />;
  }

  if (shipment && shipment.step === 'preparation') {
    return <ShipmentInPreparation article={article} />;
  }

  if (shipment && shipment.step === 'finalisation') {
    return <ShipmentInFinalisation article={article} />;
  }

  if (shipment && shipment.step === 'handover' && shipment.status === 'pending-pickup') {
    return <ShipmentPendingPickup article={article} shipment={shipment} />;
  }

  if (shipment && shipment.step === 'handover' && shipment.status === 'pending-dropoff') {
    return <ShipmentPendingDropoff article={article} shipment={shipment} />;
  }

  if (shipment && shipment.step === 'in-transit') {
    return <ShipmentInTransit article={article} />;
  }

  if (shipment && shipment.step === 'verification') {
    return <ShipmentReceived article={article} />;
  }

  if (shipmentWithIssue && shipmentWithIssue.step === 'validated' && article.hasIssue) {
    return <ShipmentIssue article={article} shipment={shipmentWithIssue} />;
  }

  return null;
};

const ClientPickupInStore = ({ article }: { article: ArticleWithRelations }) => (
  <BaseMessage
    title={
      <Trans id="article.step-message.transit.store-client.title">Waiting for client pickup</Trans>
    }
    message={
      <Trans id="article.step-message.transit.store-client.text">
        The package is at the store and the client has been notified that they can pick up the item.
      </Trans>
    }
    article={article}
  />
);

const ShipmentInPreparation = ({ article }: { article: ArticleWithRelations }) => {
  return (
    <BaseMessage
      article={article}
      title={
        <Trans id="article.step-message.transit.preparation.title">
          Waiting for package preparation
        </Trans>
      }
      message={
        <Stack gap="0.5rem" alignItems="flex-start">
          <>
            {!!article.atStoreId && !!article.toWorkshopId && (
              <p>
                <Trans id="article.step-message.transit.preparation.text.store-to-workshop">
                  The store is preparing the package for delivery to the workshop.
                </Trans>
              </p>
            )}
            {!!article.atStoreId && article.toClient && (
              <p>
                <Trans id="article.step-message.transit.preparation.text.store-to-client">
                  The store is preparing the package for delivery to the client.
                </Trans>
              </p>
            )}
            {!!article.atWorkshopId && !!article.toStoreId && (
              <p>
                <Trans id="article.step-message.transit.preparation.text.workshop-to-store">
                  The workshop is preparing the package for delivery to the store.
                </Trans>
              </p>
            )}
            {!!article.atWorkshopId && article.toClient && (
              <p>
                <Trans id="article.step-message.transit.preparation.text.workshop-to-client">
                  The workshop is preparing the package for delivery to the client.
                </Trans>
              </p>
            )}
            {!!article.atWorkshopId && !!article.toWorkshopId && (
              <p>
                <Trans id="article.step-message.transit.preparation.text.workshop-to-workshop">
                  The workshop is preparing the package for delivery to another workshop.
                </Trans>
              </p>
            )}
          </>
          <GoToShipment article={article} />
        </Stack>
      }
    />
  );
};

const ShipmentInFinalisation = ({ article }: { article: ArticleWithRelations }) => {
  return (
    <BaseMessage
      article={article}
      title={
        <Trans id="article.step-message.transit.finalisation.title">
          Waiting for package finalisation
        </Trans>
      }
      message={
        <Stack gap="0.5rem" alignItems="flex-start">
          <>
            {!!article.atStoreId && !!article.toWorkshopId && (
              <p>
                <Trans id="article.step-message.transit.finalisation.text.store-to-workshop">
                  The store is finishing the package for delivery to the workshop.
                </Trans>
              </p>
            )}
            {!!article.atStoreId && article.toClient && (
              <p>
                <Trans id="article.step-message.transit.finalisation.text.store-to-client">
                  The store is finishing the package for delivery to the client.
                </Trans>
              </p>
            )}
            {!!article.atWorkshopId && !!article.toStoreId && (
              <p>
                <Trans id="article.step-message.transit.finalisation.text.workshop-to-store">
                  The workshop is finishing the package for delivery to the store.
                </Trans>
              </p>
            )}
            {!!article.atWorkshopId && article.toClient && (
              <p>
                <Trans id="article.step-message.transit.finalisation.text.workshop-to-client">
                  The workshop is finishing the package for delivery to the client.
                </Trans>
              </p>
            )}
            {!!article.atWorkshopId &&
              !!article.toWorkshopId &&
              article.atWorkshopId !== article.toWorkshopId && (
                <p>
                  <Trans id="article.step-message.transit.finalisation.text.workshop-to-workshop">
                    The workshop is finishing the package for delivery to another workshop.
                  </Trans>
                </p>
              )}
          </>
          <GoToShipment article={article} />
        </Stack>
      }
    />
  );
};

const ShipmentPendingPickup = ({
  article,
  shipment,
}: {
  article: ArticleWithRelations;
  shipment: ShipmentWithRelations;
}) => {
  const pickupDate = shipment.pickupDateDate ? formatDate(shipment.pickupDateDate, {}) : undefined;

  return (
    <BaseMessage
      article={article}
      title={
        <Trans id="article.step-message.transit.pickup.title">Waiting for carrier pickup</Trans>
      }
      message={
        <Stack gap="0.5rem" alignItems="flex-start">
          {!!pickupDate && (
            <p>
              <Trans id="article.step-message.transit.pickup.text">
                The pickup is scheduled for {pickupDate}
              </Trans>
            </p>
          )}
          <GoToShipment article={article} />
        </Stack>
      }
    />
  );
};

const ShipmentPendingDropoff = ({
  article,
  shipment,
}: {
  article: ArticleWithRelations;
  shipment: ShipmentWithRelations;
}) => {
  const carrierName = useCarrierName(shipment);

  return (
    <BaseMessage
      article={article}
      title={
        <Trans id="article.step-message.transit.dropoff.title">Waiting for carrier drop off</Trans>
      }
      message={
        <Stack gap="0.5rem" alignItems="flex-start">
          {!!carrierName && (
            <>
              {!!article.atStoreId && (
                <p>
                  <Trans id="article.step-message.transit.dropoff.text.store">
                    The store will drop the package off in the nearest {carrierName} pickup point.
                  </Trans>
                </p>
              )}
              {!!article.atWorkshopId && (
                <p>
                  <Trans id="article.step-message.transit.dropoff.text.workshop">
                    The workshop will drop the package off in the nearest {carrierName} pickup
                    point.
                  </Trans>
                </p>
              )}
              {article.atClient && (
                <p>
                  <Trans id="article.step-message.transit.dropoff.text.client">
                    The client will drop the package off in the nearest {carrierName} pickup point.
                  </Trans>
                </p>
              )}
            </>
          )}
          <GoToShipment article={article} />
        </Stack>
      }
    />
  );
};

const ShipmentInTransit = ({ article }: { article: ArticleWithRelations }) => {
  return (
    <BaseMessage
      article={article}
      title={
        <Trans id="article.step-message.transit.transit.title">The delivery is in progress</Trans>
      }
      message={
        <Stack gap="0.5rem" alignItems="flex-start">
          <p>
            <Trans id="article.step-message.transit.transit.text">
              The carrier has taken charge of the package.
            </Trans>
          </p>
          <GoToShipment article={article} />
        </Stack>
      }
    />
  );
};

const ShipmentReceived = ({ article }: { article: ArticleWithRelations }) => {
  return (
    <BaseMessage
      article={article}
      title={
        <Trans id="article.step-message.transit.received.title">
          The delivery is undergoing verification
        </Trans>
      }
      message={
        <Stack gap="0.5rem" alignItems="flex-start">
          <>
            {!!article.toStoreId && (
              <p>
                <Trans id="article.step-message.transit.received.text.to-store">
                  The package has arrived at its destination. Its contents are currently being
                  checked by the store.
                </Trans>
              </p>
            )}
            {!!article.toWorkshopId && (
              <p>
                <Trans id="article.step-message.transit.received.text.to-workshop">
                  The package has arrived at its destination. Its contents are currently being
                  checked by the workshop.
                </Trans>
              </p>
            )}
          </>
          <GoToShipment article={article} />
        </Stack>
      }
    />
  );
};

const ShipmentIssue = ({
  article,
  shipment,
}: {
  article: ArticleWithRelations;
  shipment: ShipmentWithRelations;
}) => {
  const issue = shipment.articles.find(({ articleId }) => articleId === article.id)?.issue;

  return (
    <BaseMessage
      title={
        <Trans id="article.step-message.transit.issue.title">
          A shipment issue has been reported
        </Trans>
      }
      message={<>{!!issue && <p>&quot;{issue}&quot;</p>}</>}
      article={article}
    />
  );
};

import React, { useState } from 'react';
import { Label } from 'react-aria-components';
import { Trans, useLingui } from '@lingui/react/macro';

import { InputCountry } from '@/components/InputCountry/InputCountry';
import InputText from '@/design_system/InputText';
import Stack from '@/design_system/Stack';
import { AddressRaw } from '@/models/address';
import { createBEMClasses } from '@/utils/classname';
import { isEmailValid } from '@/utils/email';

import './AddressForm.css';

const { block, element } = createBEMClasses('address-form');

const AddressForm = ({
  label,
  contact = true,
  size = 'small',
  variant = 'default',
  value,
  onChange,
  error,
  isDisabled,
}: {
  label?: string;
  contact?: boolean;
  size?: 'small' | 'medium' | 'large';
  variant?: 'default' | 'brand';
  value: Partial<AddressRaw> | null;
  onChange?: (address: Partial<AddressRaw> | null) => void;
  error?: AddressError;
  isDisabled?: boolean;
}) => {
  const { t } = useLingui();

  const onFieldChange = (field: keyof AddressRaw) => (fieldValue: string) => {
    onChange?.({ ...value, [field]: fieldValue });
  };

  return (
    <Stack gap="1.5rem" className={block()}>
      {contact && (
        <Stack row gap="0.5rem">
          <InputText
            style={{ flex: 1 }}
            aria-label={t({ id: 'components.address-form.contact.label', message: 'Contact name' })}
            label={t({
              id: 'components.address-form.contact.label',
              message: 'Contact name',
            })}
            size={size}
            type="text"
            placeholder={t({
              id: 'components.address-form.contact.label',
              message: 'Contact name',
            })}
            value={value?.contactName ?? ''}
            onChange={onFieldChange('contactName')}
            autoComplete="name"
            isDisabled={isDisabled}
            error={
              error?.contactNameError
                ? t({
                    id: 'components.address-form.field.contact-name.error',
                    message: 'Please add a name',
                  })
                : undefined
            }
          />
          <InputText
            style={{ flex: 1 }}
            aria-label={t({
              id: 'components.address-form.contact-email.label',
              message: 'Contact email',
            })}
            label={t({
              id: 'components.address-form.contact-email.label',
              message: 'Contact email',
            })}
            size={size}
            type="text"
            placeholder={t({
              id: 'components.address-form.contact-email.label',
              message: 'Contact email',
            })}
            value={value?.contactEmail ?? ''}
            onChange={onFieldChange('contactEmail')}
            autoComplete="email"
            isDisabled={isDisabled}
            error={
              error?.contactEmailError
                ? t({
                    id: 'components.address-form.field.contact-email.error',
                    message: 'Please add a valid email',
                  })
                : undefined
            }
          />
        </Stack>
      )}
      <Stack gap="0.25rem">
        <Label className={size === 'large' ? 'paragraph-50-medium' : 'paragraph-100-medium'}>
          {label ?? <Trans id="components.address-form.label">Address</Trans>}
        </Label>
        <Stack gap="0.5rem">
          <InputText
            size={size}
            type="text"
            aria-label={t({
              id: 'components.address-form.line-1.label',
              message: 'Address line 1',
            })}
            placeholder={t({
              id: 'components.address-form.line-1.label',
              message: 'Address line 1',
            })}
            value={value?.line1 ?? ''}
            onChange={onFieldChange('line1')}
            autoComplete="address-line1"
            isDisabled={isDisabled}
            error={
              error?.line1Error
                ? t({
                    id: 'components.address-form.field.address-line1.error',
                    message: 'Please add an address',
                  })
                : undefined
            }
          />
          <InputText
            size={size}
            type="text"
            aria-label={t({
              id: 'components.address-form.line-2.label',
              message: 'Address line 2',
            })}
            placeholder={t({
              id: 'components.address-form.line-2.label',
              message: 'Address line 2',
            })}
            value={value?.line2 ?? ''}
            onChange={onFieldChange('line2')}
            autoComplete="address-line2"
            isDisabled={isDisabled}
          />
          <div className={element('zip-city-country')}>
            <InputText
              size={size}
              type="text"
              aria-label={t({ id: 'components.address-form.zip.label', message: 'Zip code' })}
              placeholder={t({ id: 'components.address-form.zip.label', message: 'Zip code' })}
              value={value?.zipCode ?? ''}
              onChange={onFieldChange('zipCode')}
              autoComplete="postal-code"
              isDisabled={isDisabled}
              error={
                error?.zipCodeError
                  ? t({
                      id: 'components.address-form.field.zip-code.error',
                      message: 'Please add a zip code',
                    })
                  : undefined
              }
            />
            <InputText
              size={size}
              type="text"
              aria-label={t({ id: 'components.address-form.city.label', message: 'City' })}
              placeholder={t({ id: 'components.address-form.city.label', message: 'City' })}
              value={value?.city ?? ''}
              onChange={onFieldChange('city')}
              autoComplete="address-level2"
              isDisabled={isDisabled}
              error={
                error?.cityError
                  ? t({
                      id: 'components.address-form.field.city.error',
                      message: 'Please add a city',
                    })
                  : undefined
              }
            />
            <InputCountry
              aria-label={t({ id: 'components.address-form.country.label', message: 'Country' })}
              placeholder={t({ id: 'components.address-form.country.label', message: 'Country' })}
              value={value?.country}
              onChange={(country) => onFieldChange('country')(country)}
              isDisabled={isDisabled}
              size={size}
              styleVariant={variant}
              error={
                error?.countryError
                  ? t({
                      id: 'components.address-form.field.country.error',
                      message: 'Please add a valid country',
                    })
                  : undefined
              }
            />
          </div>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default React.memo(AddressForm);

export const useAddressState = (initialData?: Partial<AddressRaw> | null) =>
  useState<Partial<AddressRaw> | null>(
    initialData
      ? {
          contactEmail: initialData.contactEmail,
          contactName: initialData.contactName,
          line1: initialData.line1,
          line2: initialData.line2,
          zipCode: initialData.zipCode,
          city: initialData.city,
          country: initialData.country,
        }
      : null
  );

export type AddressError = {
  line1Error: boolean;
  zipCodeError: boolean;
  cityError: boolean;
  countryError: boolean;
  contactEmailError?: boolean;
  contactNameError?: boolean;
  hasError: boolean;
};

export const getAddressError = (address: Partial<AddressRaw> | null, contact: boolean = false) => {
  const line1Error = !address?.line1?.length;
  const zipCodeError = !address?.zipCode?.length;
  const cityError = !address?.city?.length;
  const countryError = !address?.country?.length;

  const contactEmailError = !address?.contactEmail || !isEmailValid(address.contactEmail);
  const contactNameError = !address?.contactName?.length;

  return {
    line1Error,
    zipCodeError,
    cityError,
    countryError,
    ...(contact && { contactEmailError, contactNameError }),
    hasError:
      line1Error ||
      zipCodeError ||
      cityError ||
      countryError ||
      (contact && (contactEmailError || contactNameError)),
  };
};

export const isAddressValid = (
  address: Partial<AddressRaw> | null | undefined,
  contact: boolean = false
): address is AddressRaw => {
  return !getAddressError(address ?? null, contact).hasError;
};

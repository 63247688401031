import { Separator } from 'react-aria-components';
import { Trans, useLingui } from '@lingui/react/macro';
import cn from 'classnames';

import Box from '@/design_system/Box';
import Checkbox from '@/design_system/Checkbox';
import Stack from '@/design_system/Stack';
import IconEmail from '@/icons/Email.svg';
import IconInbox from '@/icons/Inbox.svg';
import {
  type NotificationCategory,
  User,
  type UserNotificationSettings,
  useUpdateUserSettings,
  useUserSettings,
} from '@/models/user';

import {
  getEmailSelectionStateByCategory,
  type SelectionState,
} from './utils/getEmailSelectionStateByCategory';
import { setNewEmailNotificationSettings } from './utils/setNewEmailNotificationSettings';

export const NotificationsTab = ({ user }: { user: User }) => {
  const { t } = useLingui();
  const { data: userSettings } = useUserSettings(user.id);

  if (!userSettings) {
    return;
  }

  return (
    <Stack>
      <Stack row alignItems="flex-start" style={{ marginBottom: '1.5rem', flexWrap: 'nowrap' }}>
        <IconInbox style={{ fontSize: '1.5rem', marginRight: '0.25rem' }} />
        <span className="paragraph-100-regular">
          <Trans id="profile.tabs.notification.inbox">
            <span className="paragraph-100-medium">Inbox</span>: You will always receive
            notifications in your Prolong inbox
          </Trans>
        </span>
      </Stack>

      <Stack row alignItems="flex-start" style={{ marginBottom: '1rem', flexWrap: 'nowrap' }}>
        <IconEmail style={{ fontSize: '1.5rem', marginRight: '0.25rem' }} />
        <span className="paragraph-100-regular">
          <Trans id="profile.tabs.notification.email">
            <span className="paragraph-100-medium">Email</span>: Set your email notifications
            preferences
          </Trans>
        </span>
      </Stack>

      <Box
        ariaLabel={t({
          id: 'profile.tabs.notification.categories',
          message: 'Notification categories',
        })}
        style={{ maxWidth: '560px' }}
      >
        <NotificationCategories
          userId={user.id}
          userNotificationSettings={userSettings.notifications}
        />
      </Box>
    </Stack>
  );
};

const NotificationCategories = ({
  userId,
  userNotificationSettings,
}: {
  userId: string;
  userNotificationSettings: UserNotificationSettings;
}) => {
  const { t } = useLingui();
  const { mutate: updateUserSettings } = useUpdateUserSettings();

  const handleUpdateNotificationSettings = (
    notificationCategory: NotificationCategory | 'all_emails',
    selectionState: SelectionState
  ) => {
    const notificationSettingsUpdated = setNewEmailNotificationSettings(
      selectionState,
      notificationCategory,
      userNotificationSettings
    );

    updateUserSettings({
      userId,
      notifications: notificationSettingsUpdated,
    });
  };

  const emailSelectionStateByCategory = getEmailSelectionStateByCategory(userNotificationSettings);

  return (
    <>
      <Stack style={{ marginTop: '-8px' }}>
        <NotificationCategoryRow
          notificationCategory={{
            id: 'all_emails',
            label: t({ id: 'profile.tabs.notification.all-emails', message: 'All emails' }),
            selectionState: emailSelectionStateByCategory.all_emails,
            userId,
          }}
          handleUpdateNotificationSettings={handleUpdateNotificationSettings}
        />
      </Stack>

      <Separator />

      <Stack style={{ padding: '16px 0' }}>
        <NotificationCategoryRow
          notificationCategory={{
            id: 'new_tasks',
            label: t({ id: 'profile.tabs.notification.new-tasks', message: 'New tasks' }),
            description: t({
              id: 'profile.tabs.notification.new-tasks.description',
              message: 'When you have a new task to handle',
            }),
            selectionState: emailSelectionStateByCategory.new_tasks,
            userId,
          }}
          handleUpdateNotificationSettings={handleUpdateNotificationSettings}
        />

        <NotificationCategoryRow
          notificationCategory={{
            id: 'task_reminders',
            label: t({ id: 'profile.tabs.notification.task-reminders', message: 'Task reminders' }),
            description: t({
              id: 'profile.tabs.notification.task-reminders.description',
              message: 'When you have a task to handle by today',
            }),
            selectionState: emailSelectionStateByCategory.task_reminders,
            userId,
          }}
          handleUpdateNotificationSettings={handleUpdateNotificationSettings}
        />

        <NotificationCategoryRow
          notificationCategory={{
            id: 'task_alerts',
            label: t({ id: 'profile.tabs.notification.task-alerts', message: 'Task alerts' }),
            description: t({
              id: 'profile.tabs.notification.task-alerts.description',
              message: 'When a task you need to handle is overdue',
            }),
            selectionState: emailSelectionStateByCategory.task_alerts,
            userId,
          }}
          handleUpdateNotificationSettings={handleUpdateNotificationSettings}
        />
      </Stack>

      <Separator />

      <Stack style={{ padding: '16px 0' }}>
        <NotificationCategoryRow
          notificationCategory={{
            id: 'new_comments',
            label: t({ id: 'profile.tabs.notification.new-comments', message: 'New comments' }),
            description: t({
              id: 'profile.tabs.notification.new-comments.description',
              message: 'When someone adds a comment on a request where you are a collaborator',
            }),
            selectionState: emailSelectionStateByCategory.new_comments,
            userId,
          }}
          handleUpdateNotificationSettings={handleUpdateNotificationSettings}
        />
      </Stack>

      <Separator />

      <Stack style={{ padding: '16px 0' }}>
        <NotificationCategoryRow
          notificationCategory={{
            id: 'new_assignations',
            label: t({
              id: 'profile.tabs.notification.new-assignations',
              message: 'New assignations',
            }),
            description: t({
              id: 'profile.tabs.notification.new-assignations.description',
              message: 'When someone assigns you on a request',
            }),
            selectionState: emailSelectionStateByCategory.new_assignations,
            userId,
          }}
          handleUpdateNotificationSettings={handleUpdateNotificationSettings}
        />
      </Stack>

      <Separator />

      <Stack style={{ paddingTop: '16px' }}>
        <NotificationCategoryRow
          notificationCategory={{
            id: 'new_events',
            label: t({
              id: 'profile.tabs.notification.new-events',
              message: 'New events',
            }),
            description: t({
              id: 'profile.tabs.notification.new-events.description',
              message: 'When a new event occurs',
            }),
            selectionState: emailSelectionStateByCategory.new_events,
            userId,
          }}
          handleUpdateNotificationSettings={handleUpdateNotificationSettings}
        />
      </Stack>
    </>
  );
};

type NotificationCategoryData = {
  id: NotificationCategory | 'all_emails';
  label: string;
  description?: string;
  selectionState: SelectionState;
  userId: string;
};

const NotificationCategoryRow = ({
  notificationCategory,
  handleUpdateNotificationSettings,
}: {
  notificationCategory: NotificationCategoryData;
  handleUpdateNotificationSettings: (
    notificationCategory: NotificationCategory | 'all_emails',
    selectionState: SelectionState
  ) => void;
}) => {
  return (
    <Stack row justifyContent="space-between" alignItems="center" flexWrap="nowrap">
      <Stack padding={'8px 8px 8px 0'}>
        <p className="paragraph-100-medium">{notificationCategory.label}</p>
        <p className={cn('paragraph-200-regular', 'text-secondary')}>
          {notificationCategory.description}
        </p>
      </Stack>
      <NotificationCategoryCheckbox
        notificationCategory={notificationCategory}
        handleUpdateNotificationSettings={handleUpdateNotificationSettings}
      />
    </Stack>
  );
};

export const NotificationCategoryCheckbox = ({
  notificationCategory,
  handleUpdateNotificationSettings,
}: {
  notificationCategory: NotificationCategoryData;
  handleUpdateNotificationSettings: (
    notificationCategory: NotificationCategory | 'all_emails',
    selectionState: SelectionState
  ) => void;
}) => {
  const newSelectionState =
    notificationCategory.selectionState === 'selected' ? 'unselected' : 'selected';

  return (
    <Checkbox
      style={{ marginLeft: '1rem' }}
      isSelected={notificationCategory.selectionState === 'selected'}
      isIndeterminate={notificationCategory.selectionState === 'indeterminate'}
      onChange={() => {
        handleUpdateNotificationSettings(notificationCategory.id, newSelectionState);
      }}
      size="large"
      variant="default"
      slot={null}
    />
  );
};

import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Trans, useLingui } from '@lingui/react/macro';

import { DiscardChangesDialog } from '@/components/DiscardChangesDialog/DiscardChangesDialog';
import {
  PageLayout,
  PageLayoutContent,
  PageLayoutLeftPart,
  PageLayoutLeftPartContent,
  PageLayoutLeftPartFooter,
  PageLayoutSimpleHeader,
} from '@/components/PageLayout';
import AlertBar from '@/design_system/AlertBar';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import { AddressWithRelations } from '@/models/address';
import { RequestWithRelations, useRequest } from '@/models/request';
import {
  ArticleWithRelations,
  Shipment,
  ShipmentCarrierService,
  ShipmentHandover,
  useCreateShipment,
  useShipments,
} from '@/models/shipment';
import ShipmentForm from '@/routes/Shipments/Shipment/components/ShipmentForm';
import { newAddressWithRelations } from '@/routes/Shipments/Shipment/components/ShipmentForm/components/PlaceSelect';
import { useCurrentSession } from '@/services/auth';
import { useShowWarningBeforeLeavingApp, useSimpleBlocker } from '@/utils/navigation';
import { useScrollIntoView } from '@/utils/useScrollIntoView';
import useViewPort from '@/utils/useViewport';

const New = () => {
  const blocker = useSimpleBlocker();
  useShowWarningBeforeLeavingApp();

  return (
    <>
      <NewDrawerWrapper />
      {blocker.state === 'blocked' && (
        <DiscardChangesDialog
          onConfirm={() => blocker.proceed()}
          onCancel={() => blocker.reset()}
        />
      )}
    </>
  );
};

const NewDrawerWrapper = () => {
  const { state } = useLocation();
  const [requestId] = useState(state?.requestId as string);
  const [articleId] = useState(state?.articleId as string);
  const { currentSession } = useCurrentSession();
  const navigate = useNavigate();

  const { data: request, isLoading: isLoadingRequest } = useRequest(requestId);
  const { data: { shipments } = { shipments: [] } } = useShipments({
    requestId,
    steps: ['preparation', 'finalisation', 'handover', 'in-transit', 'verification'],
  });

  const initialArticle = request?.articles.find((article) => article.id === articleId);

  // Ignore store-to-client with pickup & client-to-workshop transits
  const isInitialArticleReadyForTransit =
    initialArticle?.step &&
    initialArticle.step.step === 'transit' &&
    !(
      !!initialArticle.atStoreId &&
      initialArticle.toClient &&
      initialArticle.step.config.clientPickup
    ) &&
    !(!!initialArticle.atClient && initialArticle.toWorkshopId) &&
    (initialArticle.atClient || !!initialArticle.atStoreId || !!initialArticle.atWorkshopId);

  if (!!currentSession && !currentSession.canCreateShipments()) {
    navigate('/shipments', { state: { skipRouterBlocker: true }, replace: true });
    return null;
  }

  if (!requestId) {
    return <NewDrawer />;
  }

  if (isLoadingRequest) {
    return null;
  }

  if (!request || !initialArticle || !isInitialArticleReadyForTransit) {
    navigate('/shipments', { state: { skipRouterBlocker: true }, replace: true });
    return null;
  }

  const { origin, destination } = getArticleOriginDestination(request, initialArticle);

  if (!origin || !destination) {
    navigate('/shipments', { state: { skipRouterBlocker: true }, replace: true });
    return null;
  }

  const articlesReadyForShipmentWithSameOriginDestination = request.articles
    .filter((article) => {
      const isAlreadyInAnotherShipment = shipments.some((shipment) => {
        return shipment.articles.some((shipmentArticle) => {
          return shipmentArticle.articleId === article.id;
        });
      });

      const { origin: artOrigin, destination: artDestination } = getArticleOriginDestination(
        request,
        article
      );

      const isDoingTheSameTransit =
        article.step!.id === initialArticle.step!.id &&
        (article.atClient || !!article.atStoreId || !!article.atWorkshopId) &&
        artOrigin?.id === origin.id &&
        artDestination?.id === destination.id;

      return !isAlreadyInAnotherShipment && isDoingTheSameTransit;
    })
    .map((art) => art.with('request', request).with('organization', request.organization));

  return (
    <NewDrawer
      initialOrigin={origin}
      initialDestination={destination}
      initialArticles={articlesReadyForShipmentWithSameOriginDestination}
    />
  );
};

function getArticleOriginDestination(
  request: RequestWithRelations,
  article: RequestWithRelations['articles'][number]
) {
  const { client, store } = request;
  const { atWorkshop, workshop } = article;

  const originType = article.atClient ? 'client' : article.atStoreId ? 'store' : 'workshop';
  const origin = {
    client: client?.address ? newAddressWithRelations(client.address, client, null, null) : null,
    store: store?.address ? newAddressWithRelations(store.address, null, store, null) : null,
    workshop: atWorkshop?.address
      ? newAddressWithRelations(atWorkshop.address, null, null, atWorkshop)
      : null,
  }[originType];

  const destinationType = article.toClient ? 'client' : article.toStoreId ? 'store' : 'workshop';
  const destination = {
    client: client?.address ? newAddressWithRelations(client.address, client, null, null) : null,
    store: store?.address ? newAddressWithRelations(store.address, null, store, null) : null,
    workshop: workshop?.address
      ? newAddressWithRelations(workshop.address, null, null, workshop)
      : null,
  }[destinationType];

  return { origin, destination };
}

const NewDrawer = ({
  initialOrigin,
  initialDestination,
  initialArticles = [],
}: {
  initialOrigin?: AddressWithRelations;
  initialDestination?: AddressWithRelations;
  initialArticles?: ArticleWithRelations[];
}) => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();
  const navigate = useNavigate();

  const [origin, setOrigin] = useState<AddressWithRelations | undefined>(initialOrigin);
  const [destination, setDestination] = useState<AddressWithRelations | undefined>(
    initialDestination
  );
  const [articles, setArticles] = useState<ArticleWithRelations[]>(initialArticles);
  const [carrierService, setCarrierService] = useState<ShipmentCarrierService>();
  const [handover, setHandover] = useState<ShipmentHandover>();
  const [pickupDate, setPickupDate] = useState<string>();
  const [trackingId, setTrackingId] = useState<string>();
  const [error, setError] = useState<string>();
  const [alertRef, scrollAlertIntoView] = useScrollIntoView<HTMLDivElement>();

  const { mutateAsync: createShipment, isPending } = useCreateShipment();

  const handleError = (error: string) => {
    setError(error);
    scrollAlertIntoView();
  };

  const onSubmit = () => {
    if (!origin) {
      handleError(t({ id: 'shipments.new.error.origin', message: 'Please select an origin' }));
      return;
    }

    if (!destination) {
      handleError(
        t({ id: 'shipments.new.error.destination', message: 'Please select a destination' })
      );
      return;
    }

    if (articles.length === 0) {
      handleError(
        t({ id: 'shipments.new.error.articles', message: 'Please add at least one item' })
      );
      return;
    }

    if (!carrierService) {
      handleError(t({ id: 'shipments.new.error.carrier', message: 'Please select a carrier' }));
      return;
    }

    const isNotPrivateCarrierOrSelfHandled =
      carrierService !== 'private-carrier' && carrierService !== 'self-handled';

    if (!!carrierService && isNotPrivateCarrierOrSelfHandled && !handover) {
      handleError(
        t({
          id: 'shipments.new.error.handover',
          message: 'Please select a handover option',
        })
      );
      return;
    }

    if (handover === 'pickup' && isNotPrivateCarrierOrSelfHandled && !pickupDate) {
      handleError(
        t({
          id: 'shipments.new.error.pickup-date',
          message: 'Please select a pickup date',
        })
      );
      return;
    }

    createShipment({
      origin: origin.client?.id ?? origin.store?.id ?? origin.workshop?.id ?? '',
      destination:
        destination.client?.id ?? destination.store?.id ?? destination.workshop?.id ?? '',
      articles: articles.map((article) => article.id),
      carrierService,
      handover: carrierService === 'private-carrier' ? 'pickup' : handover,
      pickupDate:
        handover === 'pickup' && carrierService !== 'private-carrier' ? pickupDate : undefined,
      trackingId: carrierService === 'private-carrier' ? trackingId : undefined,
    })
      .then((shipment: Shipment) => {
        navigate(`/shipments/${shipment.id}`, {
          state: { skipRouterBlocker: true },
          replace: true,
        });
      })
      .catch((err) => {
        console.error(err);
        setError(
          (err.message as string) ?? t({ id: '_general.error.unknown', message: 'Unknown error' })
        );
      });
  };

  return (
    <PageLayout>
      <PageLayoutSimpleHeader
        onClose={() =>
          navigate('/shipments', { state: { skipRouterBlocker: false }, replace: true })
        }
        title={t({ id: 'shipments.new.title', message: 'Create a new delivery' })}
      />
      <PageLayoutContent>
        <PageLayoutLeftPart>
          <PageLayoutLeftPartContent>
            <Stack padding={isMobile ? '1rem' : undefined} ref={alertRef} gap="1rem">
              {!!error && (
                <AlertBar
                  type="error"
                  title={t({
                    id: 'shipments.new.error',
                    message:
                      'Please fill the missing information in order to be able to create a shipment',
                  })}
                />
              )}
              <ShipmentForm
                {...{
                  origin,
                  setOrigin,
                  destination,
                  setDestination,
                  articles,
                  setArticles,
                  carrierService,
                  setCarrierService,
                  handover,
                  setHandover,
                  pickupDate,
                  setPickupDate,
                  trackingId,
                  setTrackingId,
                  error,
                }}
              />
            </Stack>
          </PageLayoutLeftPartContent>

          <PageLayoutLeftPartFooter>
            <Button variant="primary" onPress={onSubmit} isLoading={isPending}>
              <Trans id="shipments.new.create">Create a shipment</Trans>
            </Button>
          </PageLayoutLeftPartFooter>
        </PageLayoutLeftPart>
      </PageLayoutContent>
    </PageLayout>
  );
};

export default New;

import { PropsWithChildren, useEffect } from 'react';
import { Outlet } from 'react-router';

import config from '@/config';
import Stack from '@/design_system/Stack';
import Header from '@/routes/Brand/components/Header';
import OrganizationLogo from '@/routes/Brand/components/OrganizationLogo';
import { useCurrentOrganization } from '@/services/auth';
import { ErrorBoundary } from '@/services/sentry';
import { createBEMClasses } from '@/utils/classname';

import './Brand.css';

const { block } = createBEMClasses('client-layout');

const BrandLayout = () => {
  const [currentOrganization, , isCurrentOrganizationInvalid] = useCurrentOrganization();

  useEffect(() => {
    if (currentOrganization) {
      document.title = `${currentOrganization.name} - powered by Prolong`; // eslint-disable-line lingui/no-unlocalized-strings
      const favicon = document.querySelector<HTMLLinkElement>('link[rel="icon"]');
      if (favicon) {
        favicon.href = `${config.apiUrl}/organizations/${currentOrganization.slug}/logo?type=square`;
      }
    }
  }, [currentOrganization]);

  return (
    <div className={block()}>
      <ErrorBoundary>
        {currentOrganization ? (
          <Outlet />
        ) : (
          <>
            <BrandWrapper>
              {isCurrentOrganizationInvalid
                ? // eslint-disable-next-line lingui/no-unlocalized-strings
                  'Unknown organization'
                : null}
            </BrandWrapper>
          </>
        )}
      </ErrorBoundary>
    </div>
  );
};

export const BrandWrapper = ({
  children,
  className,
}: PropsWithChildren & { className?: string }) => {
  const [organization] = useCurrentOrganization();

  return (
    <>
      <Header organization={organization}>
        <Stack row justifyContent="center" alignItems="center">
          <OrganizationLogo organization={organization} size="large" />
        </Stack>
      </Header>
      <main className={className}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </main>
    </>
  );
};

const { block: wrapperCenterBlock } = createBEMClasses('brand-layout-center');

export const BrandWrapperCenter = ({
  width = 'large',
  children,
}: PropsWithChildren<{
  width?: 'medium' | 'large';
}>) => {
  return <div className={wrapperCenterBlock({ width })}>{children}</div>;
};

export const BrandWrapperFooter = ({ children }: PropsWithChildren) => {
  return (
    <div className="brand-layout-footer">
      <div>{children}</div>
    </div>
  );
};

export default BrandLayout;

import { Trans, useLingui } from '@lingui/react/macro';

import { ProductL1, ProductL2, ProductL3 } from '@/api';
import ProductSearchSelect from '@/components/ProductSearchSelect';
import config from '@/config';
import PhotoCard from '@/design_system/PhotoCard';
import RadioGroup, { yesNoRadioOptions } from '@/design_system/RadioGroup';
import Stack from '@/design_system/Stack';
import { Product, PRODUCT_CATEGORIES, useProductOptions } from '@/models/product';
import { ClientRequestWithRelations } from '@/models/request';
import { useCurrentOrganization } from '@/services/auth';
import { createBEMClasses } from '@/utils/classname';
import { useScrollIntoView } from '@/utils/useScrollIntoView';
import useViewPort from '@/utils/useViewport';

import './ProductInfo.css';

const { block, element } = createBEMClasses('client-product-info');

const Reference = ({
  hasReference,
  setHasReference,
  request,
  product,
  setProduct,
  productL1,
  setProductL1,
  productL2,
  setProductL2,
  productL3,
  setProductL3,
  disabled,
}: {
  hasReference?: HasReferenceOption;
  setHasReference: (hasReference: HasReferenceOption) => void;
  request: ClientRequestWithRelations;
  product?: Product;
  setProduct: (product?: Product) => void;
  productL1?: ProductL1;
  setProductL1: (productL1?: ProductL1) => void;
  productL2?: ProductL2;
  setProductL2: (productL2?: ProductL2) => void;
  productL3?: ProductL3;
  setProductL3: (productL3?: ProductL3) => void;
  disabled: boolean;
}) => {
  const { t, i18n } = useLingui();
  const { isMobile } = useViewPort();

  const [productL2Ref, scrollToProductL2] = useScrollIntoView<HTMLDivElement>();
  const [productL3Ref, scrollToProductL3] = useScrollIntoView<HTMLDivElement>();

  const { enabledProductL2 } = request.organization;

  const { productL1Options, productL2Options, productL3Options } = useProductOptions(
    request.organization,
    productL1
  );

  const l1Options = productL1Options.map((l1) => ({
    value: l1.id,
    text: l1.text,
    imageUrl: `/product/l1/${l1.id}.png`,
    className: element('category', { type: l1.id }),
  }));

  const l2Options = productL2Options.map((l2) => ({
    value: l2.id,
    text: l2.text,
    imageUrl: `/product/l2/${l2.id}.png`,
    className: element('category', { type: productL1 }),
  }));

  const l3Options = productL3Options.map((l3) => ({
    value: l3.id,
    text: l3.text,
    imageUrl: `/product/l3/${l3.id}.png`,
    className: element('category', { type: 'material' }),
  }));

  return (
    <Stack gap="1.5rem" className={block()}>
      <RadioGroup
        theme="brand"
        label={t({
          id: 'client.new.article.form.has-reference.label',
          message: 'Do you have the item reference?',
        })}
        options={yesNoRadioOptions.map((option) => ({ ...option, text: i18n._(option.text) }))}
        value={hasReference}
        onChange={(value) => {
          setHasReference(value);
          setProduct(undefined);
          setProductL2(undefined);
          setProductL3(undefined);

          if (value === 'no' && l1Options.length === 1) {
            setProductL1(l1Options[0].value);
            scrollToProductL2();
          } else {
            setProductL1(undefined);
          }
        }}
        gap="1rem"
        isDisabled={disabled}
        scrollToOnMount
      />
      {hasReference === 'yes' && (
        <ProductSearchSelect
          value={product}
          onChange={(product) => {
            setProduct(product);
            setProductL1(product?.productL1);
            setProductL2(product?.productL2 ?? undefined);
            setProductL3(product?.productL3 ?? undefined);
          }}
          variant="brand"
          showLabel={false}
          placeholder={t({
            id: 'client.new.article.form.reference.placeholder',
            message: 'Enter your item reference number or the name of the item',
          })}
          isDisabled={disabled}
          focusOnRender
        />
      )}
      {hasReference !== 'no' && <ReferenceHelp />}
      {hasReference === 'no' && (
        <Stack gap="3rem" className={block()}>
          {l1Options.length > 1 && (
            <RadioGroup
              theme="brand"
              label={t({
                id: 'client.new.article.form.productl1.label',
                message: 'Select a type of item',
              })}
              options={l1Options}
              variant="image-column"
              value={productL1 ?? 'none'}
              onChange={(value) => {
                const productL3s = PRODUCT_CATEGORIES.find(
                  (category) => category.id === value
                )?.subCategories;

                setProductL1(value as ProductL1);
                setProductL3(productL3s?.length === 1 ? productL3s[0].id : undefined);

                const l2Options = PRODUCT_CATEGORIES.find(
                  (l1) => l1.id === value
                )?.categories.filter((l2) => enabledProductL2.includes(l2.id));

                if (l2Options?.length === 1) {
                  setProductL2(l2Options[0].id);
                  scrollToProductL3();
                } else {
                  setProductL2(undefined);
                  scrollToProductL2();
                }
              }}
              isDisabled={disabled}
              scrollToOnMount
            />
          )}
          {!!productL1 && l2Options.length > 1 && (
            <RadioGroup
              theme="brand"
              label={t({
                id: 'client.new.article.form.productl2.label',
                message: 'What category of item is it?',
              })}
              options={l2Options}
              variant={isMobile ? 'image-column' : 'image-row'}
              value={productL2 ?? 'none'}
              onChange={(value) => {
                const productL3s = PRODUCT_CATEGORIES.find(
                  (category) => category.id === productL1
                )?.subCategories;

                setProductL2(value as ProductL2);
                setProductL3(productL3s?.length === 1 ? productL3s[0].id : undefined);
                scrollToProductL3();
              }}
              ref={productL2Ref}
              isDisabled={disabled}
              scrollToOnMount
            />
          )}
          {!!productL2 && productL3Options.length > 1 && (
            <RadioGroup
              theme="brand"
              label={
                <>
                  {t({
                    id: 'client.new.article.form.productl3.label',
                    message: 'What kind of material is it made of?',
                  })}{' '}
                  <span className="text-disabled paragraph-100-regular">
                    {t({ id: 'client.new.article.form.optional', message: '(Optional)' })}
                  </span>
                </>
              }
              options={l3Options}
              variant={isMobile ? 'image-column' : 'image-row'}
              value={productL3 ?? 'none'}
              onChange={(value) => setProductL3(value as ProductL3)}
              ref={productL3Ref}
              isDisabled={disabled}
              scrollToOnMount
            />
          )}
        </Stack>
      )}
    </Stack>
  );
};

const ReferenceHelp = () => {
  const [organization] = useCurrentOrganization();
  const { t } = useLingui();

  const referencePhotoAlt = t({
    id: 'client-new.article.form.has-reference.photo-alt',
    message: `Photo of an item's reference`,
  });

  return (
    <Stack
      row
      gap="1rem"
      flexWrap="nowrap"
      alignItems="center"
      className={element('reference-help')}
    >
      <div className={element('reference-help__img')}>
        <PhotoCard
          url={`${config.appUrl}/brands/reference/${
            import.meta.env.VITE_DEFAULT_ORGANIZATION_LOGO ? 'prolong-acme' : organization?.slug
          }.webp`}
          name={referencePhotoAlt}
          alt={referencePhotoAlt}
        ></PhotoCard>
      </div>
      <Stack gap="0.5rem">
        <p className="paragraph-50-medium is-hidden-mobile">
          <Trans id="client-new.article.form.has-reference.help-title">Where can I find it?</Trans>
        </p>
        <p className="paragraph-50-regular paragraph-100-regular-mobile">
          <Trans id="client-new.article.form.has-reference.help">
            The item reference is located on the item&apos;s care label and consists of five digits,
            e.g., 12345.
          </Trans>
        </p>
      </Stack>
    </Stack>
  );
};

export type HasReferenceOption = (typeof yesNoRadioOptions)[number]['value'];

export default Reference;

import { Trans } from '@lingui/react/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconItemDelete from '@/icons/ItemDelete.svg';

export const ArticleRemovedFromShipmentActivity = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityOfType<'article_removed_from_shipment'>;
  displayArticleName: boolean;
}) => {
  return (
    <BaseActivity
      key={activity.id}
      createdAt={activity.createdAt}
      icon={<IconItemDelete />}
      type="danger"
      creatorName={activity.creator.name}
      message={
        <BaseActivityText>
          <Trans id="activities.article_removed_from_shipment.message">
            removed the item{' '}
            <BaseActivityArticleName activity={activity} displayArticleName={displayArticleName} />
            from the shipment
          </Trans>
        </BaseActivityText>
      }
    />
  );
};

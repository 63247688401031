import { ReactNode } from 'react';
import { Button as AriaButton } from 'react-aria-components';
import { NavLink } from 'react-router';
import { Trans, useLingui } from '@lingui/react/macro';

import Avatar from '@/design_system/Avatar';
import Logo from '@/design_system/Logo';
import Menu from '@/design_system/Menu';
import Stack from '@/design_system/Stack';
import Tooltip from '@/design_system/Tooltip';
import IconChevron from '@/icons/Chevron.svg';
import { createBEMClasses } from '@/utils/classname';
import useViewPort from '@/utils/useViewport';

import './SideNavigation.css';

const { block, element } = createBEMClasses('side-navigation');

interface SideNavigationProps {
  logo?: ReactNode;
  title?: string;
  poweredBy: boolean;
  collapsed: boolean;
  className?: string;
  children: React.ReactNode;
}

export const SideNavigation = ({
  logo,
  title,
  poweredBy,
  collapsed,
  className,
  children,
}: SideNavigationProps) => {
  const { t } = useLingui();

  return (
    <nav className={block({ collapsed }, className)}>
      <div className={element('header', undefined, 'is-hidden-mobile')}>
        <div className={element('header__title')}>
          {logo ?? <Logo size="large" className="is-hidden-up-to-desktop is-hidden-widescreen" />}
          <span className="is-hidden-up-to-desktop">{title}</span>
        </div>
      </div>

      <div className={element('menu')}>{children}</div>

      {poweredBy && (
        <div
          className={element('footer', undefined, 'is-hidden-mobile')}
          title={t({ id: 'navbar.footer.powered-by-prolong', message: 'Powered by Prolong' })}
        >
          <span className="is-hidden-up-to-desktop">
            <Trans id="navbar.footer.powered-by">powered by</Trans>
          </span>
          <Logo size="large" className="is-hidden-up-to-desktop" />
          <Logo size="small" className="is-hidden-widescreen" />
        </div>
      )}
    </nav>
  );
};

interface SideNavigationMenuItemProps {
  to?: string;
  onPress?: () => void;
  icon?: React.ReactNode;
  label: string;
  iconRight?: React.ReactNode;
  avatarUrl?: string | null;
  onMenuAction?: (id: string) => void;
  className?: string;
  children?: React.ReactNode;
}

export const SideNavigationMenuItem = ({
  to,
  onPress,
  icon,
  label,
  iconRight,
  onMenuAction,
  className,
  children,
}: SideNavigationMenuItemProps) => {
  let content = (
    <>
      {icon && (
        <span className={element('menu__item__icon')} aria-label={label}>
          {icon}
        </span>
      )}
      <span
        aria-hidden="true"
        className={element('menu__item__label', undefined, 'is-hidden-tablet is-hidden-desktop')}
      >
        {label}
      </span>
      {iconRight && (
        <span className={element('menu__item__icon-right', undefined)}>{iconRight}</span>
      )}
    </>
  );

  if (onPress || onMenuAction) {
    content = (
      <AriaButton onPress={onPress} className={element('menu__item', {}, className)}>
        {content}
      </AriaButton>
    );
  }

  if (onMenuAction) {
    return (
      <Menu onAction={onMenuAction} placement="right" trigger={content}>
        {children}
      </Menu>
    );
  }

  if (to) {
    content = (
      <NavLink to={to} className={element('menu__item', className)}>
        {content}
      </NavLink>
    );
  }

  return (
    <Tooltip
      content={label}
      placement="right"
      className="is-hidden-mobile is-hidden-widescreen"
      hideOnTouchDevice
    >
      {content}
    </Tooltip>
  );
};

export const SideNavigationSeparator = () => <div className={element('separator')} />;

export const SideNavigationProfileMenuItem = ({
  label,
  avatarUrl,
  onMenuAction,
  children,
}: {
  label: string;
  avatarUrl?: string | null;
  onMenuAction: (id: string) => void;
  children: React.ReactNode;
}) => {
  const { isWidescreen } = useViewPort();

  const content = (
    <AriaButton className={element('menu__item', { avatar: true })}>
      <Stack className={element('menu__item__user')}>
        <Avatar
          size={isWidescreen ? 'xx-small' : 'small'}
          url={avatarUrl}
          name={label}
          ariaLabel={label}
        />
        <span
          style={{ marginLeft: '0.5rem' }}
          aria-hidden="true"
          className={element(
            'menu__item__label',
            undefined,
            'is-hidden-tablet is-hidden-desktop text-ellipsis'
          )}
          title={label}
        >
          {label}
        </span>
      </Stack>
      <span className={element('menu__item__icon-right', undefined, 'is-hidden-up-to-desktop')}>
        <IconChevron right />
      </span>
    </AriaButton>
  );

  return (
    <Menu onAction={onMenuAction} placement="right" trigger={content}>
      {children}
    </Menu>
  );
};

import { useState } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';

import AlertBar from '@/design_system/AlertBar';
import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import Stack from '@/design_system/Stack';
import TextArea from '@/design_system/TextArea';
import { useArticleName } from '@/models/article';
import { ShipmentWithRelations, useResolveShipmentIssue } from '@/models/shipment';

const ResolveShipmentIssueDialog = ({
  shipmentArticle,
  isOpen,
  setIsOpen,
  onActionDone,
}: {
  shipmentArticle: ShipmentWithRelations['articles'][number];
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onActionDone: () => void;
}) => {
  const { t } = useLingui();

  return (
    <Dialog
      title={t({ id: 'resolve-issue-modal.title', message: 'Resolve shipment issue' })}
      isOpen={isOpen}
      onOpenChange={setIsOpen}
    >
      <ResolveShipmentIssueForm
        shipmentArticle={shipmentArticle}
        close={() => setIsOpen(false)}
        onActionDone={onActionDone}
      />
    </Dialog>
  );
};

const ResolveShipmentIssueForm = ({
  shipmentArticle,
  close,
  onActionDone,
}: {
  shipmentArticle: ShipmentWithRelations['articles'][number];
  close: () => void;
  onActionDone: () => void;
}) => {
  const { t } = useLingui();

  const [issueResolution, setIssueResolution] = useState('');
  const [showErrors, setShowErrors] = useState(false);

  const articleName = useArticleName({ article: shipmentArticle.article });

  const {
    mutateAsync: resolveShipmentIssue,
    isPending,
    isSuccess,
  } = useResolveShipmentIssue({
    shipmentId: shipmentArticle.shipmentId,
    articleId: shipmentArticle.articleId,
  });

  const save = async () => {
    setShowErrors(true);

    if (!issueResolution) {
      return;
    }

    await resolveShipmentIssue({ issueResolution });

    close();
    onActionDone();
  };

  return (
    <>
      <main>
        <Stack gap="1rem">
          <Stack gap="0.5rem">
            <p className="paragraph-100-regular text-secondary">
              <Trans id="resolve-issue-modal.intro">
                You are about to resolve the shipment issue reported for the item{' '}
                <b>{articleName}</b>. Please provide context on how the issue has been handled.
              </Trans>
            </p>
            <AlertBar
              type="warning"
              size="large"
              title={
                <p className="paragraph-100-regular">
                  <Trans id="resolve-issue-modal.intro.warning">
                    Be sure to resolve the issue only if the item has been successfully delivered to
                    the destination.
                  </Trans>
                </p>
              }
            />
          </Stack>
          <TextArea
            ariaLabel={t({
              id: 'resolve-issue-modal.label',
              message: 'Shipment issue resolution',
            })}
            placeholder={t({
              id: 'resolve-issue-modal.placeholder',
              message: 'Describe how the issue has been handled...',
            })}
            value={issueResolution}
            onChange={(e) => setIssueResolution(e.target.value)}
            error={
              showErrors && !issueResolution
                ? t({
                    id: 'resolve-issue-modal.error',
                    message: 'Please describe how the issue has been handled',
                  })
                : undefined
            }
            rows={4}
          />
        </Stack>
      </main>
      <footer>
        <Button variant="secondary" size="medium" onPress={close}>
          <Trans id="resolve-issue-modal.actions.cancel">Cancel</Trans>
        </Button>
        <Button variant="primary" size="medium" onPress={save} isLoading={isPending || isSuccess}>
          <Trans id="resolve-issue-modal.actions.report">Resolve shipment issue</Trans>
        </Button>
      </footer>
    </>
  );
};

export default ResolveShipmentIssueDialog;

import { Trans, useLingui } from '@lingui/react/macro';

import IconPackageIssue from '@/icons/PackageIssue.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import { useGetArticleName } from '@/models/article';
import type { TNotificationOfType } from '@/models/notification';

export const MissingArticleCustomsNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'missing_article_customs'>;
}) => {
  const { t } = useLingui();

  const getArticleName = useGetArticleName();

  return (
    <BaseNotification
      icon={<IconPackageIssue />}
      type="warning"
      notification={notification}
      notificationLabel={
        notification.article
          ? t({
              id: 'notification.missing-article-customs.single.label',
              message: `Missing customs information for an item in request ${notification.request.reference}`,
            })
          : t({
              id: 'notification.missing-article-customs.multiple.label',
              message: `Missing customs information for some items in request ${notification.request.reference}`,
            })
      }
      notificationHeader={
        notification.article ? (
          <Trans id="notification.missing-article-customs.single">
            The{' '}
            <span className="paragraph-100-medium">
              {getArticleName({ article: notification.article })}
            </span>{' '}
            item requires customs information
          </Trans>
        ) : (
          <Trans id="notification.missing-article-customs.multiple">
            Some items require customs information
          </Trans>
        )
      }
    />
  );
};

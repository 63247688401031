import { Trans, useLingui } from '@lingui/react/macro';

import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import { type TNotificationOfType } from '@/models/notification';

import { Comment } from './components/Comment';

export const CommentAddedNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'comment_added'>;
}) => {
  const { t } = useLingui();

  const { comment, request, shipment } = notification;
  const entityReference = request?.reference ?? shipment?.reference;

  const notificationLabel = request
    ? t({
        id: 'notification.comment-added.label.request',
        message: `New comment on request ${entityReference}`,
      })
    : shipment
      ? t({
          id: 'notification.comment-added.label.shipment',
          message: `New comment on shipment ${entityReference}`,
        })
      : t({
          id: 'notification.comment-added.label.generic',
          message: `New comment`,
        });

  return (
    <BaseNotification
      user={comment.creator}
      notification={notification}
      notificationLabel={notificationLabel}
      notificationHeader={
        <Trans id="notification.comment-added">
          <span className="paragraph-100-medium">{comment.creator.name}</span> added a new comment
        </Trans>
      }
      notificationContent={<Comment comment={comment.content} />}
    />
  );
};

import { msg } from '@lingui/core/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import { BaseActivity } from '@/components/activities/BaseActivity/BaseActivity';
import IconPackage from '@/icons/Package.svg';

export const ShipmentPreparationCompletedActivity = ({
  activity,
}: {
  activity: ActivityOfType<'shipment_preparation_completed'>;
}) => {
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="primary"
      icon={<IconPackage />}
      creatorName={activity.creator?.name ?? ''}
      message={
        activity.creator
          ? msg({
              id: 'activities.shipment_preparation_completed.message',
              message: 'validated the package preparation',
            })
          : msg({
              id: 'activities.shipment_preparation_completed.message.no-creator',
              message: 'The package preparation was validated',
            })
      }
    />
  );
};

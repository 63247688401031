import { Trans } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import { ShipmentWithRelations, useCompleteInTransit } from '@/models/shipment';
import useViewPort from '@/utils/useViewport';

const InTransitAction = ({
  shipment,
  onTransitDone,
}: {
  shipment: ShipmentWithRelations;
  onTransitDone: () => void;
}) => {
  const { isMobile } = useViewPort();
  const { mutateAsync: completeInTransit, isPending, isSuccess } = useCompleteInTransit();

  return (
    <Stack alignItems={isMobile ? 'stretch' : 'flex-end'}>
      <Button
        variant="primary"
        onPress={async () => {
          await completeInTransit(shipment.id);
          onTransitDone();
        }}
        isLoading={isPending || isSuccess}
      >
        {shipment.carrierService === 'self-handled' &&
        shipment.destinationWorkshop?.nonDigitalized ? (
          <Trans id="shipment.mark-as-delivered">Mark as delivered</Trans>
        ) : (
          <Trans id="shipment.mark-as-received">Mark as received</Trans>
        )}
      </Button>
    </Stack>
  );
};

export default InTransitAction;

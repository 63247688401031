import { Trans } from '@lingui/react/macro';

import Drawer from '@/design_system/Drawer';
import { DrawerBody, DrawerFooter } from '@/design_system/Drawer/Drawer';
import Stack from '@/design_system/Stack';
import { ClientRequestWithRelations } from '@/models/request';
import CartActions from '@/routes/Brand/Requests/New/components/CartActions';
import CartContent from '@/routes/Brand/Requests/New/components/CartContent';

const Cart = ({
  isOpen,
  onOpenChange,
  request,
  onAddArticle,
  onAddDefectsToArticle,
  onEditDefectsOnArticle,
  onEditArticle,
  onDeleteAllArticles,
  onDeleteArticle,
  onValidateRequest,
}: {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  request: ClientRequestWithRelations;
  onAddArticle: () => void;
  onAddDefectsToArticle: (articleIndex: number) => void;
  onEditDefectsOnArticle: (data: { defectIds: string[]; articleIndex: number }) => void;
  onEditArticle: (index: number) => void;
  onDeleteArticle: (index: number) => void;
  onDeleteAllArticles: () => void;
  onValidateRequest: () => void;
}) => {
  const isCartEmpty = request.articles.length === 0;

  return (
    <Drawer
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      style={{ width: 'calc(100% - 32px)', maxWidth: '37.5rem' }}
    >
      <DrawerBody
        style={{
          flex: 1,
          padding: '24px 16px',
        }}
      >
        {isCartEmpty ? (
          <Stack gap="1rem">
            <h3 className="headline-300-bold paragraph-50-medium-mobile">
              <Trans id="client.new.cart.title">Cart</Trans>
            </h3>
            <p className="paragraph-100-regular">
              <Trans id="client.new.cart.empty">No items added yet</Trans>
            </p>
          </Stack>
        ) : (
          <CartContent
            request={request}
            onAddDefectsToArticle={onAddDefectsToArticle}
            onEditDefectsOnArticle={onEditDefectsOnArticle}
            onEditArticle={onEditArticle}
            onDeleteArticle={onDeleteArticle}
            onDeleteAllArticles={onDeleteAllArticles}
            collapseHeader
          />
        )}
      </DrawerBody>
      {!isCartEmpty && (
        <DrawerFooter>
          <div style={{ flex: 1 }}>
            <CartActions onAddArticle={onAddArticle} onValidateRequest={onValidateRequest} />
          </div>
        </DrawerFooter>
      )}
    </Drawer>
  );
};

export default Cart;

import { Trans } from '@lingui/react/macro';

import Stack from '@/design_system/Stack';
import { ArticleWithRelations } from '@/models/request';

import { BaseMessage } from './BaseMessage';
import { GoToShipment } from './GoToShipment';

export const VerifyShipmentTaskMessage = ({ article }: { article: ArticleWithRelations }) => {
  return (
    <BaseMessage
      article={article}
      title={<Trans id="article.task.verify-shipment.title">Verify the delivery</Trans>}
      message={
        <Stack gap="0.5rem" alignItems="flex-start">
          <p>
            <Trans id="article.task.verify-shipment.text">
              Go to the shipment page to verify the contents of the package.
            </Trans>
          </p>
          <GoToShipment article={article} />
        </Stack>
      }
    />
  );
};

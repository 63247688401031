import { Trans } from '@lingui/react/macro';

import AlertBar from '@/design_system/AlertBar';
import Stack from '@/design_system/Stack';
import IconPayment from '@/icons/Payment.svg';
import IconReceipt from '@/icons/Receipt.svg';
import { useClientToken } from '@/services/auth';

const EstimateDisclaimer = () => {
  const clientToken = useClientToken();

  return (
    <AlertBar
      type="warning"
      size="large"
      hideIcon
      title={
        <Stack gap="0.5rem">
          <Stack row gap="0.5rem" flexWrap="nowrap">
            <IconReceipt className="color-warning-700" style={{ fontSize: '1.5rem' }} />
            <p className="paragraph-100-regular">
              {!clientToken ? (
                <Trans id="client.new.cart.disclaimer.client-via-store.1">
                  We will provide the client with an estimate shortly.
                </Trans>
              ) : (
                <Trans id="client.new.cart.disclaimer.1">
                  We will provide you with an estimate shortly.
                </Trans>
              )}
            </p>
          </Stack>
          <Stack row gap="0.5rem" flexWrap="nowrap">
            <IconPayment className="color-warning-700" style={{ fontSize: '1.5rem' }} />
            <p className="paragraph-100-medium">
              {!clientToken ? (
                <Trans id="client.new.cart.disclaimer.client-via-store.2">
                  If a payment is required, it will only be requested once the client accepts the
                  quote.
                </Trans>
              ) : (
                <Trans id="client.new.cart.disclaimer.2">
                  If a payment is required, it will only be requested once you accept the quote.
                </Trans>
              )}
            </p>
          </Stack>
        </Stack>
      }
    />
  );
};

export default EstimateDisclaimer;
